import { Box, Button, Divider, Typography } from '@mui/material'
import { AddEditCalendarEntries } from '../add-edit-calendar-entries'
import AddEditCalendarEntry from '../add-edit-calendar-entry/add-edit-calendar-entry'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import PersonIdentificationComponent from '../../person-identification-component'

/* eslint-disable-next-line */
export interface AddEditCalendarEntryComponentProps {
  multipleDays: boolean
  contractId: string
  start: Date
  end: Date
}

export function AddEditCalendarEntryComponent({
  multipleDays,
  contractId,
  start,
  end
}: AddEditCalendarEntryComponentProps) {
  const { t } = useTranslation()

  const [addEntries, setAddEntries] = useState(!multipleDays)

  return (
    <>
      <PersonIdentificationComponent title={t('flyin.calendarentries.title')} />
      <Divider sx={{ marginBottom: 2 }} />

      {!addEntries && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="h4" color={'gray'}>
              {start?.toLocaleDateString() === end?.toLocaleDateString()
                ? start?.toLocaleDateString()
                : start?.toLocaleDateString() + ' - ' + end?.toLocaleDateString()}
            </Typography>
            <Button variant="outlined" size="small" onClick={() => setAddEntries(true)}>
              {t('flyin.calendarentries.addcalendarentries')}
            </Button>
          </Box>
          <AddEditCalendarEntries contractId={contractId} end={end} start={start} />
        </>
      )}

      {addEntries && (
        <>
          <Typography variant="h6" color={'gray'}>
            {t('flyin.calendarentry.date')}:{' '}
            {start?.toLocaleDateString() === end?.toLocaleDateString()
              ? start?.toLocaleDateString()
              : start?.toLocaleDateString() + ' - ' + end?.toLocaleDateString()}
          </Typography>
          <AddEditCalendarEntry contractId={contractId} end={end} start={start} />
        </>
      )}
    </>
  )
}

export default AddEditCalendarEntryComponent

import { useTranslation } from 'react-i18next'
import { useFlyIn } from '@epix-web-apps/ui'
import { TypeOf, boolean, object, string } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormContainer } from '@epix-web-apps/ui'
import { Typography } from '@mui/material'
import { FormGridLayout } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import {
  OrderDirection,
  useCreateExternalCodeTypeMutation,
  useGetExternalCodeByKeyQuery,
  useGetPagedExternalCodesQuery,
  useUpdateExternalCodeTypeMutation
} from '@epix-web-apps/core'
import { FormSwitch } from '../../form-components/form-switch'

/* eslint-disable-next-line */
export interface AddEditExternalCodeProps {
  externalCodeKey?: string
}

export function AddEditExternalCode({ externalCodeKey }: AddEditExternalCodeProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const addEditExternalCodeSchema = object({
    key: string({
      required_error: t('form.validation.keyrequired'),
      invalid_type_error: t('form.validation.keyrequired')
    })
      .min(1, t('form.validation.keyrequired'))
      .refine(
        data => {
          if (externalCodeKey) return true
          return !existingExternalCodes?.pagedExternalCodes?.data?.some(e => e.key === data)
        },
        {
          message: t('form.validation.externalcodealreadyexists')
        }
      ),
    value: string({
      required_error: t('form.validation.valuerequired'),
      invalid_type_error: t('form.validation.valuerequired')
    }).min(1, t('form.validation.valuerequired')),
    active: boolean()
  })

  type AddEditExternalCodeForm = TypeOf<typeof addEditExternalCodeSchema>

  const { data: existingExternalCodes } = useGetPagedExternalCodesQuery({
    offset: 0,
    limit: -1,
    sortByProperty: 'Key',
    orderDirection: OrderDirection.Desc
  })

  const { data: getExternalCodeByKey } = useGetExternalCodeByKeyQuery(
    {
      key: externalCodeKey ?? ''
    },
    {
      enabled: !!externalCodeKey
    }
  )

  const form = useForm<AddEditExternalCodeForm>({
    resolver: zodResolver(addEditExternalCodeSchema),
    defaultValues: {
      key: getExternalCodeByKey?.externalCodeByKey?.key,
      value: getExternalCodeByKey?.externalCodeByKey?.value,
      active: getExternalCodeByKey?.externalCodeByKey?.active ?? true
    }
  })

  const createMutation = useCreateExternalCodeTypeMutation()
  const updateMutation = useUpdateExternalCodeTypeMutation()

  const handleOnSubmit = async (newExternalCode: AddEditExternalCodeForm) => {
    if (!externalCodeKey) {
      await createMutation
        .mutateAsync({
          createExternalCodeTypeCommand: {
            key: newExternalCode.key,
            value: newExternalCode.value,
            active: newExternalCode.active
          }
        })
        .then(closeFlyIn)
    } else {
      await updateMutation
        .mutateAsync({
          updateExternalCodeTypeCommand: {
            key: newExternalCode.key,
            value: newExternalCode.value,
            active: newExternalCode.active
          }
        })
        .then(closeFlyIn)
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditexternalcode.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="key" disabled={!!externalCodeKey} label={`${t('form.field.key')}`} />

        <FormInput sx={12} name="value" label={`${t('form.field.value')}`} />

        <FormSwitch sx={12} name="active" label={t('form.field.active')} />
      </FormGridLayout>

      <FormErrorList />

      <FormActionButtons
        isMutating={createMutation.isLoading || updateMutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditExternalCode

export interface IFormSelectOption {
  id: string
  label: string
  active?: boolean
}

export class FormSelectOption implements IFormSelectOption {
  id: string
  label: string
  active?: boolean

  constructor(id: string, label: string, active?: boolean) {
    this.id = id
    this.label = label
    this.active = active ?? true
  }
}

export function typeToFormSelectOption(data: { key: string; value: string; active?: boolean }[]) {
  return data.map(d => new FormSelectOption(d.key, d.value, d.active))
}

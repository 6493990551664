import { Link, Typography, Box, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

/* eslint-disable-next-line */
export interface EmployersWidgetTitleProps {
  title: string
  icon?: any
  url: string
  hasData: boolean
  disabled?: boolean
}

export function EmployersWidgetTitle({ title, icon, url, hasData, disabled = false }: EmployersWidgetTitleProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          my: '0.5rem',
          color: disabled ? theme.palette.text.secondary : ''
        }}
        variant="h5"
      >
        {icon}
        {title}
      </Typography>
      {hasData && (
        <Link onClick={() => navigate(url)}>
          <Typography>{t('employerspage.widget.gotolist')}</Typography>
        </Link>
      )}
    </Box>
  )
}

export default EmployersWidgetTitle

import { useGetContractAndFteTotalsForPreviousAndCurrentPeriodForProcessQueryQuery } from '@epix-web-apps/core'
import { Box, Button } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { StepParams } from '../../generic-steps'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { FinalCheckSalaryComponent } from '../final-check-salary-component'
import { FinalCheckCalendarOverview } from '../final-check-calendar-overview'

enum Tabs {
  SalaryComponents,
  CalendarOverview
}

export function FinalCheck() {
  const { t } = useTranslation()
  const params = useParams<StepParams>()
  const [currentTab, setCurrentTab] = useState(Tabs.SalaryComponents)
  const { data: results } = useGetContractAndFteTotalsForPreviousAndCurrentPeriodForProcessQueryQuery({
    payrollClosureProcessId: params.id ?? ''
  })

  return (
    <>
      <Box>
        <Trans
          i18nKey="processpage.finalcheck.previousperiodcontracts"
          values={{
            contracts: results?.contractAndFteTotalsForPreviousAndCurrentPeriodForProcessQuery.previousPeriodContracts,
            fte: results?.contractAndFteTotalsForPreviousAndCurrentPeriodForProcessQuery.previousPeriodFte
          }}
        />
      </Box>
      <Box>
        <Trans
          i18nKey="processpage.finalcheck.currentperiodcontracts"
          values={{
            contracts: results?.contractAndFteTotalsForPreviousAndCurrentPeriodForProcessQuery.currentPeriodContracts,
            fte: results?.contractAndFteTotalsForPreviousAndCurrentPeriodForProcessQuery.currentPeriodFte
          }}
        />
      </Box>
      <Box>
        <Box sx={{ display: 'flex', gap: 1, marginBottom: 1, marginTop: 1 }}>
          <Button
            variant={currentTab === Tabs.SalaryComponents ? 'contained' : 'outlined'}
            onClick={e => {
              setCurrentTab(Tabs.SalaryComponents)
            }}
          >
            {t('processpage.finalcheck.salarycomponents')}
          </Button>
          <Button
            variant={currentTab === Tabs.CalendarOverview ? 'contained' : 'outlined'}
            onClick={e => {
              setCurrentTab(Tabs.CalendarOverview)
            }}
          >
            {t('processpage.finalcheck.calendaroverview')}
          </Button>
        </Box>
      </Box>

      {currentTab === Tabs.SalaryComponents && <FinalCheckSalaryComponent />}
      {currentTab === Tabs.CalendarOverview && <FinalCheckCalendarOverview />}
    </>
  )
}

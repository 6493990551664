import { Grid, Typography, useTheme } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { boolean, date, object, string, TypeOf } from 'zod'
import { FormGridLayout, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormInput,
  FormRadioGroup,
  FormRadioOption,
  FormSelect,
  useFlyIn,
  useGlobalStore
} from '@epix-web-apps/ui'
import {
  OrderDirection,
  useGetAllEmployersByIcpIdQuery,
  useGetEmployerByIdQuery,
  useCreatePayrollClosureProcessMutation,
  useGetPayrollClosureProcessParametersQuery,
  PROCESS_PAYROLLCLOSURE_PARAMETER,
  FormSelectOption,
  formTypeSelectOptions,
  PAYPERIODTYPES,
  getFirstDayOfMonth,
  IsSameDate,
  IsDateRecurrenceRelatedToStartDate,
  PayGroupPayPeriodModel,
  getLastDayOfMonth
} from '@epix-web-apps/core'
import { useEffect, useState } from 'react'
import { FormSwitch } from '../../../form-components/form-switch'
import moment from 'moment'

/* eslint-disable-next-line */
export interface AddPayrollClosureProps {}

export const isValidPayPeriodDate = (payGroup: PayGroupPayPeriodModel | null, date: Date): boolean => {
  switch (payGroup?.payPeriodType?.key) {
    case PAYPERIODTYPES.MONTHLY: {
      const firstDayOfMonth = getFirstDayOfMonth(date)
      return !IsSameDate(date, firstDayOfMonth)
    }
    case PAYPERIODTYPES.BI_MONTHLY: {
      const firstDayOfMonth = getFirstDayOfMonth(date)
      const sixteenthDayOfMonth = new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth(), 16)
      return !(IsSameDate(date, firstDayOfMonth) || IsSameDate(date, sixteenthDayOfMonth))
    }
    case PAYPERIODTYPES.WEEKLY: {
      if (payGroup === null) {
        return false
      }

      return !IsDateRecurrenceRelatedToStartDate(date, payGroup!.firstClosure, payGroup!.payPeriodNumberOfWeeks!)
    }
    default:
      return true
  }
}

export const isValidPayPeriodEndDate = (payGroup: PayGroupPayPeriodModel | null, date: Date): boolean => {
  const firstClosure = moment(payGroup?.firstClosure).add(-1, 'days').toDate()

  switch (payGroup?.payPeriodType?.key) {
    case PAYPERIODTYPES.MONTHLY: {
      const firstDayOfMonth = getLastDayOfMonth(date)
      return !IsSameDate(date, firstDayOfMonth)
    }
    case PAYPERIODTYPES.BI_MONTHLY: {
      const firstDayOfMonth = getLastDayOfMonth(date)
      const sixteenthDayOfMonth = new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth(), 15)
      return !(IsSameDate(date, firstDayOfMonth) || IsSameDate(date, sixteenthDayOfMonth))
    }
    case PAYPERIODTYPES.WEEKLY: {
      if (payGroup === null) {
        return false
      } else {
        return !IsDateRecurrenceRelatedToStartDate(date, firstClosure, payGroup!.payPeriodNumberOfWeeks!)
      }
    }
    default:
      return true
  }
}

export function AddPayrollClosure(props: AddPayrollClosureProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const createPayrollClosureSchema = object({
    payrollProviderId: string({
      required_error: t('form.validation.contractproviderrequired'),
      invalid_type_error: t('form.validation.contractproviderrequired')
    }).min(1, t('form.validation.contractproviderrequired')),
    dueDate: date({
      required_error: t('form.validation.duedaterequired'),
      invalid_type_error: t('form.validation.duedaterequired')
    }),
    employerId: string({
      required_error: t('form.validation.employerrequired'),
      invalid_type_error: t('form.validation.employerrequired')
    }).min(1, t('form.validation.employerrequired')),
    payGroupId: string({
      required_error: t('form.validation.paygrouprequired'),
      invalid_type_error: t('form.validation.paygrouprequired')
    }).min(1, t('form.validation.paygrouprequired')),
    processName: string({
      required_error: t('form.validation.processnamerequired'),
      invalid_type_error: t('form.validation.processnamerequired')
    }).min(1, t('form.validation.processnamerequired')),
    notes: string().optional().nullable(),
    parameterTypeKey: string({
      required_error: t('form.validation.parametertyperequired'),
      invalid_type_error: t('form.validation.parametertyperequired')
    }),
    periodStartDate: date().optional().nullable(),
    isTestRun: boolean()
  }).refine(
    data =>
      data.parameterTypeKey === PROCESS_PAYROLLCLOSURE_PARAMETER.ORIGINAL_CORRECTIONS_PERSONCONTRACT
        ? data.periodStartDate
        : true,
    {
      message: t('form.validation.periodrequired'),
      path: ['period']
    }
  )
  type CreatePayrollClosureForm = TypeOf<typeof createPayrollClosureSchema>

  const { closeFlyIn } = useFlyIn()
  const { me } = useGlobalStore()
  const { icpOptions } = formTypeSelectOptions
  const { icpFilters } = useGlobalPersistedStore()
  const [employerName, setEmployerName] = useState('')
  const [payGroup, setPayGroup] = useState<PayGroupPayPeriodModel | null>()
  const [period, setPeriod] = useState('')
  const mutation = useCreatePayrollClosureProcessMutation()
  const form = useForm<CreatePayrollClosureForm>({
    resolver: zodResolver(createPayrollClosureSchema),
    defaultValues: {
      parameterTypeKey: PROCESS_PAYROLLCLOSURE_PARAMETER.ORIGINAL_CORRECTIONS_PERSONCONTRACT
    }
  })
  const { control } = form

  const filteredIcpOptions = icpFilters.length > 0 ? icpOptions.filter(icp => icpFilters.includes(icp.id)) : icpOptions

  useEffect(() => {
    if (employerName || payGroup || period) {
      form.resetField('processName', {
        defaultValue: `${employerName} ${payGroup && ` - ${payGroup.name}`} ${period && ` - ${period}`}`
      })
    } else {
      form.resetField('processName', { defaultValue: '' })
    }
  }, [employerName, payGroup, period])

  const watchedPayrollProviderId = useWatch({
    control,
    name: `payrollProviderId`
  })
  const watchedEmployerId = useWatch({ control, name: `employerId` })
  const watchedparameterTypeKey = useWatch({
    control,
    name: `parameterTypeKey`
  })

  const { data: getPayrollClosureParameters } = useGetPayrollClosureProcessParametersQuery()
  const { data: getAllEmployersByIcpId } = useGetAllEmployersByIcpIdQuery(
    {
      icpId: watchedPayrollProviderId || '',
      limit: -1,
      offset: 0,
      orderDirection: OrderDirection.Asc,
      sortByProperty: 'companyName',
      onlyCalculatedByEpix: true
    },
    {
      enabled: !!watchedPayrollProviderId,
      suspense: false
    }
  )
  const { data: getEmployerById } = useGetEmployerByIdQuery(
    {
      employerId: watchedEmployerId || ''
    },
    {
      enabled: !!watchedEmployerId,
      suspense: false
    }
  )

  const handleOnSubmit = async (payrollClosure: CreatePayrollClosureForm) => {
    await mutation
      .mutateAsync({
        createPayrollClosureProcessCommand: {
          payrollProviderId: payrollClosure.payrollProviderId,
          employerId: payrollClosure.employerId,
          payGroupId: payrollClosure.payGroupId,
          parameterTypeKey: payrollClosure.parameterTypeKey || '',
          startDate: payrollClosure.periodStartDate,
          dueDate: payrollClosure.dueDate,
          name: payrollClosure.processName,
          notes: payrollClosure.notes,
          isTestRun: payrollClosure.isTestRun
        }
      })
      .then(() => {
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addpayrollclosureprocess.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="payrollProviderId"
          label={`${t('form.field.payrollprovider')} *`}
          options={filteredIcpOptions}
        />

        <FormSelect
          sx={12}
          name="employerId"
          label={`${t('form.field.employer')} *`}
          options={getAllEmployersByIcpId?.employersByIcpId?.data.map(
            employer => new FormSelectOption(employer.id, `${employer.companyName} (${employer.number})`)
          )}
          onChange={(e, selectedEmployer) => {
            setEmployerName(selectedEmployer?.label || '')
            setPayGroup(null)
            form.resetField('payGroupId', { defaultValue: null })
          }}
        />

        <FormSelect
          sx={12}
          name="payGroupId"
          label={`${t('form.field.paygroup')} *`}
          options={getEmployerById?.employerById?.payGroups.map(
            x => new FormSelectOption(x.id, `${x.name} (${x.code})`)
          )}
          onChange={(e, selectedPayGroup) => {
            const payGroup = getEmployerById?.employerById.payGroups.find(
              paygroup => paygroup.id === selectedPayGroup?.id
            )
            setPayGroup(payGroup)
            form.resetField('periodStartDate', { defaultValue: null })
          }}
        />

        <FormRadioGroup
          sx={12}
          name="parameterTypeKey"
          label={t('form.field.pleaseselectaparameter')}
          options={getPayrollClosureParameters?.payrollClosureProcessParameters.map(
            x => new FormRadioOption(x.key, x.value)
          )}
        />

        {watchedparameterTypeKey === PROCESS_PAYROLLCLOSURE_PARAMETER.ORIGINAL_CORRECTIONS_PERSONCONTRACT && (
          <>
            <FormDatepicker
              sx={6}
              name="periodStartDate"
              label={`${t('form.field.periodstartdate')} *`}
              openTo={payGroup?.payPeriodType?.key === PAYPERIODTYPES.MONTHLY ? 'month' : 'day'}
              views={
                payGroup?.payPeriodType?.key === PAYPERIODTYPES.MONTHLY ? ['year', 'month'] : ['year', 'month', 'day']
              }
              onChange={selectedDate => {
                setPeriod(
                  selectedDate
                    ? new Date(selectedDate).toLocaleDateString(me?.locale.locale, {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric'
                      })
                    : ''
                )
              }}
              shouldDisableDate={e => isValidPayPeriodDate(payGroup ?? null, e)}
            />

            <Grid item xs={6}>
              <Typography
                color={theme.palette.text.secondary}
                sx={{
                  marginTop: 0.75
                }}
              >
                <Trans
                  i18nKey="flyin.addpayrollclosureprocess.period"
                  values={{
                    numberofweeks: payGroup?.payPeriodNumberOfWeeks,
                    payperiod: payGroup?.payPeriodType?.value
                  }}
                />
              </Typography>
            </Grid>
          </>
        )}

        <FormDatepicker sx={12} name="dueDate" label={`${t('form.field.duedate')} *`} />

        <FormInput sx={12} name="processName" label={`${t('form.field.processname')} *`} />

        <FormInput sx={12} name="notes" label={`${t('form.field.notes')}`} multiline />

        <FormSwitch sx={12} name="isTestRun" label={t('form.field.istestrun')} />
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons isMutating={mutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddPayrollClosure

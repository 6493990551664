import { Box, Typography, useTheme } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  GraphqlError,
  useGetContractByIdQuery,
  useGetEmployeeNumbersInUseByEmployerIdQuery,
  useGetEmployerByIdQuery,
  useGetSubEmployeeTypesQuery,
  useGetSubPopulationTypesQuery,
  useUpdateContractTypeMutation,
  FormSelectOption,
  formTypeSelectOptions,
  ContractEmployeeNumberInUseModel,
  useGetPayComponentsByContractIdQuery
} from '@epix-web-apps/core'
import { useParams } from 'react-router-dom'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'
import {
  FormInput,
  useFlyIn,
  FormGridLayout,
  FormSelect,
  FormContainer,
  FormActionButtons,
  FormErrorList
} from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface EditContractTypeProps {}

export function EditContractType(props: EditContractTypeProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useParams<ContractDetailPageParams>()
  const { data: getContractById, refetch: refetchGetContractById } = useGetContractByIdQuery(
    {
      contractId: params?.contractid || ''
    },
    {
      enabled: !!params?.contractid
    }
  )
  const contract = getContractById?.contractById

  const { data: getEmployeeNumbersInUse } = useGetEmployeeNumbersInUseByEmployerIdQuery(
    {
      employerId: contract?.employerId || ''
    },
    {
      enabled: !!contract?.employerId
    }
  )

  const GetHighestEmployeeNumber = (employeeNumbers: ContractEmployeeNumberInUseModel[]) => {
    if (employeeNumbers.length === 0) return null
    const filteredStrings = employeeNumbers
      .map(item => item.employeeNumber)
      .filter(num => num)
      .filter(num => !isNaN(Number(num)))

    if (filteredStrings.length === 0) return null

    const filteredNumbers = filteredStrings.map(x => Number(x))
    return Math.max(...filteredNumbers)
  }

  const highestNumber = GetHighestEmployeeNumber(getEmployeeNumbersInUse?.employeeNumbersInUseByEmployerId || [])

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const editContractTypeSchema = object({
    employerId: string({
      required_error: t('form.validation.employerrequired'),
      invalid_type_error: t('form.validation.employerrequired')
    }).min(1, t('form.validation.employerrequired')),
    payGroupId: string().optional().nullable(),
    employeeNumber: string().optional().nullable(),
    employeeTypeId: string().optional().nullable(),
    subEmployeeTypeId: string().optional().nullable(),
    populationTypeId: string().optional().nullable(),
    subPopulationTypeId: string().optional().nullable(),
    subjectedToTaxesAbroad: boolean().optional().nullable(),
    countryOfTaxationKey: string().optional().nullable(),
    subjectedToSocialSecurityAbroad: boolean().optional().nullable(),
    countryForSocialSecurityKey: string().optional().nullable()
  })
    .refine(
      data =>
        !getEmployeeNumbersInUse?.employeeNumbersInUseByEmployerId.some(c =>
          data.employeeNumber ? c.employeeNumber === data.employeeNumber && contract?.personId !== c.personId : false
        ),
      {
        message: t('form.validation.employeenumberalreadyexists'),
        path: ['employeeNumber']
      }
    )
    .refine(
      data =>
        !getEmployeeNumbersInUse?.employeeNumbersInUseByEmployerId.some(c =>
          data.employeeNumber
            ? c.employeeNumber === data.employeeNumber &&
              contract?.personId === c.personId &&
              contract?.id !== c.id &&
              (c.startDate <= contract.endDate || contract.endDate === null) &&
              (c.endDate >= contract.startDate || c.endDate === null)
            : false
        ),
      {
        message: t('form.validation.employeenumberalreadyexistspersonoverlap'),
        path: ['employeeNumber']
      }
    )

  type EditContractTypeForm = TypeOf<typeof editContractTypeSchema>
  const { closeFlyIn } = useFlyIn()

  const { employeeOptions, populationOptions, countryOptions } = formTypeSelectOptions
  const mutation = useUpdateContractTypeMutation()

  const form = useForm<EditContractTypeForm>({
    resolver: zodResolver(editContractTypeSchema),
    defaultValues: {
      employerId: contract?.employerId || undefined,
      payGroupId: contract?.payGroup?.id,
      employeeNumber: contract?.employeeNumber,
      employeeTypeId: contract?.employeeType?.key,
      subEmployeeTypeId: contract?.icpSubEmployeeType?.id,
      populationTypeId: contract?.populationType?.key,
      subPopulationTypeId: contract?.icpSubPopulationType?.id,
      subjectedToTaxesAbroad: contract?.subjectedToTaxesAbroad,
      countryOfTaxationKey: contract?.countryOfTaxation?.code,
      subjectedToSocialSecurityAbroad: contract?.subjectedToSocialSecurityAbroad,
      countryForSocialSecurityKey: contract?.countryForSocialSecurityCalculation?.code
    }
  })
  const { control } = form
  const watchedEmployerId = useWatch({ control, name: `employerId` })
  const watchedPaygroupId = useWatch({ control, name: `payGroupId` })
  const watchedEmployeeTypeId = useWatch({ control, name: `employeeTypeId` })
  const watchedPopulationTypeId = useWatch({ control, name: `populationTypeId` })
  const watchedSubjectedToTaxesAbroad = useWatch({ control, name: `subjectedToTaxesAbroad` })
  const watchedSubjectedToSocialSecurityAbroad = useWatch({ control, name: `subjectedToSocialSecurityAbroad` })

  const { data: getEmployerById } = useGetEmployerByIdQuery(
    {
      employerId: watchedEmployerId
    },
    {
      enabled: !!watchedEmployerId,
      suspense: false
    }
  )

  const { data: getPayComponentsById } = useGetPayComponentsByContractIdQuery(
    {
      contractId: contract?.id || '',
      offset: 0,
      limit: 0
    },
    {
      suspense: false
    }
  )
  const { data: getSubEmployeeTypes, isFetching: isFetchingSubEmployeeTypes } = useGetSubEmployeeTypesQuery(
    {
      configurationKey: contract?.icp?.configurationKey || '',
      employeeTypeKey: watchedEmployeeTypeId
    },
    {
      enabled: !!watchedEmployeeTypeId && !!contract?.icp?.id,
      suspense: false,
      onSuccess: data => {
        if (data.subEmployeeTypes.length === 1) {
          form.setValue('subEmployeeTypeId', data.subEmployeeTypes[0].id)
        }
      }
    }
  )
  const { data: getSubPopulationTypes, isFetching: isFetchingSubPopulationTypes } = useGetSubPopulationTypesQuery(
    {
      configurationKey: contract?.icp?.configurationKey || '',
      populationTypeKey: watchedPopulationTypeId
    },
    {
      enabled: !!watchedPopulationTypeId && !!contract?.icp?.id,
      suspense: false,
      onSuccess: data => {
        if (data.subPopulationTypes.length === 1) {
          form.setValue('subPopulationTypeId', data.subPopulationTypes[0].id)
        }
      }
    }
  )

  const handleOnSubmit = async (contractType: EditContractTypeForm) => {
    await mutation
      .mutateAsync({
        updateContractTypeCommand: {
          contractId: params.contractid || '',
          payGroupId: contractType.payGroupId,
          employeeNumber: contractType.employeeNumber || '',
          employeeTypeKey: contractType.employeeTypeId,
          icpSubEmployeeTypeId:
            getSubEmployeeTypes?.subEmployeeTypes.length === 0 ? null : contractType.subEmployeeTypeId,
          populationTypeKey: contractType.populationTypeId,
          icpSubPopulationTypeId:
            getSubPopulationTypes?.subPopulationTypes.length === 0 ? null : contractType.subPopulationTypeId,
          subjectedToTaxesAbroad: contractType.subjectedToTaxesAbroad,
          countryOfTaxationKey: contractType.subjectedToTaxesAbroad ? contractType.countryOfTaxationKey : undefined,
          subjectedToSocialSecurityAbroad: contractType.subjectedToSocialSecurityAbroad,
          countryForSocialSecurityCalculationKey: contractType.subjectedToSocialSecurityAbroad
            ? contractType.countryForSocialSecurityKey
            : undefined
        }
      })
      .then(() => {
        refetchGetContractById()
        closeFlyIn()
      })
      .catch(e => setBackendErrors([e]))
  }

  const disabledPayGroup =
    (getPayComponentsById?.payComponentsByContractId &&
      getPayComponentsById?.payComponentsByContractId.totalCount > 0) ||
    (contract?.hasOriginalPayPeriodDate != null && !contract?.hasOriginalPayPeriodDate)

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editcontracttype.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          disabled
          name="employerId"
          label={`${t('form.field.employer')} *`}
          options={
            new Array(
              new FormSelectOption(
                contract?.employerId ?? '',
                `${contract?.employer?.companyName} (${contract?.employer?.number})`
              )
            )
          }
          onChange={() => form.resetField('payGroupId', { defaultValue: null })}
        />

        {getEmployerById?.employerById && getEmployerById?.employerById?.payGroups.length > 0 && (
          <>
            <FormSelect
              sx={12}
              disabled={disabledPayGroup}
              name="payGroupId"
              label={t('form.field.paygroup')}
              options={getEmployerById?.employerById?.payGroups.map(
                x => new FormSelectOption(x.id, `${x.name} (${x.code})`)
              )}
            />
            {!watchedPaygroupId && (
              <Typography
                sx={{
                  pl: '1.25rem',
                  pt: '0.25rem',
                  fontSize: 'small',
                  color: theme.palette.text.secondary
                }}
              >
                {t('flyin.contract.info')}
              </Typography>
            )}
          </>
        )}

        <FormInput sx={12} name="employeeNumber" label={t('form.field.employeenumber')} />
        {highestNumber && !getContractById?.contractById.employeeNumber && (
          <Box ml={2}>
            <Typography color={theme.palette.text.secondary} fontStyle="italic">
              {t('flyin.contract.nextfree-employeenumber')}: {highestNumber + 1}
            </Typography>
          </Box>
        )}

        <FormSelect
          sx={12}
          name="employeeTypeId"
          label={t('form.field.employeetype')}
          options={employeeOptions}
          onChange={e => form.resetField('subEmployeeTypeId', { defaultValue: null })}
        />

        {getSubEmployeeTypes && getSubEmployeeTypes?.subEmployeeTypes.length > 1 && (
          <FormSelect
            sx={12}
            name="subEmployeeTypeId"
            label={t('form.field.subemployeetype')}
            options={getSubEmployeeTypes?.subEmployeeTypes.map(x => new FormSelectOption(x.id, x.name))}
          />
        )}

        <FormSelect
          sx={12}
          name="populationTypeId"
          label={t('form.field.populationtype')}
          options={populationOptions}
          onChange={e => form.resetField('subPopulationTypeId', { defaultValue: null })}
        />

        {getSubPopulationTypes && getSubPopulationTypes?.subPopulationTypes.length > 1 && (
          <FormSelect
            sx={12}
            name="subPopulationTypeId"
            label={t('form.field.subpopulationtype')}
            options={getSubPopulationTypes?.subPopulationTypes.map(x => new FormSelectOption(x.id, x.name))}
          />
        )}

        <FormSwitch sx={12} name="subjectedToTaxesAbroad" label={t('form.field.subjectedtotaxesabroad')} />
        {watchedSubjectedToTaxesAbroad && (
          <FormSelect
            sx={12}
            name="countryOfTaxationKey"
            label={t('form.field.countryoftaxation')}
            options={countryOptions}
          />
        )}

        <FormSwitch
          sx={12}
          name="subjectedToSocialSecurityAbroad"
          label={t('form.field.subjectedtosocialsecurityabroad')}
        />
        {watchedSubjectedToSocialSecurityAbroad && (
          <FormSelect
            sx={12}
            name="countryForSocialSecurityKey"
            label={t('form.field.countryforsocialsecuritycalculation')}
            options={countryOptions}
          />
        )}
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={mutation.isLoading}
        disabled={isFetchingSubEmployeeTypes || isFetchingSubPopulationTypes}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default EditContractType

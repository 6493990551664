import BarchartComponent from '../../../../../analytics-components/barchart-component'

/* eslint-disable-next-line */
export interface CompanyCarsNumberOfCarsComponentProps {
  color: string
  data: any
  showZeroScale: boolean
}

export function CompanyCarsNumberOfCarsComponent({
  color,
  data,
  showZeroScale
}: CompanyCarsNumberOfCarsComponentProps) {
  const nrCarsData: number[] = data?.map((b: any) => b.TotalNumberOfCars)

  return (
    <BarchartComponent
      data={data}
      keys={['TotalNumberOfCars']}
      indexBy="monthAndYear"
      layoutMode="vertical"
      groupMode="stacked"
      innerPadding={0}
      marginRight={50}
      marginLeft={50}
      colors={[color]}
      showLegend={false}
      marginTop={10}
      minValueScaleOn={showZeroScale ? undefined : nrCarsData}
    />
  )
}

export default CompanyCarsNumberOfCarsComponent

import {
  FormSelectOption,
  GraphqlError,
  useAddTenantAdminRoleToUserMutation,
  useGetEligibleUsersForTenantAdminRoleQuery
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, FormSelect, useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'

export function AddTenantAdmin() {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const { data: getEligibleUsersForTenantAdminRoleQuery } = useGetEligibleUsersForTenantAdminRoleQuery()
  const mutation = useAddTenantAdminRoleToUserMutation()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const addTenantAdminSchema = object({
    userId: string({
      required_error: t('form.validation.userrequired')
    })
  })

  type AddTenantAdminForm = TypeOf<typeof addTenantAdminSchema>

  const form = useForm<AddTenantAdminForm>({
    reValidateMode: 'onSubmit',
    defaultValues: {},
    resolver: zodResolver(addTenantAdminSchema)
  })

  function handleOnSubmit(newTenantAdmin: AddTenantAdminForm) {
    mutation
      .mutateAsync({
        addTenantAdminRoleToUserCommand: {
          userId: newTenantAdmin.userId
        }
      })
      .then(() => {
        closeFlyIn()
      })
      .catch(e => setBackendErrors([e]))
  }

  const options = getEligibleUsersForTenantAdminRoleQuery?.eligibleUsersForTenantAdminRole.map(
    t => new FormSelectOption(t.id, t.username != null ? `${t.name} (${t.username})` : t.name)
  )

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addemployer.title')}</Typography>

      <FormSelect name="userId" label={t('form.field.selectuser')} options={options} />

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={mutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

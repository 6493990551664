import { HeaderTitleNavigation, SelfService } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import DataTableEmpty from '../../../myepix/components/data-table/data-table-empty'
import DoneIcon from '@mui/icons-material/Done'
import { Link, Typography, useTheme } from '@mui/material'
import { OrderDirection, useGetAllValidationErrorsByPersonIdForCurrentUserQuery } from '@epix-web-apps/core'
import { useNavigate } from 'react-router-dom'

/* eslint-disable-next-line */
export interface PendingTasksProps {}

export function PendingTasks(props: PendingTasksProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()

  const { data: validations } = useGetAllValidationErrorsByPersonIdForCurrentUserQuery({
    showHasSeen: true,
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc
  })

  return (
    <>
      <HeaderTitleNavigation title={t('selfservice.pending-tasks.title')} mobileSizeTitle />
      {validations?.allValidationErrorsByPersonIdForCurrentUser.totalCount === 0 ? (
        <DataTableEmpty
          title={t('selfservice.pending-tasks.none')}
          icon={<DoneIcon sx={{ fontSize: '4.5rem', fill: theme.palette.success.light }} />}
        />
      ) : (
        <>
          <Typography variant="h4" color={theme.palette.warning.light}>
            {t('selfservice.pending-tasks.documents')}
          </Typography>
          {validations?.allValidationErrorsByPersonIdForCurrentUser.data.map(validation => {
            return (
              <Link
                color={theme.palette.text.primary}
                onClick={() => {
                  navigate(SelfService.MY_REQUESTS_ID(`${validation.absenceRequestId}`))
                }}
                key={validation.id}
              >
                <Typography color={theme.palette.text.primary} mb={1}>
                  {validation.validationErrorMessage}
                </Typography>
              </Link>
            )
          })}
        </>
      )}
    </>
  )
}

export default PendingTasks

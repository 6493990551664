import PiechartComponent from '../piechart-component'
import { getNumberOfColors, useGetInjuryDisabilityDistributionQuery, useGetMeQuery } from '@epix-web-apps/core'
import { useAnalyticsPersistedStore } from '../../../stores/analytics-store'
import { useTranslation } from 'react-i18next'
import { useGlobalPersistedStore } from '@epix-web-apps/ui'
import { PieData } from '../piechart-component/piechart-component'
import { Box, Typography } from '@mui/material'

/* eslint-disable-next-line */
export interface InjuryDisabilityComponentProps {}

export function InjuryDisabilityComponent(props: InjuryDisabilityComponentProps) {
  const { data: me } = useGetMeQuery()
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const { dateFilter, genderFilter, functionAreaFilter, functionLevelFilter, employeeTypeFilter } =
    useAnalyticsPersistedStore()

  const { data: injuryDisabilityDistribution } = useGetInjuryDisabilityDistributionQuery({
    icps: icpFilters,
    selectedDate: dateFilter,
    filterModel: {
      genderType: genderFilter,
      functionAreaType: functionAreaFilter,
      functionLevelType: functionLevelFilter,
      employeeType: employeeTypeFilter
    }
  })

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          sx={{
            color: getNumberOfColors(1)
          }}
        >
          (
          {new Date(injuryDisabilityDistribution?.injuryDisabilityDistribution.selectedDate).toLocaleDateString(
            me?.me.locale.locale
          )}
          )
        </Typography>
      </Box>
      <PiechartComponent
        data={injuryDisabilityDistribution?.injuryDisabilityDistribution.entries.map(
          x =>
            new PieData(
              x.injuryDisabilityKey.toString(),
              x.amount.toString(),
              x.injuryDisabilityKey + '% : ' + x.amount
            )
        )}
        total={injuryDisabilityDistribution?.injuryDisabilityDistribution.total}
        name={t('analytics.pie.disabilitytotal')}
      />
    </Box>
  )
}

export default InjuryDisabilityComponent

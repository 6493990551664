import { Box, Link, Typography, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { OnboardingLayout } from '../../../layouts'
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined'
import { environment } from '../../../../../../src/environments/environment'

/* eslint-disable-next-line */
export interface OnboardingPaycomponentLayoutProps {}

export function OnboardingPaycomponentLayout(props: OnboardingPaycomponentLayoutProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <>
      <OnboardingLayout sxTop={400} title={t('paycomponentpage.onboarding.title')} icon={<SavingsOutlinedIcon />} />
      <Box
        sx={{
          marginLeft: '20%',
          marginRight: '20%'
        }}
      >
        <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
          {t('paycomponentpage.onboarding.addfirstpaycomponent')}
          <Trans
            i18nKey={t('paycomponentpage.onboarding.howtomanagepaycomponents')}
            values={{
              linktodocusaurus: t('paycomponentpage.onboarding.readmore')
            }}
            components={{
              tt: (
                <Link
                  underline="always"
                  target="_blank"
                  href={environment.docs.domainName + '/Epix/epix-people/contract/#pay-components'}
                />
              )
            }}
          />
        </Typography>
      </Box>
    </>
  )
}

export default OnboardingPaycomponentLayout

import { Typography } from '@mui/material'
import { FormContainer } from '@epix-web-apps/ui'
import { FormGridLayout } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'
import { useTranslation } from 'react-i18next'
import { useFlyIn } from '@epix-web-apps/ui'
import { TypeOf, boolean, object, string } from 'zod'
import {
  OrderDirection,
  useCreateCarCategoryMutation,
  useGetCarCategoriesQuery,
  useGetCarCategoryByIdQuery,
  useUpdateCarCategoryMutation
} from '@epix-web-apps/core'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface AddEditCarCategoryProps {
  id?: string
  create?: boolean
}

export function AddEditCarCategory({ id, create }: AddEditCarCategoryProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const addEditCarCategorySchema = object({
    description: string({
      required_error: t('form.validation.descriptionrequired'),
      invalid_type_error: t('form.validation.descriptionrequired')
    }).min(1, t('form.validation.descriptionrequired')),
    code: string({
      required_error: t('form.validation.carcoderequired'),
      invalid_type_error: t('form.validation.carcoderequired')
    }).min(1, t('form.validation.carcoderequired')),
    active: boolean()
  })

  type AddEditCarCategoryForm = TypeOf<typeof addEditCarCategorySchema>

  const { data: getCarCategoryById, refetch: refetchCarCategory } = useGetCarCategoryByIdQuery(
    {
      id: id ?? ''
    },
    {
      enabled: !!id
    }
  )

  const { refetch: refetchCarCategories } = useGetCarCategoriesQuery(
    {
      offset: 0,
      limit: -1,
      sortByProperty: 'Value',
      orderDirection: OrderDirection.Asc
    },
    { enabled: false }
  )

  const form = useForm<AddEditCarCategoryForm>({
    resolver: zodResolver(addEditCarCategorySchema),
    defaultValues: {
      description: getCarCategoryById?.carCategory.description,
      code: getCarCategoryById?.carCategory.code,
      active: getCarCategoryById?.carCategory.active ?? true
    }
  })

  const createmutation = useCreateCarCategoryMutation()
  const updatemutation = useUpdateCarCategoryMutation()

  const handleOnSubmit = async (newCarCategory: AddEditCarCategoryForm) => {
    if (!id) {
      await createmutation
        .mutateAsync({
          createCarCategoryCommand: {
            description: newCarCategory.description,
            code: newCarCategory.code,
            active: newCarCategory.active
          }
        })
        .then(closeFlyIn)
    } else {
      await updatemutation
        .mutateAsync({
          updateCarCategoryCommand: {
            id: id || '',
            description: newCarCategory.description,
            code: newCarCategory.code,
            active: newCarCategory.active
          }
        })
        .then(() => {
          refetchCarCategories()
          refetchCarCategory()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditcarcategory.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="code" label={`${t('form.field.carcode')}*`} />

        <FormInput sx={12} name="description" label={`${t('form.field.description')}*`} />

        <FormSwitch sx={12} name="active" label={t('form.field.active')} />
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons
        isMutating={createmutation.isLoading || updatemutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditCarCategory

import { IconButton } from '@mui/material'
import { DetailBox, DetailBoxRow } from '@epix-web-apps/ui'
import EditCompanyCarTechnicalDetails from '../../edit-company-car-technical-details'
import { Trans, useTranslation } from 'react-i18next'
import { useFlyIn } from '@epix-web-apps/ui'
import EditIcon from '@mui/icons-material/Edit'
import { CompanyCarDetailModel } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface CompanyCarTechnicalDetailsProps {
  car?: CompanyCarDetailModel
}

export function CompanyCarTechnicalDetails({ car }: CompanyCarTechnicalDetailsProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()

  return (
    <DetailBox
      title={t('companycardetailpage.technicaldetails.title')}
      actionIcon={
        <IconButton
          size="small"
          onClick={() =>
            openFlyIn({
              content: <EditCompanyCarTechnicalDetails />
            })
          }
        >
          <EditIcon />
        </IconButton>
      }
    >
      {(car?.motorType || car?.co2 || car?.co2 === 0) && (
        <DetailBoxRow>
          <p>
            {car?.motorType && (
              <Trans
                i18nKey="companycardetailpage.motortype"
                values={{ motortype: car?.motorType?.value }}
                components={{ bold: <span className="bold" /> }}
              />
            )}
            {car?.motorType && (car?.co2 || car?.co2 === 0) && <span>{t('companycardetailpage.with')}</span>}
            {(car?.co2 || car?.co2 === 0) && (
              <Trans
                i18nKey="companycardetailpage.co2"
                values={{ co2: car?.co2 }}
                components={{ bold: <span className="bold" /> }}
              />
            )}
          </p>
        </DetailBoxRow>
      )}
      {(car?.horsePower || car?.horsePower === 0) && (
        <DetailBoxRow>
          <p>
            <Trans
              i18nKey="companycardetailpage.horsepower"
              values={{ horsepower: car?.horsePower }}
              components={{ bold: <span className="bold" /> }}
            />
          </p>
        </DetailBoxRow>
      )}
    </DetailBox>
  )
}

export default CompanyCarTechnicalDetails

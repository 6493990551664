import {
  GraphqlError,
  ROLE,
  useGetMeQuery,
  useGetUserByIdQuery,
  useResetUserMfaMutation,
  useUpdateUserMutation,
  useUpdateUserRolesMutation,
  useUserExistsInB2cByIdQuery
} from '@epix-web-apps/core'
import { Box, Button, LinearProgress, Typography, useTheme } from '@mui/material'
import { useParams } from 'react-router-dom'
import { DetailPageBaseQueryParams, FormActionButtons, FormContainer, FormErrorList } from '@epix-web-apps/ui'
import { UserConfiguration } from '../user-configuration'
import EditUser from '../edit-user'
import { boolean, object, string, TypeOf } from 'zod'
import { useTranslation } from 'react-i18next'
import { useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import AppBlockingOutlinedIcon from '@mui/icons-material/AppBlockingOutlined'

/* eslint-disable-next-line */
export interface UserDetailConfigurationProps {}

export function UserDetailConfiguration(props: UserDetailConfigurationProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const params = useParams<DetailPageBaseQueryParams>()
  const [resetMfaDone, setResetMfaDone] = useState(false)
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError | Error>>([])
  const { data: me, refetch: refetchMe } = useGetMeQuery()
  const { data: getUserById, refetch: refetchUserById } = useGetUserByIdQuery(
    {
      userId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )
  const { data: userExistsInB2cById } = useUserExistsInB2cByIdQuery(
    {
      userId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )

  const [currentUserRoles, setCurrentUserRoles] = useState(getUserById?.userById.roles.map(x => x.toUpperCase()) || [])

  const editmutation = useUpdateUserMutation()
  const resetMfaMutation = useResetUserMfaMutation()
  const updateUserRolesmutation = useUpdateUserRolesMutation()

  const editUserConfigurationSchema = object({
    firstName: string({
      required_error: t('form.validation.firstnamerequired'),
      invalid_type_error: t('form.validation.firstnamerequired')
    }).min(1, t('form.validation.firstnamerequired')),
    lastName: string({
      required_error: t('form.validation.lastnamerequired'),
      invalid_type_error: t('form.validation.lastnamerequired')
    }).min(1, t('form.validation.lastnamerequired')),
    userName: string().email(t('form.validation.invalidemail')),
    localeCode: string({
      required_error: t('form.validation.languagerequired'),
      invalid_type_error: t('form.validation.languagerequired')
    }).min(1, t('form.validation.languagerequired')),
    currency: string({
      required_error: t('form.validation.currencyrequired'),
      invalid_type_error: t('form.validation.currencyrequired')
    }).min(1, t('form.validation.currencyrequired')),
    isActive: boolean(),
    role: string(),
    manageTeamsAndRoles: boolean(),
    importData: boolean(),
    manageCompanyCars: boolean(),
    manageLists: boolean(),
    manageUsersAndPolicies: boolean(),
    manageAllCountryPolicies: boolean()
  })

  type EditUserForm = TypeOf<typeof editUserConfigurationSchema>
  const form = useForm<EditUserForm>({
    resolver: zodResolver(editUserConfigurationSchema),
    defaultValues: {
      firstName: getUserById?.userById.firstName || '',
      lastName: getUserById?.userById.lastName || '',
      userName: getUserById?.userById.email || '',
      localeCode: getUserById?.userById.locale.locale || me?.me.locale.locale,
      currency: getUserById?.userById.currency || me?.me.currency,
      isActive: getUserById?.userById.isActive,
      role: !currentUserRoles.some(x => [ROLE.ADMIN, ROLE.CUSTOM].map(x => x.toUpperCase()).includes(x))
        ? ''
        : currentUserRoles.includes(ROLE.ADMIN)
        ? ROLE.ADMIN
        : ROLE.CUSTOM,
      manageTeamsAndRoles: currentUserRoles.includes(ROLE.MANAGETEAMSANDROLES),
      importData: currentUserRoles.includes(ROLE.IMPORTDATA),
      manageCompanyCars: currentUserRoles.includes(ROLE.MANAGECOMPANYCARS),
      manageLists: currentUserRoles.includes(ROLE.MANAGELISTS),
      manageUsersAndPolicies: currentUserRoles.includes(ROLE.MANAGEUSERSANDPOLICIES),
      manageAllCountryPolicies: currentUserRoles.includes(ROLE.MANAGEALLCOUNTRYPOLICIES)
    }
  })
  const { control } = form

  const handleOnSubmit = async (user: EditUserForm) => {
    await editmutation
      .mutateAsync({
        updateUserCommand: {
          id: params.id ?? '',
          firstName: user.firstName,
          lastName: user.lastName,
          currency: user.currency,
          locale: user.localeCode,
          isActive: user.isActive,
          personId: getUserById?.userById.personId
        }
      })
      .then(() => {
        refetchMe()
        refetchUserById()
        setBackendErrors([])
      })
      .catch(e => setBackendErrors([e]))

    const selectedUserRoles = [
      { key: ROLE.CUSTOM, value: user.role === ROLE.CUSTOM.toString() },
      { key: ROLE.ADMIN, value: user.role === ROLE.ADMIN.toString() },
      {
        key: ROLE.MANAGETEAMSANDROLES,
        value: selectedRole === ROLE.CUSTOM ? user.manageTeamsAndRoles : false
      },
      {
        key: ROLE.IMPORTDATA,
        value: selectedRole === ROLE.CUSTOM ? user.importData : false
      },
      {
        key: ROLE.MANAGECOMPANYCARS,
        value: selectedRole === ROLE.CUSTOM ? user.manageCompanyCars : false
      },
      {
        key: ROLE.MANAGELISTS,
        value: selectedRole === ROLE.CUSTOM ? user.manageLists : false
      },
      {
        key: ROLE.MANAGEUSERSANDPOLICIES,
        value: selectedRole === ROLE.CUSTOM ? user.manageUsersAndPolicies : false
      },
      {
        key: ROLE.MANAGEALLCOUNTRYPOLICIES,
        value: selectedRole === ROLE.CUSTOM ? user.manageAllCountryPolicies : false
      }
    ]
    const userRolesToAdd = selectedUserRoles.filter(x => x.value).map(x => x.key.toString())
    const userRolesToDelete = selectedUserRoles.filter(x => !x.value).map(x => x.key.toString())
    const newUserRoles = [
      ...new Set(
        currentUserRoles
          .map(x => x.toUpperCase())
          .filter(x => !userRolesToDelete.includes(x))
          .concat(userRolesToAdd.filter(x => !currentUserRoles.includes(x)))
      )
    ]

    await updateUserRolesmutation
      .mutateAsync({
        updateUserRolesCommand: {
          id: params.id || '',
          roles: newUserRoles
        }
      })
      .then(() => {
        setCurrentUserRoles(newUserRoles)
        refetchMe()
        refetchUserById()
        setBackendErrors([])
      })
      .catch(e => setBackendErrors([e]))
  }

  const selectedRole = useWatch({ control, name: 'role' })

  return (
    <FormContainer sx={{ width: '100%' }} form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      {userExistsInB2cById?.userExistsInB2cById === false && (
        <Box sx={{ mb: '1.5rem' }}>
          <Typography variant="h6" sx={{ color: theme.palette.warning.light }}>
            {t('edituser.usernotyetsignedup')}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          gap: 10
        }}
      >
        <Box
          sx={{
            m: 2,
            width: '40%'
          }}
        >
          <EditUser />
        </Box>
        <Box
          sx={{
            width: '60%'
          }}
        >
          {me?.me.id !== params.id && <UserConfiguration selectedRole={selectedRole} />}
        </Box>
      </Box>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={editmutation.isLoading}>
        <Button
          variant="contained"
          color={resetMfaDone ? 'success' : 'error'}
          startIcon={resetMfaDone ? <CheckCircleOutlinedIcon /> : <AppBlockingOutlinedIcon />}
          disabled={resetMfaMutation.isLoading || userExistsInB2cById?.userExistsInB2cById === false}
          onClick={() =>
            resetMfaMutation
              .mutateAsync({
                resetUserMfaCommand: {
                  id: params.id ?? ''
                }
              })
              .then(() => setResetMfaDone(true))
              .catch(e => setBackendErrors([e]))
          }
        >
          {resetMfaDone ? t('edituser.resetmfadone') : t('edituser.resetmfa')}
          {resetMfaMutation.isLoading && (
            <LinearProgress
              color={resetMfaDone ? 'success' : 'error'}
              sx={{
                backgroundColor: resetMfaDone ? 'success' : 'error',
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                opacity: 0.6
              }}
            />
          )}
        </Button>
      </FormActionButtons>
    </FormContainer>
  )
}

export default UserDetailConfiguration

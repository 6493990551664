import { Paper, SxProps, Typography } from '@mui/material'
import { Suspense } from 'react'
import { LoadingOverlay } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface ConfigurationPaperProps {
  title?: string
  children?: React.ReactNode
  sx?: SxProps
}

const paperStyle = {
  px: 2,
  padding: '0.5rem',
  height: '100%'
}

export function SuspensePaper({ title, children, sx }: ConfigurationPaperProps) {
  return (
    <Paper sx={{ ...paperStyle, ...sx }}>
      <Suspense fallback={<LoadingOverlay />}>
        {title && (
          <Typography sx={{ textAlign: 'center' }} variant="h4">
            {title}
          </Typography>
        )}
        {children}
      </Suspense>
    </Paper>
  )
}

export default SuspensePaper

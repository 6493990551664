import React, { Children, isValidElement } from 'react';

export function useCheckChildrenType(children: React.ReactNode, acceptedTypes: Array<any> | any) {
  return Children.map(children, child => {

    if (!isValidElement(child)) {
      console.error(`Children must be valid elements`, {child});
      return null;
    }

    if (Array.isArray(acceptedTypes)) {
      if (!acceptedTypes.includes(child.type)) {
        console.error(`Children must be of types ${acceptedTypes.map(t => t.render.name).join(', ')}`, {child});
        return null;
      }
    } else {
      if (child.type !== acceptedTypes) {
        console.error(`Children must be of type ${acceptedTypes.render.name}`, {child});
        return null;
      }
    }

    return child;
  })
}

import { createEnhancedJSONStorage, ShowCarsType } from '@epix-web-apps/core'
import { endOfYear, startOfYear } from 'date-fns'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface ICompanyCarsStatisticsPersistedStore {
  startDateFilter: Date
  setStartDateFilter: (date: Date) => void
  endDateFilter: Date
  setEndDateFilter: (date: Date) => void
  carCategoryFilter: string | null
  setCarCategoryFilter: (carCategory: string | null) => void
  countryFilter: string | null
  setCountryFilter: (country: string | null) => void
  motorTypeFilter: string | null
  setMotorTypeFilter: (motorType: string | null) => void
  showZeroScale: boolean
  setShowZeroScale: (showZeroScale: boolean) => void
  endsInPeriodFilter: boolean
  setEndsInPeriodFilter: (endsInPeriod: boolean) => void
  showCarsFilter: ShowCarsType
  setShowCarsFilter: (showCars: ShowCarsType) => void
}

export const useCompanyCarsStatisticsPersistedStore = create<ICompanyCarsStatisticsPersistedStore>()(
  persist(
    (set, get) => ({
      startDateFilter: startOfYear(new Date()),
      setStartDateFilter: async (startDate: Date) => {
        set({ startDateFilter: startDate })
      },
      endDateFilter: endOfYear(new Date()),
      setEndDateFilter: async (endDate: Date) => {
        set({ endDateFilter: endDate })
      },
      carCategoryFilter: null,
      setCarCategoryFilter: async (carCategory: string | null) => {
        set({ carCategoryFilter: carCategory })
      },
      countryFilter: null,
      setCountryFilter: async (country: string | null) => {
        set({ countryFilter: country })
      },
      motorTypeFilter: null,
      setMotorTypeFilter: async (motorType: string | null) => {
        set({ motorTypeFilter: motorType })
      },
      showZeroScale: false,
      setShowZeroScale: async (showZeroScale: boolean) => {
        set({ showZeroScale: showZeroScale })
      },
      endsInPeriodFilter: false,
      setEndsInPeriodFilter: async (endsInPeriod: boolean) => {
        set({ endsInPeriodFilter: endsInPeriod })
      },
      showCarsFilter: ShowCarsType.ShowAllCars,
      setShowCarsFilter: async (showCarsKey: ShowCarsType) => {
        set({ showCarsFilter: showCarsKey })
      }
    }),
    {
      name: 'companyCarsStatisticsPersistedStore',
      storage: createEnhancedJSONStorage(() => localStorage)
    }
  )
)

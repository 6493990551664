import { ResponsivePie } from '@nivo/pie'
import { Box, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ABSENCE_TYPE_KEYS, useGetBalanceOverviewForPersonQuery } from '@epix-web-apps/core'
import { useEffect, useState } from 'react'

export type BalanceChartParams = {
  personId: string
  dateOfCalendar: Date
}

export type BalanceChartProps = {
  id: string
  label: string
  value: number
}

export function BalanceChart({ personId, dateOfCalendar }: BalanceChartParams) {
  const theme = useTheme()
  const { t } = useTranslation()
  const [data, setData] = useState<BalanceChartProps[]>([])
  const [total, setTotal] = useState(0)
  const [open, setOpen] = useState(0)
  const [absenceTypeKey, setAbsenceTypeKey] = useState('')

  const { data: getBalanceOverviewForPerson } = useGetBalanceOverviewForPersonQuery(
    {
      personId: personId || '',
      viewDate: dateOfCalendar || new Date()
    },
    {
      enabled: !!personId
    }
  )

  useEffect(() => {
    if (getBalanceOverviewForPerson && getBalanceOverviewForPerson.balanceOverviewForPerson) {
      const tempData: BalanceChartProps[] = [
        {
          id: 'taken',
          label: t('selfservice.balance.taken'),
          value: getBalanceOverviewForPerson.balanceOverviewForPerson.taken
        },
        {
          id: 'planned',
          label: t('selfservice.balance.planned'),
          value: getBalanceOverviewForPerson.balanceOverviewForPerson.planned
        },
        {
          id: 'open',
          label: t('selfservice.balance.open'),
          value: getBalanceOverviewForPerson.balanceOverviewForPerson.open
        }
      ]
      setData(tempData)
      setOpen(getBalanceOverviewForPerson.balanceOverviewForPerson.open)
      setTotal(getBalanceOverviewForPerson.balanceOverviewForPerson.total)
      setAbsenceTypeKey(getBalanceOverviewForPerson.balanceOverviewForPerson.absenceType?.key ?? '')
    }
  }, [getBalanceOverviewForPerson])

  const CenteredMetric = ({ centerX, centerY }: any) => {
    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        style={{
          fontSize: '25px',
          fontWeight: 'bold'
        }}
      >
        <tspan>{open}</tspan>
        <tspan style={{ fontSize: '17px' }}>/{total}</tspan>
        <tspan style={{ fontSize: '20px' }} x={centerX} y={centerY - 7} dy="1.5em">
          {absenceTypeKey === ABSENCE_TYPE_KEYS.DAYS && t('selfservice.balance.days')}
          {absenceTypeKey === ABSENCE_TYPE_KEYS.HOURS && t('selfservice.balance.hours')}
        </tspan>
      </text>
    )
  }

  return (
    <Box height={'200px'}>
      <ResponsivePie
        data={data}
        colors={[theme.palette.primary.light, theme.palette.secondary.light, theme.palette.secondary.main]}
        margin={{ top: 0, right: 150, bottom: 0, left: 0 }}
        innerRadius={0.85}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        arcLinkLabelsDiagonalLength={0}
        arcLinkLabelsStraightLength={0}
        arcLinkLabelsOffset={0}
        arcLabelsSkipAngle={0}
        animate={true}
        theme={{
          tooltip: {
            container: {
              fontSize: '0.8em',
              backgroundColor: theme.palette.common.white,
              color: theme.palette.common.black
            }
          }
        }}
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 50,
            itemHeight: 30,
            itemTextColor: theme.palette.common.black
          }
        ]}
        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
      />
    </Box>
  )
}

export default BalanceChart

import { StepProps } from '../../../generic-steps'
import { useGetCalculatedCalendarByProcessIdQuery, useGetPayrollClosureProcessQuery, colors } from '@epix-web-apps/core'
import {
  TimelineGroupBase,
  TimelineItemBase,
  TimelineHeaders,
  SidebarHeader,
  DateHeader
} from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import moment, { Moment } from 'moment'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  TextField,
  Typography,
  createFilterOptions,
  useTheme
} from '@mui/material'
import { PageOptions, PagingNavigation, TypographyBold } from '@epix-web-apps/ui'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import SearchIcon from '@mui/icons-material/Search'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledTimeline } from '../styled-timeline/styled-timeline'

interface TimeLineItem extends TimelineItemBase<Moment> {
  dayPartColor: Array<string>
}

interface TimeLineSearchOption {
  id: string
  name: string
  number: string
}

const pageSize = 25

export function CalculatedCalendarData({ processId }: StepProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [searchInputValue, setSearchInputValue] = useState<TimeLineSearchOption | null>(null)

  const [page, setPage] = useState(0)

  const { data: getCalculatedCalendarData, isFetching } = useGetCalculatedCalendarByProcessIdQuery(
    {
      processId: processId,
      limit: pageSize,
      offset: page * pageSize
    }
  )

  const { data: getPayrollClosureProcess } = useGetPayrollClosureProcessQuery(
    {
      id: processId
    }
  )

  const processStartDate = getPayrollClosureProcess?.payrollClosureProcess.startDate
  const processEndDate = getPayrollClosureProcess?.payrollClosureProcess.endDate
  const [startDate, setStartDate] = useState<Moment>(
    processStartDate ? moment(processStartDate).startOf('month') : moment().startOf('month')
  )
  const [endDate, setEndDate] = useState<Moment>(
    processEndDate ? moment(processEndDate).endOf('month') : moment().endOf('month')
  )

  const months =
    getCalculatedCalendarData?.calculatedCalendarByProcessId.data
      .map(c => c.days)
      .flatMap(d => d.map(x => moment(x.date).startOf('month').valueOf()))
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a - b) || []

  const groups: TimelineGroupBase[] =
    getCalculatedCalendarData?.calculatedCalendarByProcessId.data.map(
      c =>
        ({
          id: c.contractId,
          title: (
            <Box
              sx={{
                color: theme.palette.primary.main,
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                paddingLeft: 0.5
              }}
            >
              <Avatar alt={c.employeeName} sx={{ width: 30, height: 30, marginRight: 1 }} />
              <TypographyBold
                sx={{
                  fontSize: '0.85rem',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  flexGrow: 1
                }}
              >
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  {c.employeeName}
                  <sub
                    style={{
                      marginTop: '-3px',
                      color: theme.palette.grey[500]
                    }}
                  >
                    {c.employeeNumber}
                  </sub>
                </span>
              </TypographyBold>
              {getPayrollClosureProcess?.payrollClosureProcess.hasOriginalData && (
                <Chip
                  sx={{
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                    borderRadius: 2
                  }}
                  variant="outlined"
                  label={`${c.totalNumberOfHours}h`}
                />
              )}
            </Box>
          )
        } as TimelineGroupBase)
    ) || []

  const items =
    getCalculatedCalendarData?.calculatedCalendarByProcessId.data.flatMap(c =>
      c.days.map((cd, index) => {
        const dayItem = {} as TimeLineItem

        if (cd.calculatedEntries.length > 0) {
          dayItem.id = `${c.contractId}-${cd.date}-${index}`
          dayItem.group = c.contractId
          dayItem.title = cd.calculatedEntries
            .map(cc => cc.numberOfHours)
            .reduce((a, b) => a + b, 0)
            .toFixed(2)
          dayItem.dayPartColor = cd.calculatedEntries.map(ce => ce.payrollCode.colourCodeHex ?? colors[0])
          dayItem.start_time = moment(cd.date)
          dayItem.end_time = moment(cd.date).add(1, 'day')
        }
        return dayItem
      })
    ) || []

  const itemRenderer = ({ item, itemContext, getItemProps, getResizeProps }: any) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
    return (
      <Box {...getItemProps(item.itemProps)}>
        {itemContext.useResizeHandle ? <Box {...leftResizeProps} /> : ''}
        <Box
          className="rct-item-content"
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '0px',
            alignItems: 'center',
            padding: '0',
            height: 'auto',
            maxHeight: `${itemContext.dimensions.height}`
          }}
        >
          <TypographyBold
            sx={{
              color: theme.palette.primary.main,
              fontSize: '0.8rem',
              cursor: 'default'
            }}
          >
            {item.title}
          </TypographyBold>
          <Typography
            sx={{
              display: 'flex',
              width: '100%',
              fontSize: '0.6rem',
              cursor: 'default'
            }}
          >
            {item.dayPartColor.map((dayPartColor: string, index: number) => (
              <span
                key={index}
                style={{
                  display: 'block',
                  textAlign: 'center',
                  width: `${100 / item.dayPartColor.length}%`,
                  height: 10,
                  background: dayPartColor,
                  padding: 1,
                  borderRadius: '3px'
                }}
              ></span>
            ))}
          </Typography>
        </Box>
        {itemContext.useResizeHandle ? <Box {...rightResizeProps} /> : ''}
      </Box>
    )
  }

  const onHandlePrevMonth = () => {
    setStartDate(moment(startDate).add(-1, 'M').startOf('month'))
    setEndDate(moment(endDate).add(-1, 'M').endOf('month'))
  }
  const onHandleNextMonth = () => {
    setStartDate(moment(startDate).add(1, 'M').startOf('month'))
    setEndDate(moment(endDate).add(1, 'M').endOf('month'))
  }
  const onHandleMonth = (date: Moment) => {
    setStartDate(moment(date).startOf('month'))
    setEndDate(moment(date).endOf('month'))
  }

  if (getCalculatedCalendarData?.calculatedCalendarByProcessId.totalCount === 0) {
    return <Typography sx={{ textAlign: 'center' }}>{t('processpage.calendarstep.noitemstoshow')}</Typography>
  }

  return (
      <Box>
        {months.length > 0 && (
          <Box sx={{ display: 'flex', gap: 1, marginBottom: 1, overflowX: 'auto' }}>
            {months.map((m, index) => {
              const momentDate = moment(m)
              return (
                <Button
                  key={index}
                  startIcon={<FilterAltIcon />}
                  variant={startDate.valueOf() === momentDate.valueOf() ? 'contained' : 'outlined'}
                  onClick={_ => onHandleMonth(momentDate)}
                  sx={{ minWidth: 230 }}
                >
                  <Typography noWrap>{momentDate.format('MMMM yyyy')}</Typography>
                </Button>
              )
            })}
          </Box>
        )}
        <Box sx={{ position: 'relative' }}>
          <Typography sx={{ position: 'absolute', top: '3rem', left: '-2rem' }}>
            <IconButton onClick={_ => onHandlePrevMonth()}>
              <ArrowBackIosIcon />
            </IconButton>
          </Typography>
          <StyledTimeline
            groups={groups}
            items={items}
            sidebarWidth={250}
            lineHeight={50}
            visibleTimeStart={startDate.valueOf()}
            visibleTimeEnd={endDate.valueOf()}
            onTimeChange={(visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
              updateScrollCanvas(visibleTimeStart, visibleTimeEnd)
            }}
            itemRenderer={itemRenderer}
            canResize={false}
            canChangeGroup={false}
            canMove={false}
            itemTouchSendsClick={true}
          >
            <TimelineHeaders>
              <SidebarHeader>
                {({ getRootProps }) => {
                  return (
                    <div {...getRootProps()}>
                      <Autocomplete
                        id="search-person"
                        sx={{ padding: 1.25 }}
                        getOptionLabel={option => option.name}
                        filterOptions={createFilterOptions({
                          stringify: ({ name, number }) => `${name} ${number}`
                        })}
                        options={
                          getCalculatedCalendarData?.calculatedCalendarByProcessId.data.map(
                            c =>
                              ({
                                id: c.contractId,
                                name: c.employeeName,
                                number: c.employeeNumber
                              } as TimeLineSearchOption)
                          ) ?? []
                        }
                        noOptionsText={t('processpage.calendarstep.nosearchcontracts')}
                        value={searchInputValue}
                        onChange={(_, value) => {
                          setSearchInputValue(value || null)
                        }}
                        popupIcon={false}
                        isOptionEqualToValue={(option, value) => {
                          return option.id === value.id
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label={
                              <Box sx={{ display: 'flex', gap: 1 }}>
                                <SearchIcon />
                                <p style={{ padding: 0, margin: 0 }}>
                                  {t('processpage.calendarstep.searchcontracts')} ...
                                </p>
                              </Box>
                            }
                            fullWidth
                            size="small"
                          />
                        )}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              <Avatar alt={option.name} sx={{ width: 30, height: 30, marginRight: 1 }} />
                              <TypographyBold fontSize="0.85rem" color={theme.palette.text.secondary}>
                                <span
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    textOverflow: 'ellipsis'
                                  }}
                                >
                                  {option.name}
                                  <sub
                                    style={{
                                      marginTop: '-3px',
                                      color: theme.palette.grey[500]
                                    }}
                                  >
                                    {option.number}
                                  </sub>
                                </span>
                              </TypographyBold>
                            </li>
                          )
                        }}
                      />
                    </div>
                  )
                }}
              </SidebarHeader>
              <DateHeader unit="primaryHeader" />
              <DateHeader labelFormat="DD" />
            </TimelineHeaders>
          </StyledTimeline>
          <Typography sx={{ position: 'absolute', right: '-2rem', top: '3rem' }}>
            <IconButton onClick={_ => onHandleNextMonth()}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', mt: 1 }}>
          {getCalculatedCalendarData && (
            <PagingNavigation
              hasNext={getCalculatedCalendarData?.calculatedCalendarByProcessId.hasNext}
              onNextClick={() => setPage(prev => prev + 1)}
              hasPrevious={getCalculatedCalendarData?.calculatedCalendarByProcessId.hasPrevious}
              onPreviousClick={() => setPage(prev => prev - 1)}
              isFetching={isFetching}
              pageOptions={
                new PageOptions(
                  page,
                  pageSize,
                  getCalculatedCalendarData?.calculatedCalendarByProcessId.data.length,
                  getCalculatedCalendarData?.calculatedCalendarByProcessId.totalCount
                )
              }
            />
          )}
        </Box>
      </Box>
  )
}

export default CalculatedCalendarData

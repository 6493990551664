import { Box, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

/* eslint-disable-next-line */
export interface EmptyStateProps {
  title?: string | undefined
}

export function EmptyState({ title }: EmptyStateProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <Box sx={{ mt: 2, textAlign: 'center' }}>
      <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
        {!title ? t('selfservice.emptystate.general') : title}
      </Typography>
    </Box>
  )
}

export default EmptyState

import {
  BOOLEAN_STRING_KEYS,
  DEFAULT_CANCELLATION_ALLOWED_TYPE,
  formTypeSelectOptions,
  numericString,
  useGetRequestPolicyByIdQuery,
  useUpdateRequestPolicyMutation
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'
import { TypeOf, boolean, number, object, string } from 'zod'
import { FormGridLayout, FormRadioGroup, IFormRadioOption } from '@epix-web-apps/ui'
import { useForm, useWatch } from 'react-hook-form'
import { Box, Typography } from '@mui/material'
import { FormContainer } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { useTheme } from '@mui/material'
import { styled } from '@mui/system'

/* eslint-disable-next-line */
export interface EditRequestPolicyRequestRightsProps {
  requestPolicyId: string
}

export function EditRequestPolicyRequestRights({ requestPolicyId }: EditRequestPolicyRequestRightsProps) {
  const { closeFlyIn } = useFlyIn()
  const { t } = useTranslation()
  const theme = useTheme()

  const isUnlimitedOptions: Array<IFormRadioOption> = [
    {
      id: BOOLEAN_STRING_KEYS.TRUE,
      label: t('editrequestpolicy.form.field.isunlimited')
    },
    {
      id: BOOLEAN_STRING_KEYS.FALSE,
      label: t('editrequestpolicy.form.field.isnotunlimited')
    }
  ]

  const needsApprovalOptions: Array<IFormRadioOption> = [
    {
      id: BOOLEAN_STRING_KEYS.TRUE,
      label: t('editrequestpolicy.form.field.needsapproval')
    },
    {
      id: BOOLEAN_STRING_KEYS.FALSE,
      label: t('editrequestpolicy.form.field.noneedforapproval')
    }
  ]

  const TypographyNote = styled(Typography)(() => {
    return {
      color: theme.palette.text.secondary,
      fontStyle: 'italic'
    }
  })

  const editRequestPolicyRequestRightsSchema = object({
    canBeRequested: boolean(),
    unlimited: string(),
    negativeBalance: boolean(),
    negativeBalanceCount: numericString(number().optional()).optional(),
    needsApproval: string(),
    cancellationAllowed: string({
      required_error: t('form.validation.cancellationneedsapprovalrequired'),
      invalid_type_error: t('form.validation.cancellationneedsapprovalrequired')
    }).min(1, t('form.validation.cancellationneedsapprovalrequired'))
  }).refine(
    data =>
      data.negativeBalance
        ? data.negativeBalanceCount
        : numericString(
            number({
              required_error: t('form.validation.negativebalancecount'),
              invalid_type_error: t('form.validation.negativebalancecount')
            }).positive({ message: t('form.validation.negativebalancecount') })
          ),
    {
      message: t('form.validation.negativebalancecount'),
      path: ['negativeBalanceCount']
    }
  )

  const { data: getRequestPolicyById, refetch: refetchRequestPolicyById } = useGetRequestPolicyByIdQuery({
    id: requestPolicyId
  })

  type EditRequestPolicyGeneralForm = TypeOf<typeof editRequestPolicyRequestRightsSchema>

  const form = useForm<EditRequestPolicyGeneralForm>({
    resolver: zodResolver(editRequestPolicyRequestRightsSchema),
    defaultValues: {
      canBeRequested: getRequestPolicyById?.requestPolicyById.canBeRequested,
      unlimited: getRequestPolicyById?.requestPolicyById.unlimited
        ? BOOLEAN_STRING_KEYS.TRUE
        : BOOLEAN_STRING_KEYS.FALSE,
      negativeBalance: getRequestPolicyById?.requestPolicyById.negativeBalance ?? false,
      negativeBalanceCount: getRequestPolicyById?.requestPolicyById.negativeBalanceCount,
      needsApproval: getRequestPolicyById?.requestPolicyById.needsApproval
        ? BOOLEAN_STRING_KEYS.TRUE
        : BOOLEAN_STRING_KEYS.FALSE ?? BOOLEAN_STRING_KEYS.TRUE,
      cancellationAllowed:
        getRequestPolicyById?.requestPolicyById.cancellationAllowedType?.key ?? DEFAULT_CANCELLATION_ALLOWED_TYPE
    }
  })

  const { control } = form

  const { cancellationAllowedTypeOptions } = formTypeSelectOptions

  const updateMutation = useUpdateRequestPolicyMutation()

  const handleOnSubmit = async (updatedRequestPolicy: EditRequestPolicyGeneralForm) => {
    await updateMutation
      .mutateAsync({
        updateRequestPolicyCommand: {
          requestPolicyId: requestPolicyId,
          internalName: getRequestPolicyById?.requestPolicyById.internalName,
          userFriendlyName: getRequestPolicyById?.requestPolicyById.userFriendlyName,
          canBeRequested: updatedRequestPolicy.canBeRequested,
          unlimited: updatedRequestPolicy.unlimited === BOOLEAN_STRING_KEYS.TRUE ? true : false,
          negativeBalance: updatedRequestPolicy.negativeBalance,
          negativeBalanceCount: updatedRequestPolicy.negativeBalance ? updatedRequestPolicy.negativeBalanceCount : null,
          needsApproval: updatedRequestPolicy.needsApproval === BOOLEAN_STRING_KEYS.TRUE ? true : false,
          cancellationAllowedTypeKey: updatedRequestPolicy.cancellationAllowed,
          attachIcsOnApprovalMailToEmployee: getRequestPolicyById?.requestPolicyById.attachIcsOnApprovalMailToEmployee,
          icsBusyStatusTypeKey: getRequestPolicyById?.requestPolicyById.icsBusyStatusType?.key
        }
      })
      .then(() => {
        refetchRequestPolicyById()
        closeFlyIn()
      })
  }

  const watchNegativeBalance = useWatch({ control, name: 'negativeBalance' })
  const watchUnlimited = useWatch({ control, name: 'unlimited' })

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.requestpolicy.requestrights.title')}</Typography>

      <FormGridLayout>
        <FormSwitch
          sx={12}
          name="canBeRequested"
          onChange={() => {
            form.resetField('unlimited', {
              defaultValue: BOOLEAN_STRING_KEYS.FALSE
            })
            form.resetField('negativeBalance', { defaultValue: false })
            form.resetField('negativeBalanceCount', { defaultValue: null })
            form.resetField('needsApproval', {
              defaultValue: BOOLEAN_STRING_KEYS.TRUE
            })
            form.resetField('cancellationAllowed', {
              defaultValue: DEFAULT_CANCELLATION_ALLOWED_TYPE
            })
          }}
          label={t('editrequestpolicy.form.field.canberequested')}
        />

        <FormRadioGroup
          sx={12}
          name="unlimited"
          label={t('editrequestpolicy.form.field.unlimitedlabel')}
          options={isUnlimitedOptions}
          gap="10px"
          onChange={(_, value) => {
            if (value === BOOLEAN_STRING_KEYS.FALSE) {
              form.resetField('negativeBalance', { defaultValue: false })
              form.resetField('negativeBalanceCount', { defaultValue: null })
            }
          }}
        />

        <FormSwitch
          sx={12}
          name="negativeBalance"
          label={t('editrequestpolicy.form.field.negativebalance')}
          disabled={watchUnlimited === BOOLEAN_STRING_KEYS.FALSE}
        />

        {watchNegativeBalance && (
          <FormInput
            sx={12}
            name="negativeBalanceCount"
            label={t('editrequestpolicy.form.field.negativebalancecount')}
          />
        )}

        <FormRadioGroup
          sx={12}
          name="needsApproval"
          label={t('editrequestpolicy.form.field.needsapprovallabel')}
          options={needsApprovalOptions}
        />

        <FormRadioGroup
          sx={12}
          name="cancellationAllowed"
          label={`${t('editrequestpolicy.form.field.cancellationallowed')}`}
          options={cancellationAllowedTypeOptions}
        />
        <Box sx={{ marginLeft: 2 }}>
          <TypographyNote>{t('editrequestpolicy.form.field.cancellation-info.attention')}</TypographyNote>
          <TypographyNote>{t('editrequestpolicy.form.field.cancellation-info.attention-1')}</TypographyNote>
        </Box>
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons isMutating={updateMutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditRequestPolicyRequestRights

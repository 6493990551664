import { useNavigate, useParams } from 'react-router-dom'
import { useGetTeamByIdQuery, useGetTeamChildrenByTeamIdQuery } from '@epix-web-apps/core'
import { Typography, Box, useTheme, Button, Link, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import EditTeam from '../edit-team/add-edit-team'
import { Configuration, DetailBoxRow, DetailPageBaseQueryParams, useFlyIn } from '@epix-web-apps/ui'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import EditRootTeam from '../edit-root-team/edit-root-team'

/* eslint-disable-next-line */
export interface TeamIdentificationProps {}

export function TeamIdentification(props: TeamIdentificationProps) {
  const params = useParams<DetailPageBaseQueryParams>()
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const theme = useTheme()
  const navigate = useNavigate()

  const { data: getTeamById, refetch: refetchTeam } = useGetTeamByIdQuery(
    {
      id: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )

  const { refetch: refetchTeamChildren } = useGetTeamChildrenByTeamIdQuery(
    {
      teamId: params.id || '',
      offset: 0,
      limit: 100
    },
    {
      enabled: !!params.id
    }
  )

  return (
    <Box>
      {getTeamById?.teamById.parentTeam && (
        <Box>
          <DetailBoxRow>
            <Typography variant="h4">{t('teamdetailspage.identification.rootteam')}</Typography>
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditRootTeam teamId={getTeamById.teamById.id || ''} />,
                  callbackAfterClose: () => refetchTeam
                })
              }
            >
              <EditIcon />
            </IconButton>
          </DetailBoxRow>
          <Box>
            <Typography variant="h5" sx={{ color: theme.palette.text.secondary }}>
              <Link
                sx={{ color: theme.palette.text.primary }}
                onClick={() => navigate(Configuration.TEAMS_ID(`${getTeamById?.teamById.parentTeam?.id}`))}
              >
                {getTeamById?.teamById.parentTeam?.name}
              </Link>
            </Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography sx={{ marginBottom: 1 }} variant="h4">
          {t('teamdetailspage.identification.childteams')}
        </Typography>
      </Box>
      <Box
        sx={{
          marginBottom: 2
        }}
      >
        {getTeamById?.teamById.children?.map(subTeam => (
          <Box key={subTeam.id} sx={{ marginBottom: 1 }}>
            <Link
              sx={{ color: theme.palette.text.primary }}
              onClick={() => navigate(Configuration.TEAMS_ID(`${subTeam.id}`))}
            >
              {subTeam.name}
            </Link>
          </Box>
        ))}
      </Box>

      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() =>
          openFlyIn({
            content: <EditTeam teamId={params.id} create={true} />,
            callbackAfterClose: () => () => {
              refetchTeam()
              refetchTeamChildren()
            }
          })
        }
      >
        {t('teamdetailspage.identification.addchild')}
      </Button>
    </Box>
  )
}

export default TeamIdentification

import { FormControl, GridSize, InputLabel, OutlinedInput, Grid, Box } from '@mui/material'
import { ChangeEventHandler, useEffect, useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import { useFormContext } from 'react-hook-form'
import { OptionalGridWrapper } from '@epix-web-apps/ui'

/* eslint-disable-next-line */

export interface FormColorPickerProps {
  name: string
  label: string
  sx?: GridSize
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined
}

export function FormColorPicker({ name, label, sx, onChange }: FormColorPickerProps) {
  const form = useFormContext()
  if (!form) throw Error('Form elements can only be used inside the react hook forms provider')
  const { ref, ...register } = form.register(name)
  const defaultValue = form?.getValues(name)
  const [isOpen, toggle] = useState(false)
  const [currentColor, setCurrentColor] = useState(defaultValue)

  const handleColorChange = (color: string) => {
    setCurrentColor(color)
    form?.setValue(name, color)
  }

  useEffect(() => {
    setCurrentColor(defaultValue)
  }, [defaultValue])

  return (
    <OptionalGridWrapper sx={sx}>
      <FormControl fullWidth size="small">
        <Grid display={'flex'} sx={{ textAlign: 'center', justifyContent: 'space-between' }} item>
          <InputLabel htmlFor={register.name}>{label}</InputLabel>
          <OutlinedInput
            {...register}
            ref={ref}
            id={register.name}
            label={label}
            defaultValue={defaultValue}
            onChange={e => {
              setCurrentColor(e.currentTarget.value)
              register.onChange(e)
              onChange && onChange(e)
              form?.setValue(name, e.currentTarget.value)
            }}
            error={!!form.getFieldState(name).error}
          />
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '10px',
                border: '2px solid white',
                boxShadow: '0 0 0 1px lightgrey',
                cursor: 'pointer'
              }}
              style={{ backgroundColor: currentColor }}
              onClick={() => toggle(!isOpen)}
            />

            {isOpen && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 'calc(100% + 2px)',
                  right: 0,
                  borderRadius: '10px'
                }}
              >
                <HexColorPicker color={currentColor} onChange={handleColorChange} />
              </Box>
            )}
          </Box>
        </Grid>
      </FormControl>
    </OptionalGridWrapper>
  )
}

export default FormColorPicker

import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { useFlyIn } from '@epix-web-apps/ui'
import { useState } from 'react'
import {
  GraphqlError,
  useDeleteDayDefinitionEntryMutation,
  useGetDayDefinitionEntryByIdQuery
} from '@epix-web-apps/core'
import { useForm } from 'react-hook-form'
import { FormContainer } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface RemoveDayDefinitionEntryProps {
  dayDefinitionId: string
  dayDefinitionEntryId?: string
}

export function RemoveDayDefinitionEntry({ dayDefinitionId, dayDefinitionEntryId }: RemoveDayDefinitionEntryProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const deleteMutation = useDeleteDayDefinitionEntryMutation()
  const form = useForm()

  const { data: dayDefinitionEntryById, refetch: refetchDayDefinationEntryById } = useGetDayDefinitionEntryByIdQuery(
    {
      dayDefinitionId: dayDefinitionId || '',
      dayDefinitionEntryId: dayDefinitionEntryId || ''
    },
    {
      suspense: false
    }
  )

  const handleOnSubmit = async () => {
    await deleteMutation
      .mutateAsync({
        deleteDayDefinitionEntryCommand: {
          dayDefinitionId: dayDefinitionId || '',
          dayDefinitionEntryId: dayDefinitionEntryId || ''
        }
      })
      .then(() => {
        closeFlyIn()
        refetchDayDefinationEntryById()
      })
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.delete-daydefinition-entry.title')}</Typography>
      <Typography>{`${dayDefinitionEntryById?.dayDefinitionEntryById?.timeFrameType.value} - ${
        dayDefinitionEntryById?.dayDefinitionEntryById?.hours
      } ${t('flyin.delete-daydefinition-entry.hours')}`}</Typography>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={deleteMutation.isLoading}
        buttonText={t('common.remove')}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default RemoveDayDefinitionEntry

import { EVENT_ANNIVERSARY, useGetUpcomingAnniversariesQuery } from '@epix-web-apps/core'
import { People, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { Grid } from '@mui/material'
import { HideableSuspensePaper } from '../../suspense-paper'
import { useTranslation } from 'react-i18next'
import HomepageEventWidget from '../homepage-event-widget'
import { useNavigate } from 'react-router-dom'

/* eslint-disable-next-line */
export interface UpcomingAnniversariesProps {}

export function UpcomingAnniversaries(props: UpcomingAnniversariesProps) {
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const navigate = useNavigate()
  const { data: dataUpcomingAnniversaries, isLoading } = useGetUpcomingAnniversariesQuery(
    {
      icps: icpFilters
    },
    {
      suspense: false
    }
  )
  const limitedData = dataUpcomingAnniversaries?.upcomingAnniversaries.slice(0, 4)

  if (dataUpcomingAnniversaries?.upcomingAnniversaries && dataUpcomingAnniversaries.upcomingAnniversaries.length === 0)
    return null

  return (
    <Grid item sm={12} lg={6}>
      <HideableSuspensePaper isLoading={isLoading} title={t('homepage.event.anniversaries')}>
        <HomepageEventWidget
          onClick={personId => navigate(People.PEOPLE_ID_DETAILS(`${personId}`))}
          data={limitedData}
          length={dataUpcomingAnniversaries?.upcomingAnniversaries.length || 0}
          dataType={EVENT_ANNIVERSARY}
        />
      </HideableSuspensePaper>
    </Grid>
  )
}

export default UpcomingAnniversaries

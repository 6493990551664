import { Box, Link, Typography, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { OnboardingLayout } from '../../../layouts'
import { useNavigate } from 'react-router-dom'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { environment } from 'apps/myepix/src/environments/environment'
import { CanView, Import } from '@epix-web-apps/ui'
import { ROLE } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface OnboardingLabourCostsProps {}

export function OnboardingLabourCosts(props: OnboardingLabourCostsProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <>
      <OnboardingLayout title={t('labourcostpage.onboarding.title')} icon={<DashboardOutlinedIcon />} />
      <Box
        sx={{
          marginLeft: '20%',
          marginRight: '20%'
        }}
      >
        <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
          {t('labourcostpage.onboarding.description')}
        </Typography>

        <CanView roles={[ROLE.ADMIN, ROLE.IMPORTDATA]}>
          <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
            <Trans
              i18nKey={t('labourcostpage.onboarding.description2')}
              values={{ linktoimport: t('labourcostpage.onboarding.here') }}
              components={{
                tt: <Link underline="always" onClick={() => navigate(Import.IMPORT)} />
              }}
            />
          </Typography>
          <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
            <Trans
              i18nKey={t('labourcostpage.onboarding.description3')}
              values={{
                linktodocusaurus: t('labourcostpage.onboarding.here')
              }}
              components={{
                tt: (
                  <Link
                    underline="always"
                    target="_blank"
                    href={environment.docs.domainName + '/Import/import-csv/import-csv-calculateddata'}
                  />
                )
              }}
            />
          </Typography>
        </CanView>
      </Box>
    </>
  )
}

export default OnboardingLabourCosts

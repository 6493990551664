import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

export interface ITranslationConfig {
  defaultLanguage: string
  debug: boolean
}

export const loadTranslations = (config: ITranslationConfig) => {
  i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      lng: config.defaultLanguage,
      fallbackLng: config.defaultLanguage,
      debug: config.debug,
      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      },
      backend: {
        loadPath: '/assets/translations/{{lng}}.json'
      },
      returnEmptyString: false
    })

  return i18n
}

import { Box, Typography, Button, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useGetTenantAdminsQuery } from '@epix-web-apps/core'
import { useFlyIn, useGlobalStore } from '@epix-web-apps/ui'
import { AddTenantAdmin } from '../add-tenant-admin/add-tenant-admin'
import { RemoveTenantAdmin } from '../remove-tenant-admin/remove-tenant-admin'

export function TenantAdminsTable() {
  const { t } = useTranslation()
  const { me } = useGlobalStore()
  const { openFlyIn } = useFlyIn()
  const { data: getTenantAdmins, refetch: refetchTenantAdmins } = useGetTenantAdminsQuery()

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="h3" color="textSecondary">
          {t('tenantconfigurationpage.tenantadmins.title')}
        </Typography>
        <Button
          variant="contained"
          onClick={() =>
            openFlyIn({
              content: <AddTenantAdmin />,
              callbackAfterClose: () => refetchTenantAdmins
            })
          }
        >
          <AddIcon />
          {t('tenantconfigurationpage.tenantadmins.button.addtenantadmin')}
        </Button>
      </Box>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('tenantconfigurationpage.tenantadmins.table.name')}</TableCell>
            <TableCell>{t('tenantconfigurationpage.tenantadmins.table.username')}</TableCell>
            <TableCell width="10rem"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getTenantAdmins?.tenantAdmins.map(admin => (
            <TableRow key={admin.username}>
              <TableCell>{admin.name}</TableCell>
              <TableCell>{admin.username}</TableCell>
              <TableCell>
                {me?.id !== admin.id && (
                  <IconButton
                    sx={{ paddingY: 0 }}
                    size="small"
                    onClick={() =>
                      openFlyIn({
                        content: <RemoveTenantAdmin tenantAdmin={admin} />,
                        callbackAfterClose: () => refetchTenantAdmins
                      })
                    }
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

import { useGlobalPersistedStore } from '@epix-web-apps/ui'
import { useAnalyticsPersistedStore } from '../../../stores/analytics-store'
import FatalInjuriesIncidentsDataComponent from '../injuries-data-component/fatal-injuries-incidents-data-component'
import { useGetIncidentsQuery } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface FatalInjuriesDataComponentProps {}

export function FatalInjuriesDataComponent(props: FatalInjuriesDataComponentProps) {
  const { icpFilters } = useGlobalPersistedStore()
  const { dateFilter, genderFilter, functionAreaFilter, functionLevelFilter, employeeTypeFilter } =
    useAnalyticsPersistedStore()

  const { data: fatalInjuries } = useGetIncidentsQuery({
    icps: icpFilters,
    selectedDate: dateFilter,
    fatal: true,
    filterModel: {
      genderType: genderFilter,
      functionAreaType: functionAreaFilter,
      functionLevelType: functionLevelFilter,
      employeeType: employeeTypeFilter
    }
  })

  return <FatalInjuriesIncidentsDataComponent data={fatalInjuries?.incidents} fatalInjuries />
}

export default FatalInjuriesDataComponent

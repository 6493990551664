import { typeToFormSelectOption } from '../models'
import { getFormSelectOptions } from '../utils'
import {
  OrderDirection,
  useGetAbsenceTypesQuery,
  useGetAffectedContractsParameterTypesQuery,
  useGetAllAsbGroupAlteredWorkingAbilityTypesQuery,
  useGetAllAsbGroupContractClassificationTypesQuery,
  useGetAllAsbGroupDisabilityTypesQuery,
  useGetAllAsbGroupEducationTypesQuery,
  useGetAllAsbGroupHealthInsuranceCompanyTypesQuery,
  useGetAllAsbGroupPpContractTypesQuery,
  useGetAllAsbGroupSocialSecurityContractTypesQuery,
  useGetAllCarTypesQuery,
  useGetAllChurchDenominationTypesQuery,
  useGetAllCivilStateTypesQuery,
  useGetAllContractDurationTypesQuery,
  useGetAllCountriesQuery,
  useGetAllCurrenciesQuery,
  useGetAllDocumentAccessLevelTypesQuery,
  useGetAllEducationLevelTypesQuery,
  useGetAllEmployeeTypesQuery,
  useGetAllEmployersByIcpIdsQuery,
  useGetAllEmploymentRelationshipsTypesQuery,
  useGetAllEndReasonTypesQuery,
  useGetAllFamilyRelationTypesQuery,
  useGetAllFunctionAreaTypesQuery,
  useGetAllFunctionLevelTypesQuery,
  useGetAllFunctionsQuery,
  useGetAllGenderTypesQuery,
  useGetAllGeneralEducationLevelsTypesQuery,
  useGetAllIcpsQuery,
  useGetAllInsuranceStatusTypesQuery,
  useGetAllLastNameTypesQuery,
  useGetAllLocalesQuery,
  useGetAllNationalitiesQuery,
  useGetAllowedValueTypesQuery,
  useGetAllParentStatusTypesQuery,
  useGetAllPopulationTypesQuery,
  useGetAllProductivityViewPeriodTypesQuery,
  useGetAllProfessionalEducationLevelsTypesQuery,
  useGetAllReasonSalaryChangeTypesQuery,
  useGetAllTaxClassesTypesQuery,
  useGetAllTitleTypesQuery,
  useGetCancellationAllowedTypesQuery,
  useGetCarBrandsQuery,
  useGetCarCategoriesQuery,
  useGetCarContractTypesQuery,
  useGetCarUsageTypesQuery,
  useGetEventTypesQuery,
  useGetGroupTypesQuery,
  useGetMotorTypesQuery,
  useGetObfuscatedTypesQuery,
  useGetPagedExternalCodesQuery,
  useGetPayPeriodTypesQuery,
  useGetRequestMinimumTypesQuery,
  useGetRequestPolicyTimeTypesQuery,
  useGetSalaryEntryTypesQuery,
  useGetSubGroupTypesQuery,
  useGetTimeFrameTypesQuery,
  useGetTimeTypesQuery,
  useGetUpdateOperationTypesQuery,
  useGetValueTypesQuery,
  useGetWorkPermitTypesQuery
} from '../services'

/* prettier-ignore */
export const formTypeSelectOptions = {
  get absenceOptions() {
    return getFormSelectOptions(useGetAbsenceTypesQuery, result => typeToFormSelectOption(result.absenceTypes))
  },
  get affectedContractsParameterTypeOptions() {
    return getFormSelectOptions(useGetAffectedContractsParameterTypesQuery, result => typeToFormSelectOption(result.affectedContractsParameterTypes))
  },
  get allowedValueOptions() {
    return getFormSelectOptions(useGetAllowedValueTypesQuery, result => typeToFormSelectOption(result.allowedValueTypes))
  },
  get allValueOptions() {
    return getFormSelectOptions(useGetValueTypesQuery, result => typeToFormSelectOption(result.valueTypes))
  },
  get asbGroupAlteredWorkingAbilityTypeOptions() {
    return getFormSelectOptions(useGetAllAsbGroupAlteredWorkingAbilityTypesQuery, result => typeToFormSelectOption(result.allASBGroupAlteredWorkingAbilityTypes))
  },
  get asbContractClassificationTypeOptions() {
    return getFormSelectOptions(useGetAllAsbGroupContractClassificationTypesQuery, result => typeToFormSelectOption(result.allASBGroupContractClassificationTypes))
  },
  get asbDisabilityTypeOptions() {
    return getFormSelectOptions(useGetAllAsbGroupDisabilityTypesQuery, result => typeToFormSelectOption(result.allASBGroupDisabilityTypes))
  },
  get asbEducationTypeOptions() {
    return getFormSelectOptions(useGetAllAsbGroupEducationTypesQuery, result => typeToFormSelectOption(result.allASBGroupEducationTypes))
  },
  get asbHealthInsuranceCompanyTypeOptions() {
    return getFormSelectOptions(useGetAllAsbGroupHealthInsuranceCompanyTypesQuery, result => typeToFormSelectOption(result.allASBGroupHealthInsuranceCompanyTypes))
  },
  get asbPpContractTypeOptions() {
    return getFormSelectOptions(useGetAllAsbGroupPpContractTypesQuery, result => typeToFormSelectOption(result.allASBGroupPPContractTypes))
  },
  get asbSocialSecurityContractTypeOptions() {
    return getFormSelectOptions(useGetAllAsbGroupSocialSecurityContractTypesQuery, result => typeToFormSelectOption(result.allASBGroupSocialSecurityContractTypes))
  },
  get cancellationAllowedTypeOptions() {
    return getFormSelectOptions(useGetCancellationAllowedTypesQuery, result => typeToFormSelectOption(result.cancellationAllowedTypes))
  },
  get carBrandOptions() {
    return getFormSelectOptions(useGetCarBrandsQuery, result => typeToFormSelectOption(result.carBrands))
  },
  get carCategoryOptions() {
    return getFormSelectOptions(
      (variables?: any, options?: any) => 
        useGetCarCategoriesQuery(
          {
            offset: 0,
            limit: -1,
            sortByProperty: 'Value',
            orderDirection: OrderDirection.Asc,
            ...variables
          },
          { ...options }), 
        result => typeToFormSelectOption(result.carCategories.data.map(r => ({ key: r.id, value: r.description, active: r.active }))))
  },
  get carContractTypeOptions() {
    return getFormSelectOptions(useGetCarContractTypesQuery, result => typeToFormSelectOption(result.carContractTypes))
  },
  get carTypeOptions() {
    return getFormSelectOptions(useGetAllCarTypesQuery, result => typeToFormSelectOption(result.carTypes))
  },
  get carUsageTypeOptions() {
    return getFormSelectOptions(useGetCarUsageTypesQuery, result => typeToFormSelectOption(result.carUsageTypes))
  },
  get churchDenominationTypeOptions() {
    return getFormSelectOptions(useGetAllChurchDenominationTypesQuery, result => typeToFormSelectOption(result.churchDenominationTypes))
  },
  get civilStateOptions() {
    return getFormSelectOptions(useGetAllCivilStateTypesQuery, result => typeToFormSelectOption(result.civilStateTypes))
  },
  get contractDurationOptions() {
    return getFormSelectOptions(useGetAllContractDurationTypesQuery, result => typeToFormSelectOption(result.contractDurationTypes))
  },
  get contractEndReasonOptions() {
    return getFormSelectOptions(useGetAllEndReasonTypesQuery, result => typeToFormSelectOption(result.contractEndReasonTypes))
  },
  get countryOptions() {
    return getFormSelectOptions(useGetAllCountriesQuery, result => typeToFormSelectOption(result.countries.map(r => ({ key: r.code, value: r.name }))))
  },
  get currencyOptions() {
    return getFormSelectOptions(useGetAllCurrenciesQuery, result => typeToFormSelectOption(result.currencies.map(r => ({ key: r.currency, value: r.name }))))
  },
  get deductionGroupOptions() {
    return getFormSelectOptions(
      (variables?: any, options?: any) => useGetGroupTypesQuery({ isDeduction: true, ...variables }, { ...options }),
      result => typeToFormSelectOption(result.groupTypes.map(r => r.typeModel))
    )
  },
  get documentAccessLevelTypeOptions() {
    return getFormSelectOptions(useGetAllDocumentAccessLevelTypesQuery, result => typeToFormSelectOption(result.allDocumentAccessLevelTypes))
  },
  get earningGroupOptions() {
    return getFormSelectOptions(
      (variables?: any, options?: any) => useGetGroupTypesQuery({ isDeduction: false, ...variables }, { ...options }),
      result => typeToFormSelectOption(result.groupTypes.map(r => r.typeModel))
    )
  },
  get educationLevelOptions() {
    return getFormSelectOptions(useGetAllEducationLevelTypesQuery, result => typeToFormSelectOption(result.educationLevelTypes))
  },
  get employeeOptions() {
    return getFormSelectOptions(useGetAllEmployeeTypesQuery, result => typeToFormSelectOption(result.employeeTypes))
  },
  get employerOptions() {
    return getFormSelectOptions(useGetAllEmployersByIcpIdsQuery, result => typeToFormSelectOption(result.employersByIcpIds.map(r => ({ key: r.id, value: r.companyName }))))
  },
  get employmentRelationshipOptions() {
    return getFormSelectOptions(useGetAllEmploymentRelationshipsTypesQuery, result => typeToFormSelectOption(result.employmentRelationshipsTypes))
  },
  get eventTypeOptions() {
    return getFormSelectOptions(useGetEventTypesQuery, result => typeToFormSelectOption(result.eventTypes))
  },
  get externalCodeTypeOptions() {
    return getFormSelectOptions(
      (variables?: any, options?: any) => 
        useGetPagedExternalCodesQuery(
          {
            offset: 0,
            limit: -1,
            sortByProperty: 'Value',
            orderDirection: OrderDirection.Asc,
            ...variables
          },
          { ...options }), 
        result => typeToFormSelectOption(result.pagedExternalCodes.data.map(r => ({ key: r.key, value: r.value }))))
  },
  get familyRelationOptions() {
    return getFormSelectOptions(useGetAllFamilyRelationTypesQuery, result => typeToFormSelectOption(result.familyRelationTypes))
  },
  get functionAreaOptions() {
    return getFormSelectOptions(
      (variables?: any, options?: any) =>
        useGetAllFunctionAreaTypesQuery(
          {
            offset: 0,
            limit: -1,
            sortByProperty: 'key',
            orderDirection: OrderDirection.Asc,
            ...variables
          },
          { ...options }
        ),
      result => typeToFormSelectOption(result.functionAreaTypes.data.map(r => ({ key: r.key, value: r.value, active: r.active })))
    )
  },
  get functionLevelOptions() {
    return getFormSelectOptions(
      (variables?: any, options?: any) =>
        useGetAllFunctionLevelTypesQuery(
          {
            offset: 0,
            limit: -1,
            sortByProperty: 'key',
            orderDirection: OrderDirection.Asc,
            ...variables
          },
          { ...options }
        ),
      result => typeToFormSelectOption(result.functionLevelTypes.data.map(r => ({ key: r.key, value: r.value, active: r.active })))
    )
  },
  get functionOptions() {
    return getFormSelectOptions(
      (variables?: any, options?: any) => 
        useGetAllFunctionsQuery({
          offset: 0,
          limit: -1,
          sortByProperty: 'Name',
          orderDirection: OrderDirection.Asc,
          ...variables
        },
        {...options}
    ), result => typeToFormSelectOption(result.allFunctions.data.map(r => ({ key: r.id, value: `${r.description} - ${r.code}` }))))
  },
  get genderOptions() {
    return getFormSelectOptions(useGetAllGenderTypesQuery, result => typeToFormSelectOption(result.genderTypes))
  },
  get generalEducationLevelOptions() {
    return getFormSelectOptions(useGetAllGeneralEducationLevelsTypesQuery, result => typeToFormSelectOption(result.generalEducationLevelTypes))
  },
  get groupOptions() {
    return getFormSelectOptions(useGetGroupTypesQuery, result => typeToFormSelectOption(result.groupTypes.map(r => r.typeModel)))
  },
  get icpOptions() {
    return getFormSelectOptions(
      (variables?: any, options?: any) =>
        useGetAllIcpsQuery(
          {
            offset: 0,
            limit: -1,
            sortByProperty: 'Code',
            orderDirection: OrderDirection.Asc,
            ...variables
          },
          { ...options }
        ),
      result => typeToFormSelectOption(result.icps.data.map(r => ({ key: r.id, value: `${r.payrollProvider} (${r.code})` })))
    )
  },
  get insuranceStatusOptions() {
    return getFormSelectOptions(useGetAllInsuranceStatusTypesQuery, result => typeToFormSelectOption(result.insuranceStatusTypes))
  },
  get lastNameOptions() {
    return getFormSelectOptions(useGetAllLastNameTypesQuery, result => typeToFormSelectOption(result.lastNameTypes))
  },
  get localeOptions() {
    return getFormSelectOptions(useGetAllLocalesQuery, result => typeToFormSelectOption(result.locales.map(r => ({ key: r.locale, value: r.language }))))
  },
  get motorTypeOptions() {
    return getFormSelectOptions(useGetMotorTypesQuery, result => typeToFormSelectOption(result.motorTypes))
  },
  get nationalityOptions() {
    return getFormSelectOptions(useGetAllNationalitiesQuery, result => typeToFormSelectOption(result.nationalities.map(r => ({ key: r.code, value: r.name }))))
  },
  get obfuscatedOptions() {
    return getFormSelectOptions(useGetObfuscatedTypesQuery, result => typeToFormSelectOption(result.obfuscatedTypes.map(r => ({ key: r.key, value: r.value }))))
  },
  get parentStatusOptions() {
    return getFormSelectOptions(useGetAllParentStatusTypesQuery, result => typeToFormSelectOption(result.parentStatusTypes))
  },
  get populationOptions() {
    return getFormSelectOptions(useGetAllPopulationTypesQuery, result => typeToFormSelectOption(result.populationTypes))
  },
  get productivityViewPeriodTypeOptions() {
    return getFormSelectOptions(useGetAllProductivityViewPeriodTypesQuery, result => typeToFormSelectOption(result.allProductivityViewPeriodTypes))
  },
  get professionalEducationLevelOptions() {
    return getFormSelectOptions(useGetAllProfessionalEducationLevelsTypesQuery, result => typeToFormSelectOption(result.professionalEducationLevelTypes))
  },
  get requestMinimumOptions() {
    return getFormSelectOptions(useGetRequestMinimumTypesQuery, result => typeToFormSelectOption(result.requestMinimumTypes))
  },
  get requestPolicyTimeOptions() {
    return getFormSelectOptions(useGetRequestPolicyTimeTypesQuery, result => typeToFormSelectOption(result.requestPolicyTimeTypes))
  },
  get salaryEntryTypeOptions() {
    return getFormSelectOptions(useGetSalaryEntryTypesQuery, result => typeToFormSelectOption(result.allSalaryEntryTypes))
  },
  get salaryChangedReasonOptions() {
    return getFormSelectOptions(useGetAllReasonSalaryChangeTypesQuery, result => typeToFormSelectOption(result.reasonSalaryChangeTypes))
  },
  get subGroupOptions() {
    return getFormSelectOptions(useGetSubGroupTypesQuery, result => typeToFormSelectOption(result.subGroupTypes.map(r => ({ key: r.key, value: r.value }))))
  },
  get taxClassesOptions() {
    return getFormSelectOptions(useGetAllTaxClassesTypesQuery, result => typeToFormSelectOption(result.taxClassTypes))
  },
  get timeFrameOptions() {
    return getFormSelectOptions(useGetTimeFrameTypesQuery, result => typeToFormSelectOption(result.timeFrameTypes))
  },
  get timeOptions() {
    return getFormSelectOptions(useGetTimeTypesQuery, result => typeToFormSelectOption(result.timeTypes))
  },
  get titleOptions() {
    return getFormSelectOptions(useGetAllTitleTypesQuery, result => typeToFormSelectOption(result.titleTypes))
  },
  get updateOperationTypeOptions() {
    return getFormSelectOptions(useGetUpdateOperationTypesQuery, result => typeToFormSelectOption(result.updateOperationTypes))
  },
  get workPermitOptions() {
    return getFormSelectOptions(useGetWorkPermitTypesQuery, result => typeToFormSelectOption(result.workPermitTypes))
  },
  get payPeriodOptions() {
    return getFormSelectOptions(useGetPayPeriodTypesQuery, result => typeToFormSelectOption(result.allPayPeriodTypes))
  }
}

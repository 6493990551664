import { FormControl, FormControlLabel, FormLabel, GridSize, Radio, RadioGroup } from '@mui/material'
import { ChangeEvent, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { OptionalGridWrapper } from '../form-grid-wrapper'

export interface IFormRadioOption {
  id: string
  label: string
}

export class FormRadioOption implements IFormRadioOption {
  id: string
  label: string

  constructor(id: string, label: string) {
    this.id = id
    this.label = label
  }
}

/* eslint-disable-next-line */
export interface FormRadioGroupProps {
  name: string
  label?: string
  options?: Array<IFormRadioOption>
  onChange?: ((event: ChangeEvent<HTMLInputElement>, value: string) => void) | undefined
  sx?: GridSize
  row?: boolean
  disabled?: boolean
  defaultSelected?: string | null
  gap?: string | null
}

export function FormRadioGroup({
  name,
  label,
  options = undefined,
  onChange,
  sx,
  row = false,
  disabled = false,
  defaultSelected,
  gap
}: FormRadioGroupProps) {
  const form = useFormContext()
  if (!form) throw Error('Form elements can only be used inside the react hook forms provider')
  const { ref, ...register } = form.register(name)
  const defaultValue = defaultSelected ?? form?.getValues(name)

  useEffect(() => {
    form?.setValue(name, defaultValue)
  }, [defaultSelected])

  return (
    <OptionalGridWrapper sx={sx}>
      <FormControl error={!!form.getFieldState(name).error} fullWidth size="small">
        {label && <FormLabel id={register.name}>{label}</FormLabel>}
        <Controller
          {...register}
          defaultValue={defaultValue || null}
          render={({ field }) => (
            <RadioGroup
              {...field}
              row={row}
              id={register.name}
              value={field.value || defaultValue}
              sx={{ gap: gap, p: [1] }}
              onChange={(e, value) => {
                field.onChange(e)
                onChange && onChange(e, value)
              }}
            >
              {options?.map((x, index) => (
                <FormControlLabel
                  key={index}
                  value={x.id}
                  control={<Radio id={`${register.name}${index}`} />}
                  label={x.label}
                  disabled={disabled}
                />
              ))}
            </RadioGroup>
          )}
        />
      </FormControl>
    </OptionalGridWrapper>
  )
}

export default FormRadioGroup

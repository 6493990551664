import { Typography } from '@mui/material'
import { FormContainer } from '@epix-web-apps/ui'
import { TypeOf, string, object, boolean } from 'zod'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { FormGridLayout } from '@epix-web-apps/ui'
import { FormSelect } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import {
  FormSelectOption,
  GraphqlError,
  OrderDirection,
  useCreateTeamAuthorizationMutation,
  useGetAllFunctionsQuery,
  useGetAllTeamsQuery,
  useGetTeamAuthorizationByIdQuery,
  useUpdateTeamAuthorizationMutation
} from '@epix-web-apps/core'
import { FormSwitch } from '../../form-components/form-switch'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface AddEditTeamAuthorizationForPersonProps {
  personId: string | undefined
  teamAuthorizationId?: string | undefined
}

export function AddEditTeamAuthorizationForPerson({
  personId,
  teamAuthorizationId
}: AddEditTeamAuthorizationForPersonProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const { data: getTeamAuthorizationById, refetch } = useGetTeamAuthorizationByIdQuery(
    {
      id: teamAuthorizationId || ''
    },
    {
      enabled: !!teamAuthorizationId
    }
  )

  const { data: allFunctions } = useGetAllFunctionsQuery({
    limit: -1,
    offset: 0,
    sortByProperty: 'Name',
    orderDirection: OrderDirection.Asc
  })

  const { data: allTeams } = useGetAllTeamsQuery({})

  const addEditTeamAuthorizationForPersonSchema = object({
    roleId: string({
      required_error: t('form.validation.rolerequired'),
      invalid_type_error: t('form.validation.rolerequired')
    }),
    teamId: string({
      required_error: t('form.validation.teamrequired'),
      invalid_type_error: t('form.validation.teamrequired')
    }),
    validForChildTeams: boolean()
  })

  type AddTeamAuthorizationForPersonForm = TypeOf<typeof addEditTeamAuthorizationForPersonSchema>

  const form = useForm<AddTeamAuthorizationForPersonForm>({
    resolver: zodResolver(addEditTeamAuthorizationForPersonSchema),
    defaultValues: {
      roleId: getTeamAuthorizationById?.teamAuthorizationById.functionId,
      teamId: getTeamAuthorizationById?.teamAuthorizationById.teamId,
      validForChildTeams: getTeamAuthorizationById?.teamAuthorizationById.validForChildTeams
    }
  })

  const createMutation = useCreateTeamAuthorizationMutation()
  const updateMutation = useUpdateTeamAuthorizationMutation()

  const handleOnSubmit = async (newTeamAuthorization: AddTeamAuthorizationForPersonForm) => {
    if (!teamAuthorizationId) {
      await createMutation
        .mutateAsync({
          createTeamAuthorizationCommand: {
            personId: personId || '',
            teamId: newTeamAuthorization.teamId || '',
            functionId: newTeamAuthorization.roleId,
            validForChildTeams: newTeamAuthorization.validForChildTeams
          }
        })
        .then(closeFlyIn)
        .catch(e => setBackendErrors([e]))
    } else {
      await updateMutation
        .mutateAsync({
          updateTeamAuthorizationCommand: {
            id: teamAuthorizationId,
            teamId: newTeamAuthorization.teamId || '',
            functionId: newTeamAuthorization.roleId,
            validForChildTeams: newTeamAuthorization.validForChildTeams
          }
        })
        .then(() => {
          closeFlyIn()
          refetch()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addteamauthorization.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name={'roleId'}
          label={`${t('form.field.function')} *`}
          options={allFunctions?.allFunctions.data.map(x => new FormSelectOption(x.id, `${x.description} (${x.code})`))}
        />

        <FormSelect
          sx={12}
          name={'teamId'}
          label={`${t('form.field.teams')} *`}
          options={allTeams?.allTeams.map(x => new FormSelectOption(x.id, `${x.name}`))}
        />

        <FormSwitch sx={12} name="validForChildTeams" label={t('form.field.validforchildteams')} />
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={createMutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddEditTeamAuthorizationForPerson

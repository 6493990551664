import { DataTable, ISortModel, SortModel } from '../../data-table'
import { useEffect, useState } from 'react'
import { GridColDef, GridColumnVisibilityModel, GridRowsProp } from '@mui/x-data-grid'
import {
  CALCULATED_DATA_VALUE_TYPE_KEYS,
  formTypeSelectOptions,
  OrderDirection,
  useGetCalculatedDataByContractIdQuery,
  useGetMeQuery
} from '@epix-web-apps/core'
import { Box, Button, Typography } from '@mui/material'
import { DATE_INPUT_FORMAT, FormDatepicker, FormGridLayout, useFlyIn } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { TypeOf, date, object, string } from 'zod'
import { useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormSelect } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'

interface FilterModel {
  payrollPeriodStartDate: Date | null
  payrollPeriodEndDate: Date | null
  calculationStartDate: Date | null
  calculationEndDate: Date | null
  groupKey: string | null
  subGroupKey: string | null
  valueTypeKey: string | null
  currencyKey: string | null
}

interface CurrentCurrency {
  key: string | undefined
  name: string | undefined
  symbol: string | undefined
}

/* eslint-disable-next-line */
export interface ContractCalculatedDataTabProps {
  contractId: string | undefined
}

export function ContractCalculatedDataTab({ contractId }: ContractCalculatedDataTabProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const { data: me } = useGetMeQuery()

  const currentCurrency = {
    key: me?.me.currency,
    name: me?.me.currencyName,
    symbol: me?.me.currencySymbol
  }

  const [filterModel, setFilterModel] = useState<FilterModel>({
    payrollPeriodStartDate: null,
    payrollPeriodEndDate: null,
    calculationStartDate: null,
    calculationEndDate: null,
    groupKey: null,
    subGroupKey: null,
    valueTypeKey: null,
    currencyKey: null
  })

  const columns: GridColDef[] = [
    {
      field: 'payrollCode',
      headerName: t('contractcalculateddatapage.datatable.column.code'),
      sortable: false
    },
    {
      field: 'userFriendlyDescription',
      headerName: t('contractcalculateddatapage.datatable.column.description'),
      flex: 1,
      sortable: false
    },
    {
      field: 'description',
      headerName: t('contractcalculateddatapage.datatable.column.providerdescription'),
      flex: 1,
      sortable: false
    },
    {
      field: 'value',
      headerName: t('contractcalculateddatapage.datatable.column.value'),
      flex: 1,
      sortable: false,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'valueInCurrentCurrency',
      headerName: currentCurrency.name,
      flex: 1,
      sortable: false,
      align: 'right',
      headerAlign: 'right',
      hideable: true
    }
  ]

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns[0]?.field, OrderDirection.Asc))
  const [tableData, setTableData] = useState<GridRowsProp>([])

  const {
    data: calculatedData,
    isLoading,
    refetch: refetchCalculatedData
  } = useGetCalculatedDataByContractIdQuery({
    contractId: contractId || '',
    filterModel: filterModel
  })

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    valueInCurrentCurrency: calculatedData?.calculatedDataByContractId
      ? currentCurrency.key !== calculatedData?.calculatedDataByContractId.icpCurrencyKey
      : false
  })

  useEffect(() => {
    if (calculatedData && calculatedData.calculatedDataByContractId) {
      if (me?.me.currency !== calculatedData?.calculatedDataByContractId.icpCurrencyKey) {
        setFilterModel({
          ...filterModel,
          currencyKey: currentCurrency.key || ''
        })
        setColumnVisibilityModel({ valueInCurrentCurrency: true })
      } else {
        setColumnVisibilityModel({ valueInCurrentCurrency: false })
      }
    }
  }, [me?.me])

  useEffect(() => {
    if (calculatedData && calculatedData.calculatedDataByContractId) {
      const data = calculatedData?.calculatedDataByContractId

      setFilterModel({
        payrollPeriodStartDate: data.payrollPeriodStartDate,
        payrollPeriodEndDate: data.payrollPeriodEndDate,
        calculationStartDate: data.calculationStartDate ? data.calculationStartDate : null,
        calculationEndDate: data.calculationEndDate ? data.calculationEndDate : null,
        groupKey: data.groupKey ?? null,
        subGroupKey: data.subGroupKey ?? null,
        valueTypeKey: data.valueTypeKey ?? null,
        currencyKey: currentCurrency.key ?? null
      })

      setTableData(
        data.calculatedDataDetails?.map(row => {
          let valueSymbol

          switch (row.valueTypeKey) {
            case CALCULATED_DATA_VALUE_TYPE_KEYS.AMOUNT:
              valueSymbol = data.icpCurrencySymbol
              break
            case CALCULATED_DATA_VALUE_TYPE_KEYS.PERCENTAGE:
              valueSymbol = '%'
              break
            case CALCULATED_DATA_VALUE_TYPE_KEYS.HOURS:
            case CALCULATED_DATA_VALUE_TYPE_KEYS.DAYS:
              valueSymbol = row.valueTypeKey.charAt(0).toLowerCase()
              break
            default:
              valueSymbol = ''
              break
          }

          return {
            id: row.id,
            payrollCode: row.payrollCode,
            userFriendlyDescription: row.userFriendlyDescription,
            description: row.description,
            value: `${row.value} ${valueSymbol}`,
            valueInCurrentCurrency: row.valueInEuro !== 0 ? `${row.valueInEuro} ${currentCurrency.symbol}` : '',
            type: row.valueTypeKey
          }
        })
      )
    } else {
      setFilterModel({
        payrollPeriodStartDate: null,
        payrollPeriodEndDate: null,
        calculationStartDate: null,
        calculationEndDate: null,
        groupKey: null,
        subGroupKey: null,
        valueTypeKey: null,
        currencyKey: currentCurrency.key ?? null
      })
    }
  }, [calculatedData])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: 2
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <span>
            {t('contractcalculateddatapage.payrollperiod')}:
            <strong>
              {`
                ${new Date(filterModel.payrollPeriodStartDate!).toLocaleDateString()}
                -
                ${new Date(filterModel.payrollPeriodEndDate!).toLocaleDateString()}
              `}
            </strong>
          </span>

          {(filterModel.calculationStartDate || filterModel.calculationEndDate) && (
            <span>
              {t('contractcalculateddatapage.calculationperiod')}:
              <strong>
                {`
                  ${
                    filterModel.calculationStartDate
                      ? new Date(filterModel.calculationStartDate).toLocaleDateString()
                      : '...'
                  }
                  -
                  ${
                    filterModel.calculationEndDate
                      ? new Date(filterModel.calculationEndDate).toLocaleDateString()
                      : '...'
                  }
                `}
              </strong>
            </span>
          )}

          <Typography variant='description'>
              {t('contractcalculateddatapage.perioddisclaimer')}
          </Typography>
        </Box>
        <Button
          variant="outlined"
          onClick={() =>
            openFlyIn({
              content: (
                <CalculatedDataPerContractFilters
                  filterModel={filterModel}
                  setFilterModel={setFilterModel}
                  currentCurrency={currentCurrency}
                />
              ),
              callbackAfterClose: () => () => {
                refetchCalculatedData()
              }
            })
          }
        >
          {t('contractcalculateddatapage.button.changefilters')}
        </Button>
      </Box>
      <DataTable
        data={tableData}
        columns={columns}
        onSortChange={setSortModel}
        isLoading={isLoading}
        columnsToHide={columnVisibilityModel}
        defaultSortModel={sortModel}
        hideFooter
      />
    </>
  )
}

interface CalculatedDataPerContractFiltersProps {
  filterModel: FilterModel
  setFilterModel: (filterModel: FilterModel) => void
  currentCurrency: CurrentCurrency
}

function CalculatedDataPerContractFilters({
  filterModel,
  setFilterModel,
  currentCurrency
}: CalculatedDataPerContractFiltersProps) {
  const { groupOptions, subGroupOptions, allValueOptions } = formTypeSelectOptions
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const calculatedDataFilterSchema = object({
    payrollPeriodStartDate: date(),
    payrollPeriodEndDate: date(),
    calculationStartDate: date().optional().nullable(),
    calculationEndDate: date().optional().nullable(),
    groupKey: string().optional().nullable(),
    subGroupKey: string().optional().nullable(),
    valueTypeKey: string().optional().nullable()
  })

  type CalculatedDataFilterSchema = TypeOf<typeof calculatedDataFilterSchema>

  function handleOnSubmit(filterModel: CalculatedDataFilterSchema) {
    setFilterModel({
      payrollPeriodStartDate: filterModel.payrollPeriodStartDate,
      payrollPeriodEndDate: filterModel.payrollPeriodEndDate,
      calculationStartDate: filterModel.calculationStartDate ? filterModel.calculationStartDate : null,
      calculationEndDate: filterModel.calculationEndDate ? filterModel.calculationEndDate : null,
      groupKey: filterModel.groupKey!,
      subGroupKey: filterModel.subGroupKey!,
      valueTypeKey: filterModel.valueTypeKey!,
      currencyKey: currentCurrency.key!
    })
    closeFlyIn()
  }

  function resetFilters() {
    setFilterModel({
      ...filterModel,
      payrollPeriodStartDate: null,
      payrollPeriodEndDate: null,
      calculationStartDate: null,
      calculationEndDate: null,
      groupKey: null,
      subGroupKey: null,
      valueTypeKey: null
    })
    closeFlyIn()
  }

  const form = useForm<CalculatedDataFilterSchema>({
    resolver: zodResolver(calculatedDataFilterSchema),
    defaultValues: {
      payrollPeriodStartDate: new Date(filterModel.payrollPeriodStartDate!),
      payrollPeriodEndDate: new Date(filterModel.payrollPeriodEndDate!),
      calculationStartDate: filterModel.calculationStartDate ? new Date(filterModel.calculationStartDate) : undefined,
      calculationEndDate: filterModel.calculationEndDate ? new Date(filterModel.calculationEndDate) : undefined,
      groupKey: filterModel.groupKey,
      subGroupKey: filterModel.subGroupKey,
      valueTypeKey: filterModel.valueTypeKey
    }
  })

  const { control } = form
  const watchedGroupTypeKey = useWatch({
    control,
    name: `groupKey`
  })

  const { handleSubmit } = form

  return (
    <FormContainer form={form} onSubmit={handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('contractcalculateddatapage.button.changefilters')}</Typography>

      <FormGridLayout>
        <FormDatepicker
          sx={6}
          name="payrollPeriodStartDate"
          inputFormat={DATE_INPUT_FORMAT}
          label={`${t('form.field.payrollperiodstartdate')} *`}
        />

        <FormDatepicker
          sx={6}
          name="payrollPeriodEndDate"
          inputFormat={DATE_INPUT_FORMAT}
          label={`${t('form.field.payrollperiodenddate')} *`}
        />

        <FormDatepicker
          sx={6}
          name="calculationStartDate"
          inputFormat={DATE_INPUT_FORMAT}
          label={`${t('form.field.calculationperiodstartdate')}`}
        />

        <FormDatepicker
          sx={6}
          name="calculationEndDate"
          inputFormat={DATE_INPUT_FORMAT}
          label={`${t('form.field.calculationperiodenddate')}`}
        />

        <FormSelect sx={12} name="groupKey" label="Group" options={groupOptions} />

        <FormSelect
          sx={12}
          name="subGroupKey"
          label="Subgroup"
          options={subGroupOptions.filter(s => watchedGroupTypeKey && s.id.includes(watchedGroupTypeKey))}
        />

        <FormSelect sx={12} name="valueTypeKey" label="Value type" options={allValueOptions} />
      </FormGridLayout>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button variant="contained" type="submit">
            {t('contractcalculateddatapage.button.filter')}
          </Button>
          <Button variant="outlined" onClick={closeFlyIn}>
            {t('contractcalculateddatapage.button.cancel')}
          </Button>
        </Box>
        <Button variant="text" onClick={resetFilters}>
          {t('contractcalculateddatapage.button.resetfilters')}
        </Button>
      </Box>
    </FormContainer>
  )
}

export default ContractCalculatedDataTab

import { useCallback } from 'react'
import { Box, IconButton } from '@mui/material'
import { ToBackendFormatedDate, useGetPreviousAndNextQuery, useNavigateWithParams } from '@epix-web-apps/core'
import { People, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { usePeopleFilterStore } from '../../../stores/people-page-filter-store'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

export interface NextPreviousPersonProps {
  personId: string
}

export function NextPreviousPerson({ personId }: NextPreviousPersonProps) {
  const navigateWithParams = useNavigateWithParams()
  const { showInactive, showWithoutContract, showValidations, sortByProperty, sortDirection } = usePeopleFilterStore()
  const { icpFilters } = useGlobalPersistedStore()

  const { data: nextAndPreviousPerson } = useGetPreviousAndNextQuery(
    {
      selectedDate: ToBackendFormatedDate(new Date()),
      showInactive: showInactive,
      showWithoutContract: showWithoutContract,
      showValidations: showValidations,
      personId: personId,
      icps: icpFilters,
      sortByProperty: sortByProperty,
      orderDirection: sortDirection
    },
    {
      suspense: false,
      enabled: !!personId
    }
  )

  const indexCurrentPerson = nextAndPreviousPerson?.previousNext.map(c => c.personId).indexOf(personId)
  const hasPrevious = nextAndPreviousPerson && indexCurrentPerson === 1
  const hasNext = nextAndPreviousPerson && nextAndPreviousPerson.previousNext.length - 1 !== indexCurrentPerson

  const navigateToPreviousPerson = useCallback(() => {
    if (hasPrevious) {
      const previousPersonId = nextAndPreviousPerson.previousNext[0].personId
      navigateWithParams(People.PEOPLE_ID_DETAILS(`${previousPersonId}`))
    }
  }, [nextAndPreviousPerson, personId])

  const navigateToNextPerson = useCallback(() => {
    if (hasNext) {
      const nextPersonId = nextAndPreviousPerson.previousNext[nextAndPreviousPerson.previousNext.length - 1].personId
      navigateWithParams(People.PEOPLE_ID_DETAILS(`${nextPersonId}`))
    }
  }, [nextAndPreviousPerson, personId])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <IconButton disabled={!hasPrevious} size="small" onClick={navigateToPreviousPerson}>
        <ArrowBackIosNewIcon />
      </IconButton>
      <PeopleAltOutlinedIcon color="action" />
      <IconButton disabled={!hasNext} size="small" onClick={navigateToNextPerson}>
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  )
}

export default NextPreviousPerson

import {
  DEFAULT_SALARY_TYPE,
  formTypeSelectOptions,
  useGetAllUsedValueTypesByPayrollCodeIdQuery,
  useGetAllValueTypePayComponentsQuery,
  useGetPayrollCodeByIdQuery,
  useUpdatePayrollCodeMutation
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormLabel, Grid, Typography } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormGridLayout, FormRadioGroup, useFlyIn } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'
import FormCheckboxGroup from '../../form-components/form-checkbox-group/form-checkbox-group'
import { TypeOf, array, boolean, object, string } from 'zod'

/* eslint-disable-next-line */
export interface AddEditSalarySettingsProps {
  employerId: string
  payrollCodeId?: string
}

export function AddEditSalarySettings({ employerId, payrollCodeId }: AddEditSalarySettingsProps) {
  const { t } = useTranslation()

  const addEditPayrollCodeSchema = object({
    employerId: string({
      required_error: t('form.validation.employerrequired'),
      invalid_type_error: t('form.validation.employerrequired')
    }).min(1, t('form.validation.employerrequired')),
    reverseSignImport: boolean(),
    reverseSignExport: boolean(),
    isSalaryEntry: boolean(),
    salaryEntryKey: string().optional().nullable(),
    valueTypePayComponents: array(string()).optional().nullable()
  })

  type CreateEditPayrollCodeForm = TypeOf<typeof addEditPayrollCodeSchema>
  const { salaryEntryTypeOptions } = formTypeSelectOptions

  const { data: getPayrollCodeById, refetch: refetchPayrollCodeById } = useGetPayrollCodeByIdQuery(
    {
      payrollCodeId: payrollCodeId || ''
    },
    {
      enabled: !!payrollCodeId
    }
  )

  const { data: allValueTypePayComponents } = useGetAllValueTypePayComponentsQuery(
    {},
    {
      enabled: !!payrollCodeId
    }
  )

  const { data: allActiveValueTypePayComponents } = useGetAllUsedValueTypesByPayrollCodeIdQuery(
    {
      id: payrollCodeId || ''
    },
    {
      enabled: !!payrollCodeId
    }
  )

  const { closeFlyIn } = useFlyIn()
  const form = useForm<CreateEditPayrollCodeForm>({
    resolver: zodResolver(addEditPayrollCodeSchema),
    defaultValues: {
      employerId: employerId,
      reverseSignImport: getPayrollCodeById?.payrollCodeById.reverseSignImport ?? false,
      reverseSignExport: getPayrollCodeById?.payrollCodeById.reverseSignExport ?? false,
      isSalaryEntry: getPayrollCodeById?.payrollCodeById.isSalaryEntry ?? false,
      salaryEntryKey: getPayrollCodeById?.payrollCodeById.salaryEntryType?.key || DEFAULT_SALARY_TYPE,
      valueTypePayComponents: getPayrollCodeById?.payrollCodeById.valueTypePayComponents?.map(x => x.key)
    }
  })
  const { control } = form
  const watchedSalaryentry = useWatch({ control, name: `isSalaryEntry` })

  const editmutation = useUpdatePayrollCodeMutation()

  const handleOnSubmit = async (newPayrollCode: CreateEditPayrollCodeForm) => {
    await editmutation
      .mutateAsync({
        updatePayrollCodeCommand: {
          id: payrollCodeId || '',
          code: getPayrollCodeById?.payrollCodeById.code || '',
          providerCode: getPayrollCodeById?.payrollCodeById.providerCode,
          colourHexCode: getPayrollCodeById?.payrollCodeById.colourCodeHex || '',
          description: getPayrollCodeById?.payrollCodeById.description || '',
          groupTypeKey: getPayrollCodeById?.payrollCodeById.group.key || '',
          subGroupTypeKey: getPayrollCodeById?.payrollCodeById.subGroup?.key || '',
          valueTypeKey: getPayrollCodeById?.payrollCodeById.valueType.key || '',
          employerId: employerId || '',
          reverseSignImport: newPayrollCode.reverseSignImport,
          reverseSignExport: newPayrollCode.reverseSignExport,
          isCalendarEntry: getPayrollCodeById?.payrollCodeById.isCalendarEntry ?? false,
          isSalaryEntry: newPayrollCode.isSalaryEntry,
          isUsableInSelfService: getPayrollCodeById?.payrollCodeById.isUsableInSelfService ?? false,
          needsDocument: getPayrollCodeById?.payrollCodeById.needsDocument ?? false,
          useForBradfordCalculation: getPayrollCodeById?.payrollCodeById.useForBradfordCalculation ?? false,
          userFriendlyDescription: getPayrollCodeById?.payrollCodeById.userFriendlyDescription,
          valueTypePayComponents: newPayrollCode.valueTypePayComponents || [],
          exportToProvider: getPayrollCodeById?.payrollCodeById.exportToProvider ?? true,
          salaryEntryTypeKey: newPayrollCode.salaryEntryKey
        }
      })
      .then(() => {
        refetchPayrollCodeById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditsalarysettings.title')}</Typography>
      <Typography variant="h5">
        {getPayrollCodeById?.payrollCodeById.code} {getPayrollCodeById?.payrollCodeById.userFriendlyDescription}
      </Typography>

      <FormGridLayout>
        <Grid xs={12} item>
          <FormLabel>{t('form.field.salarysubtitle')}</FormLabel>
        </Grid>
        <FormSwitch sx={6} name="isSalaryEntry" label={t('form.field.issalaryentry')} />

        {watchedSalaryentry && (
          <FormCheckboxGroup
            sx={6}
            name="valueTypePayComponents"
            label={t('form.field.valuetypepaycomponents')}
            options={allValueTypePayComponents?.allValueTypePayComponents}
            defaultValue={
              getPayrollCodeById?.payrollCodeById.valueTypePayComponents?.length !== 0
                ? getPayrollCodeById?.payrollCodeById.valueTypePayComponents?.map(x => x.key)
                : allValueTypePayComponents?.allValueTypePayComponents.map(x => x.key)
            }
            disabledValues={allActiveValueTypePayComponents?.allUsedValueTypePayComponentsByPayrollCodeId.map(
              x => x.key
            )}
          />
        )}

        {watchedSalaryentry && (
          <FormRadioGroup
            sx={12}
            name="salaryEntryKey"
            label={`${t('form.field.salaryentry')}`}
            options={salaryEntryTypeOptions}
          />
        )}

        <FormSwitch sx={12} name="reverseSignImport" label={t('form.field.reversesignimport')} />
        <FormSwitch sx={12} name="reverseSignExport" label={t('form.field.reversesignexport')} />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons isMutating={editmutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddEditSalarySettings

import { OrderDirection, useGetStartersOrLeaversOverviewQuery } from '@epix-web-apps/core'
import { Box, Typography, useTheme } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAnalyticsPersistedStore } from '../../../stores/analytics-store'
import { useGlobalPersistedStore } from '@epix-web-apps/ui'
import { DataTable, ISortModel, SortModel } from '../../data-table'
import { IcpCodeBadge } from '../../icp-components'

export interface StartersLeaversOverviewComponentProps {
  beginDate: Date
  endDate: Date
  starters: boolean
}

export function StartersLeaversOverviewComponent(props: StartersLeaversOverviewComponentProps) {
  const { t } = useTranslation()
  const [tableData, setTableData] = useState<GridRowsProp>([])
  const { icpFilters } = useGlobalPersistedStore()
  const { genderFilter } = useAnalyticsPersistedStore()
  const { functionAreaFilter } = useAnalyticsPersistedStore()
  const { functionLevelFilter } = useAnalyticsPersistedStore()
  const { employeeTypeFilter } = useAnalyticsPersistedStore()
  const theme = useTheme()

  const { data, isLoading: isLoadingStartersOrLeavers } = useGetStartersOrLeaversOverviewQuery({
    icps: icpFilters,
    startDate: props.beginDate,
    endDate: props.endDate,
    calcStarters: props.starters,
    filterModel: {
      genderType: genderFilter,
      functionAreaType: functionAreaFilter,
      functionLevelType: functionLevelFilter,
      employeeType: employeeTypeFilter
    }
  })

  const startersOrLeavers =
    data?.overviewStartersAndLeavers.length || 0 > 100
      ? data?.overviewStartersAndLeavers.slice(0, 100)
      : data?.overviewStartersAndLeavers

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('analytics.workforceevolution.name'),
      flex: 3,
      sortable: false
    },
    {
      field: 'countryCode',
      headerName: t('analytics.workforceevolution.country'),
      flex: 1.2,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => <IcpCodeBadge icpCode={params.value} />
    },
    {
      field: 'startDate',
      headerName: t('analytics.workforceevolution.start'),
      flex: 2,
      sortable: false
    },
    {
      field: 'endDate',
      headerName: t('analytics.workforceevolution.end'),
      flex: 2,
      sortable: false
    }
  ]

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns[0]?.field, OrderDirection.Asc))

  useEffect(() => {
    if (startersOrLeavers) {
      setTableData(
        startersOrLeavers.map(row => {
          return {
            id: row.contractId,
            name: row.name,
            countryCode: row.countryCode,
            startDate: new Date(row.startDate).toLocaleDateString(),
            endDate: row.endDate ? new Date(row.endDate).toLocaleDateString() : ''
          }
        })
      )
    }
  }, [startersOrLeavers])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '30rem'
      }}
    >
      <Typography variant="h4">{t('analytics.workforceevolution.title')}</Typography>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <DataTable
          data={tableData}
          columns={columns}
          onSortChange={setSortModel}
          isLoading={isLoadingStartersOrLeavers}
          defaultSortModel={sortModel}
          hideFooter={true}
          emptyStateElement={
            <Typography
              sx={{
                mt: 2,
                color: theme.palette.text.secondary,
                fontStyle: 'italic'
              }}
            >
              {t('analytics.workforceevolution.noresults')}
            </Typography>
          }
        />
        {data && data.overviewStartersAndLeavers.length > 100 && (
          <Typography
            sx={{
              mt: -2,
              py: 1,
              color: theme.palette.text.secondary,
              fontStyle: 'italic'
            }}
          >
            {data?.overviewStartersAndLeavers.length - startersOrLeavers!.length}{' '}
            {t('analytics.workforceevolution.hiddenrecords')}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default StartersLeaversOverviewComponent

import { Avatar, Box, useTheme } from '@mui/material'
import { DriversEndingSoonModel } from '@epix-web-apps/core'
import { useNavigate } from 'react-router-dom'
import { EpixAvatarInfoBlock, People, useFlyIn } from '@epix-web-apps/ui'
import { useGlobalStore } from '@epix-web-apps/ui'
import { OverviewDriversEndingSoon } from '../overview-drivers-ending-soon'
import { useTranslation } from 'react-i18next'
import { Fragment } from 'react'

/* eslint-disable-next-line */
export interface DriversEndingSoonProps {
  data?: Array<DriversEndingSoonModel>
  length: number
}

export function DriversEndingSoon({ data, length }: DriversEndingSoonProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { me } = useGlobalStore()
  const { openFlyIn } = useFlyIn()
  const theme = useTheme()

  const limitedData = data?.slice(0, 4)

  const calculateAll = () => {
    return length ? `+${length - 3}` : 0
  }

  return (
    <Box sx={{ display: 'flex', padding: 1, justifyContent: 'space-around' }}>
      {limitedData?.map((item, index) => {
        return (
          <Fragment key={item.id}>
            {(index < 3 || (index === 3 && length === 4)) && (
              <EpixAvatarInfoBlock
                linkText={`${item.firstName} ${item.lastName.substring(0, 1) + '.'}`}
                subText={`${new Date(item.upcomingDate).toLocaleDateString(me?.locale.locale, {
                  day: 'numeric',
                  month: 'numeric'
                })}`}
                onClick={() => navigate(People.PEOPLE_ID_CONTRACTS_ID(item?.personId, item?.contractId))}
                personId={item.personId}
              />
            )}
            {index === 3 && length > 4 && (
              <EpixAvatarInfoBlock
                key={item.id}
                sx={{ backgroundColor: theme.palette.secondary.main }}
                linkText={t('common.viewall')}
                onClick={() =>
                  openFlyIn({
                    content: <OverviewDriversEndingSoon data={data} />
                  })
                }
              >
                {calculateAll()}
              </EpixAvatarInfoBlock>
            )}
          </Fragment>
        )
      })}
      {length < 4 &&
        [...Array(4 - length)].map((item, index) => (
          <Avatar
            key={index}
            sx={{
              width: '3rem',
              height: '3rem',
              backgroundColor: theme.palette.background.default
            }}
          />
        ))}
    </Box>
  )
}

export default DriversEndingSoon

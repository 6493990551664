import { useNavigate } from 'react-router-dom'
import { Link, Box, Typography, Divider, useTheme } from '@mui/material'

/* eslint-disable-next-line */
export interface ConfigurationPaperNavItemProps {
  linkTo: string
  linkName: string
  description?: string
  divider?: boolean
}

export function ConfigurationPaperNavItem({
  linkTo,
  linkName,
  description,
  divider = true
}: ConfigurationPaperNavItemProps) {
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <Box marginBottom={2}>
      <Link
        underline="always"
        onClick={() => {
          navigate(linkTo)
        }}
      >
        {linkName}
      </Link>
      <Typography fontSize="0.95rem" paddingTop="0.2rem" color={theme.palette.text.secondary} fontStyle="italic">
        {description}
      </Typography>
      {divider && <Divider sx={{ borderColor: theme.palette.divider, marginTop: '1rem' }} />}
    </Box>
  )
}

export default ConfigurationPaperNavItem

import styled from '@emotion/styled'
import {
  DEFAULT_AGENDA_VIEW,
  DEFAULT_MONTH_VIEW,
  nextMonth,
  nextYear,
  prevMonth,
  prevYear,
  useGetCalendarDistributionByContractIdQuery
} from '@epix-web-apps/core'
import { Box, Divider, Grid } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PiechartComponent, PieData } from '../../analytics-components/piechart-component/piechart-component'
import PersonIdentificationComponent from '../../person-identification-component'
import { FLYIN_WIDTH, PeriodNavigation, useGlobalStore } from '@epix-web-apps/ui'

/* eslint-disable-next-line */

export type DateRange = {
  startDate: Date
  endDate: Date
}

export interface CalendarDistributionProps {
  contractId: string | undefined
  dateRange: DateRange
  dateYearRange: DateRange
  view: string | undefined
}

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  padding: '0 0.5rem'
}))

const GridHeader = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  padding: '0 0.5rem',
  fontSize: '1.1em',
  fontWeight: 'bold',
  textDecoration: 'underline'
}))

const GridFooter = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  padding: '0 0.5rem',
  fontWeight: 'bold'
}))

export function CalendarDistribution({ contractId, dateRange, dateYearRange, view }: CalendarDistributionProps) {
  const { t } = useTranslation()
  const { me } = useGlobalStore()
  const [currentMonth, setCurrentMonth] = useState<DateRange>(dateRange)
  const [currentYear, setCurrentYear] = useState<DateRange>(dateYearRange)

  const getMonthOfDate = (currentMonth: Date): string => {
    return (
      currentMonth?.toLocaleString(me?.locale.locale, { month: 'long' }).toLocaleUpperCase() +
      ' ' +
      currentMonth?.getFullYear()
    )
  }

  const getYearOfDate = (currentYear: Date): string => {
    return currentYear?.toLocaleString(me?.locale.locale, { year: 'numeric' })
  }

  const { data: getCalendarDistribution } = useGetCalendarDistributionByContractIdQuery({
    contractId: contractId || '',
    startDate: view === DEFAULT_MONTH_VIEW ? currentMonth?.startDate : currentYear?.startDate,
    endDate: view === DEFAULT_MONTH_VIEW ? currentMonth?.endDate : currentYear?.endDate
  })

  return (
    <Box sx={{ width: FLYIN_WIDTH.DEFAULT }}>
      <PersonIdentificationComponent
        title={
          view === 'month' ? t('flyin.calendar-distribution.monthtitle') : t('flyin.calendar-distribution.yeartitle')
        }
      />
      <Divider sx={{ marginBottom: 3 }} />
      {view === DEFAULT_MONTH_VIEW && (
        <PeriodNavigation
          next={() => setCurrentMonth(nextMonth(currentMonth))}
          prev={() => setCurrentMonth(prevMonth(currentMonth))}
          currentMonth={getMonthOfDate(currentMonth?.startDate)}
        />
      )}
      {view === DEFAULT_AGENDA_VIEW && (
        <PeriodNavigation
          next={() => setCurrentYear(nextYear(currentYear))}
          prev={() => setCurrentYear(prevYear(currentYear))}
          currentMonth={getYearOfDate(currentYear?.startDate)}
        />
      )}
      <PiechartComponent
        data={
          getCalendarDistribution?.calendarDistributionByContractId.entries.map(
            x =>
              new PieData(
                `${x.key}-${x.userFriendlyDescription ?? x.description}`,
                x.hours.toString(),
                x.key + ' code: ' + x.key,
                x.colourCodeHex ?? ''
              )
          ) || []
        }
        total={getCalendarDistribution?.calendarDistributionByContractId.totalHours}
        margin={0}
        enableArcLinkLabels={false}
      />

      <Divider sx={{ marginTop: 3 }} />
      <Box sx={{ mt: 4 }}>
        <Grid container rowSpacing={0.5} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
          <GridHeader>
            <Grid item sm={10}>
              {t('flyin.calendar-distribution.calendar-type')}
            </Grid>
            <Grid textAlign={'right'} item sm={2}>
              {t('flyin.calendar-distribution.hours')}
            </Grid>
          </GridHeader>
          {getCalendarDistribution?.calendarDistributionByContractId.entries?.map((item, index: number) => (
            <StyledBox key={index}>
              <Grid item sm={10}>
                {item.userFriendlyDescription ?? item.description} ({item.key})
              </Grid>
              <Grid textAlign={'right'} item sm={2}>
                {item.hours} {t('flyin.calendar-distribution.hours-abbreviation')}
              </Grid>
            </StyledBox>
          ))}
          <GridFooter>
            <Grid item sm={10}>
              {t('flyin.calendar-distribution.total')}
            </Grid>
            <Grid textAlign={'right'} item sm={2}>
              {getCalendarDistribution?.calendarDistributionByContractId.totalHours}{' '}
              {t('flyin.calendar-distribution.hours-abbreviation')}
            </Grid>
          </GridFooter>
        </Grid>
      </Box>
    </Box>
  )
}

export default CalendarDistribution

import {
  ACCEPTED_UPLOAD_TYPES,
  DetailPageBaseQueryParams,
  FormActionButtons,
  FormContainer,
  FormFileUpload,
  FormGridLayout,
  HeaderTitleNavigation,
  SelfService
} from '@epix-web-apps/ui'
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, styled, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import {
  ABSENCE_REQUEST_STATUS_TYPE,
  CANCELLATION_ALLOWED_TYPE_KEYS,
  IsDateBeforeToday,
  useCancelAbsenceRequestMutation,
  useDeleteAbsenceRequestDocumentMutation,
  useGetAbsenceRequestByIdQuery,
  useUploadPersonalCalendarDayFileForTeamStructureMutation
} from '@epix-web-apps/core'
import { useParams, useNavigate } from 'react-router-dom'
import CheckIcon from '@mui/icons-material/Check'
import CancelIcon from '@mui/icons-material/Cancel'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { useGlobalStore } from '@epix-web-apps/ui'
import { TypeOf, any, object } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

/* eslint-disable-next-line */
export interface MyAbsenceRequestsDetailProps {}

export function MyAbsenceRequestsDetail(props: MyAbsenceRequestsDetailProps) {
  const { t } = useTranslation()
  const params = useParams<DetailPageBaseQueryParams>()
  const theme = useTheme()
  const navigate = useNavigate()
  const { me } = useGlobalStore()

  const deleteAbsenceRequestDocument = useDeleteAbsenceRequestDocumentMutation()

  const { data: absenceRequestById, refetch: refetchAbsenceRequestById } = useGetAbsenceRequestByIdQuery({
    id: params.id || ''
  })

  const { mutateAsync: uploadFileAsync } = useUploadPersonalCalendarDayFileForTeamStructureMutation()

  const editAbsenceRequestSchema = object({
    documents: any().optional()
  })

  const form = useForm<EditAbsenceRequestForm>({
    resolver: zodResolver(editAbsenceRequestSchema),
    defaultValues: {
      documents: absenceRequestById?.absenceRequestById?.documents || []
    }
  })

  type EditAbsenceRequestForm = TypeOf<typeof editAbsenceRequestSchema>

  const cancelAbsenceRequestMutation = useCancelAbsenceRequestMutation()

  const handleCancelAbsenceRequest = async () => {
    await cancelAbsenceRequestMutation
      .mutateAsync({
        cancelAbsenceRequestCommand: {
          absenceRequestId: params.id || ''
        }
      })
      .then(() => refetchAbsenceRequestById())
      .then(() => navigate(SelfService.MY_REQUESTS()))
  }

  const CustomListItem = styled(ListItem)<any>(() => {
    return {
      pl: 0
    }
  })

  const handleOnSubmit = async (editedAbsenceRequest: EditAbsenceRequestForm) => {
    editedAbsenceRequest.documents.forEach((x: any) =>
      uploadFile(absenceRequestById?.absenceRequestById?.id.toString() || '', x.file)
        .then(() => navigate(SelfService.MY_REQUESTS()))
        .finally(() => refetchAbsenceRequestById())
    )
  }

  const uploadFile = async (absenceRequestId: string, file: File) => {
    if (file) {
      await uploadFileAsync({
        file: file,
        absenceRequestId: absenceRequestId
      })
    }
  }

  return (
    <Box>
      <HeaderTitleNavigation
        backToLink={SelfService.MY_REQUESTS()}
        mobileSizeTitle
        title={t('selfservice.my-absence-requests.title')}
      />
      <Box>
        <Grid mt={1} mb={3} container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
              {absenceRequestById?.absenceRequestById.requestPolicyContract.personFirstName}{' '}
              {absenceRequestById?.absenceRequestById.requestPolicyContract.personPreferredLastName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {t('selfservice.absencerequestdetail.from')}
          </Grid>
          <Grid item xs={6}>
            {new Date(absenceRequestById?.absenceRequestById.startDate).toLocaleDateString(me?.locale.locale)}
          </Grid>
          <Grid item xs={6}>
            {t('selfservice.absencerequestdetail.until')}
          </Grid>
          <Grid item xs={6}>
            {new Date(absenceRequestById?.absenceRequestById.endDate).toLocaleDateString(me?.locale.locale)}
          </Grid>
          <Grid item xs={6}>
            {t('selfservice.absencerequestdetail.absencetype')}
          </Grid>
          <Grid item xs={6}>
            {absenceRequestById?.absenceRequestById.requestPolicyContract.requestPolicyUserFriendlyName}
          </Grid>
          <Grid item xs={6}>
            {t('selfservice.absencerequestdetail.timeframetype')}
          </Grid>
          <Grid item xs={6}>
            {absenceRequestById?.absenceRequestById.timeFrameType?.value}
          </Grid>
          <Grid item xs={6}>
            {t('selfservice.absencerequestdetail.status')}
          </Grid>
          <Grid item xs={6}>
            <Box display={'flex'} sx={{ alignItems: 'center' }}>
              {absenceRequestById?.absenceRequestById.absenceRequestStatusType?.key ===
                ABSENCE_REQUEST_STATUS_TYPE.APPROVED && <CheckIcon color="success" fontSize="small" />}
              {absenceRequestById?.absenceRequestById.absenceRequestStatusType?.key ===
                ABSENCE_REQUEST_STATUS_TYPE.REQUESTED && <HelpOutlineIcon color="primary" fontSize="small" />}
              {absenceRequestById?.absenceRequestById.absenceRequestStatusType?.key ===
                ABSENCE_REQUEST_STATUS_TYPE.DECLINED && <CancelIcon color="error" fontSize="small" />}
              {absenceRequestById?.absenceRequestById.absenceRequestStatusType?.key ===
                ABSENCE_REQUEST_STATUS_TYPE.CANCELLED && <CancelIcon color="warning" fontSize="small" />}
              <Typography sx={{ fontSize: '1em', mt: 0, ml: 0.5, textAlign: 'center' }}>
                {absenceRequestById?.absenceRequestById.absenceRequestStatusType?.value}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <List dense={true} sx={{ pl: 0 }}>
              <CustomListItem>
                <ListItemIcon>
                  <HelpOutlineIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Trans
                      i18nKey="selfservice.absencerequestdetail.requestedby"
                      values={{
                        name: absenceRequestById?.absenceRequestById.requestedByName,
                        date: new Date(absenceRequestById?.absenceRequestById.requestedOn).toLocaleDateString(
                          me?.locale.locale
                        )
                      }}
                      components={{ bold: <span className="bold" /> }}
                    />
                  }
                />
              </CustomListItem>
              {absenceRequestById?.absenceRequestById?.cancelledOn && (
                <CustomListItem>
                  <ListItemIcon>
                    <CancelIcon color="warning" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Trans
                        i18nKey="selfservice.absencerequestdetail.cancelledby"
                        values={{
                          name: absenceRequestById?.absenceRequestById.cancelledByName,
                          date: new Date(absenceRequestById?.absenceRequestById.cancelledOn).toLocaleDateString(
                            me?.locale.locale
                          )
                        }}
                        components={{ bold: <span className="bold" /> }}
                      />
                    }
                  />
                </CustomListItem>
              )}
              {absenceRequestById?.absenceRequestById?.approvedOn && (
                <CustomListItem>
                  <ListItemIcon>
                    <CheckIcon color="success" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Trans
                        i18nKey="selfservice.absencerequestdetail.approvedby"
                        values={{
                          name: absenceRequestById?.absenceRequestById.approvedByName,
                          date: new Date(absenceRequestById?.absenceRequestById.approvedOn).toLocaleDateString(
                            me?.locale.locale
                          )
                        }}
                        components={{ bold: <span className="bold" /> }}
                      />
                    }
                  />
                </CustomListItem>
              )}
              {absenceRequestById?.absenceRequestById?.declinedOn && (
                <CustomListItem>
                  <ListItemIcon>
                    <CancelIcon color="error" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Trans
                        i18nKey="selfservice.absencerequestdetail.declinedby"
                        values={{
                          name: absenceRequestById?.absenceRequestById.declinedByName,
                          date: new Date(absenceRequestById?.absenceRequestById.declinedOn).toLocaleDateString(
                            me?.locale.locale
                          )
                        }}
                        components={{ bold: <span className="bold" /> }}
                      />
                    }
                  />
                </CustomListItem>
              )}
            </List>
          </Grid>
          {absenceRequestById?.absenceRequestById.comment && (
            <>
              <Grid item xs={6}>
                {t('selfservice.absencerequestdetail.comment')}
              </Grid>
              <Grid item xs={6}>
                {absenceRequestById?.absenceRequestById.comment}
              </Grid>
            </>
          )}
        </Grid>
        {absenceRequestById?.absenceRequestById.absenceRequestStatusType?.key !==
          ABSENCE_REQUEST_STATUS_TYPE.CANCELLED && (
          <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
            <FormGridLayout>
              <FormFileUpload
                sx={12}
                name="documents"
                buttonText={t('documentcomponent.button.title')}
                accept={[ACCEPTED_UPLOAD_TYPES.JPEG, ACCEPTED_UPLOAD_TYPES.PNG, ACCEPTED_UPLOAD_TYPES.PDF]}
                deleteDocument={documentId =>
                  deleteAbsenceRequestDocument
                    .mutateAsync({
                      deleteAbsenceRequestDocumentCommand: {
                        documentId: documentId,
                        absenceRequestId: absenceRequestById?.absenceRequestById.id || ''
                      }
                    })
                    .then(() => refetchAbsenceRequestById())
                }
              />
            </FormGridLayout>

            <FormActionButtons
              showRemove={
                absenceRequestById?.absenceRequestById.absenceRequestStatusType?.key ===
                  ABSENCE_REQUEST_STATUS_TYPE.REQUESTED ||
                (absenceRequestById?.absenceRequestById.absenceRequestStatusType?.key ===
                  ABSENCE_REQUEST_STATUS_TYPE.APPROVED &&
                  (absenceRequestById.absenceRequestById.requestPolicyContract.requestPolicyCancellationAllowedType
                    ?.key === CANCELLATION_ALLOWED_TYPE_KEYS.ALLOWED ||
                    (absenceRequestById.absenceRequestById.requestPolicyContract.requestPolicyCancellationAllowedType
                      ?.key === CANCELLATION_ALLOWED_TYPE_KEYS.ALLOWED_IN_PRESENT &&
                      !IsDateBeforeToday(new Date(absenceRequestById.absenceRequestById.startDate)))))
              }
              onRemove={handleCancelAbsenceRequest}
              isMutating={cancelAbsenceRequestMutation.isLoading}
            />
          </FormContainer>
        )}
      </Box>
    </Box>
  )
}

export default MyAbsenceRequestsDetail

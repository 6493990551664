import { Box, Tab, Tabs } from '@mui/material'
import { Suspense, useEffect, useState } from 'react'
import CompanyCarDetailTabOverview from '../company-car-detail-tab-overview/company-car-detail-tab-overview'
import { LoadingOverlay } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import { TabPanel, a11yProps, LocationState } from '@epix-web-apps/ui'
import { useLocation } from 'react-router-dom'
import CompanyCarDrivers from '../../company-car-drivers/company-car-drivers'

/* eslint-disable-next-line */
export interface CompanyCarDetailTabGeneralProps {}

export function CompanyCarDetailTabGeneral(props: CompanyCarDetailTabGeneralProps) {
  const { t } = useTranslation()
  const [tabIndex, setTabIndex] = useState(0)
  const location = useLocation() as LocationState

  useEffect(() => {
    setTabIndex(location.state?.tabIndex || 0)
  }, [location.state])

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={(_, tabIndex) => setTabIndex(tabIndex)}>
          <Tab label={t('companycardetail.tab.overview')} {...a11yProps(0)} />
          <Tab label={t('companycardetail.tab.drivers')} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <Suspense fallback={<LoadingOverlay />}>
          <CompanyCarDetailTabOverview />
        </Suspense>
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <Suspense fallback={<LoadingOverlay />}>
          <CompanyCarDrivers />
        </Suspense>
      </TabPanel>
    </>
  )
}

export default CompanyCarDetailTabGeneral

import {
  useCreatePersonOfficialAddressMutation,
  useUpdatePersonOfficialAddressMutation,
  useGetPersonByIdQuery,
  useGetPersonOfficialAddressHistoryQuery,
  formTypeSelectOptions
} from '@epix-web-apps/core'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { date, object, string, TypeOf } from 'zod'
import { DetailPageBaseQueryParams, FormGridLayout } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Divider, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { FormActionButtons, FormDatepicker, FormErrorList } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { FormSelect } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface EditPersonOfficialAddressProps {}

export function EditPersonOfficialAddress(props: EditPersonOfficialAddressProps) {
  const { t } = useTranslation()
  const editPersonOfficialAddressSchema = object({
    startDate: date({
      required_error: t('form.validation.startdaterequired'),
      invalid_type_error: t('form.validation.startdaterequired')
    }),
    endDate: date().optional().nullable(),
    country: string({
      required_error: t('form.validation.countryrequired'),
      invalid_type_error: t('form.validation.countryrequired')
    }).min(1, t('form.validation.countryrequired')),
    street: string().nullable(),
    houseNumber: string().nullable(),
    houseNumberSuffix: string().nullable(),
    zipCode: string().nullable(),
    city: string().nullable(),
    county: string().nullable()
  })
  type EditPersonOfficialAddressForm = TypeOf<typeof editPersonOfficialAddressSchema>
  const params = useParams<DetailPageBaseQueryParams>()
  const { closeFlyIn } = useFlyIn()
  const { countryOptions } = formTypeSelectOptions
  const { refetch: refetchPersonById } = useGetPersonByIdQuery(
    {
      personId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )

  const { data: getPersonOfficialAddresses } = useGetPersonOfficialAddressHistoryQuery(
    {
      personId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )

  const officialAddresses = getPersonOfficialAddresses?.personOfficialAddressHistory.current
  const [selectedAddress, setSelectedAddress] = useState(officialAddresses)
  const [addNewAddress, setAddNewAddress] = useState(false)
  const createMutation = useCreatePersonOfficialAddressMutation()
  const updateMutation = useUpdatePersonOfficialAddressMutation()

  const form = useForm<EditPersonOfficialAddressForm>({
    resolver: zodResolver(editPersonOfficialAddressSchema),
    defaultValues: {
      startDate: selectedAddress?.startDate ? new Date(selectedAddress.startDate) : undefined,
      endDate: selectedAddress?.endDate ? new Date(selectedAddress.endDate) : null,
      country: selectedAddress?.country?.code,
      street: selectedAddress?.street,
      houseNumber: selectedAddress?.houseNumber,
      houseNumberSuffix: selectedAddress?.houseNumberSuffix,
      zipCode: selectedAddress?.zipCode,
      city: selectedAddress?.city,
      county: selectedAddress?.county
    }
  })

  const handleOnSubmit = async (personOfficialAddress: EditPersonOfficialAddressForm) => {
    const addressModel = {
      personId: params.id || '',
      street: personOfficialAddress.street,
      houseNumber: personOfficialAddress.houseNumber,
      houseNumberSuffix: personOfficialAddress.houseNumberSuffix,
      zipCode: personOfficialAddress.zipCode,
      city: personOfficialAddress.city,
      county: personOfficialAddress.county,
      country: personOfficialAddress.country
    }
    if (!selectedAddress) {
      await createMutation
        .mutateAsync({
          creatPersonAddressCommand: {
            startDate: personOfficialAddress.startDate && personOfficialAddress.startDate,
            ...addressModel
          }
        })
        .then(closeFlyIn)
    } else {
      await updateMutation
        .mutateAsync({
          updatePersonAddressCommand: {
            personAddressId: selectedAddress?.id || '',
            ...addressModel
          }
        })
        .then(() => {
          refetchPersonById()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editperson.officialaddress.title')}</Typography>
      <Grid className="bold" container>
        <Grid xs={4} item>
          <p>{t('form.field.startdate')}</p>
        </Grid>
        <Grid xs={4} item>
          <p>{t('form.field.enddate')}</p>
        </Grid>
        <Grid xs={4} item>
          <p>{t('form.field.street')}</p>
        </Grid>
      </Grid>
      <Divider />

      <FormGridLayout hasBorderBottom>
        <Grid xs={12} sx={{ fontSize: '0.95em' }} item>
          {getPersonOfficialAddresses?.personOfficialAddressHistory?.history && (
            <RadioGroup
              aria-labelledby="address-history"
              value={selectedAddress?.id}
              onChange={(e, addressId) => {
                const selectedAddress = getPersonOfficialAddresses?.personOfficialAddressHistory?.history?.find(
                  x => x.id === addressId
                )
                setSelectedAddress(selectedAddress)
                form.reset({
                  startDate: new Date(selectedAddress?.startDate),
                  endDate: selectedAddress?.endDate ? new Date(selectedAddress?.endDate) : null,
                  country: selectedAddress?.country?.code,
                  county: selectedAddress?.county ?? '',
                  city: selectedAddress?.city,
                  street: selectedAddress?.street,
                  zipCode: selectedAddress?.zipCode,
                  houseNumber: selectedAddress?.houseNumber,
                  houseNumberSuffix: selectedAddress?.houseNumberSuffix
                })
              }}
            >
              {getPersonOfficialAddresses?.personOfficialAddressHistory?.history?.map((address, index) => (
                <label
                  key={index}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    height: '2rem'
                  }}
                >
                  <Grid xs={4} item>
                    <p>
                      <Radio
                        sx={{
                          padding: '0 0.25rem 0 0',
                          marginTop: '-0.2rem'
                        }}
                        size="small"
                        value={address.id}
                      />
                      {new Date(address.startDate).toLocaleDateString()}
                    </p>
                  </Grid>
                  <Grid xs={4} item>
                    <p>{address.endDate && new Date(address.endDate).toLocaleDateString()}</p>
                  </Grid>
                  <Grid xs={4} item>
                    <p>{address.street}</p>
                  </Grid>
                </label>
              ))}
            </RadioGroup>
          )}
        </Grid>
        <Grid xs={12} item>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              form.reset({
                startDate: undefined,
                endDate: null,
                country: undefined
              })
              setAddNewAddress(true)
              setSelectedAddress(null)
            }}
          >
            {t('flyin.editperson.addaddress')}
          </Button>
        </Grid>
      </FormGridLayout>

      {(selectedAddress || addNewAddress) && (
        <>
          <FormGridLayout hasPaddingTop hasBorderBottom>
            <FormDatepicker
              sx={6}
              name="startDate"
              label={`${t('form.field.startdate')} *`}
              disabled={!addNewAddress}
            />

            <FormDatepicker sx={6} name="endDate" label={t('form.field.enddate')} disabled={true} />

            <FormSelect sx={12} name="country" label={`${t('form.field.country')} *`} options={countryOptions} />
          </FormGridLayout>

          <FormGridLayout hasPaddingTop>
            <FormInput sx={12} name="street" label={t('form.field.street')} />

            <FormInput sx={6} name="houseNumber" label={t('form.field.housenumber')} />

            <FormInput sx={6} name="houseNumberSuffix" label={t('form.field.housenumbersuffix')} />

            <FormInput sx={4} name="zipCode" label={t('form.field.zipcode')} />

            <FormInput sx={8} name="city" label={t('form.field.city')} />

            <FormInput sx={12} name="county" label={t('form.field.county')} />
          </FormGridLayout>

          <FormErrorList />
          <FormActionButtons
            isMutating={createMutation.isLoading || updateMutation.isLoading}
            onCancel={() => closeFlyIn()}
          />
        </>
      )}
    </FormContainer>
  )
}

export default EditPersonOfficialAddress

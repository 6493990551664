import { Box, IconButton, useTheme } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

/* eslint-disable-next-line */
export interface PeriodNavigationProps {
  currentMonth: string
  next: () => void
  prev: () => void
  disabledLeftButton?: boolean
  disabledRightButton?: boolean
}

export function PeriodNavigation({
  currentMonth,
  next,
  prev,
  disabledLeftButton = false,
  disabledRightButton = false
}: PeriodNavigationProps) {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        mb: 3,
        gap: 0.5,
        width: '100%',
        fontWeight: 'bold',
        color: theme.palette.primary.main
      }}
    >
      <IconButton disabled={disabledLeftButton} size="small" onClick={() => prev()}>
        <KeyboardArrowLeftIcon
          fontSize="medium"
          sx={{
            verticalAlign: 'bottom',
            color: disabledLeftButton ? theme.palette.action.disabled : theme.palette.primary.main
          }}
        />
      </IconButton>
      <Box>{currentMonth}</Box>
      <IconButton disabled={disabledRightButton} size="small" onClick={() => next()}>
        <KeyboardArrowRightIcon
          fontSize="medium"
          sx={{
            verticalAlign: 'bottom',
            color: disabledRightButton ? theme.palette.action.disabled : theme.palette.primary.main
          }}
        />
      </IconButton>
    </Box>
  )
}

export default PeriodNavigation

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FormActionButtons, FormSelect, useFlyIn } from '@epix-web-apps/ui'
import { CompanyCarDetailPageParams } from '../../../../pages/company-car-detail-page/company-car-detail-page'
import { TypeOf, boolean, number, object, string } from 'zod'
import {
  formTypeSelectOptions,
  numericString,
  useGetCompanyCarByIdQuery,
  useUpdateCompanyCarCompanyFinancialInformationMutation
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, useWatch } from 'react-hook-form'
import { FormContainer } from '@epix-web-apps/ui'
import { Typography } from '@mui/material'
import { FormInput } from '@epix-web-apps/ui'
import { FormGridLayout } from '@epix-web-apps/ui'
import { FormSwitch } from '../../../form-components/form-switch'

/* eslint-disable-next-line */
export interface EditCompanyCarCompanyFinancialInformationProps {}

export function EditCompanyCarCompanyFinancialInformation(props: EditCompanyCarCompanyFinancialInformationProps) {
  const { t } = useTranslation()
  const params = useParams<CompanyCarDetailPageParams>()
  const { closeFlyIn } = useFlyIn()

  const editCompanyCarCompanyFinancialInformationSchema = object({
    monthlyCost: numericString(number().optional().nullable()),
    tco: numericString(number().optional().nullable()),
    carContractTypeKey: string().optional().nullable(),
    isPersonalContribution: boolean(),
    personalContributionPercentage: numericString(number().optional().nullable()),
    oneTimeContribution: numericString(number().optional().nullable()),
    monthlyContribution: numericString(number().optional().nullable()),
    hasFuelCard: boolean(),
    fuelCardNumber: string().optional().nullable()
  })

  type EditCompanyCarCompanyFinancialInformationForm = TypeOf<typeof editCompanyCarCompanyFinancialInformationSchema>

  const { data: getCompanyCarById, refetch: refetchCompanyCarById } = useGetCompanyCarByIdQuery(
    {
      companyCarId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )
  const car = getCompanyCarById?.companyCarById

  const { carContractTypeOptions } = formTypeSelectOptions

  const mutation = useUpdateCompanyCarCompanyFinancialInformationMutation()

  const form = useForm<EditCompanyCarCompanyFinancialInformationForm>({
    resolver: zodResolver(editCompanyCarCompanyFinancialInformationSchema),
    defaultValues: {
      monthlyCost: car?.monthlyCost,
      tco: car?.tco,
      carContractTypeKey: car?.carContractType?.key,
      isPersonalContribution: car?.isPersonalContribution ?? false,
      personalContributionPercentage: car?.personalContributionPercentage,
      oneTimeContribution: car?.oneTimeContribution,
      monthlyContribution: car?.monthlyContribution,
      hasFuelCard: car?.hasFuelCard ?? false,
      fuelCardNumber: car?.fuelCardNumber
    }
  })

  const { control, handleSubmit } = form

  const handleOnSubmit = async (companyFinancialInformation: EditCompanyCarCompanyFinancialInformationForm) => {
    await mutation
      .mutateAsync({
        updateCompanyCarCompanyFinancialInformationCommand: {
          id: params.id || '',
          monthlyCost: companyFinancialInformation.monthlyCost,
          tco: companyFinancialInformation.tco,
          carContractTypeKey:
            companyFinancialInformation.carContractTypeKey && companyFinancialInformation.carContractTypeKey,
          isPersonalContribution: companyFinancialInformation.isPersonalContribution,
          personalContributionPercentage: companyFinancialInformation.isPersonalContribution
            ? companyFinancialInformation.personalContributionPercentage
            : null,
          oneTimeContribution: companyFinancialInformation.isPersonalContribution
            ? companyFinancialInformation.oneTimeContribution
            : null,
          monthlyContribution: companyFinancialInformation.isPersonalContribution
            ? companyFinancialInformation.monthlyContribution
            : null,
          hasFuelCard: companyFinancialInformation.hasFuelCard,
          fuelCardNumber: companyFinancialInformation.hasFuelCard ? companyFinancialInformation.fuelCardNumber : null
        }
      })
      .then(() => {
        refetchCompanyCarById()
        closeFlyIn()
      })
  }

  const watchIsPersonalContribution = useWatch({
    control,
    name: 'isPersonalContribution'
  })
  const watchHasFuelCard = useWatch({ control, name: 'hasFuelCard' })

  return (
    <FormContainer form={form} onSubmit={handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editcompanycarcompanyfinancialinformation.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={6} name="monthlyCost" label={t('form.field.monthlycost')} />
        <FormInput sx={6} name="tco" label={t('form.field.tco')} />

        <FormSelect
          sx={12}
          name="carContractTypeKey"
          label={t('form.field.carcontracttype')}
          options={carContractTypeOptions}
        />

        <FormSwitch sx={12} name="isPersonalContribution" label={t('form.field.ispersonalcontribution')} />

        {watchIsPersonalContribution && (
          <FormInput
            sx={6}
            name="personalContributionPercentage"
            label={t('form.field.personalcontributionpercentage')}
          />
        )}

        {watchIsPersonalContribution && (
          <FormInput sx={6} name="oneTimeContribution" label={t('form.field.onetimecontribution')} />
        )}

        {watchIsPersonalContribution && (
          <FormInput sx={6} name="monthlyContribution" label={t('form.field.monthlycontribution')} />
        )}

        <FormSwitch sx={12} name="hasFuelCard" label={t('form.field.hasfuelcard')} />

        {watchHasFuelCard && <FormInput sx={6} name="fuelCardNumber" label={t('form.field.fuelcardnumber')} />}
      </FormGridLayout>

      <FormActionButtons isMutating={mutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditCompanyCarCompanyFinancialInformation

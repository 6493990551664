import { FormGridLayout } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { Typography } from '@mui/material'
import { FormSelect } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { TypeOf, object, string } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormSelectOption, TeamModel, useGetAllTeamsQuery, useGetTeamByIdQuery, useUpdateRootTeamMutation } from '@epix-web-apps/core'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { useEffect, useState } from 'react'

/* eslint-disable-next-line */
export interface EditRootTeamProps {
  teamId: string
}

export function EditRootTeam({ teamId }: EditRootTeamProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [teams, setTeams] = useState<any>()

  const { data: getTeamById } = useGetTeamByIdQuery(
    {
      id: teamId || ''
    },
    {
      enabled: !!teamId
    }
  )

  const { data: allTeams } = useGetAllTeamsQuery(
    {
      sortByProperty: 'Name'
    },
    {
      enabled: !!teamId
    }
  )

  useEffect(() => {
    const ownChildren = getTeamById?.teamById.children?.map(x => x.id)
    const filteredTeams = allTeams?.allTeams.filter(
      x => !ownChildren?.includes(x.id) && x.id !== getTeamById?.teamById.id
    )
    setTeams(filteredTeams)
  }, [allTeams])

  const editRootTeamSchema = object({
    rootTeamId: string()
  })

  type EditRootTeamForm = TypeOf<typeof editRootTeamSchema>

  const form = useForm<EditRootTeamForm>({
    resolver: zodResolver(editRootTeamSchema),
    defaultValues: {
      rootTeamId: getTeamById?.teamById.parentTeam?.id
    }
  })

  const mutation = useUpdateRootTeamMutation()

  const handleOnSubmit = async (newRootTeam: EditRootTeamForm) => {
    await mutation
      .mutateAsync({
        updateRootTeamCommand: {
          teamId: teamId || '',
          rootTeamId: newRootTeam.rootTeamId || ''
        }
      })
      .then(() => {
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editrootteam.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="rootTeamId"
          label={t('form.field.teams')}
          options={teams?.map((x: TeamModel) => new FormSelectOption(x.id, `${x.name} (${x.code})`))}
        />
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons isMutating={mutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditRootTeam

import {
  useCreateDayDefinitionMutation,
  useGetDayDefinitionByIdQuery,
  useGetEmployerByIdQuery,
  useUpdateDayDefinitionMutation
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'
import { FormGridLayout } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface AddEditDayDefinitionProps {
  dayDefinitionId?: string
  employerId?: string
}

export function AddEditDayDefinition({ employerId, dayDefinitionId }: AddEditDayDefinitionProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const AddEditDayDefinitionSchema = object({
    code: string({
      required_error: t('form.validation.daydefinitioncoderequired'),
      invalid_type_error: t('form.validation.daydefinitioncoderequired')
    }).min(1, t('form.validation.daydefinitioncoderequired')),
    description: string({
      required_error: t('form.validation.descriptionrequired'),
      invalid_type_error: t('form.validation.descriptionrequired')
    }).min(1, t('form.validation.descriptionrequired')),
    employerId: string({
      required_error: t('form.validation.employerrequired'),
      invalid_type_error: t('form.validation.employerrequired')
    }).min(1, t('form.validation.employerrequired'))
  })

  type CreateEditDayDefinitionForm = TypeOf<typeof AddEditDayDefinitionSchema>
  const { data: getDayDefinitionById, refetch: refetchDayDefinitionById } = useGetDayDefinitionByIdQuery(
    {
      dayDefinitionId: dayDefinitionId || ''
    },
    {
      enabled: !!dayDefinitionId
    }
  )

  const form = useForm<CreateEditDayDefinitionForm>({
    resolver: zodResolver(AddEditDayDefinitionSchema),
    defaultValues: {
      code: getDayDefinitionById?.dayDefinitionById.code,
      description: getDayDefinitionById?.dayDefinitionById.description,
      employerId: employerId
    }
  })

  const createmutation = useCreateDayDefinitionMutation()
  const updatemutation = useUpdateDayDefinitionMutation()

  const handleOnSubmit = async (newDayDefinition: CreateEditDayDefinitionForm) => {
    if (dayDefinitionId === undefined) {
      await createmutation
        .mutateAsync({
          createDayDefinitionCommand: {
            code: newDayDefinition.code,
            description: newDayDefinition.description,
            employerId: employerId || ''
          }
        })
        .then(closeFlyIn)
    } else {
      await updatemutation
        .mutateAsync({
          updateDayDefinitionCommand: {
            id: dayDefinitionId,
            code: newDayDefinition.code,
            description: newDayDefinition.description
          }
        })
        .then(() => {
          refetchDayDefinitionById()
          closeFlyIn()
        })
    }
  }

  const { data: getEmployerById } = useGetEmployerByIdQuery({
    employerId: employerId || ''
  })
  const company = getEmployerById?.employerById.companyName

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditdaydefinitions.title')}</Typography>
      <Typography variant="h5" color={'gray'}>
        {t('flyin.addeditdaydefinitions.subtitle')}: {company}
      </Typography>

      <FormGridLayout>
        <FormInput sx={12} name="code" label={`${t('form.field.daydefinitioncode')}`} />
        <FormInput sx={12} name="description" label={`${t('form.field.description')}`} />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons
        isMutating={createmutation.isLoading || updatemutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditDayDefinition

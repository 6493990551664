import { Box, Link, Typography, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { OnboardingLayout } from '../../../layouts'
import { useNavigate } from 'react-router-dom'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import { environment } from '../../../../../../src/environments/environment'
import { Import } from '@epix-web-apps/ui'
import { ROLE, isAuthorized, useGetMeQuery } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface OnboardingCompanyCarsProps {}

export function OnboardingCompanyCars(props: OnboardingCompanyCarsProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const { data: me } = useGetMeQuery()

  return (
    <>
      <OnboardingLayout title={t('companycarspage.onboarding.title')} icon={<DirectionsCarIcon />} />
      <Box
        sx={{
          marginLeft: '20%',
          marginRight: '20%'
        }}
      >
        {isAuthorized(me?.me.roles, [ROLE.IMPORTDATA, ROLE.ADMIN]) ? (
          <>
            <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
              {t('companycarspage.onboarding.description')}
            </Typography>
            <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
              {t('companycarspage.onboarding.description2')}
            </Typography>
            <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
              <Trans
                i18nKey={t('companycarspage.onboarding.description3')}
                values={{ linktoimport: t('companycarspage.onboarding.here') }}
                components={{
                  tt: <Link underline="always" onClick={() => navigate(Import.IMPORT)} />
                }}
              />
            </Typography>
            <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
              <Trans
                i18nKey={t('companycarspage.onboarding.description4')}
                values={{
                  linktodocusaurus: t('companycarspage.onboarding.here')
                }}
                components={{
                  tt: (
                    <Link
                      underline="always"
                      target="_blank"
                      href={environment.docs.domainName + '/Import/import-csv/import-csv-companycars'}
                    />
                  )
                }}
              />
            </Typography>
          </>
        ) : (
          <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
            {t('companycarspage.onboarding.description5')}
          </Typography>
        )}
      </Box>
    </>
  )
}

export default OnboardingCompanyCars

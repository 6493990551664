import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { HeaderTitleNavigation, SelfService, useGlobalStore } from '@epix-web-apps/ui'
import { EmployerFolderComponent, ContractFolderComponent } from '../folder-component'
import { IsDateBeforeToday, useGetContractDocumentTypesForPersonGroupedByContractQuery } from '@epix-web-apps/core'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface DocumentsProps {}

export function Documents(props: DocumentsProps) {
  const { me } = useGlobalStore()
  const [seeMore, setSeeMore] = useState(false)
  const { data: contractDocuments } = useGetContractDocumentTypesForPersonGroupedByContractQuery()

  const { t } = useTranslation()

  const outdatedContracts =
    contractDocuments?.allContractDocumentTypesForPersonGroupedByContract?.filter(
      x => x.endDate != null && IsDateBeforeToday(x.endDate)
    ) || []

  const allCurrentContracts =
    contractDocuments?.allContractDocumentTypesForPersonGroupedByContract?.filter(
      x => !outdatedContracts.some(outdated => outdated.contractId === x.contractId)
    ) || []

  const outdatedContractsWithDocumentTypes = outdatedContracts.filter(
    x => x.contractDocumentTypes.length !== 0 || x.employerDocumentTypes.length !== 0
  )

  return (
    <>
      <HeaderTitleNavigation
        mobileSizeTitle
        title={t('selfservice.documentspage.mydocuments.title')}
        backToLink={SelfService.ROOT()}
      />

      <Box mb={2}>
        {allCurrentContracts &&
          allCurrentContracts.map(
            (contract, index) =>
              (contract.contractDocumentTypes.length > 0 || contract.employerDocumentTypes.length > 0) && (
                <Box key={index}>
                  <Typography variant="h4">
                    {`${contract.icpCode} (${new Date(contract.startDate).toLocaleDateString(me?.locale.locale)} - ${
                      contract.endDate ? new Date(contract.endDate).toLocaleDateString(me?.locale.locale) + ')' : '...)'
                    }`}
                  </Typography>
                  <Box>
                    {contract.contractDocumentTypes.length > 0 && (
                      <>
                        <Typography variant="h5">{t('selfservice.documentpage.mydocuments.subtitle')}</Typography>
                        <Box mt={1}>
                          {contract.contractDocumentTypes.map(x => (
                            <ContractFolderComponent
                              key={x.id}
                              contractId={contract.contractId}
                              documentTypeId={x.id}
                              name={x.name}
                            />
                          ))}
                        </Box>
                      </>
                    )}
                  </Box>
                  <Box>
                    {contract.employerDocumentTypes.length > 0 && (
                      <>
                        <Typography variant="h5">{t('selfservice.documentpage.companydocuments.subtitle')}</Typography>
                        <Box mt={1}>
                          {contract.employerDocumentTypes.map(x => (
                            <EmployerFolderComponent
                              key={x.id}
                              employerId={contract.employerId}
                              documentTypeId={x.id}
                              name={x.name}
                            />
                          ))}
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              )
          )}
      </Box>
      {outdatedContractsWithDocumentTypes.length > 0 && (
        <Button variant="outlined" onClick={() => setSeeMore(seeMore => !seeMore)}>
          {!seeMore
            ? t('selfservice.documentpage.mydocuments.seemore')
            : t('selfservice.documentpage.mydocuments.seeless')}
        </Button>
      )}
      {seeMore && outdatedContracts && (
        <>
          <Typography variant="h4">{t('selfservice.documentpage.mydocuments.previouscontracts')}</Typography>
          {outdatedContracts.map(
            (contract, index) =>
              (contract.contractDocumentTypes.length > 0 || contract.employerDocumentTypes.length > 0) && (
                <Box key={index}>
                  <Typography variant="h4">
                    {`${contract.icpCode} (${new Date(contract.startDate).toLocaleDateString(me?.locale.locale)} - ${
                      contract.endDate ? new Date(contract.endDate).toLocaleDateString(me?.locale.locale) + ')' : '...)'
                    }`}
                  </Typography>
                  <Box>
                    {contract.contractDocumentTypes.length > 0 && (
                      <>
                        <Typography variant="h5">{t('selfservice.documentpage.mydocuments.subtitle')}</Typography>
                        <Box mt={1}>
                          {contract.contractDocumentTypes.map(x => (
                            <ContractFolderComponent
                              key={x.id}
                              contractId={contract.contractId}
                              documentTypeId={x.id}
                              name={x.name}
                            />
                          ))}
                        </Box>
                      </>
                    )}
                  </Box>
                  <Box>
                    {contract.employerDocumentTypes.length > 0 && (
                      <>
                        <Typography variant="h5">{t('selfservice.documentpage.companydocuments.subtitle')}</Typography>
                        <Box mt={1}>
                          {contract.employerDocumentTypes.map(x => (
                            <EmployerFolderComponent
                              key={x.id}
                              employerId={contract.employerId}
                              documentTypeId={x.id}
                              name={x.name}
                            />
                          ))}
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              )
          )}
        </>
      )}
    </>
  )
}

export default Documents

import { Box } from '@mui/material'
import { useGetAllCurrenciesQuery, useGetCompanyCarByIdQuery } from '@epix-web-apps/core'
import { useParams } from 'react-router-dom'
import { CompanyCarDetailPageParams } from '../../../../pages/company-car-detail-page/company-car-detail-page'
import { useEffect } from 'react'
import CompanyCarGeneralInformation from './company-car-general-information/company-car-general-information'
import CompanyCarTechnicalDetails from './company-car-technical-details/company-car-technical-details'
import CompanyCarCompanyFinancialInformation from './company-car-company-financial-information/company-car-company-financial-information'
import CompanyCarCarFinancialInformation from './company-car-car-financial-information/company-car-car-financial-information'
import CompanyCarUsageInformation from './company-car-usage-information/company-car-usage-information'
import { useGlobalStore } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface CompanyCarDetailTabOverviewProps {}

export function CompanyCarDetailTabOverview(props: CompanyCarDetailTabOverviewProps) {
  const params = useParams<CompanyCarDetailPageParams>()

  const { data: getCompanyCarById, refetch: refetchCompanyCarById } = useGetCompanyCarByIdQuery(
    {
      companyCarId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )
  const car = getCompanyCarById?.companyCarById
  const { data: getCurrencies } = useGetAllCurrenciesQuery()

  const { me } = useGlobalStore()

  useEffect(() => {
    refetchCompanyCarById()
  }, [me])

  const currencySymbol = getCurrencies?.currencies.find(c => c.currency === car?.country.currencyKey)?.symbol
  const preferredCurrencySymbol = me?.currencySymbol

  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '2rem' }}>
        <CompanyCarGeneralInformation car={car} />
        <CompanyCarTechnicalDetails car={car} />
      </Box>

      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '2rem' }}>
        <CompanyCarCompanyFinancialInformation
          car={car}
          currencySymbol={currencySymbol}
          preferredCurrencySymbol={preferredCurrencySymbol}
        />
        <CompanyCarCarFinancialInformation
          car={car}
          currencySymbol={currencySymbol}
          preferredCurrencySymbol={preferredCurrencySymbol}
        />
      </Box>

      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '2rem' }}>
        <Box sx={{ flex: 1 }}></Box>
        <CompanyCarUsageInformation car={car} />
      </Box>
    </>
  )
}

export default CompanyCarDetailTabOverview

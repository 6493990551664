import { zodResolver } from '@hookform/resolvers/zod'
import { Typography, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { TypeOf, string, boolean, object } from 'zod'
import { Configuration, FormGridLayout } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { FormSelect } from '@epix-web-apps/ui'
import { formTypeSelectOptions, GraphqlError, useCreateUserMutation } from '@epix-web-apps/core'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { useGlobalStore } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

/* eslint-disable-next-line */
export interface AddUserProps {}

export function AddUser() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const addUserSchema = object({
    firstName: string({
      required_error: t('form.validation.firstnamerequired'),
      invalid_type_error: t('form.validation.firstnamerequired')
    }).min(1, t('form.validation.firstnamerequired')),
    lastName: string({
      required_error: t('form.validation.lastnamerequired'),
      invalid_type_error: t('form.validation.lastnamerequired')
    }).min(1, t('form.validation.lastnamerequired')),
    userName: string().email(t('form.validation.invalidemail')),
    localeCode: string({
      required_error: t('form.validation.languagerequired'),
      invalid_type_error: t('form.validation.languagerequired')
    }).min(1, t('form.validation.languagerequired')),
    currency: string({
      required_error: t('form.validation.currencyrequired'),
      invalid_type_error: t('form.validation.currencyrequired')
    }).min(1, t('form.validation.currencyrequired')),
    isActive: boolean()
  })
  type CreateUserForm = TypeOf<typeof addUserSchema>
  const { me } = useGlobalStore()
  const { currencyOptions, localeOptions } = formTypeSelectOptions

  const { closeFlyIn } = useFlyIn()
  const form = useForm<CreateUserForm>({
    resolver: zodResolver(addUserSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      userName: '',
      localeCode: me?.locale.locale,
      currency: me?.currency,
      isActive: true
    }
  })

  const createmutation = useCreateUserMutation()

  const handleOnSubmit = async (newUser: CreateUserForm) => {
    await createmutation
      .mutateAsync({
        createUserCommand: {
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          email: newUser.userName,
          currencyCode: newUser.currency,
          localeCode: newUser.localeCode,
          isActive: newUser.isActive
        }
      })
      .then(response => {
        closeFlyIn()
        setBackendErrors([])
        navigate(Configuration.USERS_ID(response.createUser))
      })
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.adduser.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={6} name="firstName" label={`${t('form.field.firstname')} *`} />

        <FormInput sx={6} name="lastName" label={`${t('form.field.lastname')} *`} />

        <FormInput sx={12} name="userName" label={`${t('form.field.username')} (work email) *`} />

        <FormSelect sx={6} name="localeCode" label={`${t('form.field.defaultlanguage')} *`} options={localeOptions} />

        <FormSelect sx={6} name="currency" label={`${t('form.field.defaultcurrency')} *`} options={currencyOptions} />

        <FormSwitch sx={12} name="isActive" label={t('adduser.form.field.isActive')} />

        <Grid xs={12} item>
          <Typography sx={{ fontStyle: 'italic' }}>{t('form.field.userregistration')}</Typography>
        </Grid>
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={createmutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddUser

import {
  REQUEST_RIGHT_EDITOR,
  getLastDayOfMonth,
  isInCurrentMonth,
  useGetPersonForSelfServiceByIdQuery,
  useGetTeamMemberReqeustRightsQuery
} from '@epix-web-apps/core'
import { Box, Grid, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { HeaderTitleNavigation, SelfService } from '@epix-web-apps/ui'
import { MonthlyCalendar } from '../../components/calendar-components/monthly-calendar'
import BalanceChart from '../../components/balance-components/balance-chart'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface SpecificPersonCalendarProps {}

export type SpecificPersonCalendarQueryParams = {
  teamid: string
  id: string
}

export function SpecificPersonCalendar(props: SpecificPersonCalendarProps) {
  const params = useParams<SpecificPersonCalendarQueryParams>()
  const { t } = useTranslation()
  const [dateOfCalendar, setDateOfCalendar] = useState<Date>(getLastDayOfMonth(new Date()))

  const { data: personForSelfServiceById } = useGetPersonForSelfServiceByIdQuery({
    personId: params.id || ''
  })

  const { data: editors } = useGetTeamMemberReqeustRightsQuery({
    otherPersonId: params.id || '',
    requestPolicyRightTypeKey: REQUEST_RIGHT_EDITOR
  })

  const isEditorForPerson = editors?.allTeamMemberRequestRights.hasRight

  const handleDataFromChild = (data: Date) => {
    if (isInCurrentMonth(data)) {
      setDateOfCalendar(new Date())
    } else {
      setDateOfCalendar(getLastDayOfMonth(data))
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <HeaderTitleNavigation
          backToLink={SelfService.TEAM_CALENDAR_TEAMID_ID(`${params.teamid}`, `${params.id}`)}
          showDivider={false}
          mobileSizeTitle
          title={
            personForSelfServiceById?.personByIdForSelfservice.firstName +
            ' ' +
            personForSelfServiceById?.personByIdForSelfservice.preferredLastName
          }
        />
        <MonthlyCalendar
          date={dateOfCalendar}
          setDate={handleDataFromChild}
          isEditorRole={isEditorForPerson}
          specificPersonPage
          personId={params.id || ''}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Typography variant="h4">
          {t('selfservice.homepage.balances')} {new Date(dateOfCalendar).toLocaleDateString()}
        </Typography>
        {params.id && (
          <Box sx={{ marginTop: 2 }}>
            <BalanceChart dateOfCalendar={dateOfCalendar} personId={params.id || ''} />
          </Box>
        )}
      </Grid>
    </Grid>
  )
}

export default SpecificPersonCalendar

import EditIcon from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'
import { useGetBuropartnerContractDetailsQuery } from '@epix-web-apps/core'
import { IconButton, Box } from '@mui/material'
import { useFlyIn } from '@epix-web-apps/ui'
import EditBuropartnerSocialSecurity from '../edit-buropartner-social-security/edit-buropartner-social-security'
import { DetailBox, DetailBoxRow } from '@epix-web-apps/ui'
import EditBuropartnerContractType from '../edit-buropartner-contract-type/edit-buropartner-contract-type'
import EditBuropartnerTaxes from '../edit-buropartner-taxes/edit-buropartner-taxes'
import EditBuropartnerEducationLevel from '../edit-buropartner-education-level/edit-buropartner-education-level'

/* eslint-disable-next-line */
export interface DeBuropartnerProps {
  contractId: string
}

export function DeBuropartner({ contractId }: DeBuropartnerProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()

  const { data: partnerSpecificDetails } = useGetBuropartnerContractDetailsQuery({
    contractId: contractId || ''
  })

  const partnerSocialSecurity =
    partnerSpecificDetails?.buropartnerContractDetailByContractId.buropartnerContractDetailSocialSecurityModel
  const partnerContractType =
    partnerSpecificDetails?.buropartnerContractDetailByContractId.buropartnerContractDetailContractTypeModel
  const partnerTaxes = partnerSpecificDetails?.buropartnerContractDetailByContractId.buropartnerContractDetailTaxesModel
  const educationLevel =
    partnerSpecificDetails?.buropartnerContractDetailByContractId.buropartnerContractDetailEducationLevelModel

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2rem'
      }}
    >
      <Box sx={{ flex: 1 }}>
        <DetailBox
          title={t('buropartnerdetailpage.socialsecurity.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditBuropartnerSocialSecurity contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {partnerSocialSecurity?.socialSecurityNumber && (
            <DetailBoxRow>
              <p>
                {t('buropartnerdetailpage.socialsecurity.desocialsecuritynumber')}:
                <span className="bold"> {partnerSocialSecurity.socialSecurityNumber}</span>
              </p>
            </DetailBoxRow>
          )}
          {partnerSocialSecurity?.nameStatutoryHealthFund && (
            <DetailBoxRow>
              <p>
                {t('buropartnerdetailpage.socialsecurity.namehealthfund')}:
                <span className="bold"> {partnerSocialSecurity.nameStatutoryHealthFund}</span>
              </p>
            </DetailBoxRow>
          )}
          {partnerSocialSecurity?.parentStatusType && (
            <DetailBoxRow>
              <p>
                {t('buropartnerdetailpage.socialsecurity.parentstatus')}:
                <span className="bold"> {partnerSocialSecurity.parentStatusType.value}</span>
                {partnerSocialSecurity?.parentStatusTypeStartDate && (
                  <span>
                    {' '}
                    {t('buropartnerdetailpage.socialsecurity.asof')}{' '}
                    <strong>{partnerSocialSecurity?.parentStatusTypeStartDate}</strong>
                  </span>
                )}
              </p>
            </DetailBoxRow>
          )}
          {partnerSocialSecurity?.insuranceStatusType && (
            <DetailBoxRow>
              <p>
                {t('buropartnerdetailpage.socialsecurity.insurancestatus')}:
                <span className="bold"> {partnerSocialSecurity.insuranceStatusType?.value}</span>
              </p>
            </DetailBoxRow>
          )}
          {partnerSocialSecurity?.medicalInsuranceContribution && (
            <DetailBoxRow>
              <p>
                {t('buropartnerdetailpage.socialsecurity.medicalcontributions')}:
                <span className="bold"> {partnerSocialSecurity.medicalInsuranceContribution}</span>
              </p>
            </DetailBoxRow>
          )}
          {partnerSocialSecurity?.medicalInsuranceDeductible && (
            <DetailBoxRow>
              <p>
                {t('buropartnerdetailpage.socialsecurity.medicalinsurancedeductible')}:
                <span className="bold"> {partnerSocialSecurity.medicalInsuranceDeductible}</span>
              </p>
            </DetailBoxRow>
          )}
          {partnerSocialSecurity?.nursingCareContributions && (
            <DetailBoxRow>
              <p>
                {t('buropartnerdetailpage.socialsecurity.nursingcarecontributions')}:
                <span className="bold"> {partnerSocialSecurity.nursingCareContributions}</span>
              </p>
            </DetailBoxRow>
          )}
          {partnerSocialSecurity?.nursingCareDeductible && (
            <DetailBoxRow>
              <p>
                {t('buropartnerdetailpage.socialsecurity.nursingcaredeductible')}:
                <span className="bold"> {partnerSocialSecurity.nursingCareDeductible}</span>
              </p>
            </DetailBoxRow>
          )}

          <DetailBoxRow>
            <p>
              {partnerSocialSecurity?.memberProfessionalUtility
                ? t('buropartnerdetailpage.socialsecurity.memberprofessionalutilityactive')
                : t('buropartnerdetailpage.socialsecurity.memberprofessionalutilityinactive')}
            </p>
          </DetailBoxRow>

          {partnerSocialSecurity?.nameProfessionalUtility && (
            <DetailBoxRow>
              <p>
                {t('buropartnerdetailpage.socialsecurity.nameprofessionalutility')}:
                <span className="bold"> {partnerSocialSecurity.nameProfessionalUtility}</span>
              </p>
            </DetailBoxRow>
          )}
          {partnerSocialSecurity?.memberShipNumber && (
            <DetailBoxRow>
              <p>
                {t('buropartnerdetailpage.socialsecurity.membershipnumber')}:
                <span className="bold"> {partnerSocialSecurity.memberShipNumber}</span>
              </p>
            </DetailBoxRow>
          )}
          {partnerSocialSecurity?.hasApprenticeship && (
            <DetailBoxRow>
              <p>
                {t('buropartnerdetailpage.socialsecurity.apprenticeship')}
                {partnerSocialSecurity?.apprenticeshipStartDate && (
                  <span>
                    {' '}
                    {t('buropartnerdetailpage.socialsecurity.asof')}{' '}
                    <strong>{partnerSocialSecurity?.apprenticeshipStartDate}</strong>
                  </span>
                )}
                {partnerSocialSecurity.apprenticeshipStartDate && partnerSocialSecurity.apprenticeshipEndDate && (
                  <span>
                    {' '}
                    {t('buropartnerdetailpage.socialsecurity.until')}{' '}
                    <strong>{partnerSocialSecurity?.apprenticeshipEndDate}</strong>
                  </span>
                )}
              </p>
            </DetailBoxRow>
          )}
          {partnerSocialSecurity?.hasStudentContract && (
            <DetailBoxRow>
              <p>
                {t('buropartnerdetailpage.socialsecurity.studentcontract')}
                {partnerSocialSecurity?.studentContractStartDate && (
                  <span>
                    {' '}
                    {t('buropartnerdetailpage.socialsecurity.asof')}{' '}
                    <strong>{partnerSocialSecurity?.studentContractStartDate}</strong>
                  </span>
                )}
                {partnerSocialSecurity.studentContractStartDate && partnerSocialSecurity.studentContractEndDate && (
                  <span>
                    {' '}
                    {t('buropartnerdetailpage.socialsecurity.until')}{' '}
                    <strong>{partnerSocialSecurity?.studentContractEndDate}</strong>
                  </span>
                )}
              </p>
            </DetailBoxRow>
          )}
        </DetailBox>

        <DetailBox
          title={t('buropartnerdetailpage.contracttype.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditBuropartnerContractType contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {partnerContractType?.employmentRelationshipType && (
            <DetailBoxRow>
              <p>
                {t('buropartnerdetailpage.socialsecurity.employmentrelationship')}:
                <span className="bold"> {partnerContractType?.employmentRelationshipType?.value}</span>
              </p>
            </DetailBoxRow>
          )}
        </DetailBox>
      </Box>

      <Box sx={{ flex: 1 }}>
        <DetailBox
          title={t('buropartnerdetailpage.taxes.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditBuropartnerTaxes contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {partnerTaxes?.taxIdentificationNumber && (
            <DetailBoxRow>
              <p>
                {t('buropartnerdetailpage.socialsecurity.taxidentificationnumber')}:
                <span className="bold"> {partnerTaxes?.taxIdentificationNumber}</span>
              </p>
            </DetailBoxRow>
          )}
          {partnerTaxes?.taxClassType && (
            <DetailBoxRow>
              <p>
                {t('buropartnerdetailpage.socialsecurity.taxclass')}:
                <span className="bold"> {partnerTaxes?.taxClassType.value}</span>
              </p>
            </DetailBoxRow>
          )}
          {partnerTaxes?.childAllowance !== null && (
            <DetailBoxRow>
              <p>
                {t('buropartnerdetailpage.socialsecurity.childallowance')}:
                <span className="bold"> {partnerTaxes?.childAllowance}</span>
              </p>
            </DetailBoxRow>
          )}
          {partnerTaxes?.churchDenominationType && (
            <DetailBoxRow>
              <p>
                {t('buropartnerdetailpage.socialsecurity.churchdenomination')}:
                <span className="bold"> {partnerTaxes?.churchDenominationType.value}</span>
              </p>
            </DetailBoxRow>
          )}
        </DetailBox>
        <DetailBox
          title={t('buropartnerdetailpage.educationlevel.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditBuropartnerEducationLevel contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {educationLevel?.generalEducationLevelType && (
            <DetailBoxRow>
              <p>
                {t('buropartnerdetailpage.socialsecurity.generaleducationlevel')}:
                <span className="bold"> {educationLevel?.generalEducationLevelType?.value}</span>
              </p>
            </DetailBoxRow>
          )}
          {educationLevel?.professionalEducationLevelType && (
            <DetailBoxRow>
              <p>
                {t('buropartnerdetailpage.socialsecurity.professionaleducationlevel')}:
                <span className="bold"> {educationLevel?.professionalEducationLevelType?.value}</span>
              </p>
            </DetailBoxRow>
          )}
        </DetailBox>
      </Box>
    </Box>
  )
}

export default DeBuropartner

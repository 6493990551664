import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ImportJobErrorModel,
  OrderDirection,
  ProcessingStatusType,
  useDeleteCsvImportJobMutation,
  useGetCsvImportJobsQuery
} from '@epix-web-apps/core'
import { Box, Button, IconButton, MenuItem, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { DataTable, ISortModel, SortModel } from '../../components/data-table'
import { Import, useFlyIn } from '@epix-web-apps/ui'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import DropdownMenu from '../../components/dropdown-menu'
import { ImportDetails, RowState } from '../../components/import-components/import-details'

export enum IMPORT_STATE {
  Queued = 0,
  Processing = 1,
  Success = 2,
  Failed = 3
}

export interface IImportRow {
  id: string
  state: IMPORT_STATE
  progress: ProcessingStatusType
  filename: string
  success?: boolean | null
  importDataType: any
  errors: Array<ImportJobErrorModel>
  fileType: string
  startedOn: Date
  endedOn: Date
}

/* eslint-disable-next-line */
export interface ImportStatusdataPageProps {}

export function ImportStatusdataPage(props: ImportStatusdataPageProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { openFlyIn } = useFlyIn()
  const [tableData, setTableData] = useState<GridRowsProp>([])
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [rowCount, setRowCount] = useState(0)
  const [clickedJob, setClickedJob] = useState<IImportRow>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const deleteMutation = useDeleteCsvImportJobMutation()

  const {
    data: getCsvImportJobs,
    isLoading,
    refetch,
    isRefetching
  } = useGetCsvImportJobsQuery({
    offset: page * pageSize,
    limit: pageSize
  })

  const getFileNameExtension = (fileName: string): string => {
    const splitName: string[] = fileName.split('.')

    if (splitName.length > 1) {
      return splitName[splitName.length - 1]
    } else {
      return ''
    }
  }

  useEffect(() => {
    if (getCsvImportJobs && getCsvImportJobs?.csvImportJobs) {
      setTableData(
        getCsvImportJobs?.csvImportJobs.data.map((row, index) => {
          return {
            id: row.id,
            state:
              row.success !== null && row.success !== undefined
                ? row.success
                  ? IMPORT_STATE.Success
                  : IMPORT_STATE.Failed
                : row.statusType === ProcessingStatusType.Processing
                ? IMPORT_STATE.Processing
                : IMPORT_STATE.Queued,
            progress: row.statusType,
            filename: row.fileName,
            success: row.success,
            importDataType: row.processingImportJobType.value,
            startedOn: row.startedOn,
            fileType: getFileNameExtension(row.fileName),
            endedOn: row.endedOn
          } as IImportRow
        })
      )
      setRowCount(getCsvImportJobs?.csvImportJobs.totalCount)
    }
  }, [getCsvImportJobs])

  const rowActions = (rowParams: GridRenderCellParams) => (
    <IconButton
      aria-label="row actions"
      aria-controls="menu-row"
      aria-haspopup="true"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        setClickedJob(rowParams.row)
        setAnchorEl(e.currentTarget)
      }}
    >
      <MoreHorizIcon />
    </IconButton>
  )

  const rowDate = (date: Date) => new Date(date).toLocaleString()

  const filterBar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: [2]
      }}
    >
      <Typography m={0} variant="h2">
        {t('configurationimport.status.title')}
      </Typography>

      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button variant="contained" sx={{ display: 'flex', gap: 1 }} onClick={() => navigate(Import.DATA)}>
          <CloudUploadOutlinedIcon />
          {t('configurationimport.status.newimport')}
        </Button>
        <Button
          variant="contained"
          sx={{ display: 'flex', gap: 1 }}
          onClick={() => {
            setPage(0)
            setPageSize(25)
            refetch()
          }}
        >
          <CachedOutlinedIcon />
          <span>{t('configurationimport.status.refresh')}</span>
        </Button>
      </Box>
    </Box>
  )

  const columns: GridColDef[] = [
    {
      field: 'state',
      headerName: t('configurationimport.status.column.state'),
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: params => RowState(params.row.state)
    },
    {
      field: 'filename',
      headerName: t('configurationimport.status.column.filename'),
      sortable: false,
      flex: 1
    },
    {
      field: 'fileType',
      headerName: t('configurationimport.status.column.filetype'),
      sortable: false,
      flex: 1
    },
    {
      field: 'importDataType',
      headerName: t('configurationimport.status.column.datatype'),
      sortable: false,
      flex: 1
    },
    {
      field: 'startedOn',
      headerName: t('configurationimport.status.column.startedon'),
      sortable: false,
      renderCell: params => rowDate(params.row.startedOn),
      flex: 1
    },
    { field: '', sortable: false, editable: false, renderCell: rowActions }
  ]

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns?.[7]?.field, OrderDirection.Asc))

  async function handleDelete() {
    if (clickedJob?.progress !== ProcessingStatusType.Finished) return
    await deleteMutation
      .mutateAsync({
        deleteCsvImportJobCommand: {
          id: clickedJob?.id || ''
        }
      })
      .then(() => {
        refetch()
      })
  }

  return (
    <>
      <DataTable
        autoHeight
        data={tableData}
        columns={columns}
        defaultSortModel={sortModel}
        totalRowCount={rowCount}
        onRowClick={params => {
          openFlyIn({
            content: <ImportDetails csvImportJobId={params.row as IImportRow} />
          })
        }}
        onSortChange={setSortModel}
        page={page}
        onPageChange={setPage}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        isLoading={isLoading || isRefetching}
        filterBarElement={filterBar}
      />

      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            openFlyIn({
              content: <ImportDetails csvImportJobId={clickedJob as IImportRow} />
            })
          }}
        >
          {t('peoplepage.list.row.menu.viewdetails')}
        </MenuItem>
        {clickedJob?.progress === ProcessingStatusType.Finished && <MenuItem onClick={handleDelete}>Delete</MenuItem>}
      </DropdownMenu>
    </>
  )
}

export default ImportStatusdataPage

import { DocumentModel, downloadFile, useGetDocumentBlobByIdQuery } from '@epix-web-apps/core'
import { Box, CircularProgress, Grid, IconButton } from '@mui/material'
import { useEffect, useState } from 'react'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'

/* eslint-disable-next-line */
export interface DownloadDocumentProps {
  documentData: DocumentModel
}

export function DownloadDocument({ documentData }: DownloadDocumentProps) {
  const [document, setDocument] = useState<DocumentModel | null>(null)
  const { data: getDocumentBlobById, isFetching } = useGetDocumentBlobByIdQuery(
    {
      id: document?.id || ''
    },
    {
      enabled: !!document?.id,
      refetchOnWindowFocus: false,
      suspense: false
    }
  )

  useEffect(() => {
    if (document && getDocumentBlobById?.documentBlobById) {
      downloadFile(getDocumentBlobById?.documentBlobById.blob, getDocumentBlobById?.documentBlobById.name, getDocumentBlobById?.documentBlobById.contentType)
      setDocument(null)
    }
  }, [document, getDocumentBlobById])

  return (
    <Box>
      {documentData && (
        <Box key={document?.id}>
          <Grid container alignItems="center">
            <Grid item xs={10}>
              {isFetching && <CircularProgress size="1.75rem" />}
              {!isFetching && (
                <IconButton
                  sx={{ m: 0, p: 0 }}
                  onClick={() => {
                    setDocument(documentData)
                  }}
                >
                  <DownloadOutlinedIcon fontSize="medium" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  )
}

export default DownloadDocument

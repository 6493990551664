import { byteArrayToFile } from '@epix-web-apps/core'
import { useFlyIn, FLYIN_WIDTH } from '@epix-web-apps/ui'
import { Box, Link, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { exportJobModelWithoutProcessingJobType } from '../../generic-steps'

function ExportJobErrorListItem({ exportJob }: { exportJob: exportJobModelWithoutProcessingJobType }) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const theme = useTheme()
  return (
    <Box>
      <Typography color="error.light">{`${t('processpage.exportjobfailed')}`}</Typography>
      <Link
        color="error"
        underline="always"
        onClick={() =>
          openFlyIn({
            content: (
              <Box sx={{ width: FLYIN_WIDTH.DEFAULT }}>
                <Typography variant="h4" color={theme.palette.error.light}>{`${exportJob.errorMessages.length} ${t(
                  'common.errors'
                )}`}</Typography>
                <Link
                  color="error"
                  underline="always"
                  onClick={() => {
                    const errorArray = new TextEncoder().encode(`${exportJob.errorMessages.join('\n')}`)
                    byteArrayToFile(
                      errorArray,
                      `${exportJob.exportType}-exportjob-errors_${new Date(exportJob.exportDate).toISOString()}.csv`
                    )
                  }}
                >
                  {t('common.download')}
                </Link>
                {exportJob.errorMessages.map((errorMessage, index) => (
                  <Typography key={index} color={theme.palette.error.light} variant="h6">
                    {`${errorMessage}`}
                  </Typography>
                ))}
              </Box>
            )
          })
        }
      >
        {t('processpage.finishoverview.jobstates.viewallerrors')}
      </Link>
    </Box>
  )
}

export default ExportJobErrorListItem

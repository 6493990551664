import { Buffer } from 'buffer'
import { saveAs } from 'file-saver'
import { DOCUMENT_CONTENT_TYPES } from '../services'

export const byteArrayToFile = (base64: Uint8Array, filename: string) => {
  const binaryString = Buffer.from(base64)
  const blob = new Blob([binaryString], { type: 'csv' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)

  document.body.appendChild(link)

  link.click()

  link.parentNode?.removeChild(link)
}

export const downloadFile = (
  blobBitArray: any[],
  filename: string,
  contentType: string
) => {
  const blob = new Blob([new Uint8Array(blobBitArray)], {
    type: contentType
  })
  if (contentType === DOCUMENT_CONTENT_TYPES.PDF) {
    window.open(URL.createObjectURL(blob))
  } else {
    saveAs(
      blob,
      filename
    )
  }
}

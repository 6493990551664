import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, ISortModel, SortModel } from '../../data-table'
import {
  downloadFile,
  OrderByAddedOnFolders,
  OrderDirection,
  useGetDocumentBlobByIdQuery,
  useGetDocumentTypeByIdQuery,
  useGetDocumentTypeWithDocumentsByIdQuery
} from '@epix-web-apps/core'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, CircularProgress, Divider, IconButton, Link, MenuItem, Typography } from '@mui/material'
import DropdownMenu from '../../dropdown-menu'
import { useFlyIn } from '@epix-web-apps/ui'
import DataTableEmpty from '../../data-table/data-table-empty'
import AddDocument from '../add-document'
import RemoveDocument from '../remove-document'
import EditDocument from '../edit-document'

export enum DocumentColumns {
  NAME = 'name',
  CREATEDON = 'addedOn',
  ROWACTIONS = 'rowactions'
}

export interface FolderDocumentListProps {
  documentTypeId: string
  title: string
  subTitle?: string
  onTitleClick?: () => void
  contractId?: string
}

export function FolderDocumentList({
  documentTypeId,
  title,
  subTitle,
  onTitleClick,
  contractId
}: FolderDocumentListProps) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [clickedDocumentId, setClickedDocumentId] = useState<string | null>(null)
  const { openFlyIn } = useFlyIn()

  function RowActions(rowParams: GridRenderCellParams) {
    const { refetch, isFetching } = useGetDocumentBlobByIdQuery(
      { id: rowParams.row.id },
      { enabled: false, suspense: false }
    )
    return (
      <>
        <IconButton
          aria-label="row actions"
          aria-controls="menu-row"
          aria-haspopup="true"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            setAnchorEl(e.currentTarget)
            if (rowParams.row.id) {
              setClickedDocumentId(rowParams.row.id)
            }
          }}
        >
          <MoreHorizIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            refetch().then(r => {
              if (r.data) {
                downloadFile(
                  r.data.documentBlobById.blob,
                  r.data.documentBlobById.name,
                  r.data.documentBlobById.contentType
                )
              }
            })
          }}
          aria-label="row details"
          aria-controls="details-row"
        >
          {isFetching ? <CircularProgress size={25} /> : <CloudDownloadOutlinedIcon />}
        </IconButton>
      </>
    )
  }

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: DocumentColumns.NAME,
        headerName: t('document.datatable.column.name'),
        flex: 1,
        sortable: true
      },
      {
        field: DocumentColumns.CREATEDON,
        headerName: t('document.datatable.column.createdon'),
        flex: 1,
        sortable: true
      },
      {
        field: DocumentColumns.ROWACTIONS,
        headerName: '',
        sortable: false,
        editable: false,
        renderCell: RowActions
      }
    ],
    []
  )

  const { data: documentType } = useGetDocumentTypeByIdQuery({
    id: documentTypeId
  })

  const defaultSorting = OrderByAddedOnFolders.includes(documentType?.documentTypeById.key || '')
    ? DocumentColumns.CREATEDON
    : DocumentColumns.NAME
  const defaultOrderDirection = OrderByAddedOnFolders.includes(documentType?.documentTypeById.key || '')
    ? OrderDirection.Desc
    : OrderDirection.Asc

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(defaultSorting, defaultOrderDirection))

  useEffect(() => {
    setSortModel(new SortModel(defaultSorting, defaultOrderDirection))
  }, [documentType])

  const { data: getDocumentTypeWithDocuments, refetch: refetchDocumentTypeWithDocuments } =
    useGetDocumentTypeWithDocumentsByIdQuery(
      {
        documentTypeId: documentTypeId,
        contractId: contractId,
        sortByProperty: sortModel.field,
        orderDirection: sortModel.orderDirection
      },
      {
        enabled: !!documentTypeId && !!documentType
      }
    )

  const filterBar = (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1
          }}
        >
          <Link onClick={onTitleClick}>
            <Typography m={0} variant="h4">
              {title}
            </Typography>
          </Link>
          {subTitle && (
            <Typography m={0} variant="h4">
              {subTitle}
            </Typography>
          )}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, paddingBottom: '0.5rem' }}>
          <Button
            variant="contained"
            onClick={() =>
              openFlyIn({
                content: <AddDocument documentTypeId={documentTypeId} contractId={contractId} />,
                callbackAfterClose: () => refetchDocumentTypeWithDocuments
              })
            }
          >
            <AddIcon />
            {t('documentlist.button.adddocument')}
          </Button>
        </Box>
      </Box>
      <Divider />
    </>
  )

  return (
    <>
      <DataTable
        data={getDocumentTypeWithDocuments?.documentTypeWithDocumentsById.documents ?? []}
        onRowClick={params => params.row.id && setClickedDocumentId(params.row.id)}
        columns={columns}
        onSortChange={setSortModel}
        hideFooter
        isLoading={false}
        filterBarElement={filterBar}
        defaultSortModel={sortModel}
        emptyStateElement={
          <Box sx={{ py: '2rem' }}>
            <DataTableEmpty title={t('emptystate.folderdocuments')} />
          </Box>
        }
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <EditDocument documentId={clickedDocumentId || ''} />,
              callbackAfterClose: () => refetchDocumentTypeWithDocuments
            })
          }
        >
          {t('common.edit')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <RemoveDocument documentId={clickedDocumentId || ''} />,
              callbackAfterClose: () => refetchDocumentTypeWithDocuments
            })
          }
        >
          {t('common.remove')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default FolderDocumentList

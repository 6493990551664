import {
  DetailedStartersAndLeaversModel,
  STARTERS_LEAVERS_PERIOD_TYPES,
  useGetDetailedStartersAndLeaversFteQuery,
  useGetDetailedStartersAndLeaversHeadcountQuery
} from '@epix-web-apps/core'
import {
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  useTheme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TableCellProps
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BorderedTableCell, useGlobalPersistedStore } from '@epix-web-apps/ui'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { styled } from '@mui/system'
import { TypographyBold } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import { useAnalyticsPersistedStore } from '../../../../stores/analytics-store'
import StartersLeaversOverviewComponent from '../../starters-leavers-overview-component'

function StartersLeaversTab() {
  const [radioValue, setRadioValue] = useState<string>('contractCount')
  const { t } = useTranslation()
  const theme = useTheme()
  const { icpFilters } = useGlobalPersistedStore()
  const { dateFilter, genderFilter, functionAreaFilter, functionLevelFilter, employeeTypeFilter } =
    useAnalyticsPersistedStore()
  const { openFlyIn } = useFlyIn()

  const { data: headcountData } = useGetDetailedStartersAndLeaversHeadcountQuery({
    icps: icpFilters,
    selectedDate: dateFilter,
    filterModel: {
      genderType: genderFilter,
      functionAreaType: functionAreaFilter,
      functionLevelType: functionLevelFilter,
      employeeType: employeeTypeFilter
    }
  })

  const { data: fteData } = useGetDetailedStartersAndLeaversFteQuery({
    icps: icpFilters,
    selectedDate: dateFilter,
    filterModel: {
      genderType: genderFilter,
      functionAreaType: functionAreaFilter,
      functionLevelType: functionLevelFilter,
      employeeType: employeeTypeFilter
    }
  })

  const labels = new Map([
    [
      STARTERS_LEAVERS_PERIOD_TYPES.YOY,
      {
        labelFull: t('analytics.startersleavers.yearonyearfull'),
        labelShort: t('analytics.startersleavers.yearonyear')
      }
    ],
    [
      STARTERS_LEAVERS_PERIOD_TYPES.YTD,
      {
        labelFull: t('analytics.startersleavers.yeartodatefull'),
        labelShort: t('analytics.startersleavers.yeartodate')
      }
    ],
    [STARTERS_LEAVERS_PERIOD_TYPES.LAST_MONTH, { labelShort: t('analytics.startersleavers.lastmonth') }],
    [STARTERS_LEAVERS_PERIOD_TYPES.CURRENT_MONTH, { labelShort: t('analytics.startersleavers.currentmonth') }],
    [STARTERS_LEAVERS_PERIOD_TYPES.NEXT_MONTH, { labelShort: t('analytics.startersleavers.nextmonth') }]
  ])

  function showStartersAndLeavers(starters: boolean, startDate?: any, endDate?: any) {
    openFlyIn({
      content: (
        <StartersLeaversOverviewComponent
          starters={starters}
          beginDate={new Date(startDate)}
          endDate={new Date(endDate)}
        />
      )
    })
  }

  const CustomBox = styled(Box)<any>(() => {
    return {
      flexGrow: 1,
      minHeight: '2.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      p: {
        paddingTop: 0,
        marginTop: 0
      }
    }
  })

  const ArrowOfBalanceTotal = ({ value }: { value: number }) => {
    if (value > 0) return <KeyboardArrowUpIcon sx={{ color: theme.palette.success.main }} />
    if (value < 0) return <KeyboardArrowDownIcon sx={{ color: theme.palette.error.dark }} />
    return <Typography sx={{ color: theme.palette.primary.main, mx: 1, fontSize: '1.5rem' }}>{'='}</Typography>
  }

  const ArrowOfStartersOrLeavers = ({ value, starter }: { value: number; starter: boolean }) => {
    if (starter && value !== 0) {
      return <KeyboardArrowUpIcon sx={{ color: theme.palette.success.main }} />
    }
    if (!starter && value !== 0) {
      return <KeyboardArrowDownIcon sx={{ color: theme.palette.error.dark }} />
    }
    return <Typography sx={{ color: theme.palette.primary.main, mx: 1, fontSize: '1.5rem' }}>{'='}</Typography>
  }

  const StartersLeaversTableRow = ({
    startersLeavers
  }: {
    startersLeavers: DetailedStartersAndLeaversModel[] | undefined
  }) => {
    return (
      <>
        {startersLeavers?.map((item, index) => {
          return (
            <TableRow key={index}>
              <BorderedTableCell align="center">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1
                  }}
                >
                  <TypographyBold>
                    {labels.get(STARTERS_LEAVERS_PERIOD_TYPES[item.periodType])?.labelShort}
                  </TypographyBold>
                  <Typography color={theme.palette.text.secondary} sx={{ fontSize: 'small' }}>
                    {labels.get(STARTERS_LEAVERS_PERIOD_TYPES[item.periodType])?.labelFull}
                  </Typography>
                </Box>
                <Typography color={theme.palette.text.secondary} sx={{ fontSize: 'small' }}>
                  ({new Date(item.periodStart).toLocaleDateString()} - {new Date(item.periodEnd).toLocaleDateString()})
                </Typography>
              </BorderedTableCell>

              <BorderedTableCell>
                <CustomBox>
                  <Box
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    onClick={() => showStartersAndLeavers(true, item.periodStart, item.periodEnd)}
                  >
                    <ArrowOfStartersOrLeavers value={item.starters} starter={true} />
                    <Typography>{item.starters}</Typography>
                  </Box>
                </CustomBox>
              </BorderedTableCell>

              <BorderedTableCell>
                <CustomBox>
                  <Box
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    onClick={() => showStartersAndLeavers(false, item.periodStart, item.periodEnd)}
                  >
                    <ArrowOfStartersOrLeavers value={item.leavers} starter={false} />
                    <Typography>
                      {item.leavers}
                      <span style={{ color: theme.palette.text.secondary }}>
                        {` (${item.leaversByEmployee}, ${item.leaversByCompany})`}
                      </span>
                    </Typography>
                  </Box>
                </CustomBox>
              </BorderedTableCell>

              <TableCell>
                <CustomBox>
                  <ArrowOfBalanceTotal value={item.balance} />
                  <Typography>{item.balance}</Typography>
                </CustomBox>
              </TableCell>
            </TableRow>
          )
        })}
      </>
    )
  }

  return (
    <>
      <FormControl sx={{ mb: 1, ml: 1 }}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={radioValue}
          onChange={e => setRadioValue(e.target.value)}
          sx={{ display: 'flex', flexDirection: 'row' }}
        >
          <FormControlLabel value="contractCount" control={<Radio />} label={t('analytics.table.contractcount')} />
          <FormControlLabel value="fte" control={<Radio />} label={t('analytics.table.fte')} />
        </RadioGroup>
      </FormControl>

      <Table
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          tableLayout: 'fixed'
        }}
      >
        <TableHead>
          <TableRow>
            <BorderedTableCell align="center">
              <TypographyBold>{t('analytics.startersleavers.period')}</TypographyBold>
            </BorderedTableCell>
            <BorderedTableCell align="center">
              <TypographyBold>{t('analytics.startersleavers.starters')}</TypographyBold>
            </BorderedTableCell>
            <BorderedTableCell align="center">
              <TypographyBold>
                {t('analytics.startersleavers.leavers')}
                <span style={{ color: theme.palette.text.secondary }}>{' ('}</span>
                <Tooltip title={t('analytics.startersleavers.initiatedbyemployee')}>
                  <PersonOutlineOutlinedIcon
                    sx={{
                      verticalAlign: 'middle',
                      color: theme.palette.text.secondary
                    }}
                  />
                </Tooltip>
                <span style={{ color: theme.palette.text.secondary }}>{', '}</span>
                <Tooltip title={t('analytics.startersleavers.initiatedbycompany')}>
                  <BusinessOutlinedIcon
                    sx={{
                      verticalAlign: 'middle',
                      color: theme.palette.text.secondary
                    }}
                  />
                </Tooltip>
                <span style={{ color: theme.palette.text.secondary }}>{')'}</span>
              </TypographyBold>
            </BorderedTableCell>
            <TableCell align="center">
              <TypographyBold>{t('analytics.startersleavers.balance')}</TypographyBold>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {radioValue === 'contractCount' ? (
            <StartersLeaversTableRow startersLeavers={headcountData?.detailedStartersLeaversHeadcount} />
          ) : (
            <StartersLeaversTableRow startersLeavers={fteData?.detailedStartersLeaversFullTimeEquivalent} />
          )}
        </TableBody>
      </Table>
    </>
  )
}

export default StartersLeaversTab

import {
  DEFAULT_LOCALE,
  OrderDirection,
  useGetPayComponentHistoriesByPayComponentUpdateProcessParametersQuery,
  useGetSelectedPayComponentUpdateProcessContractsQuery
} from '@epix-web-apps/core'
import { GridColDef, GridRenderCellParams, GridRowsProp, GridSelectionModel } from '@mui/x-data-grid'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, ISortModel, SortModel } from '../../../data-table'
import { FormInput } from '@epix-web-apps/ui'
import { StepProps } from '../../generic-steps'
import DataTableEmpty from '../../../data-table/data-table-empty'
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined'
import { Tooltip, useTheme } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

/* eslint-disable-next-line */
export interface OverviewProps {}

export function Overview({ processId }: StepProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [rowCount, setRowCount] = useState(0)

  const { data: payComponentsData, isLoading: isLoadingPayComponents } =
    useGetPayComponentHistoriesByPayComponentUpdateProcessParametersQuery(
      {
        payComponentUpdateProcessId: processId || '',
        offset: 0,
        limit: -1
      },
      {
        suspense: false
      }
    )

  const { data: getSelectedPayComponentUpdateProcess } = useGetSelectedPayComponentUpdateProcessContractsQuery(
    {
      processId: processId || ''
    },
    {
      enabled: !!processId
    }
  )

  const {
    setValue,
    getValues,
    formState: { errors }
  } = useFormContext()

  const newValueInput = useCallback((rowParams: GridRenderCellParams) => {
    if (!rowParams.row.hasConflictingPayComponentHistory) {
      return (
        <FormInput
          sx={6}
          onChange={() => {
            const selection = getValues('contractIds')
            if (!selection.includes(rowParams.row.id)) {
              selection.push(rowParams.row.id)
              setRowSelectionModel(selection)
              setValue('contractIds', selection)
            }
          }}
          name={`payComponentHistories.${rowParams.row.index}.newValue`}
          label={`${t('form.field.value')} *`}
          shrink={true}
        />
      )
    }
    return (
      <Tooltip title={t('validationerrorspage.tooltip.paycomponenthistoryexists')}>
        <InfoOutlinedIcon sx={{ color: theme.palette.primary.main, marginLeft: 2 }} />
      </Tooltip>
    )
  }, [])

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: t('paycomponent.updateprocess.datatable.column.firstname'),
      flex: 1,
      sortable: false
    },
    {
      field: 'lastName',
      headerName: t('paycomponent.updateprocess.datatable.column.lastname'),
      flex: 1,
      sortable: false
    },
    {
      field: 'contractStartDate',
      headerName: t('paycomponent.updateprocess.datatable.column.contractstartdate'),
      flex: 1,
      sortable: false
    },
    {
      field: 'contractEndDate',
      headerName: t('paycomponent.updateprocess.datatable.column.contractenddate'),
      flex: 1,
      sortable: false
    },
    {
      field: 'fte',
      headerName: t('paycomponent.updateprocess.datatable.column.fte'),
      flex: 1,
      sortable: false
    },
    {
      field: 'oldValue',
      headerName: t('paycomponent.updateprocess.datatable.column.oldvalue'),
      flex: 1,
      sortable: false
    },
    {
      field: 'newValue',
      headerName: t('paycomponent.updateprocess.datatable.column.newvalue'),
      flex: 1,
      renderCell: newValueInput,
      sortable: false
    }
  ]

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns[0]?.field, OrderDirection.Asc))
  const [tableData, setTableData] = useState<GridRowsProp>([])
  const [rowSelectionModel, setRowSelectionModel] = useState<GridSelectionModel>([])

  useEffect(() => {
    if (payComponentsData && payComponentsData.payComponentHistoriesByPayComponentUpdateProcessParameters) {
      const payComponents = payComponentsData.payComponentHistoriesByPayComponentUpdateProcessParameters
      setTableData(
        payComponents.data.map((row, index) => {
          return {
            id: row.contractId,
            payComponentHistoryId: row.payComponentHistoryId,
            personId: row.personId,
            firstName: row.firstName,
            lastName: row.lastName,
            contractStartDate: new Date(row.contractStartDate).toLocaleDateString(DEFAULT_LOCALE),
            contractEndDate: row.contractEndDate
              ? new Date(row.contractEndDate).toLocaleDateString(DEFAULT_LOCALE)
              : null,
            fte: row.fte,
            oldValue: row.oldValue,
            newValue: row.newValue,
            hasConflictingPayComponentHistory: row.hasConflictingPayComponentHistory,
            index: index
          }
        })
      )
      setRowCount(payComponents.totalCount)
    }

    const selection =
      payComponentsData?.payComponentHistoriesByPayComponentUpdateProcessParameters.data.map(x => ({
        contractId: x.contractId,
        payComponentHistoryId: x.payComponentHistoryId,
        newValue: x.newValue
      })) || []

    const selectedRows =
      getValues('contractIds') ||
      payComponentsData?.payComponentHistoriesByPayComponentUpdateProcessParameters.data.map(x => x.contractId) ||
      []
    setRowSelectionModel(selectedRows)

    selection.forEach(e => {
      if (selectedRows.includes(e.contractId)) {
        e.newValue = getSelectedPayComponentUpdateProcess?.selectedPayComponentUpdateProcessContracts.find(
          x => x.contractId === e.contractId
        )?.newValue
      }
    })
    setValue('payComponentHistories', selection)
  }, [payComponentsData, getSelectedPayComponentUpdateProcess])

  return (
    <DataTable
      isRowSelectable={params => params.row.hasConflictingPayComponentHistory === false}
      data={tableData}
      columns={columns}
      totalRowCount={rowCount}
      onSortChange={setSortModel}
      disableRowSelectionOnClick
      onSelectionModelChange={selection => {
        setRowSelectionModel(selection)
        setValue('contractIds', selection)
      }}
      selectionModel={rowSelectionModel}
      isLoading={isLoadingPayComponents}
      defaultSortModel={sortModel}
      checkboxSelection={true}
      hideFooter
      emptyStateElement={
        <DataTableEmpty
          title={t('processpage.population.nocontractsfound')}
          icon={<DoDisturbAltOutlinedIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} />}
        />
      }
    />
  )
}

export default Overview

import { Box, Link, Typography, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { OnboardingLayout } from '../../../layouts'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'

/* eslint-disable-next-line */
export interface OnboardingIcpLayoutProps {
  onLinkClick: () => void
}

export function OnboardingIcpLayout({ onLinkClick }: OnboardingIcpLayoutProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <>
      <OnboardingLayout title={t('icpproviderspage.onboarding.title')} icon={<LanguageOutlinedIcon />} />
      <Box
        sx={{
          marginLeft: '20%',
          marginRight: '20%'
        }}
      >
        <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
          {t('icpproviderspage.onboarding.description')}
        </Typography>
        <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
          {t('icpproviderspage.onboarding.description1')}
        </Typography>
        <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
          <Trans
            i18nKey={t('icpproviderspage.onboarding.description2')}
            values={{ linktoicp: t('icpproviderspage.onboarding.here') }}
            components={{
              tt: <Link underline="always" onClick={onLinkClick} />
            }}
          />
        </Typography>
      </Box>
    </>
  )
}

export default OnboardingIcpLayout

import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { DataTable, ISortModel, SortModel } from '../../components/data-table'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import DoneIcon from '@mui/icons-material/Done'
import { useEffect, useState } from 'react'
import { OrderDirection, useGetAllFunctionLevelTypesQuery } from '@epix-web-apps/core'
import { Box, Button, IconButton, MenuItem } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import DropdownMenu from '../../components/dropdown-menu'
import React from 'react'
import { HeaderTitleNavigation, People, useFlyIn } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import AddEditFunctionLevel from '../../components/function-level-components/add-edit-function-level'

/* eslint-disable-next-line */
export interface FunctionLevelsPageProps {}

export function FunctionLevelsPage(props: FunctionLevelsPageProps) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [rowCount, setRowCount] = useState(0)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [clickedclickedFunctionLevelKey, setClickedFunctionLevelKey] = useState<string>('')
  const { openFlyIn } = useFlyIn()

  const activeActions = (rowParams: GridRenderCellParams) => (rowParams.value ? <DoneIcon /> : <VisibilityOffIcon />)

  const rowActions = (rowParams: GridRenderCellParams) => {
    return (
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
          setClickedFunctionLevelKey(rowParams.row.key)
          setAnchorEl(e.currentTarget)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'value',
      headerName: t('functionlevelspage.datatable.column.description'),
      flex: 1
    },
    {
      field: 'key',
      headerName: t('functionlevelspage.datatable.column.code'),
      flex: 1
    },
    {
      field: 'active',
      headerName: t('functionlevelspage.datatable.column.active'),
      flex: 1,
      sortable: true,
      editable: false,
      renderCell: activeActions
    },
    { field: '', sortable: false, editable: false, renderCell: rowActions }
  ]

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns[0]?.field, OrderDirection.Asc))
  const [tableData, setTableData] = useState<GridRowsProp>([])

  const {
    data: functionLevelsData,
    isLoading: isLoadingFunctionLevel,
    refetch: refetchAllFunctionLevels
  } = useGetAllFunctionLevelTypesQuery(
    {
      activeOnly: false,
      offset: page * pageSize,
      limit: pageSize,
      sortByProperty: sortModel.field,
      orderDirection: sortModel.orderDirection
    },
    {
      suspense: false
    }
  )

  const filterBar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: [2]
      }}
    >
      <HeaderTitleNavigation title={t('functionlevelspage.title')} backToLink={People.PEOPLE()} />

      <Button
        variant="contained"
        onClick={() =>
          openFlyIn({
            content: <AddEditFunctionLevel />,
            callbackAfterClose: () => refetchAllFunctionLevels
          })
        }
      >
        <AddIcon />
        {t('functionlevelspage.button.addfunctionlevel')}
      </Button>
    </Box>
  )

  useEffect(() => {
    if (functionLevelsData && functionLevelsData.functionLevelTypes) {
      const functionLevels = functionLevelsData.functionLevelTypes
      setRowCount(functionLevels.totalCount)
      setTableData(
        functionLevels.data.map(row => {
          return {
            id: row.key,
            key: row.key,
            value: row.value,
            active: row.active
          }
        })
      )
    }
  }, [functionLevelsData])

  return (
    <>
      <DataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        page={page}
        onPageChange={setPage}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        onSortChange={setSortModel}
        isLoading={isLoadingFunctionLevel}
        defaultSortModel={sortModel}
        filterBarElement={filterBar}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <AddEditFunctionLevel functionLevelKey={clickedclickedFunctionLevelKey} />,
              callbackAfterClose: () => refetchAllFunctionLevels
            })
          }
        >
          {t('functionlevelspage.list.row.menu.edit-functionleveltype')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default FunctionLevelsPage

import {
  numericString,
  OrderDirection,
  useCreateDayDefinitionEntryMutation,
  useGetAllPayrollCodesByEmployerIdQuery,
  useGetDayDefinitionEntryByIdQuery,
  useUpdateDayDefinitionEntryMutation,
  FormSelectOption,
  formTypeSelectOptions
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { number, object, string, TypeOf } from 'zod'
import { FormGridLayout } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { FormContainer, FormInput, FormSelect } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface AddEditDayDefinitionEntryProps {
  employerId: string
  dayDefinitionId: string
  dayDefinitionEntryId?: string
}

export function AddEditDayDefinitionEntry({
  employerId,
  dayDefinitionId,
  dayDefinitionEntryId
}: AddEditDayDefinitionEntryProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const AddEditDayDefinitionEntrySchema = object({
    payrollCodeId: string({
      required_error: t('form.validation.payrollcoderequired'),
      invalid_type_error: t('form.validation.payrollcoderequired')
    }).min(1, t('form.validation.payrollcoderequired')),
    timeFrameType: string({
      required_error: t('form.validation.timeframetyperequired'),
      invalid_type_error: t('form.validation.timeframetyperequired')
    }).min(1, t('form.validation.timeframetyperequired')),
    hours: numericString(
      number({
        required_error: t('form.validation.hoursrequired'),
        invalid_type_error: t('form.validation.hoursmustbenumeric')
      }).positive({ message: t('form.validation.hoursgreaterthen0') })
    ),
    dayDefinitionId: string({
      required_error: t('form.validation.daydefinitionrequired'),
      invalid_type_error: t('form.validation.daydefinitionrequired')
    }).min(1, t('form.validation.daydefinitionrequired'))
  })

  type CreateEditDayDefinitionEntryForm = TypeOf<typeof AddEditDayDefinitionEntrySchema>

  const { timeFrameOptions } = formTypeSelectOptions
  const { data: payrollCodesData } = useGetAllPayrollCodesByEmployerIdQuery(
    {
      employerId: employerId || '',
      offset: 0,
      limit: -1,
      payrollCodeFilterModel: {
        showCalendarCodes: true
      },
      sortByProperty: 'Description',
      orderDirection: OrderDirection.Asc
    },
    {
      suspense: false
    }
  )

  const { data: getDayDefinitionEntryById, refetch: refetchDayDefinationEntryById } = useGetDayDefinitionEntryByIdQuery(
    {
      dayDefinitionId: dayDefinitionId || '',
      dayDefinitionEntryId: dayDefinitionEntryId || ''
    },
    {
      enabled: !!dayDefinitionEntryId
    }
  )

  const form = useForm<CreateEditDayDefinitionEntryForm>({
    resolver: zodResolver(AddEditDayDefinitionEntrySchema),
    defaultValues: {
      timeFrameType: getDayDefinitionEntryById?.dayDefinitionEntryById?.timeFrameType.key,
      hours: getDayDefinitionEntryById?.dayDefinitionEntryById?.hours,
      dayDefinitionId: dayDefinitionId,
      payrollCodeId: getDayDefinitionEntryById?.dayDefinitionEntryById?.payrollCodeId
    }
  })

  const createmutation = useCreateDayDefinitionEntryMutation()
  const updatemutation = useUpdateDayDefinitionEntryMutation()

  const handleOnSubmit = async (newDayDefinitionEntry: CreateEditDayDefinitionEntryForm) => {
    if (dayDefinitionEntryId === undefined) {
      await createmutation
        .mutateAsync({
          createDayDefinitionEntryCommand: {
            payrollCodeId: newDayDefinitionEntry.payrollCodeId,
            timeFrameTypeKey: newDayDefinitionEntry.timeFrameType,
            dayDefinitionId: dayDefinitionId,
            hours: newDayDefinitionEntry.hours
          }
        })
        .then(closeFlyIn)
    } else {
      await updatemutation
        .mutateAsync({
          updateDayDefinitionEntryCommand: {
            dayDefinitionEntryId: dayDefinitionEntryId || '',
            dayDefinitionId: dayDefinitionId || '',
            payrollCodeId: newDayDefinitionEntry.payrollCodeId,
            timeFrameTypeKey: newDayDefinitionEntry.timeFrameType,
            hours: newDayDefinitionEntry.hours
          }
        })
        .then(() => {
          refetchDayDefinationEntryById()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditdaydefinitionentries.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="payrollCodeId"
          label={`${t('form.field.payrollcode')}`}
          options={payrollCodesData?.allPayrollCodesByEmployerId.data.map(
            x => new FormSelectOption(x.id, x.code + ' - ' + x.userFriendlyDescription)
          )}
        />

        <FormSelect
          sx={12}
          name="timeFrameType"
          label={`${t('form.field.timeframetype')}`}
          options={timeFrameOptions}
        />

        <FormInput sx={12} name="hours" label={`${t('form.field.hours')} *`} />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons
        isMutating={createmutation.isLoading || updatemutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditDayDefinitionEntry

import { getNumberOfColors, useGetIncidentsHistoryQuery, useGetMeQuery } from '@epix-web-apps/core'
import { useGlobalPersistedStore } from '@epix-web-apps/ui'
import BarchartComponent from '../barchart-component'
import { useTranslation } from 'react-i18next'
import { useAnalyticsPersistedStore } from '../../../stores/analytics-store'
import { Box, Typography } from '@mui/material'

/* eslint-disable-next-line */
export interface IncidentHistoryComponentProps {}

function checkTotal(data: any) {
  let total = 0
  for (let index = 0; index < data?.length; index++) {
    const element = data[index]
    total += element.headcount
    total += element.fTECount
  }
  return total === 0
}

export function IncidentHistoryComponent(props: IncidentHistoryComponentProps) {
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const { dateFilter } = useAnalyticsPersistedStore()
  const { genderFilter } = useAnalyticsPersistedStore()
  const { functionAreaFilter } = useAnalyticsPersistedStore()
  const { functionLevelFilter } = useAnalyticsPersistedStore()
  const { employeeTypeFilter } = useAnalyticsPersistedStore()

  const { data: incidentHistory } = useGetIncidentsHistoryQuery({
    icps: icpFilters,
    selectedDate: dateFilter,
    filterModel: {
      genderType: genderFilter,
      functionAreaType: functionAreaFilter,
      functionLevelType: functionLevelFilter,
      employeeType: employeeTypeFilter
    }
  })

  const { data: me } = useGetMeQuery()

  if (checkTotal(incidentHistory?.incidentsHistory.entries))
    return <Typography sx={{ p: 2, textAlign: 'center' }}>{t('analytics.nodata')}</Typography>
  return (
    <Box height={'80%'} minHeight={'400px'}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          sx={{
            color: getNumberOfColors(1)
          }}
        >
          ({new Date(incidentHistory?.incidentsHistory?.fromDate).toLocaleDateString(me?.me.locale.locale)} -{' '}
          {new Date(incidentHistory?.incidentsHistory?.toDate).toLocaleDateString(me?.me.locale.locale)})
        </Typography>
      </Box>
      <BarchartComponent
        data={incidentHistory?.incidentsHistory.entries.map(x => ({
          [t('analytics.newincidents')]: x.newIncidents,
          [t('analytics.peopleinjured')]: x.peopleInjured,
          date: new Date(x.date).toLocaleDateString(me?.me.locale.locale, {
            month: 'short'
          })
        }))}
        keys={[t('analytics.newincidents'), t('analytics.peopleinjured')]}
        layoutMode="vertical"
        cutString={false}
        legendCharLength={50}
        legendWidth={200}
        indexBy={'date'}
        groupMode={'grouped'}
        colors={getNumberOfColors(2)}
        innerPadding={4}
        marginRight={50}
        marginLeft={50}
      />
    </Box>
  )
}

export default IncidentHistoryComponent

import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { SuspensePaper } from '../../../suspense-paper'
import SeniorityByAgeComponent from '../../seniority-by-age-component'
import GenderComponent from '../../gender-component'
import DisabledComponent from '../../disabled-component/disabled-component'
import NationalityComponent from '../../nationality-component/nationality-component'

function DemographicsTab() {
  const { t } = useTranslation()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <SuspensePaper title={t('analytics.pie.gender')}>
          <GenderComponent />
        </SuspensePaper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <SuspensePaper title={t('analytics.pie.disability')}>
          <DisabledComponent />
        </SuspensePaper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <SuspensePaper title={t('analytics.pie.nationality')}>
          <NationalityComponent />
        </SuspensePaper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <SuspensePaper title={t('analytics.heatmap.agebyseniority')}>
          <SeniorityByAgeComponent />
        </SuspensePaper>
      </Grid>
    </Grid>
  )
}

export default DemographicsTab

import { FormControl, FormControlLabel, FormLabel, GridSize, Checkbox, FormGroup } from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { OptionalGridWrapper } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface IFormCheckboxOption {
  key: string
  value: string
}

export class FormCheckboxOption implements IFormCheckboxOption {
  key: string
  value: string

  constructor(key: string, value: string) {
    this.key = key
    this.value = value
  }
}

export interface FormCheckboxGroupProps {
  name: string
  label: string
  options?: Array<IFormCheckboxOption>
  onChange?: ((selectedKeys: string[]) => void) | undefined
  sx?: GridSize
  defaultValue?: any
  disabledValues?: string[]
}

export function FormCheckboxGroup({
  name,
  label,
  options = undefined,
  onChange,
  sx,
  defaultValue,
  disabledValues
}: FormCheckboxGroupProps) {
  const form = useFormContext()
  if (!form) throw Error('Form elements can only be used inside the react hook forms provider')
  const { ref, ...register } = form.register(name)
  const [selectedKeys, setSelectedKeys] = useState<string[]>(defaultValue)

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target
    setSelectedKeys(prevSelectedKeys => {
      if (checked) {
        return [...prevSelectedKeys, value]
      } else {
        return prevSelectedKeys.filter(key => key !== value)
      }
    })
  }

  useEffect(() => {
    form?.setValue(name, selectedKeys)
    onChange && onChange(selectedKeys)
  }, [selectedKeys])

  return (
    <OptionalGridWrapper sx={sx}>
      <FormControl error={!!form.getFieldState(name).error} fullWidth size="small">
        <FormLabel id={register.name}>{label}</FormLabel>
        <Controller
          {...register}
          defaultValue={options?.find(o => o.key === defaultValue) || null}
          render={({ field }) => (
            <FormGroup {...field} id={register.name}>
              {options?.map((x, index) => (
                <FormControlLabel
                  key={index}
                  value={x.key}
                  control={
                    <Checkbox
                      checked={selectedKeys?.includes(x.key)}
                      onChange={handleCheckboxChange}
                      id={`${register.name}${index}`}
                    />
                  }
                  label={x.value}
                  disabled={disabledValues?.includes(x.key)}
                />
              ))}
            </FormGroup>
          )}
        />
      </FormControl>
    </OptionalGridWrapper>
  )
}

export default FormCheckboxGroup

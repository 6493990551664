import { Grid, styled, useTheme } from '@mui/material'
import React from 'react'

const FormGrid = ({ children, ...props }: { children: React.ReactNode }) => (
  <Grid
    {...{
      spacing: 2,
      paddingBottom: 3,
      container: true,
      ...props
    }}
  >
    {children}
  </Grid>
)

export const FormGridLayout = styled(FormGrid, {
  shouldForwardProp: prop => prop !== 'hasBorderBottom' && prop !== 'hasPaddingTop'
})<any>(
  ({ hasBorderBottom = false, hasPaddingTop = false }: { hasBorderBottom?: boolean; hasPaddingTop?: boolean }) => {
    const theme = useTheme()
    return {
      borderBottom: hasBorderBottom ? `1px solid ${theme.palette.divider}` : 'none',
      paddingTop: hasPaddingTop ? '1.2rem' : 0
    }
  }
)

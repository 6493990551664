import {
  useGetAllInjuriesByPersonIdQuery,
  useGetCompanyCarHistoriesByContractIdQuery,
  useGetContractByIdQuery,
  useUpdateContractDeletedStatusMutation
} from '@epix-web-apps/core'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, object, TypeOf } from 'zod'
import { useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Divider, Table, TableBody, TableCell, TableRow, Typography, useTheme } from '@mui/material'
import { FormActionButtons, FormErrorList, FormContainer } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'
import { IcpCodeBadge, IcpCodeBadgeSize } from '../../icp-components'

/* eslint-disable-next-line */
export interface EditContractArchived {
  contractId: string
}

export function EditContractArchived({ contractId }: EditContractArchived) {
  const { t } = useTranslation()
  const theme = useTheme()
  const EditContractArchivedSchema = object({
    archived: boolean()
  })
  type EditContractArchivedForm = TypeOf<typeof EditContractArchivedSchema>
  const { closeFlyIn } = useFlyIn()
  const { data: getContractById, refetch: refetchContractById } = useGetContractByIdQuery({
    contractId: contractId
  })

  const contract = getContractById?.contractById
  const mutation = useUpdateContractDeletedStatusMutation()
  const form = useForm<EditContractArchivedForm>({
    resolver: zodResolver(EditContractArchivedSchema),
    defaultValues: {
      archived: contract?.isArchived
    }
  })

  const { data: companyCarHistoriesByContractId } = useGetCompanyCarHistoriesByContractIdQuery({
    contractId: contractId
  })

  const { data: getAllInjuriesByPersonId } = useGetAllInjuriesByPersonIdQuery(
    {
      personId: contract?.personId || ''
    },
    {
      enabled: !!contract?.personId
    }
  )

  const injuries =
    getAllInjuriesByPersonId?.allInjuriesByPersonId.filter(i =>
      i.contractHistoryModels.some(c => c.contractBasicModel.contractId === contractId)
    ) ?? []

  const hasCompanyCarHistories =
    companyCarHistoriesByContractId && companyCarHistoriesByContractId.companyCarHistories.length > 0
  const hasWorkScheduleHistories = contract && contract.contractWorkScheduleHistory.history.length > 0
  const hasFixedDataSent = !!contract?.fixedDataSent
  const hasInjuries = injuries.length > 0

  const handleOnSubmit = async (contractDeletedInfo: EditContractArchivedForm) => {
    await mutation
      .mutateAsync({
        updateContractDeletedStatusCommand: {
          contractId: contractId,
          archived: contractDeletedInfo.archived
        }
      })
      .then(() => {
        refetchContractById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('contractspage.editcontractstate.title')}</Typography>
      <Box sx={{ display: 'flex' }}>
        <IcpCodeBadge sx={{ marginRight: '1rem' }} icpCode={contract?.icp?.code} size={IcpCodeBadgeSize.large} />
        <Box>
          <Typography>
            {contract && new Date(contract.startDate).toLocaleDateString()} -{' '}
            {contract?.endDate ? new Date(contract.endDate).toLocaleDateString() : '...'}
          </Typography>
          <Typography>
            {contract?.employeeNumber &&
              `${t('contractdetailpage.label.employeenumber')}: ${contract?.employeeNumber} |`}{' '}
            {contract?.employer?.companyName}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: '0.5rem' }} />

      {hasFixedDataSent ? (
        <Typography>{t('contractspage.editcontractstate.closure')}</Typography>
      ) : (
        <>
          {hasCompanyCarHistories && (
            <Box sx={{ pb: '0.5rem' }}>
              <Typography>
                {t('contractspage.editcontractstate.contractstillhas')}{' '}
                <strong>{t('contractspage.editcontractstate.companycarhistories')}</strong>
              </Typography>
              <Table size="small">
                <TableBody>
                  {companyCarHistoriesByContractId.companyCarHistories.map(c => (
                    <TableRow key={c.id}>
                      <TableCell>{c.startDate}</TableCell>
                      <TableCell>{c.companyCar.licensePlate}</TableCell>
                      <TableCell>{c.companyCar.model}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}

          {hasWorkScheduleHistories && (
            <Box sx={{ pb: '0.5rem' }}>
              <Typography>
                {t('contractspage.editcontractstate.contractstillhas')}{' '}
                <strong>{t('contractspage.editcontractstate.workschedulehistories')}</strong>
              </Typography>
              <Table size="small">
                <TableBody>
                  {contract.contractWorkScheduleHistory.history.map(h => (
                    <TableRow key={h.id}>
                      <TableCell>{h.workScheduleModel.code}</TableCell>
                      <TableCell>{h.validFrom}</TableCell>
                      <TableCell>{h.validTo ? h.validTo : '...'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}

          {hasInjuries && (
            <Box sx={{ pb: '0.5rem' }}>
              <Typography>
                {t('contractspage.editcontractstate.contractstillhas')}{' '}
                <strong>{t('contractspage.editcontractstate.injuryhistories')}</strong>
              </Typography>
              <Table size="small">
                <TableBody>
                  {injuries.map(i => (
                    <TableRow key={i.id}>
                      <TableCell>{i.injuryDate}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </>
      )}

      <Box>
        <FormSwitch
          name="archived"
          label={t('form.field.archived')}
          disabled={hasFixedDataSent || hasCompanyCarHistories || hasWorkScheduleHistories || hasInjuries}
        />
        <Typography sx={{ px: '0.5rem', color: theme.palette.text.secondary }}>
          {t('form.field.softdeleteinfo')}
        </Typography>
      </Box>
      <FormErrorList />
      <FormActionButtons
        isMutating={mutation.isLoading}
        disabled={hasFixedDataSent || hasCompanyCarHistories || hasWorkScheduleHistories || hasInjuries}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default EditContractArchived

import Balances from '../../components/balance-components/balances/balances'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MonthlyCalendar } from '../../components/calendar-components/monthly-calendar'
import { useState } from 'react'
import PendingRequestComponent from '../../components/absence-components/pending-request-component'
import {
  ToRouteDateFormat,
  getFirstDayOfMonth,
  useGetRequestPolicyContractsByPersonIdByTeamStructureQuery
} from '@epix-web-apps/core'
import { useSearchParams } from 'react-router-dom'
import { SuspensePaper } from '../../../myepix/components/suspense-paper'
import { useGlobalStore } from '@epix-web-apps/ui'
import TasksWarning from '../../components/tasks/tasks-warning'

/* eslint-disable-next-line */
export interface HomePageProps {}

export function HomePage(props: HomePageProps) {
  const { me } = useGlobalStore()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const dateSearchParam = new Date(searchParams.get('date') ?? new Date())
  const [date, setDate] = useState<Date>(dateSearchParam)

  const { data: getRequestPolicyContractsByPersonIdQuery } =
    useGetRequestPolicyContractsByPersonIdByTeamStructureQuery()

  function hasRequestPolicies() {
    const requestPolicies = getRequestPolicyContractsByPersonIdQuery?.allRequestPolicyContractsByPersonIdByTeamStructure
    return requestPolicies ? requestPolicies.length > 0 : false
  }

  function handleDateChange(date: Date) {
    setDate(date)
    setSearchParams({ date: ToRouteDateFormat(date) })
  }

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} md={6}>
        <Grid item xs={12}>
          <Typography variant="h4" mb={1}>
            {t('selfservice.homepage.calendar')}
          </Typography>
          <SuspensePaper sx={{ border: 0, p: 0 }}>
            <MonthlyCalendar date={getFirstDayOfMonth(date)} setDate={handleDateChange} personId={me?.personId || ''} />
          </SuspensePaper>
        </Grid>
        <Grid item mt={2} xs={12}>
          <TasksWarning />
        </Grid>
      </Grid>

      <Grid item container xs={12} md={6} height={'100%'}>
        <Grid item xs={12} height={'50%'}>
          <SuspensePaper sx={{ border: 0, p: 0 }}>
            {me?.personId && hasRequestPolicies() && <Balances personId={me.personId} date={date} />}
          </SuspensePaper>
        </Grid>
        <Grid item xs={12} height={'50%'}>
          <PendingRequestComponent dateParam={new Date(getFirstDayOfMonth(date) ?? new Date())} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default HomePage

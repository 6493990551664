import { GraphqlError, useDeleteTeamMutation, useGetTeamByIdQuery } from '@epix-web-apps/core'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Configuration, useFlyIn } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { useNavigate } from 'react-router-dom'

/* eslint-disable-next-line */
export interface DeleteTeamProps {
  teamId: string | undefined
}

export function RemoveTeam({ teamId }: DeleteTeamProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const deleteMutation = useDeleteTeamMutation()
  const form = useForm()
  const navigate = useNavigate()

  const { data: teamById } = useGetTeamByIdQuery(
    {
      id: teamId || ''
    },
    {
      suspense: false
    }
  )

  const handleOnSubmit = async () => {
    await deleteMutation
      .mutateAsync({
        deleteTeamCommand: {
          id: teamId || ''
        }
      })
      .then(closeFlyIn)
      .catch(e => setBackendErrors([e]))
      .then(() => navigate(Configuration.TEAMS()))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.deleteteam.title')}</Typography>
      <Typography>{teamById?.teamById.name}</Typography>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={deleteMutation.isLoading}
        buttonText={t('common.remove')}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default RemoveTeam

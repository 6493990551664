import { Box, Button, IconButton, Switch, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowId, GridRowsProp, GridValueGetterParams } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { DataTable, ISortModel, SortModel } from '../../components/data-table'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import DoneIcon from '@mui/icons-material/Done'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useTranslation } from 'react-i18next'
import { OrderDirection, useGetAllSortedUsersQuery } from '@epix-web-apps/core'
import AddIcon from '@mui/icons-material/Add'
import { Configuration, useFlyIn } from '@epix-web-apps/ui'
import AddUser from '../../components/user-components/add-user'
import { useNavigate } from 'react-router-dom'
import FilterButton from '../../components/filter-button/filter-button'

/* eslint-disable-next-line */
export interface UsersPageProps {}

export function UsersPage(props: UsersPageProps) {
  const navigate = useNavigate()
  const [tableData, setTableData] = useState<GridRowsProp>([])
  const { t } = useTranslation()
  const [rowCount, setRowCount] = useState(0)
  const [page, setPage] = useState(0)
  const [filters, setFilters] = useState({
    showInactive: false
  })
  const [pageSize, setPageSize] = useState(25)
  const { openFlyIn } = useFlyIn()

  const filterBar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: [2]
      }}
    >
      <Typography m={0} variant="h2">
        {t('userspage.title')}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <FilterButton filters={filters}>
          <p>
            <Switch
              checked={filters.showInactive}
              onChange={e => setFilters({ ...filters, showInactive: e.target.checked })}
              aria-label={t('configurationusermanagement.filters.showinactivepeople')}
            />
            {t('configurationusermanagement.filters.showinactivepeople')}
          </p>
        </FilterButton>
        <Button
          variant="contained"
          onClick={() =>
            openFlyIn({
              content: <AddUser />,
              callbackAfterClose: () => refetchAllUsers
            })
          }
        >
          <AddIcon />
          {t('userspage.button.adduser')}
        </Button>
      </Box>
    </Box>
  )

  const rowActions = (rowParams: GridRenderCellParams) => (
    <IconButton aria-label="row details" aria-controls="details-row">
      <KeyboardArrowRightIcon />
    </IconButton>
  )

  const activeActions = (rowParams: GridRenderCellParams) => (rowParams.value ? <DoneIcon /> : <VisibilityOffIcon />)

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: t('userspage.datatable.column.firstname'),
      flex: 1,
      sortable: true
    },
    {
      field: 'lastName',
      headerName: t('userspage.datatable.column.lastname'),
      flex: 1,
      sortable: true
    },
    {
      field: 'email',
      headerName: t('userspage.datatable.column.username'),
      flex: 1,
      sortable: false
    },
    {
      field: 'language',
      valueGetter: getLanguage,
      headerName: t('userspage.datatable.column.language'),
      flex: 1,
      sortable: false
    },
    {
      field: 'currency',
      headerName: t('userspage.datatable.column.currency'),
      flex: 1,
      sortable: false
    },
    {
      field: 'isActive',
      headerName: t('userspage.datatable.column.active'),
      flex: 1,
      sortable: true,
      editable: false,
      renderCell: activeActions
    },
    { field: '', sortable: false, editable: false, renderCell: rowActions }
  ]

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns[0]?.field, OrderDirection.Asc))

  useEffect(() => {
    setPage(0)
  }, [filters])

  const {
    data: users,
    isLoading: isLoadingUsers,
    refetch: refetchAllUsers
  } = useGetAllSortedUsersQuery(
    {
      showInactive: filters.showInactive,
      offset: page * pageSize,
      limit: pageSize,
      sortByProperty: sortModel.field,
      orderDirection: sortModel.orderDirection
    },
    {
      suspense: false
    }
  )

  useEffect(() => {
    if (users) {
      setTableData(users.allSortedUsersWithLocale.data)
      setRowCount(users.allSortedUsersWithLocale.data.length)
    }
  }, [users])

  function getLanguage(params: GridValueGetterParams) {
    return params.row.locale.language
  }

  const goToUserDetailPage = (userId: GridRowId | undefined) => {
    userId && navigate(Configuration.USERS_ID(`${userId}`))
  }

  return (
    <DataTable
      data={tableData}
      columns={columns}
      totalRowCount={rowCount}
      page={page}
      onPageChange={setPage}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      isLoading={isLoadingUsers}
      defaultSortModel={sortModel}
      onSortChange={setSortModel}
      filterBarElement={filterBar}
      onRowClick={params => goToUserDetailPage(params.id)}
    />
  )
}

export default UsersPage

import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import InheritedRoles from '../inherited-roles/inherited-roles'
import AssignedRoles from '../assigned-roles/assigned-roles'
import { OrderDirection, useGetInheritedTeamAuthorizationsByTeamIdQuery } from '@epix-web-apps/core'
import { DetailPageBaseQueryParams } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface TeamRolesProps {}

export function TeamRoles(props: TeamRolesProps) {
  const params = useParams<DetailPageBaseQueryParams>()

  const { data: getInheritedTeamAuthorization } = useGetInheritedTeamAuthorizationsByTeamIdQuery({
    teamId: params.id || '',
    offset: 0,
    limit: -1,
    sortByProperty: 'Function',
    orderDirection: OrderDirection.Asc
  })

  return (
    <Box>
      <AssignedRoles teamId={params.id} />
      {getInheritedTeamAuthorization?.allInheritedTeamAuthorizationsByTeamId &&
        getInheritedTeamAuthorization?.allInheritedTeamAuthorizationsByTeamId.totalCount > 0 && (
          <InheritedRoles teamId={params.id} />
        )}
    </Box>
  )
}

export default TeamRoles

import { Box, SxProps, useTheme } from '@mui/material'
import { BarDatum, ResponsiveBar } from '@nivo/bar'
import { LegendAnchor, LegendDirection } from '@nivo/legends'

/* eslint-disable-next-line */
interface IBarData {
  data?: Array<BarDatum>
}

export class BarData implements IBarData {
  data?: Array<BarDatum>

  constructor(data: Array<BarDatum>) {
    this.data = data
  }
}

export interface BarchartComponentProps {
  data?: Array<BarDatum>
  keys: Array<any>
  indexBy: string
  groupMode: 'grouped' | 'stacked'
  layoutMode: 'horizontal' | 'vertical'
  colors: Array<string>
  innerPadding?: number
  marginRight?: number
  marginLeft?: number
  marginTop?: number
  marginBottom?: number
  showLegend?: boolean
  legendAnchor?: LegendAnchor
  legendDirection?: LegendDirection
  legendPosition?: [number, number]
  legendCharLength?: number
  minValueScaleOn?: number[]
  sx?: SxProps
  cutString?: boolean
  legendWidth?: number
  axisLeft?: string
  axisLeftPadding?: number
  axisBottom?: string
  axisBottomPadding?: number
}

export function BarchartComponent({
  data,
  keys,
  indexBy,
  groupMode,
  layoutMode,
  colors,
  innerPadding = 0,
  marginRight = 0,
  marginLeft = 50,
  marginTop = 60,
  marginBottom = 50,
  showLegend = true,
  legendAnchor = 'top',
  legendDirection = 'row',
  legendPosition = [20, -40],
  legendCharLength = 20,
  minValueScaleOn,
  legendWidth = 140,
  axisLeft,
  axisLeftPadding = -45,
  axisBottom,
  axisBottomPadding = 35
}: BarchartComponentProps) {
  const theme = useTheme()
  let horizontalHeight = layoutMode === 'horizontal' ? ((data ?? []).length / 25) * 100 : 100
  horizontalHeight = horizontalHeight < 100 ? 100 : horizontalHeight

  //Calculate new minHeight (currently for company car graphs only)
  let minValueScale = 0
  if (minValueScaleOn && data) {
    const min = Math.min(...minValueScaleOn)
    const max = Math.max(...minValueScaleOn)
    if (max - min !== 0) {
      const differenceOverTotal = (max - min) / max / 2
      minValueScale = min - max * differenceOverTotal
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        flex: 1,
        overflowY: `${layoutMode === 'horizontal' && horizontalHeight !== 100 ? 'scroll' : 'visible'}`
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: `${horizontalHeight}%`
        }}
      >
        <ResponsiveBar
          valueScale={{
            type: 'linear',
            min: minValueScale > 0 ? minValueScale : 'auto',
            max: 'auto',
            clamp: true
          }}
          groupMode={groupMode}
          layout={layoutMode}
          data={data ? data : []}
          keys={keys}
          indexBy={indexBy}
          margin={{
            top: marginTop,
            right: marginRight,
            left: marginLeft,
            bottom: marginBottom
          }}
          labelSkipHeight={16}
          labelSkipWidth={20}
          enableLabel={true}
          labelTextColor={theme.palette.common.white}
          padding={0.1}
          colors={colors}
          borderColor="inherit:darker(1.6)"
          enableGridX={true}
          enableGridY={false}
          innerPadding={innerPadding}
          axisLeft={{
            tickRotation: 0,
            legend: axisLeft,
            legendOffset: axisLeftPadding,
            legendPosition: 'end'
          }}
          axisBottom={{
            tickRotation: 0,
            legend: axisBottom,
            legendOffset: axisBottomPadding,
            legendPosition: 'end'
          }}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: '#a9acbe',
                  strokeWidth: 1
                }
              }
            },
            grid: {
              line: {
                stroke: '#e7e8ec',
                strokeWidth: 2
              }
            },
            tooltip: {
              container: {
                fontSize: '0.8em',
                backgroundColor: theme.palette.common.white,
                color: theme.palette.common.black
              }
            }
          }}
          legends={
            showLegend
              ? [
                  {
                    data: keys?.map((key, index) => {
                      return {
                        id: index,
                        label: key.length <= legendCharLength ? key : `${key.substring(0, legendCharLength)}...`,
                        color: colors[index]
                      }
                    }),
                    anchor: legendAnchor,
                    dataFrom: 'keys',
                    direction: legendDirection,
                    itemHeight: 20,
                    itemWidth: legendWidth,
                    toggleSerie: true,
                    translateX: legendPosition[0],
                    translateY: legendPosition[1],
                    itemTextColor: theme.palette.common.black,
                    symbolShape: 'circle'
                  }
                ]
              : []
          }
          layers={['grid', 'axes', 'bars', 'markers', 'legends']}
        />
      </Box>
    </Box>
  )
}

export default BarchartComponent

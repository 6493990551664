const { fetch: originalFetch } = window

export const setFetchHeader = (headerName: string, headerValue: string) => {
  window.fetch = async (...args) => {
    const [resource, options] = args
    if (options) {
      options.headers = new Headers(options.headers)
      options?.headers.set(headerName, headerValue)
    }
    return await originalFetch(resource, options)
  }
}

import { Typography } from '@mui/material'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { object, string } from 'zod'
import { FormGridLayout } from '../../../layouts'
import { FormInput } from '../form-input'
import { useGetAllLocalesQuery } from '@epix-web-apps/core'
import { useEffect } from 'react'

/* eslint-disable-next-line */
export interface FormTranslationProps {
  name: string
  label: string
}

export const EditTranslationZodArray = () => {
  return object({
    locale: string(),
    value: string()
  }).array()
}

export function FormTranslations({ name, label }: FormTranslationProps) {
  const { control, setValue, getValues } = useFormContext()

  const translationValues = getValues(name)

  const { data: getAllLocales } = useGetAllLocalesQuery({
    ignoreTranslations: true,
    onlySupported: true
  })

  const translationsFieldArray = useFieldArray({ control, name: name })

  useEffect(() => {
    if (!translationValues && getAllLocales?.locales) {
      const initialTranslations = getAllLocales.locales.map((locale: { locale: string; language: string }) => ({
        locale: locale.locale,
        language: locale.language,
        value: ''
      }))
      setValue(name, initialTranslations)
    }
  }, [translationValues, getAllLocales, name, setValue])

  return (
    <>
      <Typography variant="h5">{label}</Typography>

      {translationsFieldArray.fields.map((item: any, index: number) => (
        <FormGridLayout key={index}>
          <FormInput sx={12} name={`${name}.${index}.value`} label={item.language} />
        </FormGridLayout>
      ))}
    </>
  )
}

export default FormTranslations

import { People } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import { FolderColumns, FolderDocumentList, FolderList } from '../../document-components'
import {
  useGetAllContractDocumentTypesByEmployerIdQuery,
  useGetContractByIdQuery,
  useGetDocumentTypeByIdQuery,
  useNavigateWithParams
} from '@epix-web-apps/core'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { ContractTabs } from '../contract-tab-general/contract-tab-general'

/* eslint-disable-next-line */
export interface ContractsDocumentTabProps {
  contractId: string | undefined
}

export type ContractDocumentTypeDetailPageParams = ContractDetailPageParams & {
  documenttypeid: string
}

export function ContractsDocumentTab({ contractId }: ContractsDocumentTabProps) {
  const { t } = useTranslation()
  const navigate = useNavigateWithParams()
  const params = useParams<ContractDocumentTypeDetailPageParams>()

  const handleTitleClick = (personId: string, contractId: string) => {
    navigate(People.PEOPLE_ID_CONTRACTS_ID(personId, contractId), {
      tabIndex: ContractTabs.DOCUMENTS.toString()
    })
  }

  const { data: getContractById } = useGetContractByIdQuery({
    contractId: contractId || ''
  })
  const { data: getAllContractDocumentTypes } = useGetAllContractDocumentTypesByEmployerIdQuery(
    {
      id: getContractById?.contractById.employerId || '',
      activeOnly: true
    },
    {
      enabled: !!getContractById?.contractById.employerId
    }
  )

  const { data: getDocumentTypeById } = useGetDocumentTypeByIdQuery(
    {
      id: params?.documenttypeid || ''
    },
    {
      enabled: !!params?.documenttypeid
    }
  )

  const contractDocumentTypeDocumentOverview = useMemo(() => !!params.documenttypeid, [params.documenttypeid])

  return (
    <>
      {!contractDocumentTypeDocumentOverview && (
        <FolderList
          hideColumns={[FolderColumns.ISACTIVE]}
          employerId={getContractById?.contractById.employerId || ''}
          folders={getAllContractDocumentTypes?.allContractDocumentTypesByEmployerId || []}
          title={t('contractdetailpage.documents.title')}
          goToDocumentTypeDetailPage={documentTypeId =>
            People.PEOPLE_ID_CONTRACTS_ID_DOCUMENTTYPE_ID(
              getContractById?.contractById.personId || '',
              contractId || '',
              documentTypeId
            )
          }
        />
      )}
      {contractDocumentTypeDocumentOverview && (
        <FolderDocumentList
          documentTypeId={params?.documenttypeid || ''}
          onTitleClick={() => handleTitleClick(params?.id || '', params?.contractid || '')}
          subTitle={`> ${getDocumentTypeById?.documentTypeById.name}`}
          title={`${t('contractdetailpage.documents.title')}`}
          contractId={contractId}
        />
      )}
    </>
  )
}

export default ContractsDocumentTab

import { Grid, GridSize } from '@mui/material'

export const OptionalGridWrapper = ({ sx, children }: { sx: GridSize | undefined; children: React.ReactElement }) => {
  if (sx) {
    return (
      <Grid xs={sx} item>
        {children}
      </Grid>
    )
  }
  return children
}

export default OptionalGridWrapper

import { ResponsivePie } from '@nivo/pie'
import { Box, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { colors } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface IPieData {
  id: string
  value: string
  label: string
  color?: string
}

export class PieData implements IPieData {
  id: string
  value: string
  label: string
  color?: string
  margin?: any
  enableArcLinkLabels?: boolean

  constructor(id: string, value: string, label: string, color?: string) {
    this.id = id
    this.value = value
    this.label = label
    this.color = color
  }
}

export interface PiechartComponentProps {
  total?: number
  name?: string
  data?: Array<IPieData>
  margin?: any
  enableArcLinkLabels?: boolean
}

export function PiechartComponent({
  total,
  name,
  data: d,
  margin = {
    top: 30,
    right: 290,
    bottom: 50,
    left: 50
  },
  enableArcLinkLabels = true
}: PiechartComponentProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  if (total === 0) return <Typography sx={{ p: 2, textAlign: 'center' }}>{t('analytics.nodata')}</Typography>

  const data = d?.map(x => {
    const label = x.label.split(':')

    if (label[0].length > 20) {
      x.label = label[0].slice(0, 20) + '...: ' + label[1]
    }
    return x
  })

  const getColor = (): any => {
    if (data && data[0]?.color) {
      return data.map(item => item.color)
    }
    return data?.map((item, index) => colors[index % colors.length])
  }

  return (
    <Box height={'250px'}>
      <ResponsivePie
        data={data ? data : []}
        margin={margin}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={getColor()}
        arcLinkLabel={d => Math.round((Number(d.value) / (total === undefined ? 0 : total)) * 100) + '%'}
        enableArcLabels={true}
        arcLinkLabelsDiagonalLength={0}
        arcLinkLabelsStraightLength={0}
        arcLinkLabelsOffset={10}
        enableArcLinkLabels={enableArcLinkLabels}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLinkLabelsThickness={2}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor="white"
        animate={true}
        theme={{
          tooltip: {
            container: {
              fontSize: '0.8em',
              backgroundColor: theme.palette.common.white,
              color: theme.palette.common.black
            }
          }
        }}
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 30,
            itemHeight: 20,
            itemTextColor: theme.palette.common.black,
            symbolShape: 'circle'
          }
        ]}
      />
      {name && (
        <Typography sx={{ fontSize: 13, textAlign: 'center', fontWeight: 'bold', mt: -4 }}>
          {name} {total}
        </Typography>
      )}
    </Box>
  )
}

export default PiechartComponent

import { useTranslation } from 'react-i18next'
import { FormActionButtons, FormErrorList, FormGridLayout, FormInput, useFlyIn } from '@epix-web-apps/ui'
import { TypeOf, object, string } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { GraphqlError, useGetDocumentByIdQuery, useUpdateDocumentMutation } from '@epix-web-apps/core'
import { FormContainer } from '@epix-web-apps/ui'
import { Typography } from '@mui/material'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface EditDocumentProps {
  documentId: string
}

export function EditDocument({
  documentId
}: EditDocumentProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const { data: getDocumentById, refetch: refetchDocumentById } =
    useGetDocumentByIdQuery({
      id: documentId
    })

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const updateDocumentSchema = object({
    name: string({
      required_error: t('form.validation.namerequired'),
      invalid_type_error: t('form.validation.namerequired')
    }).min(1, t('form.validation.namerequired'))
  })

  type UpdateDocumentForm = TypeOf<typeof updateDocumentSchema>

  const form = useForm<UpdateDocumentForm>({
    resolver: zodResolver(updateDocumentSchema),
    defaultValues: {
      name: getDocumentById?.documentById.nameWithoutExtension
    }
  })

  const updateDocumentMutation = useUpdateDocumentMutation();

  const handleOnSubmit = async (documentToUpdate: UpdateDocumentForm) => {
    await updateDocumentMutation.mutateAsync({
      updateDocumentCommand: {
        id: documentId,
        name: documentToUpdate.name
      }
    }).catch(e => setBackendErrors([e]))
    .then(() => refetchDocumentById())
    .finally(closeFlyIn)
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.updatedocument.title')}</Typography>

      <FormGridLayout>

        <FormInput
          sx={12}
          name="name"
          label={t('form.field.name')}
        />

      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={updateDocumentMutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default EditDocument

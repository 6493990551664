import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Grid, Divider, RadioGroup, Button, Radio, Link, Box, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { date, object, string, TypeOf } from 'zod'
import {
  isLastEntryById,
  OrderDirection,
  useCreateContractWorkScheduleHistoryMutation,
  useDeleteContractWorkScheduleHistoryMutation,
  useGetCollectiveSchedulesByEmployerIdQuery,
  useGetContractByIdQuery,
  useGetWorkSchedulesByEmployerIdQuery,
  useUpdateContractWorkScheduleHistoryMutation,
  FormSelectOption
} from '@epix-web-apps/core'
import { useState } from 'react'
import {
  FormSelect,
  FormContainer,
  FormGridLayout,
  useFlyIn,
  FormActionButtons,
  FormDatepicker,
  FormErrorList
} from '@epix-web-apps/ui'
import PersonIdentificationComponent from '../../person-identification-component'

/* eslint-disable-next-line */
export interface EditEmployerWorkScheduleProps {
  contractId: string | undefined
}

export function EditEmployerWorkSchedule({ contractId }: EditEmployerWorkScheduleProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const theme = useTheme()
  const [addWorkSchedule, setAddWorkSchedule] = useState(false)
  const createMutation = useCreateContractWorkScheduleHistoryMutation()
  const updateMutation = useUpdateContractWorkScheduleHistoryMutation()
  const deleteMutation = useDeleteContractWorkScheduleHistoryMutation()

  const {
    data: getContractById,
    isLoading: isLoadingContract,
    refetch: refetchContractById
  } = useGetContractByIdQuery({
    contractId: contractId || ''
  })

  const contract = getContractById?.contractById
  const employerId = contract?.employerId

  const { data: workschedules, isLoading: isLoadingWorkSchedules } = useGetWorkSchedulesByEmployerIdQuery(
    {
      employerId: employerId || '',
      offset: 0,
      limit: -1,
      sortByProperty: null,
      showInactive: false
    },
    {
      suspense: false
    }
  )

  const { data: collectiveSchedule } = useGetCollectiveSchedulesByEmployerIdQuery(
    {
      employerId: employerId || '',
      offset: 0,
      limit: -1,
      orderDirection: OrderDirection.Asc
    },
    {
      enabled: !!employerId
    }
  )

  const editEmployerWorkScheduleSchema = object({
    startDate: date({
      required_error: t('form.validation.startdaterequired'),
      invalid_type_error: t('form.validation.startdaterequired')
    }),
    endDate: date().optional().nullable(),
    workscheduleId: string({
      required_error: t('form.validation.workschedulerequired'),
      invalid_type_error: t('form.validation.workschedulerequired')
    }).min(1, t('form.validation.workschedulerequired')),
    collectiveScheduleId: string().nullable().optional()
  })

  const [selectedWorkSchedule, setSelectedWorkSchedule] = useState(contract?.contractWorkScheduleHistory?.current)

  type EditEmployerWorkScheduleForm = TypeOf<typeof editEmployerWorkScheduleSchema>

  const form = useForm<EditEmployerWorkScheduleForm>({
    resolver: zodResolver(editEmployerWorkScheduleSchema),
    defaultValues: {
      startDate: selectedWorkSchedule?.validFrom ? new Date(selectedWorkSchedule?.validFrom) : undefined,
      endDate: selectedWorkSchedule?.validTo ? new Date(selectedWorkSchedule?.validTo) : null,
      workscheduleId: selectedWorkSchedule?.workScheduleModel.id,
      collectiveScheduleId: selectedWorkSchedule?.collectiveScheduleId ?? null
    }
  })

  const [showDefaultCollectiveSchedule, setShowDefaultCollectiveSchedule] = useState(
    selectedWorkSchedule?.collectiveScheduleId == null
  )

  const [showSelectCollectiveSchedule, setShowSelectCollectiveSchedule] = useState(
    selectedWorkSchedule?.collectiveScheduleId != null
  )

  const handleOnSubmit = async (employerWorkSchedule: EditEmployerWorkScheduleForm) => {
    if (!selectedWorkSchedule) {
      await createMutation
        .mutateAsync({
          createContractWorkScheduleHistoryCommand: {
            contractId: contractId || '',
            workScheduleId: employerWorkSchedule.workscheduleId,
            validFrom: employerWorkSchedule.startDate,
            collectiveScheduleId: employerWorkSchedule.collectiveScheduleId || null
          }
        })
        .then(closeFlyIn)
    } else {
      await updateMutation
        .mutateAsync({
          updateContractWorkScheduleHistoryCommand: {
            contractId: contractId || '',
            contractWorkScheduleHistoryId: selectedWorkSchedule.id,
            workScheduleId: employerWorkSchedule.workscheduleId,
            collectiveScheduleId: employerWorkSchedule.collectiveScheduleId || null
          }
        })
        .then(() => {
          refetchContractById()
          closeFlyIn()
        })
    }
  }

  async function handleDelete() {
    await deleteMutation
      .mutateAsync({
        deleteContractWorkScheduleHistoryCommand: {
          contractId: contractId || '',
          contractWorkScheduleHistoryId: selectedWorkSchedule?.id || ''
        }
      })
      .then(() => {
        refetchContractById()
        closeFlyIn()
      })
  }

  const workScheduleOptions = workschedules?.workSchedulesByEmployerId.data.map(
    x => new FormSelectOption(x.id, `${x.description} (${x.labelOnCalendar})`)
  )

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <PersonIdentificationComponent title={t('flyin.workschedule.title')} />
      <Divider sx={{ marginBottom: 3 }} />
      <Grid className="bold" container>
        <Grid xs={4} item>
          <p>{t('form.field.startdate')}</p>
        </Grid>
        <Grid xs={4} item>
          <p>{t('form.field.enddate')}</p>
        </Grid>
        <Grid xs={4} item>
          <p>{t('form.field.workschedule')}</p>
        </Grid>
      </Grid>
      <Divider />

      <FormGridLayout hasBorderBottom>
        <Grid xs={12} sx={{ fontSize: '0.95em' }} item>
          {contract?.contractWorkScheduleHistory.history && (
            <RadioGroup
              aria-labelledby="workschedule-history"
              value={selectedWorkSchedule?.id}
              onChange={(e, workScheduleId) => {
                const selectWorkSchedule = contract.contractWorkScheduleHistory.history.find(
                  x => x.id === workScheduleId
                )
                setSelectedWorkSchedule(selectWorkSchedule)
                setAddWorkSchedule(false)
                setShowDefaultCollectiveSchedule(selectWorkSchedule?.collectiveScheduleId == null)
                setShowSelectCollectiveSchedule(selectWorkSchedule?.collectiveScheduleId != null)
                form.reset({
                  startDate: new Date(selectWorkSchedule?.validFrom),
                  endDate: selectWorkSchedule?.validTo ? new Date(selectWorkSchedule?.validTo) : null,
                  workscheduleId: selectWorkSchedule?.workScheduleModel.id,
                  collectiveScheduleId: selectWorkSchedule?.collectiveScheduleId ?? null
                })
              }}
            >
              {contract?.contractWorkScheduleHistory.history.map((workscheduleHistory, index) => (
                <label
                  key={index}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    height: '2rem'
                  }}
                >
                  <Grid xs={4} item>
                    <p>
                      <Radio
                        sx={{
                          padding: '0 0.25rem 0 0',
                          marginTop: '-0.2rem'
                        }}
                        size="small"
                        value={workscheduleHistory.id}
                      />
                      <span>{new Date(workscheduleHistory.validFrom).toLocaleDateString()}</span>
                    </p>
                  </Grid>
                  <Grid xs={4} item>
                    <p>{workscheduleHistory.validTo && new Date(workscheduleHistory.validTo).toLocaleDateString()}</p>
                  </Grid>
                  <Grid xs={4} item>
                    <p>{workscheduleHistory.workScheduleModel.labelOnCalendar}</p>
                  </Grid>
                </label>
              ))}
            </RadioGroup>
          )}
        </Grid>
        <Grid xs={12} container item gap={'0.5rem'}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              form.reset({
                startDate: undefined,
                endDate: null,
                workscheduleId: undefined,
                collectiveScheduleId: null
              })
              setSelectedWorkSchedule(null)
              setAddWorkSchedule(true)
              setShowDefaultCollectiveSchedule(true)
              setShowSelectCollectiveSchedule(false)
            }}
          >
            {t('flyin.editworkschedule.addworkschedule')}
          </Button>
          {isLastEntryById(contract?.contractWorkScheduleHistory?.history, selectedWorkSchedule?.id) && (
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={handleDelete}
              disabled={deleteMutation.isLoading}
            >
              {t('flyin.editworkschedule.removeworkschedule')}
            </Button>
          )}
        </Grid>
      </FormGridLayout>

      {(selectedWorkSchedule || addWorkSchedule) && (
        <FormGridLayout hasPaddingTop hasBorderBottom>
          <FormDatepicker
            sx={6}
            name="startDate"
            label={`${t('form.field.startdate')} *`}
            disabled={!addWorkSchedule}
          />
          <FormDatepicker sx={6} name="endDate" label={t('form.field.enddate')} disabled={true} />
          <FormSelect
            sx={12}
            name="workscheduleId"
            label={`${t('form.field.workschedule')} *`}
            options={workScheduleOptions}
          />
          {showDefaultCollectiveSchedule && (
            <Box ml={2} mt={1}>
              <Link
                onClick={() => {
                  setShowDefaultCollectiveSchedule(false)
                  setShowSelectCollectiveSchedule(true)
                }}
              >
                <p>{t('flyin.editworkschedule.addnewcollectiveschedule')}</p>
              </Link>
            </Box>
          )}
          {!showDefaultCollectiveSchedule && (
            <Box ml={2} mt={1}>
              <Link
                onClick={() => {
                  setShowDefaultCollectiveSchedule(true)
                  setShowSelectCollectiveSchedule(false)
                  form.resetField('collectiveScheduleId', {
                    defaultValue: null
                  })
                }}
              >
                <Typography>{t('flyin.editworkschedule.backtodefaultcollectiveschedule')}</Typography>
              </Link>
            </Box>
          )}
          {showSelectCollectiveSchedule && (
            <FormSelect
              sx={12}
              name="collectiveScheduleId"
              label={`${t('form.field.collectiveschedule')}`}
              options={collectiveSchedule?.collectiveSchedules.data.map(x => new FormSelectOption(x.id, x.description))}
            />
          )}
          <Typography ml={2} mt={1} color={theme.palette.text.secondary} sx={{ fontStyle: 'italic' }}>
            {t('flyin.editworkschedule.note')}
          </Typography>
        </FormGridLayout>
      )}

      <FormErrorList />
      <FormActionButtons
        isMutating={isLoadingContract || isLoadingWorkSchedules || createMutation.isLoading || updateMutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default EditEmployerWorkSchedule

import EmployersWidgetTitle from '../employers-widget-title'
import { Box, IconButton, Link, useTheme } from '@mui/material'
import { ReadMoreText, TypographyRed, TypographySmall, WidgetRowItem } from '../employer-overview/employer-overview'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import CalendarViewMonthOutlinedIcon from '@mui/icons-material/CalendarViewMonthOutlined'
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined'
import { Configuration, DetailPageBaseQueryParams, useFlyIn } from '@epix-web-apps/ui'
import AddEditWorkSchedule from '../../schedule-components/add-edit-work-schedule'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import EditDayEntries from '../../schedule-components/edit-day-entries'
import {
  OrderDirection,
  useGetAllDayDefinitionsByEmployerIdQuery,
  useGetAllPayGroupsByEmployerIdQuery,
  useGetWorkSchedulesByEmployerIdQuery
} from '@epix-web-apps/core'
import { environment } from '../../../../../environments/environment'
import { useEffect } from 'react'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'

/* eslint-disable-next-line */
export interface EmployersWidgetWorkschedulesProps {
  employerId: string
  setTabComplete: (newValue: boolean) => void
}

export function EmployersWidgetWorkschedules({ employerId, setTabComplete }: EmployersWidgetWorkschedulesProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useParams<DetailPageBaseQueryParams>()
  const navigate = useNavigate()
  const { openFlyIn } = useFlyIn()

  const { data: workSchedules, refetch: refetchWorkSchedules } = useGetWorkSchedulesByEmployerIdQuery({
    employerId: employerId || '',
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc,
    showInactive: false
  })

  const { data: payGroups } = useGetAllPayGroupsByEmployerIdQuery({
    employerId: employerId || '',
    orderDirection: OrderDirection.Asc
  })

  const { data: dayDefinitions } = useGetAllDayDefinitionsByEmployerIdQuery({
    employerId: employerId || '',
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc
  })

  useEffect(() => {
    if (workSchedules?.workSchedulesByEmployerId) {
      setTabComplete(workSchedules?.workSchedulesByEmployerId.data.length > 0)
    }
  }, [workSchedules?.workSchedulesByEmployerId])

  const disabled = dayDefinitions?.dayDefinitions.data.length === 0

  return (
    payGroups?.allPayGroupsByEmployerId &&
    workSchedules?.workSchedulesByEmployerId &&
    dayDefinitions?.dayDefinitions && (
      <>
        <EmployersWidgetTitle
          title={t('employerspage.widget.workschedules.title')}
          url={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_WORKSCHEDULE(`${params.id}`, `${employerId}`)}
          icon={<CalendarViewMonthOutlinedIcon />}
          hasData={workSchedules.workSchedulesByEmployerId.data.length > 0}
          disabled={disabled}
        />

        {disabled && (
          <TypographySmall
            sx={{
              pb: '0.25rem',
              color: disabled ? theme.palette.text.secondary : ''
            }}
          >
            {t('employerspage.widget.workschedules.noworkschedules')}
          </TypographySmall>
        )}

        {workSchedules.workSchedulesByEmployerId.data.slice(0, 3).map(workschedule => (
          <WidgetRowItem key={workschedule.id}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TypographySmall>
                {workschedule.description} ({workschedule.code}, {workschedule.ftePercentage + '%'},{' '}
                {workschedule.effectiveNumberOfHoursPerWeek}, {workschedule.averageNumberOfHoursPerWeek})
              </TypographySmall>
              {!workschedule.hasAssignedDays && (
                <TypographyRed
                  sx={{
                    display: 'flex',
                    marginLeft: '0.5rem',
                    gap: '0.25rem'
                  }}
                >
                  <ReportProblemOutlinedIcon fontSize="small" />
                  {t('employerspage.widget.workschedules.noentries')}
                </TypographyRed>
              )}
            </Box>
            <Box display={'flex'}>
              <IconButton
                size="small"
                sx={{ width: '1.5rem' }}
                onClick={() =>
                  openFlyIn({
                    content: <EditDayEntries employerId={employerId} workScheduleId={workschedule.id} />,
                    callbackAfterClose: () => refetchWorkSchedules
                  })
                }
              >
                <CalendarViewWeekOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                sx={{ width: '1.5rem' }}
                onClick={() =>
                  openFlyIn({
                    content: <AddEditWorkSchedule employerId={employerId} workScheduleId={workschedule.id} />,
                    callbackAfterClose: () => refetchWorkSchedules
                  })
                }
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
            </Box>
          </WidgetRowItem>
        ))}

        <Box>
          {workSchedules.workSchedulesByEmployerId.data.length > 3 && (
            <TypographySmall>
              <Link
                onClick={() =>
                  navigate(Configuration.ICP_PROVIDERS_ID_EMPLOYERID_WORKSCHEDULE(`${params.id}`, employerId))
                }
              >
                {t('employerspage.widget.workschedules.and')} {workSchedules.workSchedulesByEmployerId.data.length - 3}{' '}
                {t('employerspage.widget.workschedules.more')}
              </Link>
            </TypographySmall>
          )}
          {workSchedules.workSchedulesByEmployerId.data?.length < 4 && !disabled && (
            <TypographySmall>
              <Link
                onClick={() =>
                  openFlyIn({
                    content: <AddEditWorkSchedule employerId={employerId} />,
                    callbackAfterClose: () => refetchWorkSchedules
                  })
                }
              >
                {t('employerspage.widget.workschedules.addworkschedule')}
              </Link>
            </TypographySmall>
          )}
        </Box>

        <ReadMoreText
          text={
            workSchedules.workSchedulesByEmployerId.data.length === 0
              ? t('employerspage.widget.workschedules.info')
              : ''
          }
          alignRight={workSchedules.workSchedulesByEmployerId.data.length > 0}
          readMoreLink={
            environment.docs.domainName + '/Epix/epix-configuration/provider/employer/calendar/workschedule'
          }
        />
      </>
    )
  )
}

export default EmployersWidgetWorkschedules

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, IconButton, Typography, Divider, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'

/* eslint-disable-next-line */
export interface HeaderTitleNavigationProps {
  backToLink?: string
  onBackClick?: () => void
  title?: string
  showDivider?: boolean
  mobileSizeTitle?: boolean
}

export function HeaderTitleNavigation({
  title,
  backToLink,
  onBackClick,
  showDivider = true,
  mobileSizeTitle = false
}: HeaderTitleNavigationProps) {
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '-0.5rem' }}>
        <IconButton
          size={mobileSizeTitle ? 'small' : 'medium'}
          sx={{ mr: 1, color: theme.palette.primary.main }}
          onClick={() => {
            if (onBackClick !== undefined) {
              onBackClick()
            } else {
              backToLink ? navigate(backToLink) : navigate(-1)
            }
          }}
        >
          <ArrowBackIcon fontSize={mobileSizeTitle ? 'small' : 'medium'} />
        </IconButton>
        <Typography m={mobileSizeTitle ? 0.5 : 1} variant={mobileSizeTitle ? 'h4' : 'h2'}>
          {title}
        </Typography>
      </Box>
      {showDivider && <Divider sx={{ mt: 0.5 }} />}
    </>
  )
}

export default HeaderTitleNavigation

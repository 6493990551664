import {
  FormSelectOption,
  GraphqlError,
  OrderDirection,
  useDeleteCountryPolicyMutation,
  useGetAllIcpsQuery
} from '@epix-web-apps/core'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  FormContainer,
  FormSelect,
  FormActionButtons,
  FormErrorList,
  useFlyIn,
  DetailPageBaseQueryParams
} from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface DeleteCountryPolicyProps {
  countryPolicyId: string
  icpId: string
}

export function DeleteCountryPolicy({ countryPolicyId, icpId }: DeleteCountryPolicyProps) {
  const { t } = useTranslation()
  const params = useParams<DetailPageBaseQueryParams>()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const deleteMutation = useDeleteCountryPolicyMutation()
  const form = useForm({
    defaultValues: {
      userId: params.id,
      icpId: icpId
    }
  })

  const { data: icpsData } = useGetAllIcpsQuery(
    {
      activeOnly: false,
      offset: 0,
      limit: -1,
      sortByProperty: 'Payrollprovider',
      orderDirection: OrderDirection.Asc
    },
    {
      suspense: false
    }
  )

  const handleOnSubmit = async () => {
    await deleteMutation
      .mutateAsync({
        deleteCountryPolicyCommand: {
          id: countryPolicyId
        }
      })
      .then(closeFlyIn)
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.deletecountrypolicy.title')}</Typography>
      <FormSelect
        sx={12}
        name="icpId"
        label={`${t('form.field.icp')}`}
        options={icpsData?.icps.data.map(x => new FormSelectOption(x.id, x.payrollProvider + ' (' + x.country + ')'))}
        disabled={true}
      />
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={deleteMutation.isLoading}
        buttonText={t('common.remove')}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default DeleteCountryPolicy

import { OrderDirection, UpcomingLeaversModel, useGetUpcomingLeaversQuery } from '@epix-web-apps/core'
import { Box, useTheme, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowId, GridRowsProp } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, ISortModel, SortModel } from '../../data-table'
import { IcpCodeBadge } from '../../icp-components'
import { People, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { useNavigate } from 'react-router-dom'

/* eslint-disable-next-line */
export interface OverviewEventLeaversProps {}

export function OverviewEventLeavers(props: OverviewEventLeaversProps) {
  const { t } = useTranslation()
  const [tableData, setTableData] = useState<GridRowsProp>([])
  const theme = useTheme()
  const { icpFilters } = useGlobalPersistedStore()
  const { closeFlyIn } = useFlyIn()
  const navigate = useNavigate()

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('analytics.homepage.events.name'),
      flex: 3,
      sortable: false
    },
    {
      field: 'countryCode',
      headerName: t('analytics.homepage.events.country'),
      flex: 1.2,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => <IcpCodeBadge icpCode={params.value} />
    },
    {
      field: 'date',
      headerName: t('analytics.homepage.events.date'),
      flex: 2,
      sortable: false
    }
  ]

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns[0]?.field, OrderDirection.Asc))

  const { data: dataAllUpcomingLeavers, isLoading: loadingUpcomingLeavers } = useGetUpcomingLeaversQuery({
    icps: icpFilters
  })

  const goToPersonDetailPage = (personId: GridRowId | undefined) => {
    personId && navigate(People.PEOPLE_ID_DETAILS(`${personId}`))
  }

  useEffect(() => {
    if (dataAllUpcomingLeavers?.upcomingLeavers) {
      const data = dataAllUpcomingLeavers.upcomingLeavers
      setTableData(
        data.map((row: UpcomingLeaversModel) => {
          return {
            id: row.personId,
            name: `${row.firstName} ${row.lastName}`,
            countryCode: row.icpCode,
            date: new Date(row.upcomingDate).toLocaleDateString()
          }
        })
      )
    }
  }, [dataAllUpcomingLeavers?.upcomingLeavers])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '30rem'
      }}
    >
      <Typography variant="h4">{t('analytics.homepage.events.title.leavers')}</Typography>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <DataTable
          totalRowCount={dataAllUpcomingLeavers?.upcomingLeavers.length}
          data={tableData}
          columns={columns}
          onSortChange={setSortModel}
          isLoading={loadingUpcomingLeavers}
          defaultSortModel={sortModel}
          hideFooter={true}
          onRowClick={params => {
            goToPersonDetailPage(params.id)
            closeFlyIn()
          }}
          emptyStateElement={
            <Typography
              sx={{
                mt: 2,
                color: theme.palette.text.secondary,
                fontStyle: 'italic'
              }}
            >
              {t('analytics.homepage.events.noresults')}
            </Typography>
          }
        />
      </Box>
    </Box>
  )
}

export default OverviewEventLeavers

import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { boolean, object, string, TypeOf } from 'zod'
import { FormGridLayout } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { FormSelect } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'
import { useForm } from 'react-hook-form'
import {
  formTypeSelectOptions,
  useCreateBranchUnitMutation,
  useGetBranchUnitByIdQuery,
  useUpdateBranchUnitMutation
} from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface AddEditBranchUnitProps {
  branchUnitId?: string
  employerId: string | undefined
  create: boolean
}

export function AddEditBranchUnit({ create, employerId, branchUnitId }: AddEditBranchUnitProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const { countryOptions } = formTypeSelectOptions

  const {
    data: getBranchUnit,
    isLoading: isLoadingBranchUnit,
    refetch: refetchBranchUnit
  } = useGetBranchUnitByIdQuery(
    {
      id: branchUnitId ?? ''
    },
    {
      enabled: !!branchUnitId
    }
  )

  const createMutation = useCreateBranchUnitMutation()
  const updateMutation = useUpdateBranchUnitMutation()

  const addEditBranchUnitSchema = object({
    code: string({
      required_error: t('form.validation.branchunitcoderequired'),
      invalid_type_error: t('form.validation.branchunitcoderequired')
    }).min(1, t('form.validation.branchunitcoderequired')),
    description: string({
      required_error: t('form.validation.descriptionrequired'),
      invalid_type_error: t('form.validation.descriptionrequired')
    }).min(1, t('form.validation.descriptionrequired')),
    street: string({
      required_error: t('form.validation.streetrequired'),
      invalid_type_error: t('form.validation.streetrequired')
    }).min(1, t('form.validation.streetrequired')),
    number: string({
      required_error: t('form.validation.numberrequired'),
      invalid_type_error: t('form.validation.numberrequired')
    }).min(1, t('form.validation.numberrequired')),
    suffix: string().optional().nullable(),
    zipCode: string({
      required_error: t('form.validation.zipcoderequired'),
      invalid_type_error: t('form.validation.zipcoderequired')
    }).min(1, t('form.validation.zipcoderequired')),
    city: string({
      required_error: t('form.validation.cityrequired'),
      invalid_type_error: t('form.validation.cityrequired')
    }).min(1, t('form.validation.cityrequired')),
    country: string({
      required_error: t('form.validation.countryrequired'),
      invalid_type_error: t('form.validation.countryrequired')
    }).min(1, t('form.validation.countryrequired')),
    branchUnitNumber: string().nullable().optional(),
    active: boolean()
  })
  type CreateEditBranchUnitForm = TypeOf<typeof addEditBranchUnitSchema>

  const form = useForm<CreateEditBranchUnitForm>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      code: getBranchUnit?.branchUnit.code,
      description: getBranchUnit?.branchUnit.description,
      street: getBranchUnit?.branchUnit.street,
      number: getBranchUnit?.branchUnit.number,
      suffix: getBranchUnit?.branchUnit.suffix ?? '',
      zipCode: getBranchUnit?.branchUnit.zipCode,
      city: getBranchUnit?.branchUnit.city,
      country: getBranchUnit?.branchUnit.country?.code,
      branchUnitNumber: getBranchUnit?.branchUnit.branchUnitNumber ?? '',
      active: getBranchUnit?.branchUnit.active ?? true
    },
    resolver: zodResolver(addEditBranchUnitSchema)
  })

  const handleOnSubmit = async (newBranchUnit: CreateEditBranchUnitForm) => {
    if (create) {
      await createMutation
        .mutateAsync({
          createBranchUnitCommand: {
            employerId: employerId || '',
            code: newBranchUnit.code,
            description: newBranchUnit.description,
            street: newBranchUnit.street,
            number: newBranchUnit.number,
            suffix: newBranchUnit.suffix,
            zipCode: newBranchUnit.zipCode,
            city: newBranchUnit.city,
            country: newBranchUnit.country,
            branchUnitNumber: newBranchUnit.branchUnitNumber,
            active: newBranchUnit.active
          }
        })
        .then(closeFlyIn)
    } else {
      await updateMutation
        .mutateAsync({
          updateBranchUnitCommand: {
            branchUnitId: branchUnitId ?? '',
            code: newBranchUnit.code,
            description: newBranchUnit.description,
            street: newBranchUnit.street,
            number: newBranchUnit.number,
            suffix: newBranchUnit.suffix,
            zipCode: newBranchUnit.zipCode,
            city: newBranchUnit.city,
            country: newBranchUnit.country,
            branchUnitNumber: newBranchUnit.branchUnitNumber,
            active: newBranchUnit.active
          }
        })
        .then(() => {
          refetchBranchUnit()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addbranchunit.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="code" label={`${t('form.field.branchunitcode')} *`} />

        <FormInput sx={12} name="description" label={`${t('form.field.description')} *`} />

        <FormInput sx={12} name="street" label={`${t('form.field.street')} *`} />

        <FormInput sx={6} name="number" label={`${t('form.field.number')} *`} />

        <FormInput sx={6} name="suffix" label={`${t('form.field.suffix')}`} />

        <FormInput sx={6} name="zipCode" label={`${t('form.field.zipcode')} *`} />

        <FormInput sx={6} name="city" label={`${t('form.field.city')} *`} />

        <FormSelect sx={6} name="country" label={`${t('form.field.country')} *`} options={countryOptions} />

        <FormInput sx={6} name="branchUnitNumber" label={`${t('form.field.branchunitnumber')}`} />

        <FormSwitch sx={12} name="active" label={t('form.field.active')} />
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons isMutating={isLoadingBranchUnit} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddEditBranchUnit

import {
  OrderDirection,
  useCreateIcpMutation,
  useGetAllCountriesQuery,
  useGetAllIcpsQuery,
  useGetIcpByIdQuery,
  useUpdateIcpMutation,
  FormSelectOption
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, object, string, TypeOf } from 'zod'
import { FormGridLayout } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { FormSelect } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface AddEditIcpProps {
  icpId?: string
}

export function AddEditIcp({ icpId }: AddEditIcpProps) {
  const { t } = useTranslation()
  const addEditIcpSchema = object({
    code: string({
      required_error: t('form.validation.coderequired'),
      invalid_type_error: t('form.validation.coderequired')
    })
      .min(2, t('form.validation.coderequired'))
      .max(3, t('form.validation.codemaxchars')),
    country: string({
      required_error: t('form.validation.countryrequired'),
      invalid_type_error: t('form.validation.countryrequired')
    }).min(2, t('form.validation.countryrequired')),
    payrollProvider: string({
      required_error: t('form.validation.payrollproviderrequired'),
      invalid_type_error: t('form.validation.payrollproviderrequired')
    }).min(1, t('form.validation.payrollproviderrequired')),
    active: boolean().optional()
  }).refine(
    data =>
      !getAllIcps?.icps.data.find(
        i => i.code.toLocaleUpperCase() === data.code.toLocaleUpperCase() && i.active && i.id !== icpId
      )
        ? data.code
        : !data.code,
    {
      message: t('form.validation.codeunique'),
      path: ['endDate']
    }
  )

  type CreateEditIcpForm = TypeOf<typeof addEditIcpSchema>

  const { data: countries } = useGetAllCountriesQuery({
    hasCurrency: true
  })

  const countryOptions = countries?.countries.map(x => new FormSelectOption(x.code, x.name)) || []

  const { data: getAllIcps } = useGetAllIcpsQuery(
    {
      activeOnly: false,
      offset: 0,
      limit: -1,
      sortByProperty: null,
      orderDirection: OrderDirection.Asc
    },
    {
      suspense: false
    }
  )
  const { data: getIcpById, refetch: refetchIcpById } = useGetIcpByIdQuery(
    {
      icpId: icpId ?? ''
    },
    {
      enabled: !!icpId
    }
  )
  const { closeFlyIn } = useFlyIn()
  const form = useForm<CreateEditIcpForm>({
    resolver: zodResolver(addEditIcpSchema),
    defaultValues: {
      country: getIcpById?.icpById?.country || undefined,
      code: getIcpById?.icpById?.code || undefined,
      payrollProvider: getIcpById?.icpById?.payrollProvider || undefined
    }
  })

  const createmutation = useCreateIcpMutation()
  const editmutation = useUpdateIcpMutation()

  const handleOnSubmit = async (newIcp: CreateEditIcpForm) => {
    if (icpId === undefined) {
      await createmutation
        .mutateAsync({
          createIcpCommand: {
            code: newIcp.code,
            active: true,
            country: newIcp.country,
            payrollProvider: newIcp.payrollProvider
          }
        })
        .then(closeFlyIn)
    } else {
      await editmutation
        .mutateAsync({
          updateIcpCommand: {
            id: icpId || '',
            code: newIcp.code,
            active: getIcpById?.icpById?.active ?? false,
            payrollProvider: newIcp.payrollProvider
          }
        })
        .then(() => {
          refetchIcpById()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addediticp.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="code" label={`${t('form.field.icpcode')}`} />

        <FormInput sx={12} name="payrollProvider" label={`${t('form.field.payrollprovider')}`} />

        <FormSelect sx={12} name="country" label={`${t('form.field.country')}`} options={countryOptions} />
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons
        isMutating={createmutation.isLoading || editmutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditIcp

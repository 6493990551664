import { Route, Navigate } from 'react-router-dom'
import LoginRedirectPage from './myepix/pages/auth-redirect-page'
import NotFoundPage from './myepix/pages/404-page/notfound-page'
import { NotAuthorizedPage, GeneralRoutes } from '@epix-web-apps/ui'

export const useGeneralRoutes = () => {
  return (
    <>
      <Route path={GeneralRoutes.AUTHREDIRECTPAGE} element={<LoginRedirectPage />} />
      <Route path={GeneralRoutes.NOTFOUND} element={<NotFoundPage />} />
      <Route path={GeneralRoutes.NOTAUTHORIZED} element={<NotAuthorizedPage />} />
      <Route path="*" element={<Navigate to={GeneralRoutes.NOTFOUND} replace />} />
    </>
  )
}

import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { SuspensePaper } from '../../../suspense-paper'
import IncidentsDataComponent from '../../incidents-data-component'
import FatalInjuriesDataComponent from '../../fatal-injuries-data-component'
import InjuryDisabilityComponent from '../../injury-disability-component'
import IncidentHistoryComponent from '../../incident-history-component/incident-history-component'
import IncidentRateComponent from '../../incident-rate-component'
import { FEATURE, useGetFeaturesQuery } from '@epix-web-apps/core'

export function InjuriesTab() {
  const { t } = useTranslation()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <SuspensePaper title={t('analytics.data.incidentsgraph.title')}>
          <IncidentHistoryComponent />
        </SuspensePaper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <SuspensePaper title={t('analytics.data.injurydisability.title')}>
          <InjuryDisabilityComponent />
        </SuspensePaper>
      </Grid>
      <Grid item xs={3} lg={3}>
        <SuspensePaper title={t('analytics.data.incidents.title')}>
          <IncidentsDataComponent />
        </SuspensePaper>
      </Grid>
      <Grid item xs={3} lg={3}>
        <SuspensePaper title={t('analytics.data.fatalinjuries.title')}>
          <FatalInjuriesDataComponent />
        </SuspensePaper>
      </Grid>

      <Grid item xs={6} lg={6}>
        <SuspensePaper title={t('analytics.data.incidentrate.title')}>
          <IncidentRateComponent />
        </SuspensePaper>
      </Grid>
    </Grid>
  )
}

export default InjuriesTab

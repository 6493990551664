import { getPort, getTenant } from '../../../../msal-config'
import { environment } from '../../../../environments/environment'
import { EpixLoadingPage } from '@epix-web-apps/ui'

export function AuthRedirectPage() {
  const port = getPort()
  const tenant = getTenant()
  let redirectUrl = `${window.location.protocol}//`

  if (tenant) {
    redirectUrl += `${tenant}.${environment.auth.redirectDomain}${port}${window.location.hash}`
  } else {
    redirectUrl += `${environment.auth.redirectDomain}${port}${window.location.hash}`
  }

  window.location.replace(redirectUrl)
  return <EpixLoadingPage message="Redirecting to your epix environment..." />
}

export default AuthRedirectPage

import { OrderDirection, useGetUpcomingAnniversariesQuery } from '@epix-web-apps/core'
import { Box, useTheme, Typography } from '@mui/material'
import { GridColDef, GridRowId, GridRowsProp } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, ISortModel, SortModel } from '../../data-table'
import { People, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { useNavigate } from 'react-router-dom'

/* eslint-disable-next-line */
export interface OverviewEventAnniversariesProps {}

export function OverviewEventAnniversaries(props: OverviewEventAnniversariesProps) {
  const { t } = useTranslation()
  const [tableData, setTableData] = useState<GridRowsProp>([])
  const theme = useTheme()
  const navigate = useNavigate()
  const { icpFilters } = useGlobalPersistedStore()
  const { closeFlyIn } = useFlyIn()

  const { data: dataUpcomingAnniversaries, isLoading: isLoadingAnniversaries } = useGetUpcomingAnniversariesQuery({
    icps: icpFilters
  })

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('analytics.homepage.events.name'),
      flex: 3,
      sortable: false
    },
    {
      field: 'date',
      headerName: t('analytics.homepage.events.date'),
      flex: 2,
      sortable: false
    },
    {
      field: 'serviceYears',
      headerName: t('analytics.homepage.events.yearsofservice'),
      flex: 2,
      sortable: false
    }
  ]

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns[0]?.field, OrderDirection.Asc))

  const goToPersonDetailPage = (personId: GridRowId | undefined) => {
    personId && navigate(People.PEOPLE_ID_DETAILS(`${personId}`))
  }

  useEffect(() => {
    if (dataUpcomingAnniversaries?.upcomingAnniversaries) {
      const data = dataUpcomingAnniversaries.upcomingAnniversaries
      setTableData(
        data.map((row: any) => {
          return {
            id: row.personId,
            name: row.firstName + ' ' + row.lastName,
            date: new Date(row.upcomingDate).toLocaleDateString(),
            serviceYears: row.yearsOfService
          }
        })
      )
    }
  }, [dataUpcomingAnniversaries?.upcomingAnniversaries])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '30rem'
      }}
    >
      <Typography variant="h4">{t('analytics.homepage.events.title.anniversaries')}</Typography>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <DataTable
          totalRowCount={dataUpcomingAnniversaries?.upcomingAnniversaries.length}
          data={tableData}
          columns={columns}
          onSortChange={setSortModel}
          isLoading={isLoadingAnniversaries}
          defaultSortModel={sortModel}
          hideFooter={true}
          onRowClick={params => {
            goToPersonDetailPage(params.id)
            closeFlyIn()
          }}
          emptyStateElement={
            <Typography
              sx={{
                mt: 2,
                color: theme.palette.text.secondary,
                fontStyle: 'italic'
              }}
            >
              {t('analytics.homepage.events.noresults')}
            </Typography>
          }
        />
      </Box>
    </Box>
  )
}

export default OverviewEventAnniversaries

import { SxProps, Tab } from '@mui/material'
import React from 'react'

export interface IconTabProps {
  label: string | React.ReactElement | undefined
  icon?: React.ReactElement
  align?: 'start' | 'center' | 'end'
  justify?: 'start' | 'center' | 'end'
  hidden?: boolean
  onClick?: () => void
  sx?: SxProps
}

export function IconTab({
  icon,
  label,
  align = 'center',
  justify = 'center',
  hidden = false,
  onClick = () => {
    return
  },
  sx,
  ...props
}: IconTabProps) {
  let newIcon
  if (icon) {
    newIcon = React.cloneElement(icon, { fontSize: 'small' })
  }
  return (
    <Tab
      label={label}
      iconPosition="start"
      icon={icon && newIcon}
      onClick={onClick}
      sx={{
        display: hidden ? 'none' : 'flex',
        flexDirection: 'row',
        justifyContent: align,
        alignItems: justify,
        minHeight: 48,
        ...sx
      }}
      {...props}
    />
  )
}

export default IconTab

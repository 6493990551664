import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { SuspensePaper } from '../../../suspense-paper'
import ContractDurationComponent from '../../contract-duration-component'
import ContractCountryComponent from '../../contract-country-component'
import EmployeeTypeComponent from '../../employee-type-component'
import EmployeePopulationComponent from '../../employee-population-component'
import WorkingRegimeComponent from '../../working-regime-component'

function CompositionTab() {
  const { t } = useTranslation()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <SuspensePaper title={t('analytics.pie.contractduration')}>
          <ContractDurationComponent />
        </SuspensePaper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <SuspensePaper title={t('analytics.pie.contractcountry')}>
          <ContractCountryComponent />
        </SuspensePaper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <SuspensePaper title={t('analytics.pie.employeetype')}>
          <EmployeeTypeComponent />
        </SuspensePaper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <SuspensePaper title={t('analytics.pie.employeepopulation')}>
          <EmployeePopulationComponent />
        </SuspensePaper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <SuspensePaper title={t('analytics.pie.workingregime')}>
          <WorkingRegimeComponent />
        </SuspensePaper>
      </Grid>
    </Grid>
  )
}

export default CompositionTab

import { useTranslation } from 'react-i18next'
import { FormActionButtons, FormErrorList, TypographyBold, useFlyIn } from '@epix-web-apps/ui'
import { TypeOf, object } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { GraphqlError, useDeleteDocumentMutation, useGetDocumentByIdQuery } from '@epix-web-apps/core'
import { FormContainer } from '@epix-web-apps/ui'
import { Typography, useTheme } from '@mui/material'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface RemoveDocumentProps {
  documentId: string
}

export function RemoveDocument({
  documentId
}: RemoveDocumentProps) {
  const theme = useTheme();
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const { data: getDocumentById } =
    useGetDocumentByIdQuery({
      id: documentId
    })

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const removeDocumentSchema = object({})

  type RemoveDocumentForm = TypeOf<typeof removeDocumentSchema>

  const form = useForm<RemoveDocumentForm>({
    resolver: zodResolver(removeDocumentSchema)
  })

  const deleteDocumentMutation = useDeleteDocumentMutation();

  const handleOnSubmit = async (documentToRemove: RemoveDocumentForm) => {
    await deleteDocumentMutation.mutateAsync({
      deleteDocumentCommand: {
        id: documentId
      }
    }).catch(e => setBackendErrors([e]))
    .finally(closeFlyIn)
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.removedocument.title')}</Typography>

      <Typography>
        {t('flyin.removedocument.areyousure')}
      </Typography>
      <TypographyBold>
        {getDocumentById?.documentById.name}
      </TypographyBold>
      <Typography color={theme.palette.text.secondary} sx={{ fontSize: 'small' }}>
        {t('flyin.removedocument.cannotbeundone')}
      </Typography>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        showRemove={true}
        buttonColor="error"
        buttonText={t('common.delete')}
        isMutating={deleteDocumentMutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default RemoveDocument

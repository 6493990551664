import {
  OrderDirection,
  useCreateFunctionAreaTypeMutation,
  useGetAllFunctionAreaTypesQuery,
  useGetFunctionAreaTypeByKeyQuery,
  useUpdateFunctionAreaTypeMutation
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, object, string, TypeOf } from 'zod'
import { FormGridLayout } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'

/* eslint-disable-next-line */
export interface AddEditFunctionAreaProps {
  functionAreaKey?: string
}

export function AddEditFunctionArea({ functionAreaKey }: AddEditFunctionAreaProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const addEditFunctionAreaSchema = object({
    key: string({
      required_error: t('form.validation.keyrequired'),
      invalid_type_error: t('form.validation.keyrequired')
    }).min(1, t('form.validation.keyrequired')),
    value: string({
      required_error: t('form.validation.valuerequired'),
      invalid_type_error: t('form.validation.valuerequired')
    }).min(1, t('form.validation.valuerequired')),
    active: boolean()
  })

  type CreateEditFunctionAreaForm = TypeOf<typeof addEditFunctionAreaSchema>

  const { data: functionAreasData } = useGetAllFunctionAreaTypesQuery(
    {
      offset: 0,
      limit: -1,
      sortByProperty: 'key',
      orderDirection: OrderDirection.Asc
    },
    {
      suspense: false
    }
  )

  const { data: getFunctionAreasByKey, refetch: refetchFunctionAreasByKey } = useGetFunctionAreaTypeByKeyQuery(
    {
      key: functionAreaKey ?? ''
    },
    {
      enabled: !!functionAreaKey
    }
  )
  const form = useForm<CreateEditFunctionAreaForm>({
    resolver: zodResolver(addEditFunctionAreaSchema),
    defaultValues: {
      key: getFunctionAreasByKey?.functionAreaTypeByKey?.key,
      value: getFunctionAreasByKey?.functionAreaTypeByKey?.value,
      active: getFunctionAreasByKey?.functionAreaTypeByKey?.active ?? true
    }
  })

  const createmutation = useCreateFunctionAreaTypeMutation()
  const editmutation = useUpdateFunctionAreaTypeMutation()

  const handleOnSubmit = async (newFunctionArea: CreateEditFunctionAreaForm) => {
    if (
      !functionAreaKey &&
      newFunctionArea.key !==
        functionAreasData?.functionAreaTypes.data.find(f => f.key.includes(newFunctionArea.key))?.key
    ) {
      await createmutation
        .mutateAsync({
          createFunctionAreaTypeCommand: {
            key: newFunctionArea.key,
            value: newFunctionArea.value
          }
        })
        .then(closeFlyIn)
    } else {
      await editmutation
        .mutateAsync({
          updateFunctionAreaTypeCommand: {
            key: newFunctionArea.key,
            value: newFunctionArea.value,
            active: newFunctionArea.active
          }
        })
        .then(() => {
          refetchFunctionAreasByKey()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditfunctionarea.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="key" disabled={!!functionAreaKey} label={`${t('form.field.key')}`} />

        <FormInput sx={12} name="value" label={`${t('form.field.value')}`} />

        {functionAreaKey && <FormSwitch sx={12} name="active" label={t('form.field.active')} />}
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons
        isMutating={createmutation.isLoading || editmutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditFunctionArea

import { useParams } from 'react-router-dom'
import { CompanyCarDetailPageParams } from '../../../../pages/company-car-detail-page/company-car-detail-page'
import { useTranslation } from 'react-i18next'
import { FormActionButtons, FormSelect, useFlyIn } from '@epix-web-apps/ui'
import { TypeOf, number, object, string } from 'zod'
import {
  formTypeSelectOptions,
  numericString,
  useGetCompanyCarByIdQuery,
  useUpdateCompanyCarTechnicalDetailsMutation
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { FormContainer } from '@epix-web-apps/ui'
import { Typography } from '@mui/material'
import { FormGridLayout } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface EditCompanyCarTechnicalDetailsProps {}

export function EditCompanyCarTechnicalDetails(props: EditCompanyCarTechnicalDetailsProps) {
  const { t } = useTranslation()
  const params = useParams<CompanyCarDetailPageParams>()
  const { closeFlyIn } = useFlyIn()
  const editCompanyCarTechnicalDetailsSchema = object({
    motorTypeKey: string().optional().nullable(),
    co2: numericString(number().optional().nullable()),
    horsePower: numericString(number().optional().nullable())
  })

  type EditCompanyCarTechnicalDetailsForm = TypeOf<typeof editCompanyCarTechnicalDetailsSchema>

  const { data: getCompanyCarById, refetch: refetchCompanyCarById } = useGetCompanyCarByIdQuery(
    {
      companyCarId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )
  const car = getCompanyCarById?.companyCarById

  const { motorTypeOptions } = formTypeSelectOptions

  const mutation = useUpdateCompanyCarTechnicalDetailsMutation()

  const form = useForm<EditCompanyCarTechnicalDetailsForm>({
    resolver: zodResolver(editCompanyCarTechnicalDetailsSchema),
    defaultValues: {
      motorTypeKey: car?.motorType?.key,
      co2: car?.co2,
      horsePower: car?.horsePower
    }
  })

  const { handleSubmit } = form

  const handleOnSubmit = async (carTechnicalDetails: EditCompanyCarTechnicalDetailsForm) => {
    await mutation
      .mutateAsync({
        updateCompanyCarTechnicalDetailsCommand: {
          id: params.id || '',
          motorTypeKey: carTechnicalDetails.motorTypeKey && carTechnicalDetails.motorTypeKey,
          co2: carTechnicalDetails.co2,
          horsePower: carTechnicalDetails.horsePower
        }
      })
      .then(() => {
        refetchCompanyCarById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editcompanycartechnicaldetails.title')}</Typography>

      <FormGridLayout>
        <FormSelect sx={6} name="motorTypeKey" label={t('form.field.motortype')} options={motorTypeOptions} />

        <FormInput sx={6} name="co2" label={t('form.field.co2')} />

        <FormInput sx={6} name="horsePower" label={t('form.field.horsepower')} />
      </FormGridLayout>

      <FormActionButtons isMutating={mutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditCompanyCarTechnicalDetails

import { DEFAULT_CURRENCY, getNumberOfColors, useGetLabourCostPersonQuery, useGetMeQuery } from '@epix-web-apps/core'
import { useTranslation } from 'react-i18next'
import { useGlobalPersistedStore } from '@epix-web-apps/ui'
import { Typography } from '@mui/material'
import { useLabourCostAnalyticsPersistedStore } from '../../../stores/labour-cost-analytics-store'
import BarchartComponent from '../../analytics-components/barchart-component'

/* eslint-disable-next-line */
export interface LabourCostPersonChartComponentProps {}

function convertData(data: any) {
  const result = data?.map((x: any) => {
    const { subGroupType, ...temp } = x
    const typesData = Object.assign(
      {},
      ...subGroupType.map((y: any) => ({
        [Object.values(y)[0] as string]: Object.values(y)[1]
      }))
    )
    return { ...temp, ...typesData }
  })
  return result
}

function getKeys(data: any) {
  return [...new Set(data?.flatMap((x: any) => x.subGroupType.map((type: any) => type.key)))]
}

export function LabourCostPersonChartComponent(props: LabourCostPersonChartComponentProps) {
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const { data: me } = useGetMeQuery()

  const store = useLabourCostAnalyticsPersistedStore()
  const { data: labourCostPerson } = useGetLabourCostPersonQuery({
    icps: icpFilters,
    calculatedDataFilterModel: {
      startDate: store.dateFromFilter,
      endDate: store.dateToFilter,
      currency: store.currencyToFilter ?? me?.me.currency ?? DEFAULT_CURRENCY,
      genderType: store.genderFilter,
      functionAreaType: store.functionAreaFilter,
      functionLevelType: store.functionLevelFilter,
      employerId: store.employerFilter,
      employeeType: store.employeeTypeFilter,
      subGroupTypes: store.subGroupTypesFilter
    }
  })

  if (labourCostPerson?.labourCostPerPerson.entries.length === 0)
    return <Typography sx={{ p: 2, textAlign: 'center' }}>{t('analytics.nodata')}</Typography>
  return (
    <BarchartComponent
      data={convertData(labourCostPerson?.labourCostPerPerson.entries)}
      keys={getKeys(labourCostPerson?.labourCostPerPerson.entries)}
      indexBy="personName"
      layoutMode="horizontal"
      groupMode="stacked"
      colors={getNumberOfColors(9)}
      innerPadding={0}
      marginRight={150}
      marginLeft={110}
      sx={{ overflowY: 'auto' }}
      axisBottom={`${labourCostPerson?.labourCostPerPerson.currencyModel.currency} (${labourCostPerson?.labourCostPerPerson.currencyModel.symbol})`}
    />
  )
}

export default LabourCostPersonChartComponent

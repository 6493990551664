import { Box, IconButton, MenuItem, Switch, Tooltip, useTheme } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { DataTable, ISortModel, SortModel } from '../../components/data-table'
import { ERRORTYPE, OrderDirection, useGetAllValidationErrorsByPersonIdQuery } from '@epix-web-apps/core'
import { DetailPageBaseQueryParams, DetailPageContentHeader, Documentation, People } from '@epix-web-apps/ui'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { HeaderTitleNavigation } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import FilterButton from '../../components/filter-button'
import DropdownMenu from '../../components/dropdown-menu'
import EditViewedValidationError from '../../components/validation-components/edit-viewed-validation-error'
import { InfoOutlined } from '@mui/icons-material'

/* eslint-disable-next-line */
export interface PersonValidationErrorsPageProps {}

export function PersonValidationErrorsPage(props: PersonValidationErrorsPageProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { openFlyIn } = useFlyIn()
  const theme = useTheme()
  const params = useParams<DetailPageBaseQueryParams>()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [rowCount, setRowCount] = useState(0)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [validationErrorId, setValidationErrorId] = useState('')
  const [filters, setFilters] = useState({
    showViewed: false
  })

  const errorTypeActions = (rowParams: GridRenderCellParams) =>
    rowParams.value.toString().toUpperCase() === ERRORTYPE.ERROR ? (
      <Tooltip title={t('personvalidationerrorspage.tooltip.error')}>
        <ErrorOutlineOutlinedIcon sx={{ color: theme.palette.error.light }} />
      </Tooltip>
    ) : (
      <Tooltip title={t('personvalidationerrorspage.tooltip.warning')}>
        <WarningAmberOutlinedIcon sx={{ color: theme.palette.warning.light }} />
      </Tooltip>
    )

  const hasSeenActions = (rowParams: GridRenderCellParams) => {
    return rowParams.value && <VisibilityOffIcon />
  }

  const rowActions = (rowParams: GridRenderCellParams) =>
    rowParams.row.errorType.toString().toUpperCase() === ERRORTYPE.WARNING ? (
      <>
        <IconButton
          aria-label="row actions"
          aria-controls="menu-row"
          aria-haspopup="true"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            setAnchorEl(e.currentTarget)
            setValidationErrorId(rowParams.row.id)
          }}
        >
          <MoreHorizIcon />
        </IconButton>
        <IconButton aria-label="row details" aria-controls="details-row" />
      </>
    ) : (
      ''
    )

  const columns: GridColDef[] = [
    {
      field: 'errorType',
      headerName: t('personvalidationerrorspage.datatable.column.errortype'),
      flex: 0.25,
      renderCell: errorTypeActions
    },
    {
      field: 'errorCode',
      headerName: t('personvalidationerrorspage.datatable.column.errorcode'),
      flex: 0.25
    },
    {
      field: 'entity',
      headerName: t('personvalidationerrorspage.datatable.column.entity'),
      flex: 0.5
    },
    {
      field: 'validationErrorMessage',
      headerName: t('personvalidationerrorspage.datatable.column.validationerrormessage'),
      flex: 1,
      sortable: false
    },
    { field: 'hasSeen', headerName: '', renderCell: hasSeenActions },
    { field: '', sortable: false, editable: false, renderCell: rowActions }
  ]

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns[0]?.field, OrderDirection.Asc))
  const [tableData, setTableData] = useState<GridRowsProp>([])

  useEffect(() => {
    setPage(0)
  }, [filters])

  const {
    data: validations,
    isLoading,
    refetch: refetchValidations
  } = useGetAllValidationErrorsByPersonIdQuery({
    personId: params.id || '',
    showHasSeen: filters.showViewed,
    offset: page * pageSize,
    limit: pageSize,
    sortByProperty: sortModel.field,
    orderDirection: sortModel.orderDirection
  })

  useEffect(() => {
    if (validations && validations.allValidationErrorsByPersonId) {
      const validationData = validations.allValidationErrorsByPersonId
      setTableData(
        validationData.data.map(row => {
          return {
            id: row.id,
            errorCode: row.errorCode,
            errorType: row.errorType,
            entity: row.errorCodeEntityName,
            validationErrorMessage: row.validationErrorMessage,
            hasSeen: row.hasSeen,
            displayName: row.displayName
          }
        })
      )
      setRowCount(validationData.totalCount)
    }
  }, [validations])

  const filterBar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right'
      }}
    >
      <Tooltip title={t('personvalidationerrorspage.tooltip.docs')}>
        <IconButton
          sx={{ m: 0, p: [2] }}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            navigate(Documentation.VALIDATIONS)
          }}
        >
          <InfoOutlined fontSize="medium" />
        </IconButton>
      </Tooltip>
      <FilterButton filters={filters}>
        <p>
          <Switch
            checked={filters.showViewed}
            onChange={e => setFilters({ ...filters, showViewed: e.target.checked })}
            aria-label={t('personvalidationerrorspage.filters.showViewed')}
          />
          {t('personvalidationerrorspage.filters.showViewed')}
        </p>
      </FilterButton>
    </Box>
  )

  const name = validations?.allValidationErrorsByPersonId.data.at(0)?.displayName

  return (
    <>
      <DetailPageContentHeader>
        <HeaderTitleNavigation backToLink={People.PEOPLE_ID_DETAILS(`${params.id}`)} title={name} showDivider={false} />
      </DetailPageContentHeader>
      <DataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        onSortChange={setSortModel}
        page={page}
        onPageChange={setPage}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        isLoading={isLoading}
        defaultSortModel={sortModel}
        filterBarElement={filterBar}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={e =>
            openFlyIn({
              content: <EditViewedValidationError validationErrorId={validationErrorId} />,
              callbackAfterClose: () => refetchValidations
            })
          }
        >
          {t('personvalidationerrorspage.list.row.menu.editvalidationerrorhasseen')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default PersonValidationErrorsPage

import styled from "@emotion/styled"
import { useTheme } from "@mui/material"
import Timeline from 'react-calendar-timeline'

export const StyledTimeline = styled(Timeline)(props => {
    const theme = useTheme()
    const themePaletteSecondaryLightWithTransparency = 'rgba(247, 247, 252, 0.5)'
    return {
      '.rct-header-root': {
        border: 'none',
        color: theme.palette.primary.main,
        // search bar
        backgroundColor: theme.palette.primary.light,
        '> div:first-of-type': {
          backgroundColor: theme.palette.common.white,
          borderTop: `1px solid ${theme.palette.grey[400]}`,
          borderBottom: `1px solid ${theme.palette.grey[400]}`,
          borderLeft: `1px solid ${theme.palette.grey[400]}`
        },
        // month header
        '.rct-dateHeader-primary': {
          color: theme.palette.primary.main,
          fontWeight: 'bold'
        },
        // day numbers header
        '.rct-dateHeader': {
          borderLeft: 'none',
          backgroundColor: themePaletteSecondaryLightWithTransparency,
          borderBottom: 'none'
        }
      },
      // people sidebar
      '.rct-sidebar': {
        border: 'none',
        borderLeft: `1px solid ${theme.palette.grey[400]}`,
        '.rct-sidebar-row.rct-sidebar-row-odd': {
          backgroundColor: theme.palette.common.white
        }
      },
      // day square
      '.rct-scroll': {
        overflow: 'hidden',
        borderLeft: `1px solid ${theme.palette.grey[400]}`,
        borderRight: `1px solid ${theme.palette.grey[400]}`,
        '.rct-vertical-lines': {
          // left border of first day of month
          '.rct-vl.rct-vl-first': {
            border: 'none'
          },
          // weekend day colums
          '.rct-vl.rct-day-0, .rct-vl.rct-day-6': {
            backgroundColor: `${themePaletteSecondaryLightWithTransparency} !important`,
            zIndex: 5
          }
        },
        // even rows
        '.rct-hl-even': {
          backgroundColor: theme.palette.common.white,
          zIndex: 5
        },
        // odd rows
        '.rct-hl-odd': {
          backgroundColor: theme.palette.common.white
        },
        '.rct-horizontal-lines, .rct-vertical-lines': {
          border: '0px',
          cursor: 'default'
        },
        // items in day square
        '.rct-item': {
          color: `${theme.palette.text.primary} !important`,
          paddingRight: '1px',
          paddingLeft: '2px',
          background: 'transparent !important',
          borderTop: 'none !important',
          borderBottom: 'none !important',
          borderLeft: `none !important`,
          borderRight: `none !important`
        }
      }
    }
  })
import { Calendar, momentLocalizer, View } from 'react-big-calendar'
import moment from 'moment'
import React, { Children, useEffect, useMemo, useState } from 'react'
import { Box, Button, useTheme } from '@mui/material'
import styled from '@emotion/styled'
import { FirstContrastThreshold, useGetCollectiveScheduleByIdQuery, useGetMeQuery } from '@epix-web-apps/core'
import AddEditCollectiveCalendarEntry from '../add-edit-collective-calendar-entry/add-edit-collective-calendar-entry'
import { useTranslation } from 'react-i18next'
import Year from './year'
import './style.scss'
import { useFlyIn } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface CalendarProps {
  employerId: string
  collectiveScheduleId: string | undefined
}

export interface Event {
  id: string
  start: Date
  end: Date
  title: string
  colorCodeHex?: string | null
}

interface ClickedEvent {
  start: Date
  end: Date
}

export const CalendarContainer = styled.div`
  /* ~~~ container styles ~~~ */
  height: 100%;

  .rbc-row-content-scroll-container {
    margin-bottom: 1.5rem;
  }

  .rbc-toolbar-label {
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1;
    width: 100%;
  }
  .rbc-btn-group {
    background-color: #00044e;
    border-radius: 2px;
    border-radius: 10px;
    border: none;
  }
  .rbc-btn-group > * {
    border-radius: 10px;
    border: none;
    color: #ffffff;
    font-size: 0.9em;
  }
  .rbc-btn-group > *:hover {
    cursor: pointer;
  }
  .rbc-day-bg {
    display: grid;
    grid-template-columns: 1fr;
  }
`

export function CalendarYear({ employerId, collectiveScheduleId }: CalendarProps) {
  const theme = useTheme()
  const [myEvents, setEvents] = useState<Event[]>([])
  const { openFlyIn } = useFlyIn()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { t } = useTranslation()
  const [clickedEvent, setClickedEvent] = useState<ClickedEvent>()
  const { data: me } = useGetMeQuery()
  moment.locale(me?.me.locale.locale)
  const localizer = momentLocalizer(moment)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  localizer.formats.yearHeaderFormat = 'YYYY'
  const [view, setView] = useState<View | undefined>('agenda')
  const [clickedDate, setClickedDate] = useState<Date>(new Date())

  const { data: collectiveDays, refetch: refetchDays } = useGetCollectiveScheduleByIdQuery({
    collectiveScheduleId: collectiveScheduleId || ''
  })

  useEffect(() => {
    setEvents(
      collectiveDays?.collectiveSchedule.days.map(item => {
        return {
          id: item.id,
          start: item.dateTime,
          end: item.dateTime,
          title: item.description,
          colorCodeHex: item.colourCodeHex,
          timeFrameType: item.timeFrameType.key
        }
      }) || []
    )
  }, [collectiveDays])

  const eventStyleGetter = (event: any) => {
    const textColor = FirstContrastThreshold(event.colorCodeHex, 7, ['#FFFFFF', '#000000'])
    const backgroundColor = event.colorCodeHex
    const style = {
      backgroundColor: backgroundColor,
      borderRadius: '2px',
      opacity: 0.8,
      fontSize: '0.9em',
      color: textColor,
      border: '0px',
      display: 'block'
    }
    return {
      style: style
    }
  }

  const handleSelectSlot = ({ start, end }: ClickedEvent) => {
    openFlyIn({
      content: (
        <AddEditCollectiveCalendarEntry
          start={new Date(start) || new Date()}
          end={new Date(end.setDate(end.getDate() - 1)) || new Date()}
          employerId={employerId}
          collectiveScheduleId={collectiveScheduleId}
        />
      ),
      callbackAfterClose: () => refetchDays
    })
  }

  const eventWrapper = useMemo(
    () =>
      ({ children, value, end }: any) => {
        return React.cloneElement(
          Children.only(children),
          {
            style: {
              position: 'relative'
            }
          },
          <Button
            onClick={event => {
              setAnchorEl(event.currentTarget)
              setClickedEvent({ start: value, end: end })
            }}
            sx={{
              position: 'absolute',
              zIndex: theme.zIndex.drawer - 10,
              top: 0,
              right: 0,
              height: '100%',
              width: '100%',
              fontSize: '0.7em'
            }}
          ></Button>
        )
      },
    []
  )

  const { views } = useMemo(() => {
    Year.handleClick = (date: Date) => {
      setView('month')
      setClickedDate(date)
    }
    Year.collectiveDays = myEvents
    Year.isPersonal = false
    return {
      views: {
        month: true,
        agenda: Year
      }
    }
  }, [myEvents])

  return (
    <Box height={'100%'}>
      <CalendarContainer>
        <Calendar
          view={view}
          localizer={localizer}
          events={myEvents}
          messages={{ agenda: 'Year' }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          views={views}
          onView={setView}
          startAccessor="start"
          endAccessor="end"
          eventPropGetter={eventStyleGetter}
          components={{
            dateCellWrapper: eventWrapper
          }}
          onSelectSlot={handleSelectSlot}
          date={clickedDate}
          onNavigate={setClickedDate}
          selectable
        />
      </CalendarContainer>
    </Box>
  )
}

export default CalendarYear

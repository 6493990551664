import { useTranslation } from 'react-i18next'
import { TypeOf, date, number, object, string } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  GraphqlError,
  numericString,
  useCreateCompanyCarMutation,
  useGetAllCountriesQuery,
  FormSelectOption,
  formTypeSelectOptions
} from '@epix-web-apps/core'
import { Typography } from '@mui/material'
import {
  FormContainer,
  FormInput,
  FormGridLayout,
  FormSelect,
  FormActionButtons,
  FormDatepicker,
  FormErrorList,
  useFlyIn,
} from '@epix-web-apps/ui'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface AddCompanyCarProps {}

export function AddCompanyCar(props: AddCompanyCarProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const addCompanyCarSchema = object({
    carBrandKey: string({
      required_error: t('form.validation.carbrandrequired'),
      invalid_type_error: t('form.validation.carbrandrequired')
    }).min(1, t('form.validation.carbrandrequired')),
    model: string({}).optional().nullable(),
    motorTypeKey: string().optional().nullable(),
    carCategoryId: string().optional().nullable(),
    licensePlate: string({
      required_error: t('form.validation.licenseplaterequired'),
      invalid_type_error: t('form.validation.licenseplaterequired')
    }).min(1, t('form.validation.licenseplaterequired')),
    co2: numericString(number().optional().nullable()),
    countryKey: string({
      required_error: t('form.validation.countrykeyrequired'),
      invalid_type_error: t('form.validation.countrykeyrequired')
    }).min(1, t('form.validation.countrykeyrequired')),
    currency: string().optional().nullable(),
    registrationDate: date().optional().nullable(),
    startDate: date({
      required_error: t('form.validation.startdaterequired'),
      invalid_type_error: t('form.validation.startdaterequired')
    }),
    endDate: date().optional().nullable()
  }).refine(data => (data.endDate ? data.endDate >= data.startDate : !data.endDate), {
    message: t('form.validation.enddateafterstartdate'),
    path: ['endDate']
  })

  type AddCompanyCarForm = TypeOf<typeof addCompanyCarSchema>

  const form = useForm<AddCompanyCarForm>({
    resolver: zodResolver(addCompanyCarSchema),
    defaultValues: {
      carBrandKey: '',
      model: null,
      motorTypeKey: null,
      carCategoryId: null,
      licensePlate: '',
      co2: null,
      countryKey: '',
      currency: '',
      registrationDate: null,
      startDate: undefined,
      endDate: null
    }
  })

  const createmutation = useCreateCompanyCarMutation()

  const { carBrandOptions, motorTypeOptions, carCategoryOptions, currencyOptions } = formTypeSelectOptions

  const { data: countries } = useGetAllCountriesQuery({
    hasCurrency: true
  })

  const countryOptions = countries?.countries.map(x => new FormSelectOption(x.code, x.name)) || []

  const handleOnSubmit = async (newCompanyCar: AddCompanyCarForm) => {
    await createmutation
      .mutateAsync({
        createCompanyCarCommand: {
          carBrandTypeKey: newCompanyCar.carBrandKey,
          model: newCompanyCar.model,
          motorTypeKey: newCompanyCar.motorTypeKey,
          carCategoryId: newCompanyCar.carCategoryId,
          licensePlate: newCompanyCar.licensePlate,
          co2: newCompanyCar.co2,
          countryKey: newCompanyCar.countryKey,
          registrationDate: newCompanyCar.registrationDate,
          startDate: newCompanyCar.startDate,
          endDate: newCompanyCar.endDate
        }
      })
      .then(closeFlyIn)
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addcompanycar.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={6}
          name="countryKey"
          label={`${t('form.field.countryregistration')}*`}
          options={countryOptions}
          onChange={(e, selectedOption) =>
            form.resetField('currency', {
              defaultValue:
                currencyOptions.find(
                  cu => cu.id === countries?.countries.find(co => co.code === selectedOption?.id)?.currencyKey
                )?.label || ''
            })
          }
        />

        <FormInput sx={6} name="currency" label={t('form.field.currency')} disabled={true} />

        <FormDatepicker sx={6} name="registrationDate" label={t('form.field.registrationdate')} />

        <FormInput sx={6} name="licensePlate" label={`${t('form.field.licenseplate')}*`} />

        <FormDatepicker sx={6} name="startDate" label={`${t('form.field.startdate')}*`} />

        <FormDatepicker sx={6} name="endDate" label={t('form.field.enddate')} />

        <FormSelect sx={6} name="carBrandKey" label={`${t('form.field.carbrand')}*`} options={carBrandOptions} />

        <FormInput sx={6} name="model" label={t('form.field.model')} />

        <FormSelect sx={6} name="motorTypeKey" label={t('form.field.motorType')} options={motorTypeOptions} />

        <FormInput sx={6} name="co2" label={t('form.field.co2')} />

        <FormSelect sx={6} name="carCategoryId" label={t('form.field.carcategory')} options={carCategoryOptions} />
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={createmutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddCompanyCar

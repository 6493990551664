import React from 'react'
import moment from 'moment'
import * as dates from 'date-arithmetic'
import { Navigate } from 'react-big-calendar'

function createCalendar(currentDate) {
  if (!currentDate) {
    currentDate = moment()
  } else {
    currentDate = moment(currentDate)
  }

  const first = currentDate.clone().startOf('month')
  const last = currentDate.clone().endOf('month')
  const weeksCount = Math.ceil((first.day() + last.date()) / 7)
  const calendar = Object.assign([], { currentDate, first, last })

  for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
    const week = []
    calendar.push(week)
    calendar.year = currentDate.year()
    calendar.month = currentDate.month()

    for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
      const date = currentDate.clone().set('date', day + 2 - first.day())
      date.calendar = calendar
      week.push(date)
    }
  }

  return calendar
}

function CalendarDate(props) {
  const { dateToRender, dateOfMonth, collectiveDays, personalDays, isPersonal } = props
  const today = dateToRender.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? 'today' : ''

  if (dateToRender.month() < dateOfMonth.month()) {
    return (
      <button disabled={true} className="date prev-month">
        {dateToRender.date()}
      </button>
    )
  }

  if (dateToRender.month() > dateOfMonth.month()) {
    return (
      <button disabled={true} className="date next-month">
        {dateToRender.date()}
      </button>
    )
  }

  function isDate(date) {
    let matchingDays
    if (collectiveDays && !isPersonal) {
      matchingDays = collectiveDays.filter(collectiveDay => {
        return new Date(date).toLocaleDateString() === new Date(collectiveDay.start).toLocaleDateString()
      })
      return matchingDays.length > 0 ? matchingDays : null
    } else if (personalDays && isPersonal) {
      matchingDays = personalDays.filter(personalDay => {
        return new Date(date).toLocaleDateString() === new Date(personalDay.start).toLocaleDateString()
      })
      return matchingDays.length > 0 ? matchingDays : null
    }
  }

  return (
    <button
      className={`date in-month ${today}`}
      onClick={() => props.onClick(dateToRender)}
      style={{ position: 'relative' }}
    >
      {dateToRender.date()}
      {isDate(dateToRender) &&
        isDate(dateToRender).map((day, index, array) => (
          <div
            key={index}
            style={{
              position: 'absolute',
              bottom: '0',
              left: `${(index / array.length) * 100}%`,
              width: `${100 / array.length}%`,
              borderBottom: `4px solid ${day.colorCodeHex}`
            }}
          />
        ))}
    </button>
  )
}

class Calendar extends React.Component {
  state = {
    calendar: undefined
  }

  componentDidMount() {
    this.setState({ calendar: createCalendar(this.props.date) })
  }

  componentDidUpdate(prevProps) {
    if (this.props.date !== prevProps.date) {
      this.setState({ calendar: createCalendar(this.props.date) })
    }
  }

  render() {
    if (!this.state.calendar) {
      return null
    }

    return (
      <div className="month">
        <div className="month-name">{this.state.calendar.currentDate.format('MMMM').toUpperCase()}</div>
        {['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((day, index) => (
          <span key={index} className="day">
            {day}
          </span>
        ))}
        {this.state.calendar.map((week, index) => (
          <div key={index}>
            {week.map(date => (
              <CalendarDate
                key={date.date()}
                dateToRender={date}
                dateOfMonth={this.state.calendar.currentDate}
                collectiveDays={this.props.onCollectiveDays}
                personalDays={this.props.onPersonalDays}
                isPersonal={this.props.onIsPersonal}
                onClick={date => this.props.onDayClicked(date)}
              />
            ))}
          </div>
        ))}
      </div>
    )
  }
}

class Year extends React.Component {
  render() {
    let { date, ...props } = this.props
    let range = Year.range(date)
    const months = []
    const firstMonth = dates.startOf(date, 'year')

    for (let i = 0; i < 12; i++) {
      months.push(
        <Calendar
          key={i + 1}
          date={dates.add(firstMonth, i, 'month')}
          onDayClicked={Year.handleClick}
          onCollectiveDays={Year.collectiveDays}
          onIsPersonal={Year.isPersonal}
          onPersonalDays={Year.personalDays}
        />
      )
    }

    return <div className="year">{months.map(month => month)}</div>
  }
}

Year.handleClick = undefined
Year.collectiveDays = undefined
Year.isPersonal = undefined
Year.personalDays = undefined

Year.range = date => {
  return [dates.startOf(date, 'year')]
}

Year.navigate = (date, action) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return dates.add(date, -1, 'year')

    case Navigate.NEXT:
      return dates.add(date, 1, 'year')

    default:
      return date
  }
}

Year.title = (date, { localizer }) => localizer.format(date, 'yearHeaderFormat')

export default Year

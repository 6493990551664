import {
  GraphqlError,
  useCreateEmployerMutation,
  useGetEmployerByIdQuery,
  useGetFeaturesQuery,
  useGetTenantFeaturesQuery,
  useUpdateEmployerMutation
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography, Box, Grid } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { boolean, object, string, TypeOf } from 'zod'
import {
  useFlyIn,
  FormContainer,
  FormActionButtons,
  FormErrorList,
  DetailPageBaseQueryParams,
  FormGridLayout,
  FormInput,
  FeatureForPurchaseIndicator
} from '@epix-web-apps/ui'
import { useForm, useWatch } from 'react-hook-form'
import { FormSwitch } from '../../form-components/form-switch'
import { useEffect, useState } from 'react'

/* eslint-disable-next-line */
export interface AddEditEmployerProps {
  employerId?: string
  create?: boolean
}

export function AddEditEmployer({ employerId, create = false }: AddEditEmployerProps) {
  const { t } = useTranslation()
  const { data: tenantFeaturesData } = useGetTenantFeaturesQuery()
  const { data: getFeatureFlags } = useGetFeaturesQuery()
  const {
    calculatedByEpix,
    hasCalendarManagement,
    hasCompanyCars,
    hasDocumentManagement,
    hasPayComponentManagement,
    hasSelfService,
    hasSmartPayComponents
  } = tenantFeaturesData?.tenantFeatures ?? {}

  const addEditEmployerSchema = object({
    companyName: string({
      required_error: t('form.validation.companynamerequired'),
      invalid_type_error: t('form.validation.companynamerequired')
    }).min(1, t('form.validation.companynamerequired')),
    number: string({
      required_error: t('form.validation.numberrequired'),
      invalid_type_error: t('form.validation.numberrequired')
    }).min(1, t('form.validation.numberrequired')),
    companyRegistrationNumber: string().nullable(),
    nationalSocialSecurityNumber: string().nullable(),
    providerPayrollContactEmail: string().nullable(),
    calculatedByEpix: boolean(),
    hasPayComponentManagement: boolean(),
    hasCalendarManagement: boolean(),
    hasDocumentManagement: boolean(),
    hasSelfService: boolean(),
    hasCompanyCars: boolean(),
    hasSmartPayComponents: boolean(),
    active: boolean(),
    useContactEmailForPayrollClosure: boolean(),
    useContactEmailForPayrollCodeChange: boolean()
  })
  type CreateEditEmployerForm = TypeOf<typeof addEditEmployerSchema>
  const { closeFlyIn } = useFlyIn()
  const createmutation = useCreateEmployerMutation()
  const updatemutation = useUpdateEmployerMutation()
  const params = useParams<DetailPageBaseQueryParams>()

  const { data: getEmployerById, refetch: refetchEmployerById } = useGetEmployerByIdQuery(
    {
      employerId: employerId ?? ''
    },
    {
      enabled: !create
    }
  )

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const form = useForm<CreateEditEmployerForm>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      companyName: getEmployerById?.employerById.companyName,
      number: getEmployerById?.employerById.number,
      companyRegistrationNumber: getEmployerById?.employerById.companyRegistrationNumber,
      nationalSocialSecurityNumber: getEmployerById?.employerById.nationalSocialSecurityNumber,
      providerPayrollContactEmail: getEmployerById?.employerById.providerPayrollContactEmail,
      useContactEmailForPayrollClosure: getEmployerById?.employerById.useContactEmailForPayrollClosure ?? true,
      useContactEmailForPayrollCodeChange: getEmployerById?.employerById.useContactEmailForPayrollCodeChange ?? true,
      calculatedByEpix: getEmployerById?.employerById.calculatedByEpix ?? false,
      hasCalendarManagement: getEmployerById?.employerById.hasCalendarManagement ?? false,
      hasDocumentManagement: getEmployerById?.employerById.hasDocumentManagement ?? false,
      hasPayComponentManagement: getEmployerById?.employerById.hasPayComponentManagement ?? false,
      active: getEmployerById?.employerById.active ?? true,
      hasSelfService: getEmployerById?.employerById.hasSelfService ?? false,
      hasCompanyCars: getEmployerById?.employerById.hasCompanyCars ?? false,
      hasSmartPayComponents: getEmployerById?.employerById.hasSmartPayComponents ?? false
    },
    resolver: zodResolver(addEditEmployerSchema)
  })

  // to keep a checkbox selected when disabled, we need to set the values ourselves. If we don't
  // do this, the values stay undefined as this is the default behavior of a form.
  useEffect(() => {
    form.setValue('calculatedByEpix', getEmployerById?.employerById.calculatedByEpix ?? false)
    form.setValue('hasCalendarManagement', getEmployerById?.employerById.hasCalendarManagement ?? false)
    form.setValue('hasDocumentManagement', getEmployerById?.employerById.hasDocumentManagement ?? false)
    form.setValue('hasPayComponentManagement', getEmployerById?.employerById.hasPayComponentManagement ?? false)
    form.setValue('hasSelfService', getEmployerById?.employerById.hasSelfService ?? false)
    form.setValue('hasCompanyCars', getEmployerById?.employerById.hasCompanyCars ?? false)
    form.setValue('hasSmartPayComponents', getEmployerById?.employerById.hasSmartPayComponents ?? false)

    // default to true if no contact email is filled in
    if (!getEmployerById?.employerById.providerPayrollContactEmail) {
      form.setValue('useContactEmailForPayrollClosure', true)
      form.setValue('useContactEmailForPayrollCodeChange', true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { control } = form

  const handleOnSubmit = async (newEmployer: CreateEditEmployerForm) => {
    if (create) {
      await createmutation
        .mutateAsync({
          createEmployerCommand: {
            icpId: params.id || '',
            companyName: newEmployer.companyName,
            number: newEmployer.number,
            companyRegistrationNumber: newEmployer.companyRegistrationNumber,
            nationalSocialSecurityNumber: newEmployer.nationalSocialSecurityNumber,
            providerPayrollContactEmail: newEmployer.providerPayrollContactEmail,
            useContactEmailForPayrollClosure: newEmployer.useContactEmailForPayrollClosure,
            useContactEmailForPayrollCodeChange: newEmployer.useContactEmailForPayrollCodeChange,
            calculatedByEpix: newEmployer.calculatedByEpix,
            hasPayComponentManagement: newEmployer.hasPayComponentManagement,
            hasCalendarManagement: newEmployer.hasCalendarManagement,
            hasDocumentManagement: newEmployer.hasDocumentManagement,
            hasSelfService: newEmployer.hasSelfService,
            hasCompanyCars: newEmployer.hasCompanyCars,
            hasSmartPayComponents: newEmployer.hasSmartPayComponents,
            active: newEmployer.active
          }
        })
        .then(closeFlyIn)
        .catch(e => setBackendErrors([e]))
    } else {
      await updatemutation
        .mutateAsync({
          updateEmployerCommand: {
            id: employerId ?? '',
            companyName: newEmployer.companyName,
            number: newEmployer.number,
            companyRegistrationNumber: newEmployer.companyRegistrationNumber,
            nationalSocialSecurityNumber: newEmployer.nationalSocialSecurityNumber,
            providerPayrollContactEmail: newEmployer.providerPayrollContactEmail,
            useContactEmailForPayrollClosure: newEmployer.useContactEmailForPayrollClosure,
            useContactEmailForPayrollCodeChange: newEmployer.useContactEmailForPayrollCodeChange,
            calculatedByEpix: newEmployer.calculatedByEpix,
            hasPayComponentManagement: newEmployer.hasPayComponentManagement,
            hasCalendarManagement: newEmployer.hasCalendarManagement,
            hasDocumentManagement: newEmployer.hasDocumentManagement,
            hasSelfService: newEmployer.hasSelfService,
            hasCompanyCars: newEmployer.hasCompanyCars,
            hasSmartPayComponents: newEmployer.hasSmartPayComponents,
            active: newEmployer.active
          }
        })
        .then(() => {
          refetchEmployerById()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  const watchCalendarManagement = useWatch({
    control,
    name: 'hasCalendarManagement'
  })

  const watchPaycomponentManagement = useWatch({
    control,
    name: 'hasPayComponentManagement'
  })

  const contactEmailWatch = useWatch({
    control,
    name: 'providerPayrollContactEmail'
  })

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addemployer.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="companyName" label={`${t('form.field.employer')} *`} />

        <FormInput sx={12} name="number" label={`${t('form.field.employernumber')} *`} />

        <FormInput sx={12} name="companyRegistrationNumber" label={`${t('form.field.companyregistrationnumber')}`} />

        <FormInput sx={12} name="nationalSocialSecurityNumber" label={`${t('form.field.socialsecuritynumber')}`} />

        <Grid item xs={12}>
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item>
              <FormSwitch
                name="calculatedByEpix"
                disabled={!calculatedByEpix}
                onChange={e => {
                  if (e) {
                    form.resetField('hasPayComponentManagement', {
                      defaultValue: true
                    })
                    form.resetField('hasCalendarManagement', { defaultValue: true })
                    form.resetField('hasSelfService', { defaultValue: true })
                  }
                }}
                label={t('form.field.payrollviaepix')}
              />
            </Grid>
            <Grid item sx={{ paddingLeft: '5px' }}>
              {!calculatedByEpix && <FeatureForPurchaseIndicator />}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item>
              <FormSwitch
                name="hasPayComponentManagement"
                label={t('form.field.paycomponentmanagement')}
                disabled={!hasPayComponentManagement}
                onChange={e => {
                  if (!e) {
                    form.resetField('hasSmartPayComponents', { defaultValue: false })
                  }
                }}
              />{' '}
            </Grid>
            <Grid item sx={{ paddingLeft: '5px' }}>
              {!hasPayComponentManagement && <FeatureForPurchaseIndicator />}
            </Grid>
          </Grid>
        </Grid>

        {getFeatureFlags?.features.smartPayComponents && (
          <Box ml={8}>
            <Grid item xs={12}>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item>
                  <FormSwitch
                    name="hasSmartPayComponents"
                    label={t('form.field.hassmartpaycomponents')}
                    disabled={!hasSmartPayComponents || !watchPaycomponentManagement}
                  />
                </Grid>
                <Grid item sx={{ paddingLeft: '5px' }}>
                  {!hasSmartPayComponents && <FeatureForPurchaseIndicator />}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}

        <Grid item xs={12}>
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item>
              <FormSwitch
                sx={12}
                name="hasCalendarManagement"
                disabled={!hasCalendarManagement}
                onChange={e => {
                  if (!e) {
                    form.resetField('hasSelfService', { defaultValue: false })
                  }
                }}
                label={t('form.field.calendarmanagement')}
              />
            </Grid>
            <Grid item sx={{ paddingLeft: '5px' }}>
              {!hasCalendarManagement && <FeatureForPurchaseIndicator />}
            </Grid>
          </Grid>
        </Grid>

        <Box ml={8}>
          <Grid item xs={12}>
            <Grid container sx={{ alignItems: 'center' }}>
              <Grid item>
                <FormSwitch
                  disabled={!hasSelfService || !watchCalendarManagement}
                  name="hasSelfService"
                  label={t('form.field.selfservice.hasselfservice')}
                />
              </Grid>
              <Grid item sx={{ paddingLeft: '5px' }}>
                {!hasSelfService && <FeatureForPurchaseIndicator />}
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Grid item xs={12}>
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item>
              <FormSwitch name="hasCompanyCars" label={t('form.field.hascompanycars')} disabled={!hasCompanyCars} />
            </Grid>
            <Grid item sx={{ paddingLeft: '5px' }}>
              {!hasCompanyCars && <FeatureForPurchaseIndicator />}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item>
              <FormSwitch
                name="hasDocumentManagement"
                label={t('form.field.hasdocumentmanagement')}
                disabled={!hasDocumentManagement}
              />
            </Grid>
            <Grid item sx={{ paddingLeft: '5px' }}>
              {!hasDocumentManagement && <FeatureForPurchaseIndicator />}
            </Grid>
          </Grid>
        </Grid>

        <FormSwitch sx={12} name="active" label={t('form.field.active')} />

        <FormInput
          sx={12}
          name="providerPayrollContactEmail"
          label={`${t('form.field.providerpayrollcontactemail')}`}
        />

        <Grid item xs={12}>
          <Grid container sx={{ alignItems: 'baseline' }}>
            <Grid item xs={4}>
              {t('flyin.addemployer.useincaseof')}:
            </Grid>
            <Grid item xs={8}>
              <FormSwitch
                sx={12}
                disabled={!contactEmailWatch}
                name="useContactEmailForPayrollClosure"
                label={t('form.field.payrollclosure')}
              />
              <FormSwitch
                sx={12}
                disabled={!contactEmailWatch}
                name="useContactEmailForPayrollCodeChange"
                label={t('form.field.payrollcodechange')}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={12} item>
          <Typography sx={{ fontStyle: 'italic', fontWeight: 'bold' }}>
            <Trans
              i18nKey="form.field.activeemployerdescription"
              values={{
                settingscode: getEmployerById?.employerById.settingsCode
              }}
              components={{ bold: <span className="bold" /> }}
            />
          </Typography>
        </Grid>
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={createmutation.isLoading || updatemutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditEmployer

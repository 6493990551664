import { useTranslation } from 'react-i18next'
import { HeaderTitleNavigation, SelfService } from '@epix-web-apps/ui'
import { Typography, Grid, useTheme, Box } from '@mui/material'
import {
  GraphqlError,
  ToRouteDateFormat,
  useApproveAbsenceRequestMutation,
  useDeclineAbsenceRequestMutation,
  useGetAbsenceRequestByIdQuery
} from '@epix-web-apps/core'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import { FormActionButtons } from '@epix-web-apps/ui'
import { FormErrorList } from '@epix-web-apps/ui'
import { DetailPageBaseQueryParams } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface AbsenceRequestsDetailProps {}

export function AbsenceRequestsDetail(props: AbsenceRequestsDetailProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useParams<DetailPageBaseQueryParams>()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const dateSearchParam = searchParams.get('date')

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const { data: absenceRequestById } = useGetAbsenceRequestByIdQuery({
    id: params.id || ''
  })

  const createMutation = useApproveAbsenceRequestMutation()
  const deleteMutation = useDeclineAbsenceRequestMutation()

  const handleApprovePendingRequest = async () => {
    await createMutation
      .mutateAsync({
        approveAbsenceRequestCommand: {
          absenceRequestId: params.id || ''
        }
      })
      .then(() => navigate(-1))
      .catch(e => setBackendErrors([e]))
  }

  const handleDeclinePendingRequest = async () => {
    await deleteMutation
      .mutateAsync({
        declineAbsenceRequestCommand: {
          absenceRequestId: params.id || ''
        }
      })
      .then(() => navigate(-1))
      .catch(e => setBackendErrors([e]))
  }

  return (
    <>
      <HeaderTitleNavigation
        mobileSizeTitle
        backToLink={SelfService.SELFSERVICE_DATE(
          `${ToRouteDateFormat(dateSearchParam ? new Date(dateSearchParam) : new Date())}`
        )}
        title={t('selfservice.pendingrequests.title')}
      />
      <Grid mt={1} mb={3} container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
            {absenceRequestById?.absenceRequestById.requestPolicyContract.personFirstName}{' '}
            {absenceRequestById?.absenceRequestById.requestPolicyContract.personPreferredLastName}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {t('selfservice.absencerequestdetail.from')}
        </Grid>
        <Grid item xs={6}>
          {absenceRequestById?.absenceRequestById.startDate}
        </Grid>
        <Grid item xs={6}>
          {t('selfservice.absencerequestdetail.until')}
        </Grid>
        <Grid item xs={6}>
          {absenceRequestById?.absenceRequestById.endDate}
        </Grid>
        <Grid item xs={6}>
          {t('selfservice.absencerequestdetail.absencetype')}
        </Grid>
        <Grid item xs={6}>
          {absenceRequestById?.absenceRequestById.requestPolicyContract.requestPolicyUserFriendlyName}
        </Grid>
        <Grid item xs={6}>
          {t('selfservice.absencerequestdetail.timeframetype')}
        </Grid>
        <Grid item xs={6}>
          {absenceRequestById?.absenceRequestById.timeFrameType?.value}
        </Grid>
        {absenceRequestById?.absenceRequestById.comment && (
          <>
            <Grid item xs={6}>
              {t('selfservice.absencerequestdetail.comment')}
            </Grid>
            <Grid item xs={6}>
              {absenceRequestById?.absenceRequestById.comment}
            </Grid>
          </>
        )}
      </Grid>
      <FormErrorList customErrors={backendErrors} />
      <Box
        sx={{
          display: 'inline-flex'
        }}
      >
        <FormActionButtons
          onClick={handleApprovePendingRequest}
          isMutating={createMutation.isLoading}
          buttonText={t('selfservice.absencerequestdetail.approve')}
        />
        <Box
          sx={{
            width: '20px'
          }}
        />
        <FormActionButtons
          onClick={handleDeclinePendingRequest}
          isMutating={deleteMutation.isLoading}
          buttonText={t('selfservice.absencerequestdetail.decline')}
          buttonColor="error"
        />
      </Box>
    </>
  )
}

export default AbsenceRequestsDetail

import styled from '@emotion/styled'
import { CompanionSearchResponse } from '@epix-web-apps/core'
import { Box, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export interface AiCompanionResultsProps {
  response: CompanionSearchResponse | undefined
}

const ResultLink = styled(Link)<any>(() => {
  return {
    color: '#00c291'
  }
})

export function AiCompanionResults({ response }: AiCompanionResultsProps) {
  const { t } = useTranslation()

  return (
    <Box>
      {response && (
        <>
          <Typography sx={{ mt: 3.5 }} variant="h6">
            {t('aicompanionpage.result.info')}
          </Typography>
          <Typography>{response.answer}</Typography>
          <Typography sx={{ mt: 3.5 }} variant="h6">
            {t('aicompanionpage.result.sites')}
          </Typography>
          {response.results.map((result, index) => (
            <Box display={'flex'} key={'link-' + index}>
              <ResultLink href={result.url} target="_blank" rel="noopener">
                [{index + 1}] {result.url}{' '}
              </ResultLink>
            </Box>
          ))}
        </>
      )}
    </Box>
  )
}

export default AiCompanionResults

import {
  OrderDirection,
  useGetAllPayComponentsQuery,
  useGetMeQuery,
  CALCULATED_DATA_VALUE_TYPE_KEYS,
  DateRange,
  VALUE_TYPE_KEYS,
  prevPeriodBasedOnPayGroup,
  nextPeriodBasedOnPayGroup,
  PayComponentModel,
  ContractModel,
  ToBackendFormatedDate
} from '@epix-web-apps/core'
import { GridColDef, GridColumnVisibilityModel, GridRowsProp } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, SortModel } from '../../data-table'
import { Box, Typography, Button, useTheme, IconButton, MenuItem, CircularProgress } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { GetFirstAndLastDayOfPeriod, PeriodNavigationDatePicker } from '../period-navigation-date-picker'
import { useFlyIn } from '@epix-web-apps/ui'
import { AddEditPaycomponent } from '../add-edit-paycomponent'
import { RemovePaycomponent } from '../remove-paycomponent'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import DropdownMenu from '../../dropdown-menu'
import { ViewPaycomponentHistory } from '../view-paycomponent-history'
import { OnboardingPaycomponentLayout } from '../../onboarding-components/onboarding-paycomponent-layout'
import { lastDayOfMonth } from 'date-fns'

/* eslint-disable-next-line */
interface ContractsSalaryTabProps {
  contract: ContractModel
}

type TableRow = {
  id: string
  payComponentId: string
  payrollCode: string
  description: string
  comment: string | null | undefined
  onlyShowCommentInFirstPeriod: boolean
  value: string
  valueType: string
  convertedValue: string
  startDate: string
  endDate: string | null
}

export function ContractsSalaryTab({ contract }: ContractsSalaryTabProps) {
  const [period, setPeriod] = useState<DateRange>(contractInitialPeriod(contract))
  const { t } = useTranslation()
  const theme = useTheme()
  const { data: me } = useGetMeQuery()
  const { openFlyIn } = useFlyIn()

  const [tableDataEarnings, setTableDataEarnings] = useState<GridRowsProp<TableRow>>([])
  const [tableDataDeductions, setTableDataDeductions] = useState<GridRowsProp<TableRow>>([])

  const {
    data: earnings,
    isLoading: isLoadingEarnings,
    refetch: refetchEarnings
  } = useGetAllPayComponentsQuery(
    {
      contractId: contract.id,
      startDate: period.startDate,
      endDate: period.endDate,
      isDeduction: false
    },
    { suspense: false }
  )
  const {
    data: deductions,
    isLoading: isLoadingDeductions,
    refetch: refetchDeductions
  } = useGetAllPayComponentsQuery(
    {
      contractId: contract.id,
      startDate: period.startDate,
      endDate: period.endDate,
      isDeduction: true
    },
    { suspense: false }
  )

  useEffect(() => {
    const period = contractInitialPeriod(contract)
    setPeriod(period)
  }, [contract])

  useEffect(() => {
    refetchEarnings()
    refetchDeductions()
  }, [me?.me.currency])

  useEffect(() => {
    if (earnings && earnings?.payComponents) {
      const payComponentsData = earnings.payComponents
      setTableDataEarnings(
        payComponentsData.map(row => {
          return {
            id: row.payComponentHistory.current?.id ?? '',
            payComponentId: row.payComponentId,
            payrollCode: row.payComponentHistory.current?.payrollCode ?? '',
            description: row.payComponentHistory.current?.description ?? '',
            comment: row.payComponentHistory.current?.comment,
            onlyShowCommentInFirstPeriod: row.payComponentHistory.current?.onlyShowCommentInFirstPeriod ?? true,
            value: row.payComponentHistory.current?.value,
            valueType: row.payComponentHistory.current?.valueType.key ?? '',
            convertedValue: row.payComponentHistory.current?.valueRate,
            startDate: row.payComponentHistory.current?.startDate,
            endDate: row.payComponentHistory.current?.endDate ? row.payComponentHistory.current?.endDate : ''
          }
        })
      )
      if (earnings?.payComponents.length > 0) {
        if (earnings?.payComponents[0].icpCurrency === me?.me.currency) {
          setColumnVisibilityModel({ convertedValue: false })
        } else {
          setColumnVisibilityModel({ convertedValue: true })
        }
      }
    }
    if (deductions && deductions?.payComponents) {
      const payComponentsData = deductions.payComponents
      setTableDataDeductions(
        payComponentsData.map(row => {
          return {
            id: row.payComponentHistory.current?.id ?? '',
            payComponentId: row.payComponentId,
            payrollCode: row.payComponentHistory.current?.payrollCode ?? '',
            description: row.payComponentHistory.current?.description ?? '',
            comment: row.payComponentHistory.current?.comment,
            onlyShowCommentInFirstPeriod: row.payComponentHistory.current?.onlyShowCommentInFirstPeriod ?? true,
            value: row.payComponentHistory.current?.value,
            valueType: row.payComponentHistory.current?.valueType.key ?? '',
            convertedValue: row.payComponentHistory.current?.valueRate,
            startDate: row.payComponentHistory.current?.startDate,
            endDate: row.payComponentHistory.current?.endDate ? row.payComponentHistory.current?.endDate : ''
          }
        })
      )
    }
  }, [deductions, earnings, me?.me])

  const [clickedPayComponentHistoryId, setClickedPayComponentHistoryId] = useState<string>()
  const [clickedPayComponentId, setClickedPayComponentId] = useState<string>()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  function descriptionColumn(row: TableRow) {
    const shouldShowDescription = row.onlyShowCommentInFirstPeriod
      ? row.startDate === ToBackendFormatedDate(period.startDate)
      : true
    return (
      <Box>
        <Typography>{row.description}</Typography>
        {shouldShowDescription && row.comment && <Typography variant="description">{row.comment}</Typography>}
      </Box>
    )
  }

  function valueColumn(row: TableRow) {
    switch (row.valueType) {
      case CALCULATED_DATA_VALUE_TYPE_KEYS.PERCENTAGE:
        return (
          <p>
            {row.value} {'%'}
          </p>
        )
      case CALCULATED_DATA_VALUE_TYPE_KEYS.HOURS:
        return (
          <p>
            {row.value} {t('common.hours')}
          </p>
        )
      case CALCULATED_DATA_VALUE_TYPE_KEYS.AMOUNT:
        return (
          <p>
            {row.value} {earnings?.payComponents[0]?.icpCurrencySymbol}
          </p>
        )
      default:
        return <p>{row.value}</p>
    }
  }

  function convertedValueColumn(row: TableRow) {
    return <p>{row.valueType === VALUE_TYPE_KEYS.AMOUNT && `${row.convertedValue} ${me?.me.currencySymbol}`}</p>
  }

  function periodColumn(row: TableRow) {
    return (
      <p>
        {new Date(row.startDate).toLocaleDateString()} -{' '}
        {row.endDate ? new Date(row.endDate).toLocaleDateString() : '...'}
      </p>
    )
  }

  const rowActions = (row: TableRow) => (
    <IconButton
      aria-label="row actions"
      aria-controls="menu-row"
      aria-haspopup="true"
      onClick={e => {
        setClickedPayComponentHistoryId(row.id)
        setClickedPayComponentId(row.payComponentId)
        setAnchorEl(e.currentTarget)
      }}
    >
      <MoreHorizIcon />
    </IconButton>
  )

  const columns: GridColDef[] = [
    {
      field: 'payrollCode',
      headerName: t('contractsalarypage.datatable.column.payrollcode'),
      flex: 0,
      sortable: false,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'description',
      headerName: t('contractsalarypage.datatable.column.description'),
      renderCell: params => descriptionColumn(params.row),
      flex: 2,
      sortable: false
    },
    {
      field: 'value',
      headerName: t('contractsalarypage.datatable.column.value'),
      flex: 1,
      renderCell: params => valueColumn(params.row),
      sortable: false,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'convertedValue',
      headerName: me?.me.currencyName,
      flex: 1,
      sortable: false,
      renderCell: params => convertedValueColumn(params.row),
      hideable: true,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'startDate',
      headerName: t('contractsalarypage.datatable.column.period'),
      flex: 2,
      renderCell: params => periodColumn(params.row),
      sortable: false
    },
    { field: '', sortable: false, editable: false, renderCell: params => rowActions(params.row) }
  ]

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    convertedValue: true,
    validFrom: true
  })

  const hasDeductions = (deductions && deductions.payComponents.length > 0) ?? false
  const hasEarnings = (earnings && earnings.payComponents.length > 0) ?? false

  function NewFilterBar() {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        {/* empty element for flexbox to calculate spacing correctly */}
        <Box></Box>
        <PeriodNavigationDatePicker
          period={period}
          payGroup={contract.payGroup!}
          onChange={date => {
            setPeriod({ startDate: date, endDate: lastDayOfMonth(date) })
          }}
          onPrevious={() => {
            setPeriod(prevPeriodBasedOnPayGroup(period, contract.payGroup!))
          }}
          onNext={() => {
            setPeriod(nextPeriodBasedOnPayGroup(period, contract.payGroup!))
          }}
        />
        <Button
          variant="contained"
          onClick={() =>
            openFlyIn({
              content: (
                <AddEditPaycomponent contractId={contract.id} employerId={contract.employerId} period={period} />
              ),
              callbackAfterClose: () => () => {
                refetchDeductions()
                refetchEarnings()
              }
            })
          }
        >
          <AddIcon />
          {t('salarypage.button.additem')}
        </Button>
      </Box>
    )
  }

  return (
    <>
      <NewFilterBar />

      {isLoadingEarnings && isLoadingDeductions && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}

      {!hasEarnings && !hasDeductions && !isLoadingEarnings && !isLoadingDeductions && <OnboardingPaycomponentLayout />}

      {hasEarnings && (
        <DataTable
          autoHeight
          data={tableDataEarnings}
          columns={columns}
          isLoading={isLoadingEarnings}
          defaultSortModel={new SortModel(columns[0]?.field, OrderDirection.Asc)}
          filterBarElement={
            <Typography
              sx={{
                textAlign: 'left',
                color: theme.palette.success.dark,
                my: 0
              }}
              variant="h4"
            >
              {t('contractsalarypage.earnings')}
            </Typography>
          }
          hideFooter={true}
          columnsToHide={columnVisibilityModel}
        />
      )}
      {hasDeductions && (
        <DataTable
          autoHeight
          data={tableDataDeductions}
          columns={columns}
          isLoading={isLoadingDeductions}
          defaultSortModel={new SortModel(columns[0]?.field, OrderDirection.Asc)}
          filterBarElement={
            <Typography
              sx={{
                textAlign: 'left',
                color: theme.palette.error.dark,
                my: 0
              }}
              variant="h4"
            >
              {t('contractsalarypage.deductions')}
            </Typography>
          }
          hideFooter={true}
          columnsToHide={columnVisibilityModel}
        />
      )}
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <AddEditPaycomponent
                  contractId={contract.id}
                  employerId={contract.employerId}
                  period={period}
                  payComponentId={clickedPayComponentId}
                  payComponentHistoryId={clickedPayComponentHistoryId}
                  editRecord={true}
                  lastHistoryEndDate={
                    earnings?.payComponents.find(p => p.payComponentId === clickedPayComponentId)?.lastHistoryEndDate ||
                    deductions?.payComponents.find(p => p.payComponentId === clickedPayComponentId)?.lastHistoryEndDate
                  }
                />
              ),
              callbackAfterClose: () => () => {
                refetchDeductions()
                refetchEarnings()
              }
            })
          }
        >
          {t('contractsalarypage.list.row.menu.editpaycomponent')}
        </MenuItem>

        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <AddEditPaycomponent
                  contractId={contract.id}
                  employerId={contract.employerId}
                  period={period}
                  payComponentId={clickedPayComponentId}
                  payComponentHistoryId={clickedPayComponentHistoryId}
                  addRecord={true}
                />
              ),
              callbackAfterClose: () => () => {
                refetchDeductions()
                refetchEarnings()
              }
            })
          }
        >
          {t('contractsalarypage.list.row.menu.addpaycomponent')}
        </MenuItem>

        {(earnings?.payComponents.find(p => p.payComponentId === clickedPayComponentId)?.lastHistoryEndDate ||
          deductions?.payComponents.find(p => p.payComponentId === clickedPayComponentId)?.lastHistoryEndDate) && (
          <MenuItem
            onClick={() =>
              openFlyIn({
                content: (
                  <AddEditPaycomponent
                    contractId={contract.id}
                    employerId={contract.employerId}
                    period={period}
                    payComponentId={clickedPayComponentId}
                    payComponentHistoryId={clickedPayComponentHistoryId}
                    addRecord={true}
                    addRecordAfterClosedPayComponent={true}
                    lastHistoryEndDate={
                      earnings?.payComponents.find(p => p.payComponentId === clickedPayComponentId)
                        ?.lastHistoryEndDate ||
                      deductions?.payComponents.find(p => p.payComponentId === clickedPayComponentId)
                        ?.lastHistoryEndDate
                    }
                  />
                ),
                callbackAfterClose: () => () => {
                  refetchDeductions()
                  refetchEarnings()
                }
              })
            }
          >
            {t('contractsalarypage.list.row.menu.addpaycomponentafterclosedpaycomponent')}
          </MenuItem>
        )}

        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <RemovePaycomponent
                  payComponentId={clickedPayComponentId || ''}
                  payComponentHistoryId={clickedPayComponentHistoryId || ''}
                  startDate={period.startDate}
                />
              ),
              callbackAfterClose: () => () => {
                refetchDeductions()
                refetchEarnings()
              }
            })
          }
        >
          {t('contractsalarypage.list.row.menu.removepaycomponent')}
        </MenuItem>

        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <ViewPaycomponentHistory
                  payComponentId={clickedPayComponentId || ''}
                  contractId={contract.id || ''}
                  endDate={period.endDate || new Date()}
                  icpCurrencySymbol={
                    earnings?.payComponents[0].icpCurrencySymbol || deductions?.payComponents[0].icpCurrencySymbol || ''
                  }
                />
              ),
              callbackAfterClose: () => () => {
                refetchDeductions()
                refetchEarnings()
              }
            })
          }
        >
          {t('contractsalarypage.list.row.menu.viewhistory')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default ContractsSalaryTab

function contractInitialPeriod(contract: ContractModel) {
  const today = new Date()
  const numberOfDaysInContractMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()

  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), numberOfDaysInContractMonth)

  if (contract.currentPayPeriod) {
    return {
      startDate: GetFirstAndLastDayOfPeriod(contract.payGroup!, contract.currentPayPeriod).startDate,
      endDate: GetFirstAndLastDayOfPeriod(contract.payGroup!, contract.currentPayPeriod).endDate
    }
  }

  if (
    (contract.endDate === null && new Date(contract.startDate) <= today) ||
    (new Date(contract.endDate) <= lastDayOfMonth && contract.endDate >= firstDayOfMonth)
  ) {
    return {
      startDate: GetFirstAndLastDayOfPeriod(contract.payGroup!, firstDayOfMonth).startDate,
      endDate: GetFirstAndLastDayOfPeriod(contract.payGroup!, firstDayOfMonth).endDate
    }
  }

  if (new Date(contract.startDate) >= today) {
    return {
      startDate: GetFirstAndLastDayOfPeriod(contract.payGroup!, contract.startDate).startDate,
      endDate: GetFirstAndLastDayOfPeriod(contract.payGroup!, contract.startDate).endDate
    }
  }

  return {
    startDate: GetFirstAndLastDayOfPeriod(contract.payGroup!, contract.endDate).startDate,
    endDate: GetFirstAndLastDayOfPeriod(contract.payGroup!, contract.endDate).endDate
  }
}

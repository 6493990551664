import styled from '@emotion/styled'
import { Grid, Typography, Box, useTheme } from '@mui/material'

/* eslint-disable-next-line */
export interface GeneralStatisticsProps {
  image: any
  name: string
  value?: string | number
}

const StyledButton = styled(Box)<any>(() => {
  const theme = useTheme()
  return {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    svg: {
      marginRight: 10,
      color: theme.palette.primary.main,
      height: '2.5rem',
      width: '2.5rem'
    },
    h4: {
      marginBottom: '0',
      color: theme.palette.text.primary
    }
  }
})

export function GeneralStatistics({ image, name, value }: GeneralStatisticsProps) {
  const theme = useTheme()
  return (
    <Grid textAlign="center" item xs={12} md={6} lg={3}>
      <StyledButton>
        {image}
        <Typography variant="h4">{value}</Typography>
      </StyledButton>
      <Typography color={theme.palette.text.primary} variant="h6">
        {name}
      </Typography>
    </Grid>
  )
}

export default GeneralStatistics

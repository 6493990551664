import {
  useGetContractByIdQuery,
  useGetPersonByIdQuery,
  useUpdateContractPaymentInformationMutation
} from '@epix-web-apps/core'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { object, string, TypeOf } from 'zod'
import { FormGridLayout } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Typography } from '@mui/material'
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'
import { FormContainer } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface EditContractPaymentInformationProps {}

export function EditContractPaymentInformation(props: EditContractPaymentInformationProps) {
  const { t } = useTranslation()
  const editContractPaymentInfoSchema = object({
    bankAccount: string().nullable(),
    accountHolder: string().nullable(),
    iban: string().nullable(),
    bankIdentificationNumber: string().nullable(),
    bankSortCode: string().nullable()
  })
  type EditContractPaymentInfoForm = TypeOf<typeof editContractPaymentInfoSchema>
  const params = useParams<ContractDetailPageParams>()
  const { closeFlyIn } = useFlyIn()
  const { data: getPersonById } = useGetPersonByIdQuery(
    {
      personId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )
  const person = getPersonById?.personById
  const { data: getContractById, refetch: refetchContractById } = useGetContractByIdQuery(
    {
      contractId: params?.contractid || ''
    },
    {
      enabled: !!params?.contractid
    }
  )
  const contract = getContractById?.contractById
  const mutation = useUpdateContractPaymentInformationMutation()
  const form = useForm<EditContractPaymentInfoForm>({
    resolver: zodResolver(editContractPaymentInfoSchema),
    defaultValues: {
      accountHolder: contract?.accountHolder,
      bankAccount: contract?.bankAccount,
      iban: contract?.iban,
      bankIdentificationNumber: contract?.bic,
      bankSortCode: contract?.bankSortCode
    }
  })

  const handleOnSubmit = async (contractPaymentInfo: EditContractPaymentInfoForm) => {
    await mutation
      .mutateAsync({
        updateContractPaymentInformationCommand: {
          contractId: params.contractid || '',
          accountHolder: contractPaymentInfo.accountHolder,
          bankAccount: contractPaymentInfo.bankAccount,
          iban: contractPaymentInfo.iban,
          bic: contractPaymentInfo.bankIdentificationNumber,
          bankSortCode: contractPaymentInfo.bankSortCode
        }
      })
      .then(() => {
        refetchContractById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editcontract.paymentinfo.title')}</Typography>

      <Box sx={{ marginBottom: '2rem' }}>
        <Typography fontWeight="bold">{t('flyin.editcontract.paymentinfo.onpersonallevel')}</Typography>
        <Typography>{person?.accountHolder}</Typography>
        <Typography>{person?.bankAccount}</Typography>
        <Typography>{person?.iban}</Typography>
        <Typography>{person?.bic}</Typography>
        <Typography>{person?.bankSortCode}</Typography>
      </Box>

      <Typography sx={{ marginBottom: '1rem' }}>
        {t('flyin.editcontract.paymentinfo.overrideoncontractlevel')}
      </Typography>
      <Box sx={{ display: 'flex', gap: 8 }}>
        <p>
          <AccountBalanceOutlinedIcon />
        </p>
        <FormGridLayout>
          <FormInput sx={12} name="accountHolder" label={t('form.field.accountholder')} />

          <FormInput sx={12} name="bankAccount" label={t('form.field.bankaccount')} />

          <FormInput sx={12} name="iban" label={t('form.field.iban')} />

          <FormInput sx={12} name="bankIdentificationNumber" label={t('form.field.bankidentificationnumber')} />

          <FormInput sx={12} name="bankSortCode" label={t('form.field.banksortcode')} />
        </FormGridLayout>
      </Box>

      <FormErrorList />
      <FormActionButtons isMutating={mutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditContractPaymentInformation

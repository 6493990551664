import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { GraphqlError, useDeleteTeamAuthorizationMutation, useGetTeamAuthorizationByIdQuery } from '@epix-web-apps/core'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { useState } from 'react'
import { FormContainer } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface RemoveTeamAuthorizationProps {
  teamAuthorizationId: string | undefined
}

export function RemoveTeamAuthorization({ teamAuthorizationId }: RemoveTeamAuthorizationProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const { data: getTeamAuthorizationById } = useGetTeamAuthorizationByIdQuery({
    id: teamAuthorizationId || ''
  })

  const deleteMutation = useDeleteTeamAuthorizationMutation()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const form = useForm()

  const handleOnSubmit = async () => {
    await deleteMutation
      .mutateAsync({
        deleteTeamAuthorizationCommand: {
          id: teamAuthorizationId || ''
        }
      })
      .then(closeFlyIn)
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.deleteteamauthorization.title')}</Typography>
      <Typography>
        {getTeamAuthorizationById?.teamAuthorizationById.personName} -{' '}
        {getTeamAuthorizationById?.teamAuthorizationById.functionName} -{' '}
        {getTeamAuthorizationById?.teamAuthorizationById.teamName}
      </Typography>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={deleteMutation.isLoading}
        buttonText={t('common.remove')}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default RemoveTeamAuthorization

import { StrictMode, Suspense } from 'react'
import * as ReactDOMClient from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import App from './app/app'
import { environment } from './environments/environment'
import { Box } from '@mui/material'
import { clarity } from 'react-microsoft-clarity'
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser'
import { AUTHORITY_BASE, getPort } from './msal-config'
import { GetMfaEnabledQuery, useGetMfaEnabledQuery } from '@epix-web-apps/core'
import { EpixLoadingPage } from '@epix-web-apps/ui'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorBoundryHandler } from './app/myepix/components/error-boundry-handler'

clarity.init('lgkpi82ojq')
const root = ReactDOMClient.createRoot(document.getElementById('root') as HTMLElement)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      retry: false
    },
    mutations: {
      retry: false
    }
  }
})

const getMsalInstance = async () => {
  const port = getPort()
  const msalInstance = new PublicClientApplication({
    auth: {
      clientId: environment.auth.clientId,
      authority: `${AUTHORITY_BASE}/${environment.auth.policyNameMFA}`,
      knownAuthorities: [environment.auth.b2cDomainName],
      redirectUri: `${window.location.protocol}//${environment.auth.redirectDomain}${port}/${environment.auth.redirectAuthPage}`,
      postLogoutRedirectUri: `${window.location.protocol}//${environment.auth.redirectDomain}${port}/${environment.auth.redirectAuthPage}`
    }
  })

  //update authority in msal config if MFA is not enabled for this tenant
  if (sessionStorage.getItem('tenant')) {

    const mfaEnabled = await queryClient.fetchQuery<GetMfaEnabledQuery>(
      useGetMfaEnabledQuery.getKey(),
      useGetMfaEnabledQuery.fetcher()
    ).catch(e => console.error(e))

    if (!mfaEnabled?.mfaEnabledForTenant) {
      msalInstance.getConfiguration().auth.authority = `${AUTHORITY_BASE}/${environment.auth.policyName}`
    }
  }

  msalInstance.initialize().then(() => {
    const accounts = msalInstance.getAllAccounts()
    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0])
    }
    // get a new access token
    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_FAILURE || event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        msalInstance.loginRedirect()
      }
      const payload = event.payload as AuthenticationResult
      if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && payload) {
        msalInstance.setActiveAccount(payload.account)
      }
    })
  });

  return msalInstance
}

root.render(
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh'
    }}
  >
    <StrictMode>
      <ErrorBoundary FallbackComponent={ErrorBoundryHandler}>
        <QueryClientProvider client={queryClient}>
          <Box sx={{ flex: 1 }}>
            <Suspense fallback={<EpixLoadingPage message="Initializing application..." />}>
              <App msalInstance={(await getMsalInstance())} />
            </Suspense>
          </Box>
          {!environment.production && <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />}
        </QueryClientProvider>
      </ErrorBoundary>
    </StrictMode>
  </Box>
)

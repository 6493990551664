import { useTranslation } from 'react-i18next'
import { minWidthPayrollClosure } from '../steps'
import { StepParams } from '../../generic-steps'
import { useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { GridColDef, GridRowsProp } from '@mui/x-data-grid'
import {
  DEFAULT_LOCALE,
  OrderDirection,
  useGetPayrollClosureProcessQuery,
  useGetPersonFamilyMemberCorrectionsByProcessIdQuery
} from '@epix-web-apps/core'
import { useParams } from 'react-router-dom'
import { DataTable, ISortModel, SortModel } from '../../../data-table'
import DoneIcon from '@mui/icons-material/Done'
import DataTableEmpty from '../../../data-table/data-table-empty'
import { useGlobalStore } from '@epix-web-apps/ui'
import DataExceedsPagesize from '../data-exceeds-pagesize'

/* eslint-disable-next-line */
export interface FamilyCorrectionsComponentProps {}

export function FamilyCorrectionsComponent(props: FamilyCorrectionsComponentProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { me } = useGlobalStore()
  const params = useParams<StepParams>()
  const [rowCount, setRowCount] = useState(0)
  const [pageSize, setPageSize] = useState(101)
  const [page, setPage] = useState(0)
  const [tableData, setTableData] = useState<GridRowsProp>([])

  const { data: getPayrollClosureProcess } = useGetPayrollClosureProcessQuery(
    {
      id: params?.id || ''
    },
    {
      enabled: !!params?.id
    }
  )

  const { data: personFamilyMemberCorrections, isLoading } = useGetPersonFamilyMemberCorrectionsByProcessIdQuery(
    {
      processId: getPayrollClosureProcess?.payrollClosureProcess.id || '',
      offset: page * pageSize,
      limit: pageSize
    },
    {
      suspense: false
    }
  )

  const columns: GridColDef[] = [
    {
      field: 'version',
      headerName: t('personcontractdata.datatable.column.version'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employerNumber',
      headerName: t('personcontractdata.datatable.column.employernumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employeeNumber',
      headerName: t('personcontractdata.datatable.column.employeenumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'firstName',
      headerName: t('personcontractdata.datatable.column.firstname'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'otherNames',
      headerName: t('personcontractdata.datatable.column.othernames'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'lastNamePrefix',
      headerName: t('personcontractdata.datatable.column.lastnameprefix'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'lastName',
      headerName: t('personcontractdata.datatable.column.lastname'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'genderTypeKey',
      headerName: t('personcontractdata.datatable.column.gendertypekey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'genderType',
      headerName: t('personcontractdata.datatable.column.gendertype'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'dateOfBirth',
      headerName: t('personcontractdata.datatable.column.dateofbirth'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'placeOfBirth',
      headerName: t('personcontractdata.datatable.column.placeofbirth'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'countryOfBirthKey',
      headerName: t('personcontractdata.datatable.column.countryofbirthkey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'countryOfBirth',
      headerName: t('personcontractdata.datatable.column.countryofbirth'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'nationalityKey',
      headerName: t('personcontractdata.datatable.column.nationalitykey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'nationality',
      headerName: t('personcontractdata.datatable.column.nationality'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'nationalitySecurityNumber',
      headerName: t('personcontractdata.datatable.column.nationalitysecuritynumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'relationTypeKey',
      headerName: t('personcontractdata.datatable.column.relationtypekey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'relationType',
      headerName: t('personcontractdata.datatable.column.relationtype'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    }
  ]

  const [sortModel, _] = useState<ISortModel>(new SortModel(columns?.[1]?.field, OrderDirection.Asc))

  useEffect(() => {
    if (personFamilyMemberCorrections && personFamilyMemberCorrections?.personFamilyMemberCorrectionsByProcessId) {
      const personFamilyCorrections = personFamilyMemberCorrections.personFamilyMemberCorrectionsByProcessId
      setTableData(
        personFamilyCorrections.data.map((row: any, index: number) => {
          return {
            id: index,
            version: row.version,
            employerNumber: row.employerNumber,
            employeeNumber: row.employeeNumber,
            firstName: row.firstName,
            otherNames: row.otherNames,
            lastNamePrefix: row.lastNamePrefix,
            lastName: row.lastName,
            genderTypeKey: row.genderType?.key,
            genderType: row.genderType?.value,
            dateOfBirth: row.dateOfBirth ? new Date(row.dateOfBirth).toLocaleDateString(DEFAULT_LOCALE) : null,
            placeOfBirth: row.placeOfBirth,
            countryOfBirthKey: row.countryOfBirth,
            countryOfBirth: row.countryOfBirth,
            nationalityKey: row.nationality,
            nationality: row.nationality,
            nationalitySecurityNumber: row.nationalSecurityNumber,
            relationTypeKey: row.relationType?.key,
            relationType: row.relationType?.value
          }
        })
      )
      setRowCount(personFamilyCorrections.totalCount)
    }
  }, [personFamilyMemberCorrections])

  return (
    <>
    {rowCount === pageSize && <DataExceedsPagesize />}
    <DataTable
      autoHeight
      data={tableData}
      columns={columns}
      totalRowCount={rowCount}
      hideFooter={true}
      isLoading={isLoading}
      defaultSortModel={sortModel}
      emptyStateElement={
        <DataTableEmpty
          title={t('emptystate.process.contract')}
          icon={<DoneIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} />}
        />
      }
    />
    </>
  )
}

export default FamilyCorrectionsComponent

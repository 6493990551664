import {
  OrderDirection,
  useGetAllCountriesQuery,
  useGetCollectiveSchedulesByEmployerIdQuery,
  useGetEmployerByIdQuery,
  useGetIcpByIdQuery
} from '@epix-web-apps/core'
import { Box, Button, IconButton, Typography, MenuItem } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowId, GridRowsProp } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { DataTable, ISortModel, SortModel } from '../../components/data-table'
import { Configuration, HeaderTitleNavigation } from '@epix-web-apps/ui'
import { DetailPageBaseQueryParams } from '@epix-web-apps/ui'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useFlyIn } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import DropdownMenu from '../../components/dropdown-menu/dropdown-menu'
import AddEditCollectiveSchedule from '../../components/schedule-components/add-edit-collective-schedule'

/* eslint-disable-next-line */
export interface CollectiveCalendarPageProps {}

export type CollectiveCalendarDetailPageParams = DetailPageBaseQueryParams & {
  employerid: string
}

export function CollectiveCalendarPage(props: CollectiveCalendarPageProps) {
  const params = useParams<CollectiveCalendarDetailPageParams>()
  const [tableData, setTableData] = useState<GridRowsProp>([])
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [collectiveScheduleId, setCollectiveScheduleId] = useState()
  const [pageSize, setPageSize] = useState(25)
  const [rowCount, setRowCount] = useState(0)
  const [page, setPage] = useState(0)
  const navigate = useNavigate()

  const rowActions = (rowParams: GridRenderCellParams) => (
    <IconButton
      aria-label="row actions"
      aria-controls="menu-row"
      aria-haspopup="true"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        setAnchorEl(e.currentTarget)
        setCollectiveScheduleId(rowParams.row.id)
      }}
    >
      <MoreHorizIcon />
    </IconButton>
  )

  const employerId = params.employerid!
  const icpId = params.id!

  const { data: getEmployerById } = useGetEmployerByIdQuery({
    employerId: employerId
  })
  const { data: getIcpById } = useGetIcpByIdQuery({
    icpId: icpId
  })
  const { data: getCountries } = useGetAllCountriesQuery()
  const code = getIcpById?.icpById.code
  const country = getCountries?.countries.find(c => c.code.includes(code ? code : ''))?.name
  const payrollProvider = getIcpById?.icpById.payrollProvider
  const company = getEmployerById?.employerById.companyName

  const filterBar = (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: [2],
          paddingLeft: [2],
          paddingRight: [2]
        }}
      >
        <HeaderTitleNavigation
          backToLink={Configuration.ICP_PROVIDERS_ID_EMPLOYERS(`${params.id}`)}
          title={t('collectiveschedulepage.title')}
        />
        <Button
          variant="contained"
          onClick={() =>
            openFlyIn({
              content: <AddEditCollectiveSchedule employerId={params.employerid} />,
              callbackAfterClose: () => refetchAllCollectiveSchedules
            })
          }
        >
          <AddIcon />
          {t('collectiveschedulepage.button.add')}
        </Button>
      </Box>
      <Typography color={'gray'} variant="h3" paddingLeft={2}>
        {country} - {payrollProvider} - {company}
      </Typography>
    </Box>
  )

  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: t('collectiveschedulpage.datatable.column.code'),
      flex: 1,
      sortable: true
    },
    {
      field: 'description',
      headerName: t('collectiveschedulpage.datatable.column.description'),
      flex: 1
    },
    { field: '', sortable: false, editable: false, renderCell: rowActions }
  ]

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel('field', OrderDirection.Asc))

  const {
    data: collectiveSchedules,
    isLoading: isLoadingCollectiveSchedules,
    refetch: refetchAllCollectiveSchedules
  } = useGetCollectiveSchedulesByEmployerIdQuery(
    {
      employerId: employerId || '',
      offset: page * pageSize,
      limit: pageSize,
      sortByProperty: sortModel.field,
      orderDirection: sortModel.orderDirection
    },
    {
      suspense: false
    }
  )

  useEffect(() => {
    if (collectiveSchedules && collectiveSchedules.collectiveSchedules) {
      const collectiveSchedulesData = collectiveSchedules.collectiveSchedules
      setRowCount(collectiveSchedulesData.totalCount)
      setTableData(
        collectiveSchedulesData.data.map(row => {
          return {
            id: row.id,
            code: row.code,
            description: row.description
          }
        })
      )
    }
  }, [collectiveSchedules])

  const goToCollectiveScheduleDaysPage = (clickedRow: GridRowId | undefined) => {
    if (clickedRow) {
      navigate(
        Configuration.ICP_PROVIDERS_ID_EMPLOYERID_COLLECTIVE_CALENDAR_ID(
          `${params.id}`,
          `${params.employerid}`,
          `${clickedRow}`
        ),
        {
          state: {
            id: clickedRow
          }
        }
      )
    }
  }

  return (
    <>
      <DataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        onSortChange={setSortModel}
        onRowClick={row => goToCollectiveScheduleDaysPage(row.id)}
        page={page}
        onPageChange={setPage}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        isLoading={isLoadingCollectiveSchedules}
        defaultSortModel={sortModel}
        filterBarElement={filterBar}
        hideFooter={true}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <AddEditCollectiveSchedule employerId={params.employerid} collectiveScheduleId={collectiveScheduleId} />
              ),
              callbackAfterClose: () => refetchAllCollectiveSchedules
            })
          }
        >
          {t('collectiveschedulepage.list.row.menu.edit')}
        </MenuItem>
        <MenuItem onClick={() => goToCollectiveScheduleDaysPage(collectiveScheduleId)}>
          {t('collectiveschedulepage.list.row.menu.editdays')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default CollectiveCalendarPage

import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { useFlyIn, useGlobalStore } from '@epix-web-apps/ui'
import { useState } from 'react'
import { GraphqlError, useDeleteCompanyCarMutation, useGetCompanyCarByIdQuery } from '@epix-web-apps/core'
import { useForm } from 'react-hook-form'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import { FormContainer } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface RemoveCompanyCarProps {
  companyCarId: string
}

export function RemoveCompanyCar({ companyCarId }: RemoveCompanyCarProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const form = useForm()

  const deleteMutation = useDeleteCompanyCarMutation()

  const { me } = useGlobalStore()

  const { data: companyCar, refetch } = useGetCompanyCarByIdQuery(
    {
      companyCarId: companyCarId || ''
    },
    {
      suspense: false
    }
  )

  const handleOnSubmit = async () => {
    await deleteMutation
      .mutateAsync({
        deleteCompanyCarCommand: {
          companyCarId: companyCarId || ''
        }
      })
      .then(() => {
        closeFlyIn()
        refetch()
      })
      .catch(e => setBackendErrors([e]))
  }

  const startDate = new Date(companyCar?.companyCarById?.startDate).toLocaleDateString(me?.locale.locale)
  const endDate = companyCar?.companyCarById.endDate
    ? new Date(companyCar?.companyCarById?.endDate).toLocaleDateString(me?.locale.locale)
    : '...'

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.delete-companycars.title')}</Typography>
      <Typography>{`${companyCar?.companyCarById.carBrandType.value} - ${companyCar?.companyCarById.licensePlate}`}</Typography>
      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
        {startDate} {<ChevronRightOutlinedIcon fontSize="small" />} {endDate}
      </Typography>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={deleteMutation.isLoading}
        buttonText={t('common.remove')}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default RemoveCompanyCar

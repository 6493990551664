import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { SuspensePaper } from '../../../suspense-paper'
import FunctionLevelComponent from '../../function-level-component'
import FunctionAreaComponent from '../../function-area-component'

function OrganisationTab() {
  const { t } = useTranslation()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <SuspensePaper title={t('analytics.pie.functionarea')}>
          <FunctionAreaComponent />
        </SuspensePaper>
      </Grid>

      <Grid item xs={12} lg={6}>
        <SuspensePaper title={t('analytics.pie.functionlevel')}>
          <FunctionLevelComponent />
        </SuspensePaper>
      </Grid>
    </Grid>
  )
}

export default OrganisationTab

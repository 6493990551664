import { HeaderTitleWithIcon } from '@epix-web-apps/ui'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined'

import { TenantAdminsTable } from '../../components/tenant-configuration-components/tenant-admins-table/tenant-admins-table'
import { TenantFeaturesForm } from '../../components/tenant-configuration-components/tenant-features/tenant-features-form'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TenantConfigurationPageProps {}

export function TenantConfigurationPage(props: TenantConfigurationPageProps) {
  const { t } = useTranslation()

  return (
    <>
      <HeaderTitleWithIcon icon={<ConstructionOutlinedIcon />} title={t('tenantconfigurationpage.title')} />
      <Grid container>
        <Grid item xs={12} md={6}>
          <TenantFeaturesForm />
        </Grid>

        <Grid item xs={12} md={6}>
          <TenantAdminsTable />
        </Grid>
      </Grid>
    </>
  )
}

export default TenantConfigurationPage

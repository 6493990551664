import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { date, number, object, string, TypeOf } from 'zod'
import { FormGridLayout } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { useFlyIn } from '@epix-web-apps/ui'
import {
  GraphqlError,
  numericString,
  useCreateInjuryHistoryCommandMutation,
  useUpdateInjuryHistoryCommandMutation,
  useGetInjuryHistoryByIdQuery,
  useGetAllContractsWithEmployerByPersonIdQuery,
  FormSelectOption
} from '@epix-web-apps/core'
import { FormActionButtons, FormDatepicker, FormErrorList } from '@epix-web-apps/ui'
import { useParams } from 'react-router-dom'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'
import { FormInput } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { FormSelect } from '@epix-web-apps/ui'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface AddEditInjuryHistoryProps {
  injuryId: string
  injuryHistoryId?: string
  contractId?: string | undefined
}

export function AddEditInjuryHistory({ injuryId, injuryHistoryId, contractId }: AddEditInjuryHistoryProps) {
  const { t } = useTranslation()

  const addEditInjuryHistorySchema = object({
    contractId: string(),
    startDate: date({
      required_error: t('form.validation.startdaterequired'),
      invalid_type_error: t('form.validation.startdaterequired')
    }),
    endDate: date({
      required_error: t('form.validation.enddaterequired'),
      invalid_type_error: t('form.validation.enddaterequired')
    }),
    disabledPercentage: numericString(
      number({
        required_error: t('form.validation.percentagerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      })
        .gte(0, { message: t('form.validation.percentagegreaterthan0') })
        .lte(100, { message: t('form.validation.precentagelessthan100') })
        .optional()
        .nullable()
    )
  }).refine(data => (data.endDate ? data.endDate >= data.startDate : !data.endDate), {
    message: t('form.validation.enddateafterstartdate'),
    path: ['endDate']
  })
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  type AddEditInjuryHistoryForm = TypeOf<typeof addEditInjuryHistorySchema>
  const params = useParams<ContractDetailPageParams>()
  const { closeFlyIn } = useFlyIn()
  const personId = params.id || ''

  const { data: getAllContracts } = useGetAllContractsWithEmployerByPersonIdQuery(
    {
      personId: personId || ''
    },
    {
      enabled: !!personId
    }
  )
  const contracts = getAllContracts?.allContractsWithEmployerByPersonId

  const { data: injuryHistory, refetch: refetchHistory } = useGetInjuryHistoryByIdQuery(
    {
      injuryId: injuryId || '',
      injuryHistoryId: injuryHistoryId || ''
    },
    {
      enabled: !!injuryHistoryId && !!injuryId
    }
  )

  const form = useForm<AddEditInjuryHistoryForm>({
    resolver: zodResolver(addEditInjuryHistorySchema),
    defaultValues: {
      contractId: contracts?.length === 1 ? contracts[0].id : contractId || '',
      startDate: new Date(injuryHistory?.injuryHistoryById?.startDate),
      endDate: new Date(injuryHistory?.injuryHistoryById?.endDate),
      disabledPercentage: injuryHistory?.injuryHistoryById?.disabledPercentage
    }
  })

  const createMutation = useCreateInjuryHistoryCommandMutation()
  const updateMutation = useUpdateInjuryHistoryCommandMutation()

  const handleOnSubmit = async (newInjuryHistory: AddEditInjuryHistoryForm) => {
    if (!injuryHistoryId) {
      await createMutation
        .mutateAsync({
          createInjuryHistoryCommand: {
            injuryId: injuryId || '',
            startDate: newInjuryHistory.startDate,
            endDate: newInjuryHistory.endDate,
            contractId: newInjuryHistory.contractId,
            disabledPercentage: newInjuryHistory.disabledPercentage
          }
        })
        .then(() => {
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    } else {
      await updateMutation
        .mutateAsync({
          updateInjuryHistoryCommand: {
            injuryId: injuryId || '',
            injuryHistoryId: injuryHistoryId || '',
            startDate: newInjuryHistory.startDate,
            endDate: newInjuryHistory.endDate,
            contractId: newInjuryHistory.contractId,
            disabledPercentage: newInjuryHistory.disabledPercentage
          }
        })
        .then(() => {
          refetchHistory()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">
        {injuryHistoryId ? t('flyin.editinjuryhistory.title') : t('flyin.addinjuryhistory.title')}
      </Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="contractId"
          label={t('form.field.contract')}
          options={contracts?.map(x => new FormSelectOption(x.id, `${x.countryKey} (${x.employerName})`))}
        />

        <FormDatepicker sx={12} name="startDate" label={t('form.field.injury.startdate')} />

        <FormDatepicker sx={12} name="endDate" label={t('form.field.injury.enddate')} />

        <FormInput sx={12} name="disabledPercentage" label={t('form.field.disabledPercentage')} />
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={createMutation.isLoading || updateMutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditInjuryHistory

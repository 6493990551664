import { PROCESS_PAYROLLCLOSURE_PARAMETER, useGetPayrollClosureProcessParametersQuery } from '@epix-web-apps/core'
import { FormGridLayout } from '@epix-web-apps/ui'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormDatepicker, FormRadioGroup, FormRadioOption } from '@epix-web-apps/ui'
import { StepProps } from '../../generic-steps'

export function Parameters({ processId }: StepProps) {
  const { t } = useTranslation()
  const { data: getPayrollClosureParameters } = useGetPayrollClosureProcessParametersQuery()

  const {
    control,
    formState: { errors }
  } = useFormContext()

  const watchedparameterTypeKey = useWatch({
    control,
    name: `parameterTypeKey`
  })

  return (
    <FormGridLayout>
      <FormRadioGroup
        sx={12}
        name="parameterTypeKey"
        label={t('form.field.type')}
        options={getPayrollClosureParameters?.payrollClosureProcessParameters.map(x => {
          return new FormRadioOption(x.key, x.value)
        })}
        disabled={true}
      />
      {watchedparameterTypeKey === PROCESS_PAYROLLCLOSURE_PARAMETER.ORIGINAL_CORRECTIONS_PERSONCONTRACT && (
        <>
        <FormDatepicker
          sx={2}
          name="periodStartDate"
          label={`${t('form.field.periodstartdate')}`}
          openTo="month"
          views={['year', 'month']}
          disabled={true}
        />

        <FormDatepicker
          sx={2}
          name="periodEndDate"
          label={`${t('form.field.periodenddate')}`}
          openTo="month"
          views={['year', 'month']}
          disabled={true}
        />
        </>
      )}
    </FormGridLayout>
  )
}

export default Parameters

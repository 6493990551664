import React from 'react'
import styled from '@emotion/styled'
import { alpha, Menu, PopoverOrigin, useTheme } from '@mui/material'

export interface DropdownMenuProps {
  anchorEl: HTMLElement | null
  anchorOrigin?: PopoverOrigin | undefined
  transformOrigin?: PopoverOrigin | undefined
  open?: boolean
  onClose: () => void
  closeOnChildClicked?: boolean
  children?: React.ReactNode
}

const StyledDropdownMenu = styled((props: any) => <Menu keepMounted {...props} />)(() => {
  const theme = useTheme()
  return {
    '& .MuiPaper-root': {
      borderRadius: 4,
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary
        },
        '&:active': {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
        }
      }
    }
  }
})

export function DropdownMenu({
  anchorEl,
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
  transformOrigin = { vertical: 'top', horizontal: 'right' },
  open,
  onClose,
  closeOnChildClicked = true,
  children
}: DropdownMenuProps) {
  return (
    <StyledDropdownMenu
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      anchorEl={anchorEl}
      open={open !== undefined ? open : Boolean(anchorEl)}
      onClose={onClose}
      onClick={() => closeOnChildClicked && onClose()}
    >
      {children}
    </StyledDropdownMenu>
  )
}

export default DropdownMenu

import { isSelfServiceUser, useGetPersonByIdQuery, useGetUserByPersonIdQuery } from '@epix-web-apps/core'
import { Box, IconButton } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import { DetailBox, DetailBoxRow } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'
import { EditSelfServiceAccess } from '../../../components/selfservice-components/edit-selfservice-access'

/* eslint-disable-next-line */
export function SelfServiceTab() {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const params = useParams<ContractDetailPageParams>()
  const personId = params.id || ''
  const { data: getPersonById } = useGetPersonByIdQuery(
    {
      personId: personId
    },
    {
      enabled: !!personId
    }
  )
  const { data: getUserByPersonId } = useGetUserByPersonIdQuery(
    {
      personId: personId
    },
    {
      enabled: !!personId,
      useErrorBoundary: false
    }
  )

  const person = getPersonById?.personById
  const hasAccess = isSelfServiceUser(getUserByPersonId?.userByPersonId?.roles)

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2rem'
      }}
    >
      <Box sx={{ flex: 1 }}>
        <DetailBox
          title={t('contractdetailpage.selfserviceaccess.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditSelfServiceAccess />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {!hasAccess && (
            <DetailBoxRow>
              <p>
                <Trans
                  i18nKey="peopledetailpage.selfserviceaccess.hasnoaccess"
                  values={{ firstname: person?.firstName }}
                  components={{ bold: <span className="bold" /> }}
                />
              </p>
            </DetailBoxRow>
          )}
          {hasAccess && (
            <>
              <DetailBoxRow>
                <p>
                  <Trans
                    i18nKey="peopledetailpage.selfserviceaccess.hasaccess"
                    values={{ firstname: person?.firstName }}
                    components={{ bold: <span className="bold" /> }}
                  />
                </p>
              </DetailBoxRow>
              <DetailBoxRow>
                <p>
                  <Trans
                    i18nKey="peopledetailpage.selfserviceaccess.usernameis"
                    values={{
                      username: getUserByPersonId?.userByPersonId?.email
                    }}
                    components={{ bold: <span className="bold" /> }}
                  />
                </p>
              </DetailBoxRow>
            </>
          )}
        </DetailBox>
      </Box>

      <Box sx={{ flex: 1 }}></Box>
    </Box>
  )
}

export default SelfServiceTab

import { useGetUserByIdQuery, useUpdateUserMutation, ROLE, useGetMeQuery, GraphqlError } from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { boolean, object, string, TypeOf } from 'zod'
import { DetailPageBaseQueryParams, FormGridLayout } from '@epix-web-apps/ui'
import { FormSearchPerson } from '../../form-components'
import { FormActionButtons, FormContainer, FormErrorList } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'
import { useMemo, useState } from 'react'

/* eslint-disable-next-line */
export interface SelfServiceAccessProps {}

export function SelfServiceAccess(props: SelfServiceAccessProps) {
  const { t } = useTranslation()
  const params = useParams<DetailPageBaseQueryParams>()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const editUserSchema = object({
    selfServiceAccess: boolean(),
    personId: string().optional().nullable()
  })

  type EditUserForm = TypeOf<typeof editUserSchema>
  const { refetch: refetchMe } = useGetMeQuery()
  const { data: getUserById, refetch: refetchUserById } = useGetUserByIdQuery(
    {
      userId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )
  const userRoles = useMemo(
    () => getUserById?.userById.roles.map(x => x.toUpperCase()) || [],
    [getUserById?.userById.roles]
  )

  const form = useForm<EditUserForm>({
    resolver: zodResolver(editUserSchema),
    defaultValues: {
      selfServiceAccess: userRoles.includes(ROLE.SELFSERVICEUSER),
      personId: getUserById?.userById.personId
    }
  })
  const [hasAccess, setHasAccess] = useState(userRoles.includes(ROLE.SELFSERVICEUSER))

  const updateUserMutation = useUpdateUserMutation()

  const handleOnSubmit = async (user: EditUserForm) => {
    let userRoles = getUserById?.userById.roles
    if (user.selfServiceAccess) {
      userRoles?.push(ROLE.SELFSERVICEUSER)
    } else {
      userRoles = userRoles?.filter(x => x.toUpperCase() !== ROLE.SELFSERVICEUSER.toString())
    }
    await updateUserMutation
      .mutateAsync({
        updateUserCommand: {
          id: params.id || '',
          firstName: getUserById?.userById.firstName || '',
          lastName: getUserById?.userById.lastName || '',
          currency: getUserById?.userById.currency || '',
          roleNames: userRoles,
          locale: getUserById?.userById.locale.locale || '',
          isActive: getUserById?.userById.isActive ?? true,
          personId: user.personId
        }
      })
      .then(() => {
        refetchMe()
        refetchUserById()
        setBackendErrors([])
      })
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Box>
        <FormGridLayout>
          <FormSwitch
            sx={12}
            name="selfServiceAccess"
            label={t('form.field.selfserviceaccess')}
            onChange={hasAccess => setHasAccess(hasAccess)}
          />

          <FormSearchPerson
            name={'personId'}
            label={t('form.field.personforselfservice')}
            disabled={!hasAccess}
            onPersonSelected={() => setBackendErrors([])}
          />
        </FormGridLayout>
      </Box>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={updateUserMutation.isLoading} />
    </FormContainer>
  )
}

export default SelfServiceAccess

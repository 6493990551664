import { Trans, useTranslation } from 'react-i18next'
import { exportJobModelWithoutProcessingJobType } from '../../generic-steps'
import { downloadFile, ExportType, ProcessingStatusType, useGetDocumentBlobByIdQuery } from '@epix-web-apps/core'
import { CircularProgress, ListItem, ListItemIcon, ListItemText, useTheme, Link } from '@mui/material'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import ExportJobErrorListItem from '../export-job-error-list-item'
import ExportJobApiListItem from '../export-job-api-list-item'

interface ExportJobListItemProps {
  exportJob: exportJobModelWithoutProcessingJobType
  payrollProviderCode: string | undefined
}

function ExportJobListItem({ exportJob, payrollProviderCode }: ExportJobListItemProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  const { refetch: refetchDocumentBlobById, isFetching } = useGetDocumentBlobByIdQuery(
    { id: exportJob.document?.id || '' },
    { enabled: false, suspense: false }
  )

  let downloadLinkText
  switch (exportJob.exportType) {
    case ExportType.BuropartnerXlsx:
      downloadLinkText = (
        <>
          <Trans
            i18nKey="processpage.finishoverview.downloadlink.asspecificexport"
            values={{ provider: payrollProviderCode }}
          />{' '}
          ({exportJob.document?.extension})
        </>
      )
      break
    case ExportType.GeneralXlsx:
      downloadLinkText = `${t('processpage.finishoverview.downloadlink.asgenericexport')} (${
        exportJob.document?.extension
      })`
      break
    default:
      downloadLinkText = (
        <Trans
          i18nKey="processpage.finishoverview.downloadlink.asicpspecificexport"
          values={{
            provider: payrollProviderCode,
            filename: exportJob.document?.name
          }}
        />
      )
  }

  return (
    <ListItem key={exportJob.id}>
      {exportJob.statusType !== ProcessingStatusType.Finished && (
        <>
          <ListItemIcon>
            <CircularProgress size="1.3rem" />
          </ListItemIcon>
          <ListItemText
            primary={<LoadingStateText exportJobType={exportJob.exportType} />}
            secondary={t('processpage.finishoverview.jobstates.keepwindowopen')}
          />
        </>
      )}
      {exportJob.statusType === ProcessingStatusType.Finished && (
        <>
          <ListItemIcon>
            {exportJob.success && exportJob.document?.name && <CloudDownloadOutlinedIcon />}
            {exportJob.success && !exportJob.document?.name && <CloudSyncOutlinedIcon />}
            {!exportJob.success && <WarningAmberOutlinedIcon color="error" />}
          </ListItemIcon>

          {exportJob.success && exportJob.document?.name && (
            <Link
              color={isFetching ? theme.palette.text.secondary : theme.palette.primary.main}
              onClick={() => {
                if (!isFetching) {
                  refetchDocumentBlobById().then(r => {
                    if (r.data) {
                      downloadFile(
                        r.data.documentBlobById.blob,
                        r.data.documentBlobById.name,
                        r.data.documentBlobById.contentType
                      )
                    }
                  })
                }
              }}
            >
              {downloadLinkText}
            </Link>
          )}
          {exportJob.success && !exportJob.document?.name && <ExportJobApiListItem exportJob={exportJob} />}
          {!exportJob.success && <ExportJobErrorListItem exportJob={exportJob} />}
        </>
      )}
    </ListItem>
  )
}

export default ExportJobListItem

function LoadingStateText({ exportJobType }: { exportJobType: ExportType }) {
  const { t } = useTranslation()
  switch (exportJobType) {
    case ExportType.BuropartnerXlsx:
      return t('processpage.finishoverview.loading.buropartner')
    case ExportType.GeneralXlsx:
      return t('processpage.finishoverview.loading.general')
    default:
      return t('processpage.finishoverview.jobstates.exporting')
  }
}

import { Box, Button, IconButton, MenuItem, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowId, GridRowsProp } from '@mui/x-data-grid'
import React, { useEffect } from 'react'
import { useState } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useTranslation } from 'react-i18next'
import { Configuration, DetailPageBaseQueryParams, useFlyIn } from '@epix-web-apps/ui'
import { HeaderTitleNavigation } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import {
  OrderDirection,
  useGetAllCountriesQuery,
  useGetAllDayDefinitionsByEmployerIdQuery,
  useGetEmployerByIdQuery,
  useGetIcpByIdQuery
} from '@epix-web-apps/core'
import { DataTable, ISortModel, SortModel } from '../../components/data-table'
import DropdownMenu from '../../components/dropdown-menu'
import { useNavigate, useParams } from 'react-router-dom'
import AddEditDayDefinition from '../../components/day-definition-components/add-edit-day-definition/add-edit-day-definition'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

/* eslint-disable-next-line */
export interface DayDefinitionsPageProps {}

export type DayDefinitionsDetailPageParams = DetailPageBaseQueryParams & {
  employerid: string
}

export function DayDefinitionsPage(props: DayDefinitionsPageProps) {
  const [tableData, setTableData] = useState<GridRowsProp>([])
  const params = useParams<DayDefinitionsDetailPageParams>()
  const employerId = params.employerid!
  const icpId = params.id!
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [rowCount, setRowCount] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [dayDefinitionId, setDayDefinitionId] = useState('')
  const navigate = useNavigate()

  const rowActions = (rowParams: GridRenderCellParams) => (
    <>
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
          setDayDefinitionId(rowParams.row.id)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <IconButton aria-label="row details" aria-controls="details-row">
        <KeyboardArrowRightIcon />
      </IconButton>
    </>
  )

  const { data: getEmployerById } = useGetEmployerByIdQuery({
    employerId: employerId
  })
  const { data: getIcpById } = useGetIcpByIdQuery({
    icpId: icpId
  })
  const { data: getCountries } = useGetAllCountriesQuery()
  const code = getIcpById?.icpById.code
  const country = getCountries?.countries.find(c => c.code.includes(code ? code : ''))?.name
  const payrollProvider = getIcpById?.icpById.payrollProvider
  const company = getEmployerById?.employerById.companyName
  const filterBar = (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: [2],
          paddingLeft: [2],
          paddingRight: [2]
        }}
      >
        <HeaderTitleNavigation
          backToLink={Configuration.ICP_PROVIDERS_ID_EMPLOYERS(`${params.id}`)}
          title={t('daydefinitions.title')}
        />
        <Button
          variant="contained"
          onClick={() =>
            openFlyIn({
              content: <AddEditDayDefinition employerId={employerId} />,
              callbackAfterClose: () => refetchAllDayDefinitions
            })
          }
        >
          <AddIcon />
          {t('daydefinitionspage.button.daydefinitions')}
        </Button>
      </Box>
      <Typography color={'gray'} variant="h3" paddingLeft={2}>
        {country} - {payrollProvider} - {company}
      </Typography>
    </Box>
  )

  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: t('daydefinitionspage.datatable.column.code'),
      flex: 1,
      sortable: true
    },
    {
      field: 'description',
      headerName: t('daydefinitionspage.datatable.column.description'),
      flex: 1
    },
    { field: '', sortable: false, editable: false, renderCell: rowActions }
  ]

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns?.[0].field, OrderDirection.Asc))
  const [page, setPage] = useState(0)

  const {
    data: dayDefinitionsData,
    isLoading: isLoadingDayDefinitions,
    refetch: refetchAllDayDefinitions
  } = useGetAllDayDefinitionsByEmployerIdQuery(
    {
      employerId: employerId || '',
      offset: page * pageSize,
      limit: pageSize,
      sortByProperty: sortModel.field,
      orderDirection: sortModel.orderDirection
    },
    {
      suspense: false
    }
  )

  useEffect(() => {
    if (dayDefinitionsData && dayDefinitionsData.dayDefinitions) {
      const dayDefinitions = dayDefinitionsData.dayDefinitions
      setRowCount(dayDefinitions.totalCount)
      setTableData(
        dayDefinitions.data.map(row => {
          return {
            id: row.id,
            code: row.code,
            description: row.description
          }
        })
      )
    }
  }, [dayDefinitionsData])

  const goToDayDefinitionEntriesPage = (clickedRow: GridRowId) => {
    if (clickedRow) {
      navigate(Configuration.ICP_PROVIDERS_ID_EMPLOYERID_DAYDEFINITIONENTRIES(icpId, employerId, `${clickedRow}`), {
        state: {
          dayDefinitionId: clickedRow,
          employerId: employerId,
          country: country,
          payrollProvider: payrollProvider,
          company: company
        }
      })
    }
  }

  return (
    <>
      <DataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        page={page}
        onPageChange={setPage}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        isLoading={isLoadingDayDefinitions}
        defaultSortModel={sortModel}
        onRowClick={row => goToDayDefinitionEntriesPage(row.id)}
        onSortChange={setSortModel}
        filterBarElement={filterBar}
        hideFooter={true}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <AddEditDayDefinition dayDefinitionId={dayDefinitionId} employerId={employerId} />,
              callbackAfterClose: () => refetchAllDayDefinitions
            })
          }
        >
          {t('daydefinitionspage.list.row.menu.edit-daydefinition')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default DayDefinitionsPage

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Box, IconButton, SxProps, useTheme } from '@mui/material'
import { EpixDatePicker } from '../../epix-date-picker'
import {
  DateRange,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  PayGroupPayPeriodModel,
  PAYPERIODTYPES
} from '@epix-web-apps/core'
import { isValidPayPeriodDate } from '../../process-components/payroll-closure/add-payroll-closure'
import moment from 'moment'

const sxPopper: SxProps = {
  '& .MuiInputBase-input': {
    padding: '0',
    margin: '0'
  }
}

export const GetFirstAndLastDayOfPeriod = (payGroup: PayGroupPayPeriodModel | null, date: Date): DateRange => {
  switch (payGroup?.payPeriodType?.key) {
    case PAYPERIODTYPES.MONTHLY: {
      const startDate = getFirstDayOfMonth(date)
      const endDate = getLastDayOfMonth(date)
      return { startDate, endDate }
    }
    case PAYPERIODTYPES.BI_MONTHLY: {
      const startDate = new Date(date)
      const dayOfMonth = startDate.getDate()

      if (dayOfMonth <= 15) {
        const nextPeriodStart = moment(startDate).date(1).toDate()
        const nextPeriodEnd = moment(startDate).date(15).toDate()
        return { startDate: nextPeriodStart, endDate: nextPeriodEnd }
      } else {
        const nextPeriodStart = moment(startDate).date(16).toDate()
        const nextPeriodEnd = moment(startDate).endOf('month').toDate()
        return { startDate: nextPeriodStart, endDate: nextPeriodEnd }
      }
    }
    case PAYPERIODTYPES.WEEKLY: {
      if (!payGroup || payGroup.payPeriodNumberOfWeeks === undefined || !payGroup.firstClosure) {
        throw new Error('Invalid pay group configuration.')
      }

      const numberOfWeeks = payGroup.payPeriodNumberOfWeeks!
      const firstClosureDate = new Date(payGroup.firstClosure)
      const currentDate = new Date(date)

      const periodLengthInDays = numberOfWeeks * 7

      const periodsSinceClosure = Math.floor(
        (currentDate.getTime() - firstClosureDate.getTime()) / (periodLengthInDays * 24 * 60 * 60 * 1000)
      )

      const startDate = new Date(firstClosureDate)
      startDate.setDate(startDate.getDate() + periodsSinceClosure * periodLengthInDays)

      const endDate = new Date(startDate)
      endDate.setDate(startDate.getDate() + periodLengthInDays - 1)

      return { startDate, endDate }
    }
  }
  return { startDate: new Date(), endDate: new Date() }
}

/* eslint-disable-next-line */
export interface DateComponentProps {
  label?: string
  period: DateRange
  payGroup: PayGroupPayPeriodModel | null
  onChange: (date: Date) => void
  onNext: () => void
  onPrevious: () => void
}

export function PeriodNavigationDatePicker({ payGroup, period, onChange, onNext, onPrevious }: DateComponentProps) {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        color: theme.palette.text.secondary,
        maxWidth: '13rem'
      }}
    >
      <IconButton size="small" onClick={onPrevious}>
        <KeyboardArrowLeftIcon fontSize="medium" sx={{ verticalAlign: 'bottom' }} />
      </IconButton>
      <EpixDatePicker
        openTo={payGroup?.payPeriodType?.key === PAYPERIODTYPES.MONTHLY ? 'month' : 'day'}
        value={period.startDate}
        onAccept={newStartDate => {
          onChange(newStartDate ?? new Date())
        }}
        shouldDisableDate={e => isValidPayPeriodDate(payGroup ?? null, e)}
        views={payGroup?.payPeriodType?.key === PAYPERIODTYPES.MONTHLY ? ['year', 'month'] : ['year', 'month', 'day']}
        textFieldInputProps={{
          disableUnderline: true,
          sx: sxPopper
        }}
        variant="standard"
      />
      <IconButton size="small" onClick={onNext}>
        <KeyboardArrowRightIcon fontSize="medium" sx={{ verticalAlign: 'bottom' }} />
      </IconButton>
    </Box>
  )
}

export default PeriodNavigationDatePicker

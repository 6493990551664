import { HeaderTitleNavigation, SelfService, useGlobalStore } from '@epix-web-apps/ui'
import { FolderInfoComponent } from '../folder-component'
import { Box, Typography, useTheme } from '@mui/material'
import { DocumentComponent } from '../document-component'
import {
  OrderByAddedOnFolders,
  OrderDirection,
  useGetContractDocumentTypeByContractIdAndDocumentTypeIdQuery,
  useGetDocumentTypeByIdQuery,
  useGetEmployerDocumentTypeByEmployerIdIdAndDocumentTypeIdQuery
} from '@epix-web-apps/core'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

/* eslint-disable-next-line */
export interface DocumentDetailProps {}

export type DocumentDetailsQueryParams = {
  contractid: string
  employerid: string
  documenttypeid: string
}

export function DocumentDetail(props: DocumentDetailProps) {
  const { me } = useGlobalStore()
  const theme = useTheme()
  const { t } = useTranslation()
  const params = useParams<DocumentDetailsQueryParams>()

  const { data: documentType } = useGetDocumentTypeByIdQuery(
    {
      id: params.documenttypeid || ''
    },
    {
      enabled: !!params.documenttypeid
    }
  )

  const { data: contractDocumentType } = useGetContractDocumentTypeByContractIdAndDocumentTypeIdQuery(
    {
      contractId: params.contractid || '',
      documentTypeId: params.documenttypeid || '',
      sortByProperty: OrderByAddedOnFolders.includes(documentType?.documentTypeById.key || '') ? 'AddedOn' : 'Name',
      orderDirection: OrderByAddedOnFolders.includes(documentType?.documentTypeById.key || '')
        ? OrderDirection.Desc
        : OrderDirection.Asc
    },
    {
      enabled: !!params.contractid && !!documentType
    }
  )

  const { data: employerDocumentType } = useGetEmployerDocumentTypeByEmployerIdIdAndDocumentTypeIdQuery(
    {
      employerId: params.employerid || '',
      documentTypeId: params.documenttypeid || '',
      sortByProperty: OrderByAddedOnFolders.includes(documentType?.documentTypeById.key || '') ? 'AddedOn' : 'Name',
      orderDirection: OrderByAddedOnFolders.includes(documentType?.documentTypeById.key || '')
        ? OrderDirection.Desc
        : OrderDirection.Asc
    },
    {
      enabled: !!params.employerid && !!documentType
    }
  )

  const data = params.contractid
    ? contractDocumentType?.contractDocumentTypeByContractIdAndDocumentTypeId
    : employerDocumentType?.employerDocumentTypeByEmployerIdAndDocumentTypeId

  return (
    <>
      <HeaderTitleNavigation
        mobileSizeTitle
        title={
          params.contractid
            ? `${contractDocumentType?.contractDocumentTypeByContractIdAndDocumentTypeId?.icpCode} (${new Date(
                contractDocumentType?.contractDocumentTypeByContractIdAndDocumentTypeId?.startDate
              ).toLocaleDateString(me?.locale.locale)} - ${
                contractDocumentType?.contractDocumentTypeByContractIdAndDocumentTypeId?.endDate
                  ? new Date(
                      contractDocumentType?.contractDocumentTypeByContractIdAndDocumentTypeId?.endDate
                    ).toLocaleDateString(me?.locale.locale) + ')'
                  : '...)'
              }`
            : employerDocumentType?.employerDocumentTypeByEmployerIdAndDocumentTypeId.companyName
        }
        backToLink={SelfService.DOCUMENTS()}
      />
      <Box mt={1}>
        <FolderInfoComponent name={data?.name || ''} underline={false} />
      </Box>
      <Box>
        {data && data.documents && data?.documents.length > 0 ? (
          data?.documents.map(x => (
            <DocumentComponent key={x.id} addedOnDate={new Date(x.addedOn)} documentName={x.name} document={x} />
          ))
        ) : (
          <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
            {t('selfservice.emptystate.documents')}
          </Typography>
        )}
      </Box>
    </>
  )
}

export default DocumentDetail

import { createEnhancedJSONStorage } from '@epix-web-apps/core'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface IWorkScheduleStore {
  showInactive: boolean
  setShowInactive: (value: boolean) => void
}

export const useWorkScheduleFilterStore = create<IWorkScheduleStore>()(
  persist(
    (set, get) => ({
      showInactive: false,
      setShowInactive: async (value: boolean) => {
        set({ showInactive: value })
      }
    }),
    {
      name: 'workScheduleFilterStore',
      storage: createEnhancedJSONStorage(() => sessionStorage)
    }
  )
)

import {
  useGetAsbGroupContractDetailsQuery,
  GraphqlError,
  useUpdateAsbGroupContractDetailTaxesMutation
} from '@epix-web-apps/core'
import { useFlyIn, FormContainer, FormGridLayout, FormErrorList, FormActionButtons } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, boolean, object } from 'zod'
import { FormSwitch } from '../../../../form-components/form-switch'

/* eslint-disable-next-line */
export interface EditAsbgroupTaxesProps {
  contractId: string
}

export function EditAsbgroupTaxes({ contractId }: EditAsbgroupTaxesProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const theme = useTheme()

  const { data: partnerSpecificDetails, refetch: refetchPartnerSpecificDetails } = useGetAsbGroupContractDetailsQuery({
    contractId: contractId || ''
  })

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const editASBGroupTaxesSchema = object({
    hasSignedTaxReliefForm: boolean(),
    hasEmployeeTaxReduction: boolean(),
    hasDisabilityReduction: boolean(),
    willApplyTaxRelief: boolean()
  })

  type EditASBGroupTaxesForm = TypeOf<typeof editASBGroupTaxesSchema>

  const updateMutation = useUpdateAsbGroupContractDetailTaxesMutation()

  const taxes = partnerSpecificDetails?.asbGroupContractDetailByContractId.asbGroupTaxesModel

  const form = useForm<EditASBGroupTaxesForm>({
    resolver: zodResolver(editASBGroupTaxesSchema),
    defaultValues: {
      hasSignedTaxReliefForm: taxes?.hasSignedTaxReliefForm ?? false,
      hasEmployeeTaxReduction: taxes?.hasEmployeeTaxReduction ?? false,
      hasDisabilityReduction: taxes?.hasDisabilityReduction ?? false,
      willApplyTaxRelief: taxes?.willApplyTaxRelief ?? false
    }
  })

  const handleOnSubmit = async (taxes: EditASBGroupTaxesForm) => {
    await updateMutation
      .mutateAsync({
        asbGroupContractDetailTaxesCommand: {
          id: partnerSpecificDetails?.asbGroupContractDetailByContractId.id || '',
          hasSignedTaxReliefForm: taxes?.hasSignedTaxReliefForm,
          hasEmployeeTaxReduction: taxes?.hasSignedTaxReliefForm ? taxes?.hasEmployeeTaxReduction : false,
          hasDisabilityReduction: taxes?.hasSignedTaxReliefForm ? taxes?.hasDisabilityReduction : false,
          willApplyTaxRelief: taxes?.hasSignedTaxReliefForm ? taxes?.willApplyTaxRelief : false
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
      .catch(e => setBackendErrors([e]))
  }

  const { control } = form
  const watchedSignedTaxReliefForm = useWatch({
    control,
    name: `hasSignedTaxReliefForm`
  })

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editasbgrouptaxes.title')}</Typography>
      <FormGridLayout>
        <FormSwitch sx={12} name="hasSignedTaxReliefForm" label={t('form.field.hassignedtaxreliefform')} />

        {watchedSignedTaxReliefForm && (
          <>
            <FormSwitch sx={12} name="hasEmployeeTaxReduction" label={t('form.field.hasemployeetaxreduction')} />
            <FormSwitch sx={12} name="hasDisabilityReduction" label={t('form.field.hasdisabilityreduction')} />
            <FormSwitch sx={12} name="willApplyTaxRelief" label={t('form.field.willapllytaxrelief')} />

            <Typography
              sx={{
                pl: '1.25rem',
                pt: '0.25rem',
                fontSize: 'small',
                color: theme.palette.text.secondary
              }}
            >
              {t('flyin.editasbgrouptaxes.info')}
            </Typography>
          </>
        )}
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={updateMutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditAsbgroupTaxes

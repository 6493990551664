import { createEnhancedJSONStorage } from '@epix-web-apps/core'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface IPayrollCodesPersistedStore {
  codeFilter: string
  setCodeFilter: (code: string) => void
  descriptionFilter: string
  setDescriptionFilter: (description: string) => void
  groupFilter: string | null
  setGroupFilter: (group: string | null) => void
  subGroupFilter: string | null
  setSubGroupFilter: (subGroup: string | null) => void
  showSalaryCodesFilter: boolean
  setShowSalaryCodesFilter: (salaryCodes: boolean) => void
  showCalendarCodesFilter: boolean
  setShowCalendarCodesFilter: (calendarCodes: boolean) => void
  usableInSelfServiceFilter: boolean
  setUsableInSelfServiceFilter: (usableInSelfService: boolean) => void
}

export const usePayrollCodesPersistedStore = create<IPayrollCodesPersistedStore>()(
  persist(
    (set, get) => ({
      codeFilter: '',
      setCodeFilter: async (code: string) => {
        set({ codeFilter: code })
      },
      descriptionFilter: '',
      setDescriptionFilter: async (description: string) => {
        set({ descriptionFilter: description })
      },
      groupFilter: null,
      setGroupFilter: async (group: string | null) => {
        set({ groupFilter: group })
      },
      subGroupFilter: null,
      setSubGroupFilter: async (subGroup: string | null) => {
        set({ subGroupFilter: subGroup })
      },
      showSalaryCodesFilter: false,
      setShowSalaryCodesFilter: async (salaryCodes: boolean) => {
        set({ showSalaryCodesFilter: salaryCodes })
      },
      showCalendarCodesFilter: false,
      setShowCalendarCodesFilter: async (calendarCodes: boolean) => {
        set({ showCalendarCodesFilter: calendarCodes })
      },
      usableInSelfServiceFilter: false,
      setUsableInSelfServiceFilter: async (usableInSelfService: boolean) => {
        set({ usableInSelfServiceFilter: usableInSelfService })
      }
    }),
    {
      name: 'payrollCodesPersistedStore',
      storage: createEnhancedJSONStorage(() => localStorage)
    }
  )
)

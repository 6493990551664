import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  DEFAULT_LOCALE,
  GetFirstDayOfNextYear,
  GetFirstDayOfPreviousYear,
  GetFirstDayOfYear,
  GetYearOfDate,
  ToBackendFormatedDate,
  isDateStringBeforeDate,
  useGetRequestPolicyContractByIdByTeamStructureQuery,
  useGetTotalBalanceCounterForRequestPolicyContractQuery
} from '@epix-web-apps/core'
import { useState } from 'react'
import { PeriodNavigation, useGlobalStore } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface BalanceCalculationCountersProps {
  requestPolicyContractId: string
}

export function BalanceCalculationCounters(props: BalanceCalculationCountersProps) {
  const { t } = useTranslation()
  const { me } = useGlobalStore()
  const [currentYear, setCurrentYear] = useState(GetFirstDayOfYear(new Date()))

  const BoxItem = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }))

  const { data } = useGetTotalBalanceCounterForRequestPolicyContractQuery({
    requestPolicyContractId: props.requestPolicyContractId,
    viewDate: ToBackendFormatedDate(currentYear)
  })

  const { data: requestPolicyContract } = useGetRequestPolicyContractByIdByTeamStructureQuery({
    id: props.requestPolicyContractId
  })

  return (
    <>
      <Box
        sx={{
          mt: 2
        }}
      >
        <PeriodNavigation
          disabledLeftButton={
            currentYear.getFullYear() <=
            new Date(requestPolicyContract?.requestPolicyContractByIdByTeamStructure.startDate).getFullYear()
          }
          disabledRightButton={isDateStringBeforeDate(
            currentYear,
            requestPolicyContract?.requestPolicyContractByIdByTeamStructure.endDate
          )}
          next={() => setCurrentYear(GetFirstDayOfNextYear(currentYear))}
          prev={() => setCurrentYear(GetFirstDayOfPreviousYear(currentYear))}
          currentMonth={GetYearOfDate(currentYear, me?.locale.locale || DEFAULT_LOCALE)}
        />
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Box>
              <Typography fontWeight={'bold'}>
                {t('balancecaculationcounters.total')} -{' '}
                {data?.totalBalanceCounterForRequestPolicyContract.requestPolicyName}
              </Typography>
              <Typography>
                {t('balancecaculationcounters.balance')}: {data?.totalBalanceCounterForRequestPolicyContract.balance}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <BoxItem>
                <p>{t('balancecaculationcounters.category.taken')}</p>
                <p>{data?.totalBalanceCounterForRequestPolicyContract.taken}</p>
              </BoxItem>
              <BoxItem>
                <p>{t('balancecaculationcounters.category.planned')}</p>
                <p>{data?.totalBalanceCounterForRequestPolicyContract.planned}</p>
              </BoxItem>
              <BoxItem>
                <p>{t('balancecaculationcounters.category.balance')}</p>
                <p>{data?.totalBalanceCounterForRequestPolicyContract.balance}</p>
              </BoxItem>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box sx={{ mt: 2 }}>
        {data?.totalBalanceCounterForRequestPolicyContract.counters.map(entry => (
          <Accordion key={entry.requestPolicyPayrollCodeId}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Box>
                <Typography fontWeight={'bold'}>{entry.type}</Typography>
                <Typography>
                  {t('balancecaculationcounters.category.balance')}: {entry.balance}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <BoxItem>
                  <p>{t('balancecaculationcounters.category.taken')}</p>
                  <p>{entry.taken}</p>
                </BoxItem>
                <BoxItem>
                  <p>{t('balancecaculationcounters.category.planned')}</p>
                  <p>{entry.planned}</p>
                </BoxItem>
                <BoxItem>
                  <p>{t('balancecaculationcounters.category.balance')}</p>
                  <p>{entry.balance}</p>
                </BoxItem>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </>
  )
}

export default BalanceCalculationCounters

import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, literal, object, string, TypeOf } from 'zod'
import { FormGridLayout } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { useFlyIn } from '@epix-web-apps/ui'
import {
  GetUserByEmailQuery,
  GraphqlError,
  isAuthorized,
  ROLE,
  useCreateUserMutation,
  useGetAllLocalesQuery,
  useGetMeQuery,
  useGetPersonByIdQuery,
  useGetUserByPersonIdQuery,
  useGetUserByEmailQuery,
  useUpdateUserMutation,
  useUpdateUserRolesMutation,
  FormSelectOption,
  formTypeSelectOptions
} from '@epix-web-apps/core'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { useParams } from 'react-router-dom'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'
import { FormInput } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { useGlobalStore } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'
import { FormSelect } from '@epix-web-apps/ui'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface EditSelfServiceAccessProps {}

export function EditSelfServiceAccess(props: EditSelfServiceAccessProps) {
  const { t } = useTranslation()
  const editSelfServiceSchema = object({
    hasAccess: boolean(),
    selfServiceEmail: string().email(t('form.validation.invalidemail')).or(literal('')),
    localeCode: string({
      required_error: t('form.validation.languagerequired'),
      invalid_type_error: t('form.validation.languagerequired')
    }).min(1, t('form.validation.languagerequired')),
    currency: string({
      required_error: t('form.validation.currencyrequired'),
      invalid_type_error: t('form.validation.currencyrequired')
    }).min(1, t('form.validation.currencyrequired'))
  })

  type EditSelfServiceForm = TypeOf<typeof editSelfServiceSchema>
  const params = useParams<ContractDetailPageParams>()
  const { closeFlyIn } = useFlyIn()
  const { me } = useGlobalStore()
  const { currencyOptions } = formTypeSelectOptions
  const { refetch: refetchMe } = useGetMeQuery()
  const { data: getAllLocales } = useGetAllLocalesQuery({
    ignoreTranslations: true,
    onlySupported: true
  })
  const localeOptions = getAllLocales?.locales?.map(x => new FormSelectOption(x.locale, x.language))
  const personId = params?.id || ''
  const { data: getPersonById } = useGetPersonByIdQuery(
    {
      personId: personId
    },
    {
      enabled: !!personId
    }
  )
  const { data: getUserByPersonId, refetch: refetchUserByPersonId } = useGetUserByPersonIdQuery(
    {
      personId: personId
    },
    {
      enabled: !!personId,
      useErrorBoundary: false
    }
  )

  const [formValues, setFormValues] = useState<EditSelfServiceForm>()
  useGetUserByEmailQuery(
    {
      userEmail: formValues?.selfServiceEmail || ''
    },
    {
      enabled: !!formValues?.selfServiceEmail,
      suspense: false,
      useErrorBoundary: false,
      onError: () => upsertSelfServiceUser(undefined),
      onSuccess: data => upsertSelfServiceUser(data)
    }
  )

  const upsertSelfServiceUser = async (getUserByEmail?: GetUserByEmailQuery) => {
    const coupledUser = getUserByPersonId?.userByPersonId
    const userToCouple = getUserByEmail?.userByEmail
    const userToCoupleAlreadyInSelfServiceRole = isAuthorized(userToCouple?.roles, [ROLE.SELFSERVICEUSER])
    const userToUpdateIsNotTheSameUser = userToCouple?.email !== coupledUser?.email

    if (formValues && formValues.hasAccess) {
      if (coupledUser && isSelfServiceUser) {
        await updateUsermutation.mutateAsync({
          updateUserCommand: {
            id: coupledUser.id,
            firstName: coupledUser.firstName || '',
            lastName: coupledUser.lastName || '',
            roleNames: coupledUser.roles.filter(x => x.toUpperCase() !== ROLE.SELFSERVICEUSER.toString()),
            currency: coupledUser.currency,
            locale: coupledUser.locale.locale,
            isActive: coupledUser.isActive,
            personId: null
          }
        })
      }
      if (userToCouple) {
        if (userToCoupleAlreadyInSelfServiceRole && userToUpdateIsNotTheSameUser) {
          setBackendErrors([
            {
              message: t('form.validation.alreadyinselfservicerole'),
              name: 'alreadyinselfservicerole'
            } as GraphqlError
          ])
        } else {
          const userRoles = userToCouple.roles
          userRoles.push(ROLE.SELFSERVICEUSER)
          await updateUsermutation
            .mutateAsync({
              updateUserCommand: {
                id: userToCouple.id,
                firstName: userToCouple.firstName || '',
                lastName: userToCouple.lastName || '',
                roleNames: userRoles,
                currency: userToUpdateIsNotTheSameUser ? userToCouple.currency : formValues.currency,
                locale: userToUpdateIsNotTheSameUser ? userToCouple.locale.locale : formValues.localeCode,
                isActive: userToCouple.isActive,
                personId: personId
              }
            })
            .then(() => {
              refetchUserByPersonId()
              refetchMe()
              closeFlyIn()
            })
            .catch(e => setBackendErrors([e]))
        }
      } else {
        await createUsermutation
          .mutateAsync({
            createUserCommand: {
              personId: personId,
              firstName: getPersonById?.personById.firstName || '',
              lastName: getPersonById?.personById.lastName || '',
              roleNames: [ROLE.SELFSERVICEUSER],
              email: formValues.selfServiceEmail,
              currencyCode: formValues.currency,
              localeCode: formValues.localeCode,
              isActive: true
            }
          })
          .then(() => {
            refetchUserByPersonId()
            refetchMe()
            closeFlyIn()
          })
          .catch(e => setBackendErrors([e]))
      }
    } else {
      await updateUserRoleMutation
        .mutateAsync({
          updateUserRolesCommand: {
            id: coupledUser?.id || '',
            roles: coupledUser?.roles.filter(x => x.toUpperCase() !== ROLE.SELFSERVICEUSER.toString()) || []
          }
        })
        .then(() => {
          refetchUserByPersonId()
          refetchMe()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  const isSelfServiceUser = isAuthorized(getUserByPersonId?.userByPersonId?.roles, [ROLE.SELFSERVICEUSER])
  const [hasAccess, setHasAccess] = useState(isSelfServiceUser)
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const createUsermutation = useCreateUserMutation()
  const updateUsermutation = useUpdateUserMutation()
  const updateUserRoleMutation = useUpdateUserRolesMutation()

  const form = useForm<EditSelfServiceForm>({
    resolver: zodResolver(editSelfServiceSchema),
    defaultValues: {
      hasAccess: isSelfServiceUser,
      selfServiceEmail: getUserByPersonId?.userByPersonId?.email || getPersonById?.personById.workEmail || '',
      localeCode: getUserByPersonId?.userByPersonId?.locale.locale || me?.locale?.locale || '',
      currency: getUserByPersonId?.userByPersonId?.currency || me?.currency || ''
    }
  })

  const handleOnSubmit = async (editSelfService: EditSelfServiceForm) => {
    setFormValues(editSelfService)
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editselfserviceaccess.title')}</Typography>

      <FormGridLayout>
        <FormSwitch
          sx={12}
          name="hasAccess"
          label={t('form.field.selfservice.hasaccess')}
          onChange={hasAccess => setHasAccess(hasAccess)}
        />

        <FormInput sx={12} name="selfServiceEmail" label={t('form.field.selfservice.email')} disabled={!hasAccess} />

        <FormSelect
          sx={6}
          name="localeCode"
          label={`${t('form.field.defaultlanguage')} *`}
          options={localeOptions}
          disabled={!hasAccess}
        />

        <FormSelect
          sx={6}
          name="currency"
          label={`${t('form.field.defaultcurrency')} *`}
          options={currencyOptions}
          disabled={!hasAccess}
        />
      </FormGridLayout>

      <Typography color={'gray'} sx={{ fontStyle: 'italic' }}>
        {t('contractdetailpage.selfserviceaccess.note')}
      </Typography>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={createUsermutation.isLoading || updateUsermutation.isLoading || updateUserRoleMutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default EditSelfServiceAccess

import { FormControl, GridSize, InputLabel, OutlinedInput } from '@mui/material'
import { ChangeEventHandler, FocusEventHandler, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { OptionalGridWrapper } from '../form-grid-wrapper'

export interface FormInputProps {
  name: string
  label: string
  sx?: GridSize
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined
  onBlur?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined
  disabled?: boolean
  multiline?: boolean
  rows?: number
  shrink?: boolean
  placeholder?: string
}

export function FormInput({
  name,
  label,
  sx,
  onChange,
  onBlur,
  placeholder,
  shrink,
  disabled = false,
  multiline = false,
  rows = multiline ? 4 : 0
}: FormInputProps) {
  const form = useFormContext()
  if (!form) throw Error('Form elements can only be used inside the react hook forms provider')
  const { ref, ...register } = form.register(name)
  const [isFocused, setIsFocused] = useState(false)
  const formValue = form?.getValues(name)

  return (
    <OptionalGridWrapper sx={sx}>
      <FormControl fullWidth size="small">
        <InputLabel shrink={shrink || isFocused || !!formValue} htmlFor={register.name}>
          {label}
        </InputLabel>
        <OutlinedInput
          {...register}
          ref={ref}
          id={name}
          label={label}
          placeholder={placeholder}
          defaultValue={formValue}
          onChange={e => {
            e.currentTarget.value = e.currentTarget.value.replace(/,/g, '.')
            register.onChange(e)
            onChange && onChange(e)
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={e => {
            setIsFocused(false)
            register.onBlur(e)
            onBlur && onBlur(e)
          }}
          error={!!form.getFieldState(name).error}
          disabled={disabled}
          multiline={multiline}
          rows={rows}
        />
      </FormControl>
    </OptionalGridWrapper>
  )
}

export default FormInput

import { Box, Button, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { NOT_AUTHENTICATED, NOT_AUTHORIZED } from './error-code-constants'
import SentimentVeryDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentVeryDissatisfiedOutlined'
import { EpixLoadingPage } from '@epix-web-apps/ui'
import { environment } from '../../../../environments/environment'
import { AuthError, GraphqlError } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface ErrorBoundryHandlerProps {
  error: Error | AuthError | GraphqlError
}

export function ErrorBoundryHandler({ error }: ErrorBoundryHandlerProps) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  let authorizationFailed = false
  let showErrorInProduction = false
  let errorMessage = error?.message || t('error.unknown')

  if (error instanceof AuthError && error.code === 401 ||
    (error instanceof GraphqlError && error?.extensions?.code && error.extensions.code === NOT_AUTHENTICATED)) {
      sessionStorage.clear()
      window.location.reload()
      return <EpixLoadingPage message="Reauthenticating, session expired..." />
  }
  if (error instanceof AuthError && error.code === 403 ||
    (error instanceof GraphqlError && error?.extensions?.code && error.extensions.code === NOT_AUTHORIZED)) {
      authorizationFailed = true
      showErrorInProduction = true
      if (!error?.message) errorMessage = t('error.authorizationfailed')
  }

  const clearCacheAndReturnHome = () => {
    localStorage.clear()
    queryClient.clear()
    window.location.href = window.location.origin
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      role="alert"
    >
      <Box
        sx={{
          mt: '6%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2
          }}
          variant="h2"
        >
          <SentimentVeryDissatisfiedOutlinedIcon sx={{ fontSize: '6rem' }} />
        </Typography>
        <Typography variant="h2" align="center">{t('error.somethingwentwrong')}</Typography>
        {(!environment.production || showErrorInProduction) && (
          <Paper sx={{ px: 3 }}>
            <pre>{errorMessage}</pre>
          </Paper>
        )}
        <Box sx={{ mt: 8 }}>
          {authorizationFailed && (
            <Typography align="center" color="primary" sx={{ fontWeight: 'bold' }} >
              {t('error.checkurlmispelled')}
            </Typography>
          )}
          {!authorizationFailed && (
            <Button variant="outlined" onClick={clearCacheAndReturnHome}>
              {t('error.returntohomeclearcache')}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ErrorBoundryHandler

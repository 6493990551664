import {
  GraphqlError,
  useDeleteRequestPolicyPayrollCodeMutation,
  useGetRequestPolicyPayrollCodeByIdQuery
} from '@epix-web-apps/core'
import { useFlyIn } from '@epix-web-apps/ui'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormContainer } from '@epix-web-apps/ui'
import { Typography } from '@mui/material'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface RemoveRequestPolicyPayrollcodeProps {
  requestPolicyId: string
  requestPolicyPayrollCodeId: string
}

export function RemoveRequestPolicyPayrollcode({
  requestPolicyId,
  requestPolicyPayrollCodeId
}: RemoveRequestPolicyPayrollcodeProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const deleteMutation = useDeleteRequestPolicyPayrollCodeMutation()
  const form = useForm()

  const { data: requestPolicyPayrollCodeById } = useGetRequestPolicyPayrollCodeByIdQuery(
    {
      id: requestPolicyPayrollCodeId || '',
      requestPolicyId: requestPolicyId || ''
    },
    {
      suspense: false
    }
  )

  const handleOnSubmit = async () => {
    await deleteMutation
      .mutateAsync({
        deleteRequestPolicyPayrollCodeCommand: {
          requestPolicyPayrollCodeId: requestPolicyPayrollCodeId || '',
          requestPolicyId: requestPolicyId || ''
        }
      })
      .then(closeFlyIn)
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.deleterequestpolicypayrollcode.title')}</Typography>
      <Typography>
        {t('flyin.deleterequestpolicypayrollcode.subtitle')}{' '}
        {requestPolicyPayrollCodeById?.requestPolicyPayrollCodeById.description}
      </Typography>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={deleteMutation.isLoading}
        buttonText={t('common.remove')}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default RemoveRequestPolicyPayrollcode

import { Typography } from '@mui/material'
import { FormContainer } from '@epix-web-apps/ui'
import { TypeOf, boolean, date, object, string } from 'zod'
import { useTranslation } from 'react-i18next'
import {
  FormSelectOption,
  GraphqlError,
  useCreateRequestPolicyContractMutation,
  useGetAllRequestPoliciesByEmployerIdQuery,
  useGetContractByIdQuery,
  useGetRequestPolicyContractByIdQuery,
  useGetRequestPolicyContractsByContractIdQuery,
  useUpdateRequestPolicyContractMutation
} from '@epix-web-apps/core'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormGridLayout } from '@epix-web-apps/ui'
import { FormSelect } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { FormSwitch } from '../../form-components/form-switch'
import { FormActionButtons, FormDatepicker, FormErrorList } from '@epix-web-apps/ui'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface AddEditRequestPolicyContractProps {
  requestPolicyContractId?: string
  contractId: string
}

export function AddEditRequestPolicyContract({
  requestPolicyContractId,
  contractId
}: AddEditRequestPolicyContractProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const { data: getContractById } = useGetContractByIdQuery({
    contractId: contractId
  })

  const { data: getAllRequestPoliciesByEmployerId } = useGetAllRequestPoliciesByEmployerIdQuery(
    {
      offset: 0,
      limit: -1,
      employerId: getContractById?.contractById.employerId || ''
    },
    {
      enabled: !!getContractById?.contractById.employerId
    }
  )

  const { refetch: refetchRequestPolicies } = useGetRequestPolicyContractsByContractIdQuery(
    {
      contractId: contractId
    },
    {
      suspense: false
    }
  )

  const { data: getRequestPolicyContractById, refetch: refetchRequestPolicyContractById } =
    useGetRequestPolicyContractByIdQuery(
      {
        id: requestPolicyContractId || ''
      },
      {
        enabled: !!requestPolicyContractId
      }
    )

  const addEditRequestPolicyContractSchema = object({
    requestPolicyId: string({
      required_error: t('form.validation.requestpolicyrequired'),
      invalid_type_error: t('form.validation.requestpolicyrequired')
    }),
    startDate: date({
      required_error: t('form.validation.startdaterequired'),
      invalid_type_error: t('form.validation.startdaterequired')
    }),
    balanceShown: boolean(),
    endDate: date().optional().nullable()
  }).refine(data => (data.endDate ? data.endDate >= data.startDate : !data.endDate), {
    message: t('form.validation.enddateafterstartdate'),
    path: ['endDate']
  })

  type AddEditRequestPolicyContractForm = TypeOf<typeof addEditRequestPolicyContractSchema>

  const form = useForm<AddEditRequestPolicyContractForm>({
    resolver: zodResolver(addEditRequestPolicyContractSchema),
    defaultValues: {
      requestPolicyId: getRequestPolicyContractById?.requestPolicyContractById.requestPolicyId,
      startDate: new Date(getRequestPolicyContractById?.requestPolicyContractById.startDate),
      endDate: getRequestPolicyContractById?.requestPolicyContractById.endDate
        ? new Date(getRequestPolicyContractById?.requestPolicyContractById.endDate)
        : null,
      balanceShown: getRequestPolicyContractById?.requestPolicyContractById.balanceShown
    }
  })

  const createMutation = useCreateRequestPolicyContractMutation()
  const updateMutation = useUpdateRequestPolicyContractMutation()

  const handleOnSubmit = async (newRequestPolicyContract: AddEditRequestPolicyContractForm) => {
    if (!requestPolicyContractId) {
      await createMutation
        .mutateAsync({
          createRequestPolicyContractCommand: {
            contractId: contractId,
            requestPolicyId: newRequestPolicyContract.requestPolicyId,
            startDate: newRequestPolicyContract.startDate,
            endDate: newRequestPolicyContract.endDate ? newRequestPolicyContract.endDate : null,
            balanceShown: newRequestPolicyContract.balanceShown
          }
        })
        .then(() => {
          closeFlyIn()
          refetchRequestPolicies()
        })
        .catch(e => setBackendErrors([e]))
    } else {
      await updateMutation
        .mutateAsync({
          updateRequestPolicyContractCommand: {
            requestPolicyContractId: requestPolicyContractId,
            requestPolicyId: newRequestPolicyContract.requestPolicyId,
            startDate: newRequestPolicyContract.startDate,
            endDate: newRequestPolicyContract.endDate ? newRequestPolicyContract.endDate : null,
            balanceShown: newRequestPolicyContract.balanceShown
          }
        })
        .then(() => {
          closeFlyIn()
          refetchRequestPolicies()
          refetchRequestPolicyContractById()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.requestpolicycontract.title')}</Typography>
      <FormGridLayout>
        <FormSelect
          sx={12}
          name="requestPolicyId"
          options={getAllRequestPoliciesByEmployerId?.allRequestPoliciesByEmployer.data.map(
            x => new FormSelectOption(x.id, x.internalName)
          )}
          label={`${t('form.field.requestpolicy')} *`}
        />

        <FormDatepicker sx={6} name="startDate" label={`${t('form.field.startdate')} *`} />

        <FormDatepicker sx={6} name="endDate" label={t('form.field.enddate')} />

        <FormSwitch sx={12} name="balanceShown" label={`${t('form.field.balanceselfservice')}`} />
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={createMutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddEditRequestPolicyContract

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { Box, IconButton, Typography } from '@mui/material'

export class PageOptions {
  page: number
  size: number
  offset: number
  itemCount: number
  totalCount: number

  constructor(page: number, pageSize: number, itemCount: number, totalCount: number) {
    this.page = page
    this.size = pageSize
    this.offset = this.page * this.size
    this.itemCount = itemCount
    this.totalCount = totalCount
  }
}

interface PagingNavigationProps {
  hasNext: boolean
  onNextClick: () => void
  hasPrevious: boolean
  onPreviousClick: () => void
  isFetching: boolean
  pageOptions: PageOptions
}

export function PagingNavigation({
  hasNext,
  onNextClick,
  hasPrevious,
  onPreviousClick,
  isFetching,
  pageOptions
}: PagingNavigationProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography fontSize="small">
        {pageOptions.offset + 1} -{' '}
        {pageOptions.page * pageOptions.size + (pageOptions.size - (pageOptions.size - pageOptions.itemCount))}
        {' of '}
        {pageOptions.totalCount}
      </Typography>
      <Box>
        <IconButton
          size="small"
          disabled={isFetching || !hasPrevious}
          onClick={() => !isFetching && hasPrevious && onPreviousClick()}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          size="small"
          disabled={isFetching || !hasNext}
          onClick={() => !isFetching && hasNext && onNextClick()}
        >
          <KeyboardArrowRight />
        </IconButton>
      </Box>
    </Box>
  )
}

import { Box, Link, Typography, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { OnboardingLayout } from '../../../layouts'
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined'

/* eslint-disable-next-line */
export interface OnboardingEmployerLayoutProps {
  onLinkClick: () => void
}

export function OnboardingEmployerLayout({ onLinkClick }: OnboardingEmployerLayoutProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <>
      <OnboardingLayout title={t('employerspage.onboarding.title')} icon={<ApartmentOutlinedIcon />} />
      <Box
        sx={{
          marginLeft: '20%',
          marginRight: '20%'
        }}
      >
        <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
          {t('employerspage.onboarding.description')}
        </Typography>
        <Box
          sx={{
            textAlign: 'center'
          }}
        >
          <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
            <Trans
              i18nKey={t('employerspage.onboarding.description2')}
              values={{ linktoemployer: t('employerspage.onboarding.here') }}
              components={{
                tt: <Link underline="always" onClick={onLinkClick} />
              }}
            />
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default OnboardingEmployerLayout

import { ToBackendFormatedDate, isDateISOString } from '../utils'

export interface AuthError extends Error {
  code: number
}
export const AuthError = class AuthErrorContructor extends Error implements AuthError {
  code!: number

  constructor(error: AuthError) {
    super()
    Object.assign(this, error)
  }
}

export interface GraphqlError extends Error {
  extensions: { code: string }
  locations?: Array<{ line: number; column: number }>
  path: Array<string>
}
export const GraphqlError = class GraphqlErrorContructor extends Error implements GraphqlError {
  extensions!: { code: string }
  locations?: { line: number; column: number }[] | undefined
  path!: string[]

  constructor(error: GraphqlError) {
    super()
    Object.assign(this, error)
  }
}

// Globally apply to body json parser
const replacer = function (this: any, key: any, value: any) {
  if (this[key] instanceof Date) {
    return ToBackendFormatedDate(this[key])
  }
  if (isDateISOString(value)) {
    return ToBackendFormatedDate(new Date(value))
  }

  return value
}

export const CustomFetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: HeadersInit | undefined
) => {
  let headers: HeadersInit | undefined = {
    __tenant__: sessionStorage.getItem('tenant') || '',
    __language__: sessionStorage.getItem('language') || ''
  }
  let body: string | FormData
  const files = variables && Object.values(variables as any).filter(x => x instanceof File)

  if (query.includes('Upload') && files && files.length > 0) {
    headers = { ...headers, ...{ 'GraphQL-preflight': '1' } }
    body = new FormData()
    body.append('operations', JSON.stringify({ query, variables }, replacer))
    body.append('map', '{ "0": ["variables.file"] }')
    body.append('0', files[0] as File)
  } else {
    headers = { ...headers, ...{ 'Content-Type': 'application/json' } }
    body = JSON.stringify({ query, variables }, replacer)
  }

  return async (): Promise<any> => {
    const response = await fetch(process.env['NX_GRAPHQL_SCHEMA'] as string, {
      method: 'POST',
      headers: headers,
      body: body
    })

    const contentType = response.headers.get('content-type')
    if (contentType && contentType.indexOf('json') !== -1) {
      const json = await response.json()
      if (json.errors) throw new GraphqlError(json.errors[0] || json.error)
      if (json.error) {
        if (json.error.code) throw new AuthError(json.error)
        throw json.error
      }
      return json.data
    } else {
      throw await response.text()
    }
  }
}

import { Box, Typography, useTheme } from '@mui/material'
import PostAddIcon from '@mui/icons-material/PostAdd'
import { useTranslation } from 'react-i18next'

/* eslint-disable-next-line */
export interface DataTableEmptyProps {
  title?: string | undefined
  icon?: any
}

export function DataTableEmpty({ title, icon }: DataTableEmptyProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <Box sx={{ textAlign: 'center' }}>
      <>
        {!icon ? <PostAddIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} /> : icon}
        <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
          {!title ? t('emptystate.general') : title}
        </Typography>
      </>
    </Box>
  )
}

export default DataTableEmpty

import { IconButton, MenuItem, useTheme } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowId, GridRowsProp } from '@mui/x-data-grid'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CompanyCarModel, OrderDirection, useGetCompanyCarsQuery } from '@epix-web-apps/core'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import DoneIcon from '@mui/icons-material/Done'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { DataTable, ISortModel, SortModel } from '../../../data-table'
import DropdownMenu from '../../../dropdown-menu'
import { useNavigate } from 'react-router-dom'
import { useCompanyCarsStatisticsPersistedStore } from '../../../../stores/company-cars-statistics-store'
import { CompanyCar, useFlyIn } from '@epix-web-apps/ui'
import { RemoveCompanyCar } from '../../remove-company-car'

/* eslint-disable-next-line */
export interface CompanyCarsTabOverviewProps {
  triggerRefetch: boolean
  setTriggerRefetch: Dispatch<SetStateAction<boolean>>
}

export function CompanyCarsTabOverview({ triggerRefetch, setTriggerRefetch }: CompanyCarsTabOverviewProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [rowCount, setRowCount] = useState(0)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [clickedCompanyCar, setClickedCompanyCar] = useState<CompanyCarModel>()
  const theme = useTheme()
  const navigate = useNavigate()

  const activeActions = (rowParams: GridRenderCellParams) =>
    rowParams.value ? (
      <DoneIcon sx={{ color: theme.palette.success.main }} />
    ) : (
      <CancelOutlinedIcon sx={{ color: theme.palette.error.light }} />
    )

  const rowActions = (rowParams: GridRenderCellParams) => {
    return (
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setClickedCompanyCar(rowParams.row)
          setAnchorEl(e.currentTarget)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
    )
  }
  const columns: GridColDef[] = [
    {
      field: 'countryKey',
      headerName: t('companycarspage.datatable.column.countryKey'),
      flex: 1
    },
    {
      field: 'licensePlate',
      headerName: t('companycarspage.datatable.column.licenseplate'),
      flex: 1
    },
    {
      field: 'carBrandType',
      headerName: t('companycarspage.datatable.column.brand'),
      flex: 1
    },
    {
      field: 'model',
      headerName: t('companycarspage.datatable.column.model'),
      flex: 1
    },
    {
      field: 'co2',
      headerName: t('companycarspage.datatable.column.co2'),
      flex: 1
    },
    {
      field: 'startDate',
      headerName: t('companycarspage.datatable.column.startdate'),
      flex: 1
    },
    {
      field: 'endDate',
      headerName: t('companycarspage.datatable.column.enddate'),
      flex: 1
    },
    {
      field: 'carCategory',
      headerName: t('companycarspage.datatable.column.category'),
      flex: 1
    },
    {
      field: 'isInUse',
      headerName: t('companycarspage.datatable.column.isinuse'),
      flex: 1,
      sortable: true,
      editable: false,
      renderCell: activeActions
    },
    { field: '', sortable: false, editable: false, renderCell: rowActions }
  ]

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns[0]?.field, OrderDirection.Asc))
  const [tableData, setTableData] = useState<GridRowsProp>([])

  useEffect(() => {
    if (triggerRefetch) {
      refetchAllCompanyCars()
      setTriggerRefetch(false)
    }
  }, [triggerRefetch])

  const store = useCompanyCarsStatisticsPersistedStore()

  const {
    data: CompanyCarsData,
    isLoading: isLoadingCompanyCars,
    refetch: refetchAllCompanyCars
  } = useGetCompanyCarsQuery(
    {
      startDate: store.startDateFilter,
      endDate: store.endDateFilter,
      companyCarFilterModel: {
        carCategoryId: store.carCategoryFilter,
        country: store.countryFilter,
        motorTypeKey: store.motorTypeFilter,
        endsInPeriod: store.endsInPeriodFilter,
        showCars: store.showCarsFilter
      },
      offset: page * pageSize,
      limit: pageSize,
      sortByProperty: sortModel.field,
      orderDirection: sortModel.orderDirection
    },
    {
      suspense: false
    }
  )

  useEffect(() => {
    if (CompanyCarsData && CompanyCarsData.companyCars) {
      const companyCars = CompanyCarsData.companyCars
      setRowCount(companyCars.totalCount)
      setTableData(
        companyCars.data.map(row => {
          return {
            id: row.id,
            countryKey: row.countryKey,
            licensePlate: row.licensePlate,
            carBrandType: row.carBrandType,
            model: row.model,
            co2: row.co2,
            startDate: new Date(row.startDate).toLocaleDateString(),
            endDate: row.endDate ? new Date(row.endDate).toLocaleDateString() : null,
            carCategory: row.carCategory,
            isInUse: row.isInUse
          }
        })
      )
    }
  }, [CompanyCarsData])

  const goToCompanyCarDetailPage = (companyCarId: GridRowId | undefined) => {
    companyCarId && navigate(CompanyCar.ID(`${companyCarId}`))
  }

  return (
    <>
      <DataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        page={page}
        onPageChange={setPage}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        onSortChange={setSortModel}
        isLoading={isLoadingCompanyCars}
        defaultSortModel={sortModel}
        onRowClick={row => goToCompanyCarDetailPage(row.id)}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => goToCompanyCarDetailPage(clickedCompanyCar?.id)}>
          {t('companycarspage.list.row.menu.view-details')}
        </MenuItem>
        {!clickedCompanyCar?.isInUse && (
          <MenuItem
            onClick={() =>
              openFlyIn({
                content: <RemoveCompanyCar companyCarId={clickedCompanyCar?.id || ''} />,
                callbackAfterClose: () => refetchAllCompanyCars
              })
            }
          >
            {t('companycarspage.list.row.menu.remove-companycar')}
          </MenuItem>
        )}
      </DropdownMenu>
    </>
  )
}

export default CompanyCarsTabOverview

import { useTranslation } from 'react-i18next'
import { useGlobalPersistedStore } from '@epix-web-apps/ui'
import { useLabourCostAnalyticsPersistedStore } from '../../../stores/labour-cost-analytics-store'
import BarchartComponent from '../../analytics-components/barchart-component'
import { Typography } from '@mui/material'
import { DEFAULT_CURRENCY, getNumberOfColors, useGetLabourCostPeriodQuery, useGetMeQuery } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface LabourCostPeriodChartComponentProps {}

function convertData(data: any, locale: any) {
  const result = data?.map((x: any) => {
    const { subGroupType, ...temp } = x
    const dateParts = temp['monthAndYear'].split('-')
    temp['monthAndYear'] = new Date(Date.UTC(dateParts[1], dateParts[0] - 1)).toLocaleDateString(locale, {
      year: 'numeric',
      month: 'short'
    })
    const typesData = Object.assign(
      {},
      ...subGroupType.map((y: any) => ({
        [Object.values(y)[0] as string]: Object.values(y)[1]
      }))
    )
    return { ...temp, ...typesData }
  })
  return result
}

function getKeys(data: any) {
  return [...new Set(data?.flatMap((x: any) => x.subGroupType.map((type: any) => type.key)))]
}

export function LabourCostPeriodChartComponent(props: LabourCostPeriodChartComponentProps) {
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const { data: me } = useGetMeQuery()

  const store = useLabourCostAnalyticsPersistedStore()
  const { data: labourCostPeriod } = useGetLabourCostPeriodQuery({
    icps: icpFilters,
    calculatedDataFilterModel: {
      startDate: store.dateFromFilter,
      endDate: store.dateToFilter,
      currency: store.currencyToFilter ?? me?.me.currency ?? DEFAULT_CURRENCY,
      genderType: store.genderFilter,
      functionAreaType: store.functionAreaFilter,
      functionLevelType: store.functionLevelFilter,
      employerId: store.employerFilter,
      employeeType: store.employeeTypeFilter,
      subGroupTypes: store.subGroupTypesFilter
    }
  })

  if (labourCostPeriod?.labourCostPerPeriod.entries.length === 0)
    return <Typography sx={{ p: 2, textAlign: 'center' }}>{t('analytics.nodata')}</Typography>
  return (
    <BarchartComponent
      data={convertData(labourCostPeriod?.labourCostPerPeriod.entries, me?.me.locale.locale)}
      keys={getKeys(labourCostPeriod?.labourCostPerPeriod.entries)}
      indexBy="monthAndYear"
      layoutMode="vertical"
      groupMode="stacked"
      colors={getNumberOfColors(9)}
      innerPadding={0}
      marginRight={150}
      marginLeft={100}
      axisLeft={`${labourCostPeriod?.labourCostPerPeriod.currencyModel.currency} (${labourCostPeriod?.labourCostPerPeriod.currencyModel.symbol})`}
      axisLeftPadding={-90}
    />
  )
}

export default LabourCostPeriodChartComponent

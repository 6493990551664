import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { GraphqlError, useDeleteTeamMemberMutation, useGetTeamMemberByIdQuery } from '@epix-web-apps/core'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { useState } from 'react'
import { FormContainer } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface RemoveTeamMemberProps {
  teamMemberId: string | undefined
  teamId: string | undefined
}

export function RemoveTeamMember({ teamMemberId, teamId }: RemoveTeamMemberProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const { data: getTeamMemberById } = useGetTeamMemberByIdQuery({
    id: teamMemberId || ''
  })

  const deleteMutation = useDeleteTeamMemberMutation()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const form = useForm()

  const handleOnSubmit = async () => {
    await deleteMutation
      .mutateAsync({
        deleteTeamMemberCommand: {
          id: teamMemberId || ''
        }
      })
      .then(closeFlyIn)
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.deleteteammember.title')}</Typography>
      <Typography>{getTeamMemberById?.teamMemberById.personName}</Typography>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={deleteMutation.isLoading}
        buttonText={t('common.remove')}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default RemoveTeamMember

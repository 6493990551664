import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Typography, useTheme, Button } from '@mui/material'
import { EpixDatePicker } from '../../epix-date-picker'
import { FLYIN_WIDTH, FilterSelectBox, useFlyIn } from '@epix-web-apps/ui'
import { WorkforceFilters } from '../../../pages/analytics-page/analytics-page'
import { formTypeSelectOptions, ToBackendFormatedDate } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface FilterAnalyticsFlyinProps {
  filters: WorkforceFilters
  updateStore: (filters: WorkforceFilters) => void
}

export function FilterAnalyticsFlyin({ filters: initialFilters, updateStore }: FilterAnalyticsFlyinProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [filters, setFilters] = useState(initialFilters)
  const { closeFlyIn } = useFlyIn()

  const { genderOptions, employeeOptions, functionAreaOptions, functionLevelOptions } = formTypeSelectOptions

  function handleFilter() {
    updateStore({
      ...filters,
      date: new Date(ToBackendFormatedDate(filters.date) as string)
    })
    closeFlyIn()
  }

  function handleReset() {
    updateStore({
      date: new Date(),
      gender: null,
      functionArea: null,
      functionLevel: null,
      employeeType: null
    })
    closeFlyIn()
  }

  return (
    <Box sx={{ width: FLYIN_WIDTH.DEFAULT }}>
      <Box>
        <Typography color={theme.palette.primary.main} variant="h4">
          {t('analytics.filters.title')}
        </Typography>
      </Box>

      <Box sx={{ marginBottom: 2 }}>
        <EpixDatePicker
          onChange={e => (e ? setFilters({ ...filters, date: e }) : null)}
          label={t('analytics.datepicker.viewpoint')}
          value={filters.date}
          actionBarActions={['today']}
          fullWidth={true}
        />

        <Divider sx={{ borderColor: theme.palette.divider, mt: 2 }} />
        <Box sx={{ overflow: 'hidden', py: 2 }}>
          <FilterSelectBox
            id="genderId"
            label={t('analytics.flyin.gender')}
            keys={genderOptions}
            value={filters.gender}
            handleChange={(e: string) => setFilters({ ...filters, gender: e })}
          />
          <FilterSelectBox
            id="functionAreaId"
            label={t('analytics.flyin.functionarea')}
            keys={functionAreaOptions}
            value={filters.functionArea}
            handleChange={(e: string) => setFilters({ ...filters, functionArea: e })}
          />
          <FilterSelectBox
            id="functionLevelId"
            label={t('analytics.flyin.functionlevel')}
            keys={functionLevelOptions}
            value={filters.functionLevel}
            handleChange={(e: string) => setFilters({ ...filters, functionLevel: e })}
          />
          <FilterSelectBox
            id="employeeId"
            label={t('analytics.flyin.employeetype')}
            keys={employeeOptions}
            value={filters.employeeType}
            handleChange={(e: string) => setFilters({ ...filters, employeeType: e })}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button variant="contained" onClick={handleFilter}>
            {t('analytics.flyin.filter')}
          </Button>
          <Button variant="outlined" onClick={closeFlyIn}>
            {t('analytics.flyin.cancel')}
          </Button>
        </Box>

        <Button variant="text" onClick={handleReset}>
          {t('analytics.flyin.reset')}
        </Button>
      </Box>
    </Box>
  )
}

export default FilterAnalyticsFlyin

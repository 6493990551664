import { a11yProps, EmptyState, LoadingOverlay, TabPanel } from '@epix-web-apps/ui'
import { Box, Tab, Tabs } from '@mui/material'
import { Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { FolderDocumentList } from '../../components/document-components'
import DocumenttypesTreeView from '../../components/document-components/documenttypes-tree-view/documenttypes-tree-view'

enum DocumentTabs {
  COMPANY_DOCUMENTS,
  MY_DOCUMENTS
}

/* eslint-disable-next-line */
export interface DocumentsPageProps {}

export interface NodePath {
  id: string
  name: string
}

export type DocumentPageParams = {
  icpid: string
  employerid: string
  documenttypeid: string
}

export function DocumentsPage(props: DocumentsPageProps) {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const tabIndex = parseInt(searchParams.get('tabIndex') ?? DocumentTabs.COMPANY_DOCUMENTS.toString())

  const params = useParams<DocumentPageParams>()

  const [clickedTitleName, setClickedTitleName] = useState<string>()

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%'
      }}
    >
      <Box width={'450px'}>
        <Box>
          <Tabs
            value={tabIndex}
            onChange={(_, tabIndex) => {
              searchParams.set('tabIndex', `${tabIndex}`)
              setSearchParams(searchParams, { replace: true })
            }}
          >
            <Tab label={t('documentspage.tab.companydocuments')} {...a11yProps(DocumentTabs.COMPANY_DOCUMENTS)} />
          </Tabs>
        </Box>
        <TabPanel value={tabIndex} index={DocumentTabs.COMPANY_DOCUMENTS}>
          <Suspense fallback={<LoadingOverlay />}>
            <Box sx={{ overflowY: 'auto', maxHeight: '75vh' }}>
              <DocumenttypesTreeView setTitleName={setClickedTitleName} />
            </Box>
          </Suspense>
        </TabPanel>
        <TabPanel value={tabIndex} index={DocumentTabs.MY_DOCUMENTS}></TabPanel>
      </Box>
      <Box
        sx={{
          flex: 1
        }}
      >
        {!params.documenttypeid ? (
          <Box sx={{ mt: '4rem' }}>
            <EmptyState title={t('documentspage.title.selectdocumenttype')} />
          </Box>
        ) : (
          <Suspense fallback={<LoadingOverlay />}>
            <FolderDocumentList documentTypeId={params.documenttypeid || ''} title={clickedTitleName || ''} />
          </Suspense>
        )}
      </Box>
    </Box>
  )
}

export default DocumentsPage

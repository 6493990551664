import { Box, Button, IconButton, MenuItem, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { DataTable, ISortModel, SortModel } from '../../components/data-table'
import DropdownMenu from '../../components/dropdown-menu'
import { Configuration, DetailPageBaseQueryParams, useFlyIn } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  OrderDirection,
  useGetAllCountriesQuery,
  useGetAllPayrollCodesByEmployerIdQuery,
  useGetDayDefinitionByIdQuery,
  useGetEmployerByIdQuery,
  useGetIcpByIdQuery
} from '@epix-web-apps/core'
import AddEditDayDefinitionEntry from '../../components/day-definition-components/add-edit-day-definition-entry'
import { HeaderTitleNavigation } from '@epix-web-apps/ui'
import RemoveDayDefinitionEntry from '../../components/day-definition-components/remove-day-definition-entry/remove-day-definition-entry'

/* eslint-disable-next-line */
export interface DayDefinitionEntriesPageProps {}

export type DayDefinitionEntriesDetailPageParams = DetailPageBaseQueryParams & {
  daydefinitionid: string
  employerid: string
}

export function DayDefinitionEntriesPage(props: DayDefinitionEntriesPageProps) {
  const [tableData, setTableData] = useState<GridRowsProp>([])
  const params = useParams<DayDefinitionEntriesDetailPageParams>()
  const employerId = params.employerid!
  const dayDefinitionId = params.daydefinitionid!
  const icpId = params.id!
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [dayDefinitionEntryId, setDayDefinitionEntryId] = useState('')

  const rowActions = (rowParams: GridRenderCellParams) => (
    <>
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
          setDayDefinitionEntryId(rowParams.row.id)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <IconButton aria-label="row details" aria-controls="details-row"></IconButton>
    </>
  )

  const { data: getEmployerById } = useGetEmployerByIdQuery({
    employerId: employerId
  })
  const { data: getIcpById } = useGetIcpByIdQuery({
    icpId: icpId
  })
  const { data: getCountries } = useGetAllCountriesQuery()
  const code = getIcpById?.icpById.code
  const country = getCountries?.countries.find(c => c.code.includes(code ? code : ''))?.name
  const payrollProvider = getIcpById?.icpById.payrollProvider
  const company = getEmployerById?.employerById.companyName
  const filterBar = (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: [2],
          paddingLeft: [2],
          paddingRight: [2]
        }}
      >
        <HeaderTitleNavigation
          backToLink={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_DAYDEFINITIONS(`${params.id}`, `${employerId}`)}
          title={t('daydefinitionentries.title')}
        />
        <Button
          variant="contained"
          onClick={() =>
            openFlyIn({
              content: <AddEditDayDefinitionEntry employerId={employerId} dayDefinitionId={dayDefinitionId} />,
              callbackAfterClose: () => refetchAllDayDefinitionEntries
            })
          }
        >
          <AddIcon />
          {t('daydefinitionentriespage.button.daydefinitionentries')}
        </Button>
      </Box>
      <Typography color={'gray'} variant="h3" paddingLeft={2}>
        {country} - {payrollProvider} - {company}
      </Typography>
    </Box>
  )

  const columns: GridColDef[] = [
    {
      field: 'payrollcode',
      headerName: t('daydefinitionentriespage.datatable.column.payrollcode'),
      flex: 1,
      sortable: true
    },
    {
      field: 'description',
      headerName: t('daydefinitionentriespage.datatable.column.payrollcodedescription'),
      flex: 1,
      sortable: true
    },
    {
      field: 'timeframetype',
      headerName: t('daydefinitionentriespage.datatable.column.timeframe'),
      flex: 1,
      sortable: true
    },
    {
      field: 'hours',
      headerName: t('daydefinitionentriespage.datatable.column.hours'),
      flex: 1,
      sortable: true
    },
    { field: '', sortable: false, editable: false, renderCell: rowActions }
  ]

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns?.[0].field, OrderDirection.Asc))
  const { data: payrollCodesData } = useGetAllPayrollCodesByEmployerIdQuery(
    {
      employerId: employerId || '',
      offset: 0,
      limit: -1,
      sortByProperty: 'Description',
      orderDirection: OrderDirection.Asc
    },
    {
      suspense: false
    }
  )
  const {
    data: dayDefinitionData,
    isLoading: isLoadingDayDefinitionEntries,
    refetch: refetchAllDayDefinitionEntries
  } = useGetDayDefinitionByIdQuery(
    {
      dayDefinitionId: dayDefinitionId || ''
    },
    {
      suspense: false
    }
  )

  useEffect(() => {
    if (payrollCodesData && payrollCodesData?.allPayrollCodesByEmployerId.data) {
      const payrollCodes = payrollCodesData?.allPayrollCodesByEmployerId.data
      if (dayDefinitionData?.dayDefinitionById) {
        const entries = dayDefinitionData?.dayDefinitionById.dayDefinitionEntries
        setTableData(
          entries
            .map(row => {
              let fullData: {
                code: string | undefined | null
                description: string | undefined
                uxdescription: string | undefined | null
              }[] = payrollCodes
                .filter(p => p.id === row.payrollCodeId)
                .map(p => {
                  return {
                    code: p.code,
                    description: p.description,
                    uxdescription: p.userFriendlyDescription
                  }
                })

              fullData =
                fullData.length > 0
                  ? fullData
                  : [
                      {
                        code: row.payrollCodeId,
                        description: row.payrollCodeId,
                        uxdescription: row.payrollCodeId
                      }
                    ]
              return fullData.map(d => {
                return {
                  id: row.id,
                  payrollcode: d.code,
                  description: d.uxdescription || d.description,
                  timeframetype: row.timeFrameType.value,
                  hours: row.hours
                }
              })
            })
            .reduce((acc, n) => [...acc, ...n], [])
        )
      }
    }
  }, [dayDefinitionData, payrollCodesData])

  return (
    <>
      <DataTable
        data={tableData}
        columns={columns}
        isLoading={isLoadingDayDefinitionEntries}
        defaultSortModel={sortModel}
        onSortChange={setSortModel}
        filterBarElement={filterBar}
        hideFooter={true}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <AddEditDayDefinitionEntry
                  employerId={employerId}
                  dayDefinitionId={dayDefinitionId}
                  dayDefinitionEntryId={dayDefinitionEntryId}
                />
              ),
              callbackAfterClose: () => refetchAllDayDefinitionEntries
            })
          }
        >
          {t('daydefinitionentriespage.list.row.menu.edit-daydefinitionentries')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <RemoveDayDefinitionEntry
                  dayDefinitionId={dayDefinitionId}
                  dayDefinitionEntryId={dayDefinitionEntryId}
                />
              ),
              callbackAfterClose: () => refetchAllDayDefinitionEntries
            })
          }
        >
          {t('daydefinitionentriespage.list.row.menu.remove-daydefinitionentries')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default DayDefinitionEntriesPage

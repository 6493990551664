export const environment = {
  production: true,
  auth: {
    b2cDomainName: 'myepix.b2clogin.com',
    tenantName: 'myepix.onmicrosoft.com',
    policyName: 'b2c_1_signup_signin',
    policyNameMFA: 'b2c_1_signup_signin_2fa',
    changePasswordPolicyName: 'b2c_1_reset_password',
    clientId: '4df8939c-5bcc-4f11-8151-5e44895791a0',
    redirectDomain: 'dev.myepix.io',
    redirectAuthPage: 'auth-redirect'
  },
  i18n: {
    debug: true,
    defaultLanguage: 'en-GB'
  },
  docs: {
    domainName: 'https://docs.dev.myepix.io'
  }
}

import styled from '@emotion/styled'
import { Box, Link, Typography } from '@mui/material'
import { InjuryReportingModel, getNumberOfColors, useGetMeQuery } from '@epix-web-apps/core'
import { useFlyIn } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import IncidentsListComponent from '../incidents-list-component'
import InjuriesListComponent from '../injuries-list-component'

/* eslint-disable-next-line */
export interface FatalInjuriesIncidentsDataComponentProps {
  data: InjuryReportingModel | undefined
  fatalInjuries?: boolean
}

export function FatalInjuriesIncidentsDataComponent({
  data,
  fatalInjuries = false
}: FatalInjuriesIncidentsDataComponentProps) {
  const { t } = useTranslation()
  const { data: me } = useGetMeQuery()
  const { openFlyIn } = useFlyIn()

  const LargeNumber = styled(Typography)<any>(() => {
    return {
      color: getNumberOfColors(2),
      fontSize: '4rem'
    }
  })

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          sx={{
            color: getNumberOfColors(1)
          }}
        >
          ({new Date(data?.from).toLocaleDateString(me?.me.locale.locale)} -{' '}
          {new Date(data?.to).toLocaleDateString(me?.me.locale.locale)})
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1em'
        }}
      >
        <Box
          sx={{
            flex: 1
          }}
        ></Box>
        <Box
          sx={{
            flexBasis: 'auto'
          }}
        >
          <LargeNumber>{data?.totalCount}</LargeNumber>
        </Box>
        <Box
          sx={{
            flex: 1
          }}
        >
          {data && data?.totalCount > 0 && (
            <Link
              onClick={() =>
                openFlyIn({
                  content: fatalInjuries ? <InjuriesListComponent /> : <IncidentsListComponent />
                })
              }
            >
              <p>{t('analytics.data.incidents.seewho')}</p>
            </Link>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default FatalInjuriesIncidentsDataComponent

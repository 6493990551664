import { Box, Typography } from '@mui/material'

/* eslint-disable-next-line */
export interface HeaderTitleWithIconProps {
  icon: React.ReactElement
  title: string
  mobileSizeTitle?: boolean
}

export function HeaderTitleWithIcon({ icon, title, mobileSizeTitle = false }: HeaderTitleWithIconProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {icon}
      <Typography m={1} variant={mobileSizeTitle ? 'h4' : 'h2'}>
        {title}
      </Typography>
    </Box>
  )
}

export default HeaderTitleWithIcon

import {
  formTypeSelectOptions,
  GraphqlError,
  useGetAsbGroupContractDetailsQuery,
  useUpdateAsbGroupContractDetailSocialSecurityMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormSelect,
  useFlyIn,
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, object, string } from 'zod'

/* eslint-disable-next-line */
export interface EditAsbgroupSocialSecurityProps {
  contractId: string
}

export function EditAsbgroupSocialSecurity({ contractId }: EditAsbgroupSocialSecurityProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: partnerSpecificDetails, refetch: refetchPartnerSpecificDetails } = useGetAsbGroupContractDetailsQuery({
    contractId: contractId || ''
  })

  const { asbHealthInsuranceCompanyTypeOptions } = formTypeSelectOptions

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const editASBGroupSocialSecuritySchema = object({
    healthInsuranceCompanyTypeKey: string().optional().nullable()
  })

  type EditASBGroupSocialSecurityForm = TypeOf<typeof editASBGroupSocialSecuritySchema>

  const updateMutation = useUpdateAsbGroupContractDetailSocialSecurityMutation()

  const socialSecurity = partnerSpecificDetails?.asbGroupContractDetailByContractId.asbGroupSocialSecurityModel

  const form = useForm<EditASBGroupSocialSecurityForm>({
    resolver: zodResolver(editASBGroupSocialSecuritySchema),
    defaultValues: {
      healthInsuranceCompanyTypeKey: socialSecurity?.healthInsuranceCompanyType?.key
    }
  })

  const handleOnSubmit = async (socialSecurity: EditASBGroupSocialSecurityForm) => {
    await updateMutation
      .mutateAsync({
        asbGroupContractDetailSocialSecurityCommand: {
          id: partnerSpecificDetails?.asbGroupContractDetailByContractId.id || '',
          asbGroupHealthInsuranceCompanyTypeKey: socialSecurity?.healthInsuranceCompanyTypeKey
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
      .catch(e => setBackendErrors([e]))
  }
  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editasbgroupsecurity.title')}</Typography>
      <FormGridLayout>
        <FormSelect
          sx={12}
          name="healthInsuranceCompanyTypeKey"
          label={t('form.field.healthinsurancecompany')}
          options={asbHealthInsuranceCompanyTypeOptions}
        />
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={updateMutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditAsbgroupSocialSecurity

import styled from '@emotion/styled'
import { Avatar, SxProps, Theme, useTheme } from '@mui/material'

export enum IcpCodeBadgeSize {
  'small',
  'large'
}

/* eslint-disable-next-line */
export interface IcpcodeBadgeProps {
  icpCode?: string
  size?: IcpCodeBadgeSize
  sx?: SxProps<Theme> | undefined
}

const IcpBadge = styled(Avatar)(() => {
  const theme = useTheme()
  return {
    fontSize: '0.75rem',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText
  }
})

export function IcpCodeBadge({ icpCode, size = IcpCodeBadgeSize.small, sx }: IcpcodeBadgeProps) {
  return (
    <IcpBadge
      sx={{
        ...sx,
        padding: 1.5,
        fontWeight: 'bold',
        width: size === IcpCodeBadgeSize.small ? 22 : 44,
        height: size === IcpCodeBadgeSize.small ? 22 : 44
      }}
    >
      {icpCode}
    </IcpBadge>
  )
}

export default IcpCodeBadge

import { Box, Typography, Link, useTheme, Button } from '@mui/material'
import EventIcon from '@mui/icons-material/Event'
import BalanceChart from '../balance-chart/balance-chart'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ToRouteDateFormat, useGetRequestPolicyContractsByPersonIdByTeamStructureQuery } from '@epix-web-apps/core'
import { SelfService, useGlobalStore } from '@epix-web-apps/ui'

export type BalancesProps = {
  personId: string
  date: Date
}

export function Balances({ date, personId }: BalancesProps) {
  const { me } = useGlobalStore()
  const navigate = useNavigate()
  const theme = useTheme()
  const { t } = useTranslation()

  const { data } = useGetRequestPolicyContractsByPersonIdByTeamStructureQuery(
    {},
    {
      suspense: false
    }
  )

  const hasBalanceShownTrue = data?.allRequestPolicyContractsByPersonIdByTeamStructure
    .flatMap(item => item.requestPolicyContractModels)
    .some(item => item.balanceShown)

  return (
    <>
      {hasBalanceShownTrue && (
        <Typography variant="h4">
          {t('selfservice.homepage.balances')} {date.toLocaleDateString(me?.locale.locale)}
        </Typography>
      )}
      <Box sx={{ marginTop: 2 }}>
        {hasBalanceShownTrue && (
          <Box>
            <BalanceChart dateOfCalendar={date} personId={personId} />
          </Box>
        )}
        <Box display="flex" marginTop={2} justifyContent="space-between">
          <Button
            variant="outlined"
            size="small"
            startIcon={<EventIcon />}
            onClick={() =>
              navigate(SelfService.SELFSERVICE_REQUEST_ABSENCE_DATE(`${me?.personId}`, `${ToRouteDateFormat(date)}`))
            }
          >
            {t('selfservice.balances.requestabsence')}
          </Button>
          <Link
            underline="always"
            align="right"
            onClick={() => {
              navigate(SelfService.BALANCE_OVERVIEW_DATE(`${ToRouteDateFormat(date)}`))
            }}
          >
            <Typography
              color={theme.palette.primary.main}
              fontSize="default"
              fontWeight="bold"
              sx={{ whiteSpace: 'nowrap', marginBottom: 0.5 }}
            >
              {t('selfservice.balances.viewallbalances')}
            </Typography>
          </Link>
        </Box>
      </Box>
    </>
  )
}

export default Balances

import { useTranslation } from 'react-i18next'
import { useFlyIn } from '@epix-web-apps/ui'
import { TypeOf, object, string } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormContainer } from '@epix-web-apps/ui'
import { Typography } from '@mui/material'
import { FormGridLayout } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { useCreateShiftCodeMutation, useGetShiftCodeByIdQuery, useUpdateShiftCodeMutation } from '@epix-web-apps/core'
import { useMemo } from 'react'

export interface AddEditShiftCodeProps {
  employerId: string
  shiftCodeId?: string
}

export function AddEditShiftCode({ employerId, shiftCodeId }: AddEditShiftCodeProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const addEditShiftCodeSchema = object({
    description: string({
      required_error: t('form.validation.descriptionrequired'),
      invalid_type_error: t('form.validation.descriptionrequired')
    }),
    code: string({
      required_error: t('form.validation.coderequired'),
      invalid_type_error: t('form.validation.coderequired')
    })
  })

  const { data: getShiftCodeById, refetch: refetchShiftCodeById } = useGetShiftCodeByIdQuery(
    {
      shiftCodeId: shiftCodeId || ''
    },
    {
      enabled: !!shiftCodeId
    }
  )

  type AddEditShiftCodeForm = TypeOf<typeof addEditShiftCodeSchema>

  const defaultFormValues = useMemo(
    () =>
      ({
        code: getShiftCodeById?.shiftCodeById?.code || undefined,
        description: getShiftCodeById?.shiftCodeById?.description || undefined
      } as AddEditShiftCodeForm),
    [getShiftCodeById?.shiftCodeById]
  )

  const form = useForm<AddEditShiftCodeForm>({
    resolver: zodResolver(addEditShiftCodeSchema),
    defaultValues: defaultFormValues
  })

  const createmutation = useCreateShiftCodeMutation()
  const updatemutation = useUpdateShiftCodeMutation()

  const handleOnSubmit = async (newShiftCode: AddEditShiftCodeForm) => {
    if (shiftCodeId === undefined) {
      await createmutation
        .mutateAsync({
          createShiftCodeCommand: {
            code: newShiftCode.code,
            description: newShiftCode.description,
            employerId: employerId
          }
        })
        .then(closeFlyIn)
    } else {
      await updatemutation
        .mutateAsync({
          updateShiftCodeCommand: {
            code: newShiftCode.code,
            description: newShiftCode.description,
            employerId: employerId,
            shiftCodeId: shiftCodeId
          }
        })
        .then(() => {
          refetchShiftCodeById()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.shiftcode.title')}</Typography>
      <FormGridLayout>
        <FormInput sx={12} name="code" label={t('form.field.code')} />
        <FormInput sx={12} name="description" label={t('form.field.description')} />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons
        isMutating={createmutation.isLoading || updatemutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditShiftCode

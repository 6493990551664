import {
  formTypeSelectOptions,
  useGetBuropartnerContractDetailsQuery,
  useUpdateBuropartnerContractDetailContractTypeMutation
} from '@epix-web-apps/core'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'
import { FormContainer, FormSelect, useFlyIn } from '@epix-web-apps/ui'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { FormGridLayout } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface EditBuropartnerContractTypeProps {
  contractId: string
}

export function EditBuropartnerContractType({ contractId }: EditBuropartnerContractTypeProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: partnerSpecificDetails, refetch: refetchPartnerSpecificDetails } =
    useGetBuropartnerContractDetailsQuery({
      contractId: contractId || ''
    })

  const { employmentRelationshipOptions } = formTypeSelectOptions

  const editBuropartnerContractTypeSchema = object({
    employmentRelationship: string().optional().nullable()
  })

  type EditBuropartnerContractTypeForm = TypeOf<typeof editBuropartnerContractTypeSchema>

  const updateMutation = useUpdateBuropartnerContractDetailContractTypeMutation()

  const form = useForm<EditBuropartnerContractTypeForm>({
    resolver: zodResolver(editBuropartnerContractTypeSchema),
    defaultValues: {
      employmentRelationship:
        partnerSpecificDetails?.buropartnerContractDetailByContractId.buropartnerContractDetailContractTypeModel
          .employmentRelationshipType?.key
    }
  })

  const handleOnSubmit = async (buroparterContractType: EditBuropartnerContractTypeForm) => {
    await updateMutation
      .mutateAsync({
        buropartnerContractDetailContractTypeCommand: {
          id: partnerSpecificDetails?.buropartnerContractDetailByContractId.id || '',
          employmentRelationshipTypeKey: buroparterContractType.employmentRelationship
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editburoparnercontracttype.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="employmentRelationship"
          label={t('form.field.employmentrelationships')}
          options={employmentRelationshipOptions}
        />
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons isMutating={updateMutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditBuropartnerContractType

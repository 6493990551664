import { OrderDirection, useGetInheritedTeamAuthorizationsByTeamIdQuery } from '@epix-web-apps/core'
import { GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { DataTable, ISortModel, SortModel } from '../../data-table'
import DataTableEmpty from '../../data-table/data-table-empty/data-table-empty'
import { Typography, Box, useTheme } from '@mui/material'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import { useTranslation } from 'react-i18next'

/* eslint-disable-next-line */
export interface InheritedRolesProps {
  teamId: string | undefined
}

export function InheritedRoles({ teamId }: InheritedRolesProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const [tableDataInherited, setTableDataInherited] = useState<GridRowsProp>([])
  const [pageSize, setPageSize] = useState(25)
  const [rowCount, setRowCount] = useState(0)
  const [page, setPage] = useState(0)

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('teamroles.datatable.column.name'),
      flex: 1,
      sortable: false
    },
    {
      field: 'function',
      headerName: t('teamroles.datatable.column.role'),
      flex: 1
    },
    {
      field: 'team',
      headerName: t('teamroles.datatable.column.inheritedteam'),
      flex: 1,
      sortable: false
    }
  ]

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns?.[1]?.field, OrderDirection.Asc))

  const { data: getInheritedTeamAuthorization, isLoading: isLoadingInheritedTeams } =
    useGetInheritedTeamAuthorizationsByTeamIdQuery({
      teamId: teamId || '',
      offset: page * pageSize,
      limit: pageSize,
      sortByProperty: sortModel.field,
      orderDirection: sortModel.orderDirection
    })

  useEffect(() => {
    if (getInheritedTeamAuthorization && getInheritedTeamAuthorization?.allInheritedTeamAuthorizationsByTeamId.data) {
      const data = getInheritedTeamAuthorization?.allInheritedTeamAuthorizationsByTeamId.data
      setTableDataInherited(
        data.map(row => {
          return {
            id: row.id,
            name: row.personName,
            function: row.functionName,
            team: row.teamName
          }
        })
      )
      setRowCount(getInheritedTeamAuthorization?.allInheritedTeamAuthorizationsByTeamId.totalCount)
    }
  }, [getInheritedTeamAuthorization?.allInheritedTeamAuthorizationsByTeamId])

  return (
    <Box>
      <Typography variant="h4">{t('teamdetailspage.inheritedroles.title')}</Typography>
      <DataTable
        autoHeight
        data={tableDataInherited}
        columns={columns}
        totalRowCount={rowCount}
        onSortChange={setSortModel}
        page={page}
        onPageChange={setPage}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        isLoading={isLoadingInheritedTeams}
        defaultSortModel={sortModel}
        emptyStateElement={
          <DataTableEmpty
            title={t('emptystate.teamauthorization')}
            icon={<GroupAddIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} />}
          />
        }
      />
    </Box>
  )
}

export default InheritedRoles

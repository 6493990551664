import { List, ListItemButton, ListItemText, Collapse } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface ListComponentProps {
  listItemText: string
  children?: React.ReactNode
}

export function ListComponent({ listItemText, children }: ListComponentProps) {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <List sx={{ paddingX: '0px' }} aria-labelledby="nested-list-subheader">
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={listItemText} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse sx={{ px: 0 }} in={open} timeout="auto" unmountOnExit>
        <List component="div">{children}</List>
      </Collapse>
    </List>
  )
}

export default ListComponent

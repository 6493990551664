import { Typography } from '@mui/material'
import { FormContainer } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import { useFlyIn } from '@epix-web-apps/ui'
import {
  FormSelectOption,
  OrderDirection,
  REQUEST_RIGHT_APPROVER,
  REQUEST_RIGHT_EDITOR,
  useCreateRequestPolicyFunctionMutation,
  useGetAllFunctionsQuery,
  useGetRequestPolicyFunctionByRequestPolicyIdAndRequestPolicyRightQuery
} from '@epix-web-apps/core'
import { TypeOf, object, string } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormGridLayout } from '@epix-web-apps/ui'
import { FormSelect } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { useEffect, useState } from 'react'

/* eslint-disable-next-line */
export interface AddRequestPolicyRoleProps {
  requestPolicyId: string
  requestPolicyRight: string
}

export function AddRequestPolicyRole({ requestPolicyId, requestPolicyRight }: AddRequestPolicyRoleProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [tableData, setTableData] = useState<Array<string>>([])

  const { data: getRoles } = useGetAllFunctionsQuery({
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc
  })

  const { refetch: refetchRoles } = useGetRequestPolicyFunctionByRequestPolicyIdAndRequestPolicyRightQuery({
    requestPolicyId: requestPolicyId || ''
  })

  const { data: requestPolicyFunctions } = useGetRequestPolicyFunctionByRequestPolicyIdAndRequestPolicyRightQuery({
    requestPolicyId: requestPolicyId || '',
    requestPolicyRightKey: requestPolicyRight
  })

  useEffect(() => {
    if (requestPolicyFunctions && requestPolicyFunctions?.requestPolicyFunctionByRequestPolicyId) {
      const requestPolicyFunctionsAll = requestPolicyFunctions.requestPolicyFunctionByRequestPolicyId
      setTableData(requestPolicyFunctionsAll.map(row => row.functionId))
    }
  }, [requestPolicyFunctions])

  const addRequestPolicyRolesSchema = object({
    role: string({
      required_error: t('form.validation.rolerequired'),
      invalid_type_error: t('form.validation.rolerequired')
    }).min(1, t('form.validation.rolerequired'))
  })

  type AddRequestPolicyRoleGeneralForm = TypeOf<typeof addRequestPolicyRolesSchema>

  const form = useForm<AddRequestPolicyRoleGeneralForm>({
    resolver: zodResolver(addRequestPolicyRolesSchema)
  })

  const createMutation = useCreateRequestPolicyFunctionMutation()

  const handleOnSubmit = async (newRequestPolicyRole: AddRequestPolicyRoleGeneralForm) => {
    await createMutation
      .mutateAsync({
        createRequestPolicyFunctionCommand: {
          requestPolicyId: requestPolicyId || '',
          functionId: newRequestPolicyRole.role,
          requestPolicyRightKey: requestPolicyRight
        }
      })
      .then(() => {
        closeFlyIn()
        refetchRoles()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      {requestPolicyRight === REQUEST_RIGHT_APPROVER ? (
        <Typography variant="h4">{t('flyin.requestpolicy.approverrole.title')}</Typography>
      ) : requestPolicyRight === REQUEST_RIGHT_EDITOR ? (
        <Typography variant="h4">{t('flyin.requestpolicy.editorrole.title')}</Typography>
      ) : (
        <Typography variant="h4">{t('flyin.requestpolicy.informerrole.title')}</Typography>
      )}
      <FormGridLayout>
        <FormSelect
          sx={12}
          name="role"
          label={`${t('form.field.role')} *`}
          options={getRoles?.allFunctions.data
            .filter(x => !tableData.includes(x.id))
            .map(x => new FormSelectOption(x.id, `${x.description}`))}
        />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons isMutating={createMutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddRequestPolicyRole

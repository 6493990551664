import {
  AsbGroupPaymentModel,
  FormSelectOption,
  GraphqlError,
  useUpdateAsbGroupPaymentDetailsMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'

interface EditAsbGroupPaymentsProps {
  asbGroupPayment: AsbGroupPaymentModel
}

export function EditAsbGroupPayments({ asbGroupPayment }: EditAsbGroupPaymentsProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const editAsbGroupPaymentsSchema = object({
    payrollCodeContractId: string(),
    variableSymbol: string(),
    constantSymbol: string(),
    specificSymbol: string()
  })

  type EditAsbGroupPaymentForm = TypeOf<typeof editAsbGroupPaymentsSchema>

  const form = useForm<EditAsbGroupPaymentForm>({
    resolver: zodResolver(editAsbGroupPaymentsSchema),
    defaultValues: {
      payrollCodeContractId: asbGroupPayment.bankdetails.id,
      variableSymbol: asbGroupPayment.metaData?.variableSymbol,
      constantSymbol: asbGroupPayment.metaData?.constantSymbol,
      specificSymbol: asbGroupPayment.metaData?.specificSymbol
    }
  })

  const mutation = useUpdateAsbGroupPaymentDetailsMutation()
  const [backendErrors, setBackendErrors] = useState<GraphqlError[]>([])

  async function handleOnSubmit(form: EditAsbGroupPaymentForm) {
    mutation
      .mutateAsync({
        asbGroupPaymentDetailsCommand: {
          contractId: asbGroupPayment.bankdetails.contractId,
          payrollCodeContractId: asbGroupPayment.bankdetails.id,
          variableSymbol: form.variableSymbol,
          constantSymbol: form.constantSymbol,
          specificSymbol: form.specificSymbol
        }
      })
      .then(_ => closeFlyIn())
      .catch(e => setBackendErrors(e))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('asbgroupdetailpage.payments.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name={'payrollCodeContractId'}
          label={t('form.field.payrollcode')}
          disabled
          options={[
            new FormSelectOption(
              asbGroupPayment.bankdetails.id,
              `${asbGroupPayment.bankdetails.payrollCodeCode} - ${asbGroupPayment.bankdetails.payrollCodeDescription}`
            )
          ]}
        />

        <FormInput sx={12} name={'variableSymbol'} label={t('asbgroupdetailpage.payments.variablesymbol.label')} />
        <Typography variant="description" sx={{ pl: 2 }}>
          {t('asbgroupdetailpage.payments.variablesymbol.info')}
        </Typography>

        <FormInput sx={12} name={'constantSymbol'} label={t('asbgroupdetailpage.payments.constantsymbol.label')} />
        <Typography variant="description" sx={{ pl: 2, pb: 0 }}>
          {t('asbgroupdetailpage.payments.constantsymbol.info')}
        </Typography>

        <FormInput sx={12} name={'specificSymbol'} label={t('asbgroupdetailpage.payments.specificsymbol.label')} />
        <Typography variant="description" sx={{ pl: 2 }}>
          {t('asbgroupdetailpage.payments.specificsymbol.info')}
        </Typography>
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={false} onCancel={closeFlyIn} />
    </FormContainer>
  )
}

import { IconButton } from '@mui/material'
import { DetailBox, DetailBoxRow } from '@epix-web-apps/ui'
import { Trans, useTranslation } from 'react-i18next'
import { useFlyIn } from '@epix-web-apps/ui'
import EditCompanyCarUsageInformation from '../../edit-company-car-usage-information'
import EditIcon from '@mui/icons-material/Edit'
import { CompanyCarDetailModel } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface CompanyCarUsageInformationProps {
  car?: CompanyCarDetailModel
}

export function CompanyCarUsageInformation({ car }: CompanyCarUsageInformationProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()

  return (
    <DetailBox
      title={t('companycardetailpage.usageinformation.title')}
      actionIcon={
        <IconButton
          size="small"
          onClick={() =>
            openFlyIn({
              content: <EditCompanyCarUsageInformation />
            })
          }
        >
          <EditIcon />
        </IconButton>
      }
    >
      {car?.carUsageType && (
        <DetailBoxRow>
          <p>
            <Trans
              i18nKey="companycardetailpage.carusage"
              values={{ carusage: car?.carUsageType.value }}
              components={{ bold: <span className="bold" /> }}
            />
          </p>
        </DetailBoxRow>
      )}
      {car?.hasChargingStation && (
        <DetailBoxRow>
          <p>
            <Trans i18nKey="companycardetailpage.haschargingstation" components={{ bold: <span className="bold" /> }} />
          </p>
        </DetailBoxRow>
      )}
    </DetailBox>
  )
}

export default CompanyCarUsageInformation

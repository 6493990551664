import { Suspense, useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import { BalanceCalculationList } from '../balance-calculation-list'
import { useTranslation } from 'react-i18next'
import { DetailPageBaseQueryParams, HeaderTitleNavigation } from '@epix-web-apps/ui'
import { useParams } from 'react-router-dom'
import { BalanceCalculationCounters } from '../balance-calculation-counters'
import { LoadingOverlay } from '@epix-web-apps/ui'
import { TabPanel, a11yProps } from '@epix-web-apps/ui'
import { useGetRequestPolicyContractByIdByTeamStructureQuery } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface BalanceCalculationRequestPolicyProps {}

export function BalanceCalculationRequestPolicy(props: BalanceCalculationRequestPolicyProps) {
  const [tabIndex, setTabIndex] = useState(0)
  const params = useParams<DetailPageBaseQueryParams>()
  const { t } = useTranslation()

  const { data: requestPolicyContract } = useGetRequestPolicyContractByIdByTeamStructureQuery(
    {
      id: params.id || ''
    },
    {
      suspense: false
    }
  )

  return (
    <>
      <HeaderTitleNavigation
        mobileSizeTitle
        title={
          t('selfservice.balance-calculation-request-policy.title') +
          ' - ' +
          requestPolicyContract?.requestPolicyContractByIdByTeamStructure.requestPolicyUserFriendlyName
        }
      />
      <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={(_, tabIndex) => setTabIndex(tabIndex)}>
          <Tab label={t('balance-calculation-request-policy.tab.list')} {...a11yProps(0)} />
          <Tab label={t('balance-calculation-request-policy.tab.counters')} {...a11yProps(1)} />
        </Tabs>
      </Box>

      <TabPanel padding={'0px'} value={tabIndex} index={0}>
        <Suspense fallback={<LoadingOverlay />}>
          <BalanceCalculationList requestPolicyContractId={params.id || ''} />
        </Suspense>
      </TabPanel>
      <TabPanel padding={'0px'} value={tabIndex} index={1}>
        <Suspense fallback={<LoadingOverlay />}>
          <BalanceCalculationCounters requestPolicyContractId={params.id || ''} />
        </Suspense>
      </TabPanel>
    </>
  )
}

export default BalanceCalculationRequestPolicy

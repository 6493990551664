import BarchartComponent from '../../../../../analytics-components/barchart-component'

/* eslint-disable-next-line */
export interface CompanyCarsAverageTcoComponentProps {
  color: string
  data: any
  showZeroScale: boolean
}

export function CompanyCarsAverageTcoComponent({ color, data, showZeroScale }: CompanyCarsAverageTcoComponentProps) {
  const tcoData: number[] = data?.map((b: any) => b.AverageTCO)

  return (
    <BarchartComponent
      data={data}
      keys={['AverageTCO']}
      indexBy="monthAndYear"
      layoutMode="vertical"
      groupMode="stacked"
      innerPadding={0}
      marginRight={50}
      marginLeft={50}
      colors={[color]}
      showLegend={false}
      marginTop={10}
      minValueScaleOn={showZeroScale ? undefined : tcoData}
    />
  )
}

export default CompanyCarsAverageTcoComponent

import { PROCESS_TYPE } from '../services'

export const getEnumKeys = <T extends object>(obj: T): Array<keyof T> =>
  Object.keys(obj).map(key => key as keyof T)

export const getEnumValues = <T extends object>(obj: T): Array<keyof T> =>
  Object.values(obj).map(key => key as keyof T)

export const getEnumAsKeyValue = <T extends object>(
  obj: T
): Array<{ key: string; value: string }> =>
  Object.entries(obj).map(([key, value]) => ({ key: key, value: value }))

export const isProcessOfType = (enumType: string, key: string | undefined) =>
  enumType === PROCESS_TYPE[key as keyof typeof PROCESS_TYPE]

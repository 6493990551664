import { createEnhancedJSONStorage } from '@epix-web-apps/core'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface IAnalyticsPersistedStore {
  dateFilter: Date
  setDateFilter: (date: Date) => void
  genderFilter: string | null
  setGenderFilter: (gender: string | null) => void
  functionAreaFilter: string | null
  setFunctionAreaFilter: (functionArea: string | null) => void
  functionLevelFilter: string | null
  setFunctionLevelFilter: (functionLevel: string | null) => void
  employeeTypeFilter: string | null
  setEmployeeTypeFilter: (employeeType: string | null) => void
}

export const useAnalyticsPersistedStore = create<IAnalyticsPersistedStore>()(
  persist(
    (set, get) => ({
      dateFilter: new Date(),
      setDateFilter: async (date: Date) => {
        set({ dateFilter: date })
      },
      genderFilter: null,
      setGenderFilter: async (gender: string | null) => {
        set({ genderFilter: gender })
      },
      functionAreaFilter: null,
      setFunctionAreaFilter: async (functionArea: string | null) => {
        set({ functionAreaFilter: functionArea })
      },
      functionLevelFilter: null,
      setFunctionLevelFilter: async (functionLevel: string | null) => {
        set({ functionLevelFilter: functionLevel })
      },
      employeeTypeFilter: null,
      setEmployeeTypeFilter: async (employeeType: string | null) => {
        set({ employeeTypeFilter: employeeType })
      }
    }),
    {
      name: 'analyticsPersistedStore',
      storage: createEnhancedJSONStorage(() => localStorage)
    }
  )
)

import { useGetUserByIdQuery } from '@epix-web-apps/core'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Configuration, HeaderTitleNavigation } from '@epix-web-apps/ui'
import UserTabGeneral from '../../components/user-components/user-tab-general/user-tab-general'
import {
  DetailPageBaseQueryParams,
  DetailPageContent,
  DetailPageContentHeader,
  DetailPageLayout
} from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface UserDetailPageProps {}

export function UserDetailPage(props: UserDetailPageProps) {
  const { t } = useTranslation()
  const params = useParams<DetailPageBaseQueryParams>()

  const { data: getUserById } = useGetUserByIdQuery(
    {
      userId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )

  return (
    <DetailPageLayout>
      <DetailPageContent>
        <DetailPageContentHeader>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <HeaderTitleNavigation backToLink={Configuration.USERS()} title={`${t('userdetailpage.title')}`} />
          </Box>
          <Typography color={'gray'} variant="h4" paddingLeft={4} paddingTop={1}>
            {getUserById?.userById.email}
          </Typography>
        </DetailPageContentHeader>

        <UserTabGeneral userId={params.id!} />
      </DetailPageContent>
    </DetailPageLayout>
  )
}

export default UserDetailPage

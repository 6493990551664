import { Paper, SxProps, Typography } from '@mui/material'
import { LoadingOverlay } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface ConfigurationHideablePaperProps {
  isLoading: boolean
  title?: string
  children?: React.ReactNode
  sx?: SxProps
}

const paperStyle = {
  px: 2,
  padding: '0.5rem'
}

export function HideableSuspensePaper({ isLoading = true, title, children, sx }: ConfigurationHideablePaperProps) {
  return (
    <Paper sx={{ ...paperStyle, ...sx }}>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <>
          {title && (
            <Typography sx={{ ml: 2 }} variant="h4">
              {title}
            </Typography>
          )}
          {children}
        </>
      )}
    </Paper>
  )
}

export default HideableSuspensePaper

import { Box, Grid, IconButton, Typography } from '@mui/material'
import { Button } from '@mui/material'
import { Configuration, HeaderTitleNavigation } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import { DetailBox, DetailBoxRow, DetailPageBaseQueryParams } from '@epix-web-apps/ui'
import {
  DEFAULT_ABSENCE_TYPE,
  DEFAULT_REQUEST_MINIMUM_TYPE,
  DEFAULT_REQUEST_POLICY_TIME_TYPE,
  REQUEST_RIGHT_APPROVER,
  REQUEST_RIGHT_EDITOR,
  REQUEST_RIGHT_INFORMER,
  useGetEmployerByIdQuery,
  useGetRequestPolicyByIdQuery,
  useGetRequestPolicyFunctionByRequestPolicyIdAndRequestPolicyRightQuery,
  useGetRequestPolicyPayrollCodesQuery
} from '@epix-web-apps/core'
import { useFlyIn } from '@epix-web-apps/ui'
import { EditRequestPolicyGeneral } from '../edit-request-policy-general'
import { EditRequestPolicyRequestRights } from '../edit-request-policy-request-rights'
import { RequestPolicyPayrollcodes } from '../request-policy-payrollcodes'
import AddIcon from '@mui/icons-material/Add'
import { AddRequestPolicyPayrollcode } from '../add-edit-request-policy-payrollcode'
import { AddRequestPolicyRole } from '../add-request-policy-role'
import { RequestPolicyRoles } from '../request-policy-roles'

/* eslint-disable-next-line */
export interface RequestPolicySettingsProps {}

export type RequestPolicySettingsPageParams = DetailPageBaseQueryParams & {
  employerid: string
  requestpolicyid: string
}

export function RequestPolicySettings(props: RequestPolicySettingsProps) {
  const { t } = useTranslation()
  const params = useParams<RequestPolicySettingsPageParams>()
  const employerId = params.employerid
  const { openFlyIn } = useFlyIn()

  const { data: getEmployerById } = useGetEmployerByIdQuery({
    employerId: employerId || ''
  })

  const company = getEmployerById?.employerById.companyName

  const { data: requestPolicyById, refetch: refetchRequestPolicyById } = useGetRequestPolicyByIdQuery({
    id: params.requestpolicyid || ''
  })

  const { refetch: refetchPayrollcodes } = useGetRequestPolicyPayrollCodesQuery({
    requestPolicyId: params.requestpolicyid || ''
  })

  const { refetch: refetchApproverRoles } = useGetRequestPolicyFunctionByRequestPolicyIdAndRequestPolicyRightQuery({
    requestPolicyId: params.requestpolicyid || '',
    requestPolicyRightKey: REQUEST_RIGHT_APPROVER
  })

  const { refetch: refetchEditorRoles } = useGetRequestPolicyFunctionByRequestPolicyIdAndRequestPolicyRightQuery({
    requestPolicyId: params.requestpolicyid || '',
    requestPolicyRightKey: REQUEST_RIGHT_EDITOR
  })

  const { refetch: refetchInformerRoles } = useGetRequestPolicyFunctionByRequestPolicyIdAndRequestPolicyRightQuery({
    requestPolicyId: params.requestpolicyid || '',
    requestPolicyRightKey: REQUEST_RIGHT_INFORMER
  })

  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: [3],
          paddingLeft: [3],
          paddingRight: [2]
        }}
      >
        <HeaderTitleNavigation
          backToLink={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_REQUESTPOLICIES(`${params.id}`, `${params.employerid}`)}
          title={t('requestpolicysettingspage.title')}
        />
        <Typography color={'gray'} variant="h3" paddingBottom={2}>
          {company} - {requestPolicyById?.requestPolicyById.internalName}
        </Typography>

        <Grid columnSpacing={'2rem'} container>
          <Grid item md={6} lg={4}>
            <DetailBox
              title={t('requestpolicysettingspage.generalsettings.title')}
              actionIcon={
                <IconButton
                  size="small"
                  onClick={() =>
                    openFlyIn({
                      content: <EditRequestPolicyGeneral requestPolicyId={params.requestpolicyid || ''} />,
                      callbackAfterClose: () => refetchRequestPolicyById
                    })
                  }
                >
                  <EditIcon />
                </IconButton>
              }
            >
              <DetailBoxRow>
                <p>
                  {t('form.field.internalname')}:
                  <span className="bold"> {requestPolicyById?.requestPolicyById.internalName}</span>
                </p>
              </DetailBoxRow>
              <DetailBoxRow>
                <p>
                  {t('form.field.userfriendlyname')}:
                  <span className="bold"> {requestPolicyById?.requestPolicyById.userFriendlyName}</span>
                </p>
              </DetailBoxRow>
              <DetailBoxRow>
                {requestPolicyById?.requestPolicyById.absenceType.key === DEFAULT_ABSENCE_TYPE ? (
                  <p>{t('form.field.absencetype.days.explanation')}</p>
                ) : (
                  <p>{t('form.field.absencetype.hours.explanation')}</p>
                )}
              </DetailBoxRow>
              <DetailBoxRow>
                {requestPolicyById?.requestPolicyById.requestMinimumType.key === DEFAULT_REQUEST_MINIMUM_TYPE ? (
                  <p>{t('form.field.requestminimumtype.lessthanaday.explanation')}</p>
                ) : (
                  <p>{t('form.field.requestminimumtype.day.explanation')}</p>
                )}
              </DetailBoxRow>
              <DetailBoxRow>
                {requestPolicyById?.requestPolicyById.requestPolicyTimeType.key === DEFAULT_REQUEST_POLICY_TIME_TYPE ? (
                  <p>{t('form.field.requestpolicytimetype.workschedule.explanation')}</p>
                ) : (
                  <p>{t('form.field.requestpolicytimetype.additional.explanation')}</p>
                )}
              </DetailBoxRow>
              <DetailBoxRow>
                {requestPolicyById?.requestPolicyById.obfuscated ? (
                  <p>
                    {t('form.field.obfuscated.explanation')} '
                    {requestPolicyById?.requestPolicyById.obfuscatedType?.value}'
                  </p>
                ) : (
                  <p>{t('form.field.notobfuscated.explanation')} </p>
                )}
              </DetailBoxRow>
            </DetailBox>
          </Grid>

          <Grid item md={6} lg={4}>
            <DetailBox
              title={t('requestpolicysettingspage.requestpolicies.title')}
              actionIcon={
                <IconButton
                  size="small"
                  onClick={() =>
                    openFlyIn({
                      content: <EditRequestPolicyRequestRights requestPolicyId={params.requestpolicyid || ''} />,
                      callbackAfterClose: () => refetchRequestPolicyById
                    })
                  }
                >
                  <EditIcon />
                </IconButton>
              }
            >
              <DetailBoxRow>
                {requestPolicyById?.requestPolicyById.canBeRequested ? (
                  <p>{t('form.field.canberequested.explanation')}</p>
                ) : (
                  <p>{t('form.field.cannotberequested.explanation')}</p>
                )}
              </DetailBoxRow>
              {requestPolicyById?.requestPolicyById.canBeRequested && (
                <>
                  <DetailBoxRow>
                    {requestPolicyById?.requestPolicyById.unlimited ? (
                      <p>{t('form.field.unlimited.explanation')}</p>
                    ) : (
                      <p>{t('form.field.notunlimited.explanation')}</p>
                    )}
                  </DetailBoxRow>
                  <DetailBoxRow>
                    {requestPolicyById?.requestPolicyById.negativeBalance ? (
                      <p>{t('form.field.negativebalance')}</p>
                    ) : (
                      <p>{t('form.field.notnegativebalance.explanation')}</p>
                    )}
                  </DetailBoxRow>
                  <DetailBoxRow>
                    {requestPolicyById?.requestPolicyById.needsApproval ? (
                      <p>{t('form.field.needsapproval.explanation')}</p>
                    ) : (
                      <p>{t('form.field.notneedsapproval.explanation')}</p>
                    )}
                  </DetailBoxRow>
                  <DetailBoxRow>
                    <p>
                      {t('form.field.cancellationneedsapprovaltype.explanation')}{' '}
                      {requestPolicyById?.requestPolicyById.cancellationAllowedType?.value.toLocaleLowerCase()}{' '}
                    </p>
                  </DetailBoxRow>
                </>
              )}
            </DetailBox>
          </Grid>

          <Grid item md={6} lg={4}>
            <DetailBox
              title={t('requestpolicysettingspage.payrollcodes.title')}
              actionIcon={
                <Button
                  size="small"
                  onClick={() =>
                    openFlyIn({
                      content: (
                        <AddRequestPolicyPayrollcode
                          requestPolicyId={params.requestpolicyid || ''}
                          employerId={params.employerid || ''}
                        />
                      ),
                      callbackAfterClose: () => refetchPayrollcodes
                    })
                  }
                >
                  <AddIcon />
                  {t('requestpolicysettingspage.button.addpayrollcode')}
                </Button>
              }
            >
              <RequestPolicyPayrollcodes requestPolicyId={params.requestpolicyid || ''} employerId={employerId || ''} />
            </DetailBox>
          </Grid>

          <Grid item md={6} lg={4}>
            <DetailBox
              title={t('requestpolicysettingspage.approver.title')}
              actionIcon={
                <Button
                  size="small"
                  onClick={() =>
                    openFlyIn({
                      content: (
                        <AddRequestPolicyRole
                          requestPolicyId={params.requestpolicyid || ''}
                          requestPolicyRight={REQUEST_RIGHT_APPROVER}
                        />
                      ),
                      callbackAfterClose: () => refetchApproverRoles
                    })
                  }
                >
                  <AddIcon />
                  {t('requestpolicysettingspage.button.addapproverrole')}
                </Button>
              }
            >
              <RequestPolicyRoles
                requestPolicyId={params.requestpolicyid || ''}
                requestPolicyRight={REQUEST_RIGHT_APPROVER}
              />
            </DetailBox>
            <Box color={'gray'} sx={{ fontStyle: 'italic' }}>
              <Typography>{t('requestpolicysettingspage.approver.note1')}</Typography>
              <Typography>{t('requestpolicysettingspage.approver.note2')}</Typography>
              <Typography>{t('requestpolicysettingspage.approver.note3')}</Typography>
              <Typography>{t('requestpolicysettingspage.approver.note4')}</Typography>
            </Box>
          </Grid>

          <Grid item md={6} lg={4}>
            <DetailBox
              title={t('requestpolicysettingspage.editor.title')}
              actionIcon={
                <Button
                  size="small"
                  onClick={() =>
                    openFlyIn({
                      content: (
                        <AddRequestPolicyRole
                          requestPolicyId={params.requestpolicyid || ''}
                          requestPolicyRight={REQUEST_RIGHT_EDITOR}
                        />
                      ),
                      callbackAfterClose: () => refetchEditorRoles
                    })
                  }
                >
                  <AddIcon />
                  {t('requestpolicysettingspage.button.addeditorrole')}
                </Button>
              }
            >
              <RequestPolicyRoles
                requestPolicyId={params.requestpolicyid || ''}
                requestPolicyRight={REQUEST_RIGHT_EDITOR}
              />
            </DetailBox>
            <Box color={'gray'} sx={{ fontStyle: 'italic' }}>
              <Typography>{t('requestpolicysettingspage.editor.note1')}</Typography>
              <Typography>{t('requestpolicysettingspage.editor.note2')}</Typography>
              <Typography>{t('requestpolicysettingspage.editor.note3')}</Typography>
            </Box>
          </Grid>

          <Grid item md={6} lg={4}>
            <DetailBox
              title={t('requestpolicysettingspage.informer.title')}
              actionIcon={
                <Button
                  size="small"
                  onClick={() =>
                    openFlyIn({
                      content: (
                        <AddRequestPolicyRole
                          requestPolicyId={params.requestpolicyid || ''}
                          requestPolicyRight={REQUEST_RIGHT_INFORMER}
                        />
                      ),
                      callbackAfterClose: () => refetchInformerRoles
                    })
                  }
                >
                  <AddIcon />
                  {t('requestpolicysettingspage.button.addinformerrole')}
                </Button>
              }
            >
              <RequestPolicyRoles
                requestPolicyId={params.requestpolicyid || ''}
                requestPolicyRight={REQUEST_RIGHT_INFORMER}
              />
            </DetailBox>
            <Typography color={'gray'} sx={{ fontStyle: 'italic' }}>
              {t('requestpolicysettingspage.informer.note1')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default RequestPolicySettings

import { DEFAULT_DATE, PayGroupPayPeriodModel, PAYPERIODTYPES } from '../services'
import moment from 'moment'

export type DateRange = {
  startDate: Date
  endDate: Date
}

export const WithoutTime = (date: Date) => {
  return date.setHours(0, 0, 0)
}

export const IsDateBeforeToday = (date: Date) => {
  return new Date(date) < new Date(new Date().toDateString())
}

export const LastDayOfMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), moment(date).daysInMonth())
}

export const ToDateRangeFormat = (dateRange: DateRange, locale: string) => {
  if (dateRange.startDate.getTime() === dateRange.endDate.getTime()) {
    return dateRange.startDate.toLocaleDateString(locale)
  }

  return `${dateRange.startDate.toLocaleDateString(locale, {
    day: '2-digit',
    month: '2-digit'
  })} - ${dateRange.endDate.toLocaleDateString(locale, {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  })}`
}

export const ToRouteDateFormat = (date: Date) => {
  return moment(date).format('yyyy-MM-DD')
}

export const ToShortDate = (value: string, locale: string) => {
  if (!value) {
    return null
  }

  try {
    const date = Date.parse(value)
    const formatter = new Intl.DateTimeFormat(locale)
    return formatter.format(date)
  } catch {
    return null
  }
}

export const ToLongDate = (value: string, locale: string) => {
  if (!value) {
    return null
  }

  try {
    const date = Date.parse(value)
    const formatter = new Intl.DateTimeFormat(locale, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })
    return formatter.format(date)
  } catch {
    return null
  }
}

export const isDateISOString = (value: any) => {
  return typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value)
}

export const ToLongDateTime = (value: string, locale: string) => {
  if (!value) {
    return null
  }

  try {
    const date = Date.parse(value)
    const formatter = new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit'
    })
    return formatter.format(date)
  } catch {
    return null
  }
}

/* the reason we use this function over something like toIsoString() is that the latter will
 convert the date to standard time, which can alter the date due to timezones
  fe: 01/01/2000 created in CEST will become 31/12/1999 when using toIsoString() */
export const ToBackendFormatedDate = (date: Date) => {
  date = new Date(date)
  if (!date || isNaN(date?.getTime())) {
    return null
  }

  const dd = String(date.getDate()).padStart(2, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const yyyy = date.getFullYear()

  return `${yyyy}-${mm}-${dd}`
}

export const IsDefaultBackendDate = (date: Date) => {
  return ToBackendFormatedDate(date) === ToBackendFormatedDate(new Date(DEFAULT_DATE))
}

export const getReferenceFullDay = (date: Date, locales: any, index: number): string => {
  const dateAddDays = new Date(date).setDate(new Date(date).getDate() + index)
  return new Date(dateAddDays).toLocaleDateString(locales, { weekday: 'long' })
}

export const prevYear = (date: DateRange) => {
  const currentYear = date.startDate.getFullYear()
  const prevYear = currentYear - 1

  const prevYearStart = moment([prevYear, 0]).startOf('year').toDate()
  const prevYearEnd = moment([prevYear, 11]).endOf('year').toDate()

  const newDates = {
    newStartDate: prevYearStart,
    newEndDate: prevYearEnd
  }

  return { startDate: newDates.newStartDate, endDate: newDates.newEndDate }
}

export const nextMonth = (date: DateRange) => {
  const currentDateMonth = date.startDate.getMonth()
  const currentYear = date.startDate.getFullYear()

  let nextMonth = currentDateMonth + 1
  let nextYear = currentYear
  if (nextMonth > 11) {
    nextMonth = 0
    nextYear++
  }
  const nextMonthStart = moment([nextYear, nextMonth]).startOf('month').toDate()
  const nextMonthEnd = moment([nextYear, nextMonth]).endOf('month').toDate()
  const newDates = {
    newStartDate: nextMonthStart,
    newEndDate: nextMonthEnd
  }
  return { startDate: newDates.newStartDate, endDate: newDates.newEndDate }
}

export const nextPeriodBasedOnPayGroup = (date: DateRange, payGroup: PayGroupPayPeriodModel) => {
  switch (payGroup?.payPeriodType?.key) {
    case PAYPERIODTYPES.MONTHLY: {
      const currentDateMonth = date.startDate.getMonth()
      const currentYear = date.startDate.getFullYear()

      let nextMonth = currentDateMonth + 1
      let nextYear = currentYear
      if (nextMonth > 11) {
        nextMonth = 0
        nextYear++
      }
      const nextMonthStart = moment([nextYear, nextMonth]).startOf('month').toDate()
      const nextMonthEnd = moment([nextYear, nextMonth]).endOf('month').toDate()
      const newDates = {
        newStartDate: nextMonthStart,
        newEndDate: nextMonthEnd
      }
      return { startDate: newDates.newStartDate, endDate: newDates.newEndDate }
    }
    case PAYPERIODTYPES.BI_MONTHLY: {
      const startDate = date.startDate
      const dayOfMonth = startDate.getDate()

      if (dayOfMonth <= 15) {
        // Next period is from the 16th to the end of the next month
        const nextPeriodStart = moment(startDate).date(16).toDate()
        const nextPeriodEnd = moment(startDate).endOf('month').toDate()
        return { startDate: nextPeriodStart, endDate: nextPeriodEnd }
      } else {
        // Next period is from the 1st to the 15th of the next month
        const nextPeriodStart = moment(startDate).add(1, 'months').date(1).toDate()
        const nextPeriodEnd = moment(startDate).add(1, 'months').date(15).toDate()
        return { startDate: nextPeriodStart, endDate: nextPeriodEnd }
      }
    }
    case PAYPERIODTYPES.WEEKLY: {
      const currentEndDate = date.endDate

      const weeks = payGroup.payPeriodNumberOfWeeks!

      const nextPeriodStart = moment(currentEndDate).add(1, 'days').toDate()
      const nextPeriodEnd = moment(nextPeriodStart)
        .add(weeks * 7 - 1, 'days')
        .toDate()

      return { startDate: nextPeriodStart, endDate: nextPeriodEnd }
    }
  }
  return { startDate: new Date(), endDate: new Date() }
}

export const prevMonth = (date: DateRange) => {
  const currentDateMonth = date.startDate.getMonth()
  const currentYear = date.startDate.getFullYear()

  let prevMonth = currentDateMonth - 1
  let prevYear = currentYear
  if (prevMonth < 0) {
    prevMonth = 11
    prevYear--
  }
  const prevMonthStart = moment([prevYear, prevMonth]).startOf('month').toDate()
  const prevMonthEnd = moment([prevYear, prevMonth]).endOf('month').toDate()
  const newDates = {
    newStartDate: prevMonthStart,
    newEndDate: prevMonthEnd
  }
  return { startDate: newDates.newStartDate, endDate: newDates.newEndDate }
}

export const prevPeriodBasedOnPayGroup = (date: DateRange, payGroup: PayGroupPayPeriodModel | null) => {
  switch (payGroup?.payPeriodType?.key) {
    case PAYPERIODTYPES.MONTHLY: {
      const currentDateMonth = date.startDate.getMonth()
      const currentYear = date.startDate.getFullYear()

      let prevMonth = currentDateMonth - 1
      let prevYear = currentYear
      if (prevMonth < 0) {
        prevMonth = 11
        prevYear--
      }
      const prevMonthStart = moment([prevYear, prevMonth]).startOf('month').toDate()
      const prevMonthEnd = moment([prevYear, prevMonth]).endOf('month').toDate()
      const newDates = {
        newStartDate: prevMonthStart,
        newEndDate: prevMonthEnd
      }
      return { startDate: newDates.newStartDate, endDate: newDates.newEndDate }
    }
    case PAYPERIODTYPES.BI_MONTHLY: {
      const startDate = date.startDate
      const dayOfMonth = startDate.getDate()

      if (dayOfMonth <= 15) {
        // Previous period is from the 16th of last month to the end of last month
        const prevMonthEnd = moment(startDate).subtract(1, 'months').endOf('month').toDate()
        const prevMonthStart = moment(startDate).subtract(1, 'months').date(16).toDate()
        return { startDate: prevMonthStart, endDate: prevMonthEnd }
      } else {
        // Previous period is from the 1st to the 15th of the current month
        const prevPeriodStart = moment(startDate).date(1).toDate()
        const prevPeriodEnd = moment(startDate).date(15).toDate()
        return { startDate: prevPeriodStart, endDate: prevPeriodEnd }
      }
    }
    case PAYPERIODTYPES.WEEKLY: {
      const currentStartDate = date.startDate

      const weeks = payGroup.payPeriodNumberOfWeeks!

      // The previous period ends the day before the current period starts
      const previousPeriodEnd = moment(currentStartDate).subtract(1, 'days').toDate()

      // The previous period starts (weeks * 7) days before the previous period ends
      const previousPeriodStart = moment(previousPeriodEnd)
        .subtract(weeks * 7 - 1, 'days')
        .toDate()

      return { startDate: previousPeriodStart, endDate: previousPeriodEnd }
    }
  }
  return { startDate: new Date(), endDate: new Date() }
}

export const nextYear = (date: DateRange) => {
  const currentYear = date.startDate.getFullYear()
  const nextYear = currentYear + 1

  const nextYearStart = moment([nextYear, 0]).startOf('year').toDate()
  const nextYearEnd = moment([nextYear, 11]).endOf('year').toDate()

  const newDates = {
    newStartDate: nextYearStart,
    newEndDate: nextYearEnd
  }

  return { startDate: newDates.newStartDate, endDate: newDates.newEndDate }
}

export const getLastDayOfMonth = (date: Date) => {
  const year = new Date(date).getFullYear()
  const month = new Date(date)?.getMonth()
  const lastDay = new Date(year, month + 1, 0).getDate()
  return new Date(year, month, lastDay)
}

export const getFirstDayOfMonth = (date: Date) => {
  const year = new Date(date).getFullYear()
  const month = new Date(date).getMonth()
  return new Date(year, month, 1)
}

export const addMonths = (date: Date, months: number) => {
  return moment(date).add(months, 'M').toDate()
}

export const addYears = (date: Date, y: number) => {
  return moment(date).add(y, 'y').toDate()
}

export const addDays = (date: Date, days: number) => {
  return moment(date).add(days, 'd').toDate()
}

// time added is in milliseconds
export const addDaysToDate = (date: Date, numberOfDays: number) => {
  return new Date(date.getTime() + 86400000 * numberOfDays)
}

export const getDatesOfWeekStartingFrom = (startDate: Date) => {
  const startDay = startDate.getDay()

  const daysToSubtract = startDay === 0 ? 6 : startDay - 1
  const firstDate = new Date(startDate)
  firstDate.setDate(startDate.getDate() - daysToSubtract)

  const dates = []

  for (let i = 0; i < 7; i++) {
    const date = new Date(firstDate)
    date.setDate(firstDate.getDate() + i)
    dates.push(date)
  }

  return dates
}

export const getNextMonday = (startDate: Date) => {
  const currentDate = new Date(startDate)
  const currentDay = currentDate.getDay()
  const daysUntilNextMonday = currentDay === 1 ? 7 : 8 - currentDay

  currentDate.setDate(currentDate.getDate() + daysUntilNextMonday)

  return currentDate
}

export const getPreviousMonday = (startDate: Date) => {
  const currentDate = new Date(startDate)
  const currentDay = currentDate.getDay()
  const daysUntilPreviousMonday = currentDay === 1 ? 7 : currentDay === 0 ? 6 : currentDay - 1

  currentDate.setDate(currentDate.getDate() - daysUntilPreviousMonday)

  return currentDate
}

export const getNumberOfDaysBetweenTwoDate = (startDate: Date, endDate: Date) => {
  const diffInTime = endDate.getTime() - startDate.getTime()
  return diffInTime / (1000 * 3600 * 24)
}

export const isInCurrentMonth = (date: Date): boolean => {
  const currentDate = new Date()
  return date.getFullYear() === currentDate.getFullYear() && date.getMonth() === currentDate.getMonth()
}

export const getDatesBetween = (startDate: Date, stopDate: Date): Date[] => {
  const dateArray: Date[] = []
  const currentDate = new Date(startDate)

  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dateArray
}

export const IsDateWithinRange = (currentDate: Date, startDate: string, endDate: string | null): boolean => {
  const currentDateTime = new Date(currentDate)
  currentDateTime.setHours(0, 0, 0, 0)

  const startDateTime = new Date(startDate)
  startDateTime.setHours(0, 0, 0, 0)

  const endDateTime = endDate ? new Date(endDate) : new Date()
  endDateTime.setHours(0, 0, 0, 0)

  return currentDateTime.getTime() >= startDateTime.getTime() && currentDateTime.getTime() <= endDateTime.getTime()
}

export const IsSameDate = (date1: Date, date2: Date): boolean => {
  return moment(date1).isSame(date2)
}

export const IsDateRecurrenceRelatedToStartDate = (
  dateToCheck: Date,
  referenceDate: Date,
  numberOfWeeks: number
): boolean => {
  const numberOfWeeksDifference = moment(dateToCheck).diff(referenceDate, 'day')
  return numberOfWeeksDifference % (numberOfWeeks * 7) === 0
}

export const FormatDateToDDMM = (date: Date) => {
  return moment(date).format('DD/MM')
}

export const GetFirstDayOfYear = (date: Date) => {
  const year = date.getFullYear()
  return new Date(year, 0, 1)
}

export const GetFirstDayOfPreviousYear = (date: Date) => {
  const year = date.getFullYear()
  return new Date(year - 1, 0, 1)
}

export const GetFirstDayOfNextYear = (date: Date) => {
  const year = date.getFullYear()
  return new Date(year + 1, 0, 1)
}

export const GetLastDayOfYear = (date: Date) => {
  const year = date.getFullYear()
  return new Date(year, 11, 31)
}

export const GetYearOfDate = (currentYear: Date, locale: string): string => {
  return currentYear?.toLocaleString(locale, { year: 'numeric' })
}

export function isDateStringBeforeDate(viewDate: Date, dateString?: string) {
  if (dateString) {
    const requestPolicyEndDate = new Date(dateString)
    return requestPolicyEndDate.getFullYear() <= viewDate.getFullYear()
  }
  return false
}

import { Box, Button, Paper, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SentimentVeryDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentVeryDissatisfiedOutlined'

/* eslint-disable-next-line */
export interface NotFoundPageProps {}

export function NotFoundPage(props: NotFoundPageProps) {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      role="alert"
    >
      <Box
        sx={{
          mt: '6%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2
          }}
          variant="h2"
        >
          <SentimentVeryDissatisfiedOutlinedIcon sx={{ fontSize: '6rem' }} />
        </Typography>
        <Typography variant="h2" align="center">{t('error.somethingwentwrong')}</Typography>
        <Paper sx={{ px: 3 }}>
          <pre>{t('error.pagenotfound')}</pre>
        </Paper>
        <Typography align="center" color="primary" sx={{ mt: 2, fontWeight: 'bold' }}>
          {t('error.checkurlmispelled')}
        </Typography>
        <Button sx={{ mt: 6 }} variant="outlined" onClick={() => (window.location.href = window.location.origin)}>
          {t('error.returntohome')}
        </Button>
      </Box>
    </Box>
  )
}

export default NotFoundPage

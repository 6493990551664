import { Box, Grid } from '@mui/material'
import { SuspensePaper } from '../../components/suspense-paper'
import {
  DriversEndingSoonComponent,
  UpcomingAnniversaries,
  UpcomingBirthdays,
  UpcomingEndProbationPeriod,
  UpcomingLeaversComponent,
  UpcomingStartersComponent
} from '../../components/event-components'
import { AnalyticsOverview } from '../../components/general-analytics-components'
import { TaskProcessOverview } from '../../components/task-process-summary'
import ValidationsToSolve from '../../components/validation-components/validations-to-solve'
import { useGetAllValidationErrorsAndWarningsQuery } from '@epix-web-apps/core'
import { useGlobalPersistedStore } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface HomePageWidgetsProps {}

export function HomePageWidgets(props: HomePageWidgetsProps) {
  const { icpFilters } = useGlobalPersistedStore()

  const { data: errorsAndWarnings } = useGetAllValidationErrorsAndWarningsQuery(
    {
      icps: icpFilters
    },
    {
      suspense: false
    }
  )

  const errors = errorsAndWarnings?.allValidationErrorsAndWarnings.errors
  const warnings = errorsAndWarnings?.allValidationErrorsAndWarnings.warnings

  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
      <Grid item md={6} lg={8} container rowSpacing={2} columnSpacing={2}>
        <Grid item md={12}>
          <SuspensePaper>
            <AnalyticsOverview />
          </SuspensePaper>
        </Grid>

        <Grid item md={12}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <UpcomingStartersComponent />
            <UpcomingLeaversComponent />
            <UpcomingAnniversaries />
            <UpcomingBirthdays />
            <UpcomingEndProbationPeriod />
            <DriversEndingSoonComponent />
          </Grid>
        </Grid>
      </Grid>

      <Grid item container md={6} lg={4} rowSpacing={2}>
        {(errors !== 0 || warnings !== 0) && (
          <Grid item md={12}>
            <SuspensePaper>
              <ValidationsToSolve errors={errors} warnings={warnings} />
            </SuspensePaper>
          </Grid>
        )}

        <Grid item md={12}>
          <TaskProcessOverview />
        </Grid>
      </Grid>
    </Box>
  )
}

export default HomePageWidgets

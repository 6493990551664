import { Navigate, useParams } from 'react-router-dom'
import { PayrollClosure } from '../../components/process-components/payroll-closure'
import { PROCESS_TYPE } from '@epix-web-apps/core'
import { PaycomponentUpdate } from '../../components/process-components/paycomponent-update/paycomponent-update'
import { StepParams } from '../../components/process-components/generic-steps'
import { GeneralRoutes } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface ProcessDetailPageProps {}

export function ProcessDetailPage(props: ProcessDetailPageProps) {
  const params = useParams<StepParams>()

  switch (params.type) {
    case PROCESS_TYPE.PAYROLL_CLOSURE:
      return <PayrollClosure />
    case PROCESS_TYPE.PAYCOMPONENT_UPDATE:
      return <PaycomponentUpdate />
    default:
      return <Navigate to={GeneralRoutes.NOTFOUND} replace />
  }
}

export default ProcessDetailPage

import { Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import PersonIdentificationComponent from '../../person-identification-component'
import {
  getReferenceFullDay,
  GraphqlError,
  OrderDirection,
  useCreateAlternateWorkScheduleDayMutation,
  useGetAllDayDefinitionsByEmployerIdQuery,
  useGetAllShiftCodesByEmployerIdQuery,
  useGetAlternateWorkScheduleDayQuery,
  useGetCalendarForDateRangeQuery,
  useGetContractByIdQuery,
  useGetMeQuery,
  useUpdateAlternateWorkScheduleDayMutation,
  FormSelectOption
} from '@epix-web-apps/core'
import { useParams } from 'react-router-dom'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'
import { object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  FormActionButtons,
  FormErrorList,
  FormSelect,
  FormGridLayout,
  FormContainer,
  useFlyIn
} from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface ChangeWorkScheduleProps {
  start: Date
  contractId: string | undefined
  alternateWorkScheduleDayId: string | undefined
}

export function ChangeWorkSchedule({ start, contractId, alternateWorkScheduleDayId }: ChangeWorkScheduleProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const { data: me } = useGetMeQuery()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const params = useParams<ContractDetailPageParams>()

  const { data: getAlternateWorkScheduleById } = useGetAlternateWorkScheduleDayQuery(
    {
      id: alternateWorkScheduleDayId || ''
    },
    {
      enabled: !!alternateWorkScheduleDayId
    }
  )

  const { data: calendarData, refetch: refetchCalendarData } = useGetCalendarForDateRangeQuery({
    contractId: contractId || '',
    startDate: start,
    endDate: start
  })

  const { data: getContractById } = useGetContractByIdQuery(
    {
      contractId: params?.contractid || ''
    },
    {
      enabled: !!params?.contractid
    }
  )
  const contract = getContractById?.contractById

  const { data: dayDefinitionsData } = useGetAllDayDefinitionsByEmployerIdQuery(
    {
      employerId: contract?.employerId || '',
      offset: 0,
      limit: -1,
      sortByProperty: null,
      orderDirection: OrderDirection.Asc,
      includeDefault: true
    },
    {
      suspense: false
    }
  )

  const { data: shiftCodesForEmployerData } = useGetAllShiftCodesByEmployerIdQuery(
    {
      employerId: contract?.employerId || '',
      offset: 0,
      limit: -1,
      sortByProperty: null,
      orderDirection: OrderDirection.Asc
    },
    {
      enabled: !!contract?.employerId
    }
  )

  const ChangeWorkScheduleSchema = object({
    dayDefinitionId: string({
      required_error: t('form.validation.daydefinitionrequired'),
      invalid_type_error: t('form.validation.daydefinitionrequired')
    }).min(1, t('form.validation.daydefinitionrequired')),
    labelOnCalendar: string({
      required_error: t('form.validation.labeloncalendarrequired'),
      invalid_type_error: t('form.validation.labeloncalendarrequired')
    }).min(1, t('form.validation.labeloncalendarrequired')),
    shiftCodeId: string().optional().nullable()
  })

  const createMutation = useCreateAlternateWorkScheduleDayMutation()
  const updateMutation = useUpdateAlternateWorkScheduleDayMutation()

  type ChangeWorkScheduleForm = TypeOf<typeof ChangeWorkScheduleSchema>

  const form = useForm<ChangeWorkScheduleForm>({
    resolver: zodResolver(ChangeWorkScheduleSchema),
    defaultValues: {
      dayDefinitionId: getAlternateWorkScheduleById?.alternateWorkScheduleDay.dayDefinition?.id || '',
      labelOnCalendar: alternateWorkScheduleDayId
        ? calendarData?.calendarDaysByContractId[0].workScheduleDay?.labelOnCalendar
        : '',
      shiftCodeId: calendarData?.calendarDaysByContractId[0]?.workScheduleDay?.shiftCodeId
    }
  })

  const handleOnSubmit = async (newWorkSchedule: ChangeWorkScheduleForm) => {
    if (!alternateWorkScheduleDayId) {
      return createMutation
        .mutateAsync({
          createAlternateWorkScheduleDayCommand: {
            contractId: contractId || '',
            dayDefinitionId: newWorkSchedule.dayDefinitionId || '',
            date: start,
            labelOnCalendar: newWorkSchedule.labelOnCalendar || '',
            shiftCodeId: newWorkSchedule.shiftCodeId
          }
        })
        .then(() => {
          refetchCalendarData()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    } else {
      return updateMutation
        .mutateAsync({
          updateAlternateWorkScheduleDayCommand: {
            alternateWorkScheduleDayId: alternateWorkScheduleDayId,
            dayDefinitionId: newWorkSchedule.dayDefinitionId || '',
            labelOnCalendar: newWorkSchedule.labelOnCalendar,
            shiftCodeId: newWorkSchedule.shiftCodeId
          }
        })
        .then(() => {
          refetchCalendarData()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <PersonIdentificationComponent title={t('flyin.change-workschedule.title')} />
      <Divider sx={{ marginBottom: 3 }} />
      <Typography variant="h6" color={'gray'}>{`${t(
        'flyin.collectiveschedule-entry.date'
      )}: ${start?.toLocaleDateString()} (${getReferenceFullDay(start, me?.me.locale.locale, 0)})`}</Typography>
      <FormGridLayout>
        <FormSelect
          sx={7}
          name={'dayDefinitionId'}
          label={`${t('form.field.daydefinition')}`}
          options={dayDefinitionsData?.dayDefinitions.data.map(x => new FormSelectOption(x.id, x.description))}
          onChange={(e, value) => {
            form.resetField('labelOnCalendar', { defaultValue: value?.label })
          }}
        />

        <FormSelect
          sx={5}
          name={'shiftCodeId'}
          label={`${t('form.field.shiftcode')}`}
          options={shiftCodesForEmployerData?.allShiftCodesByEmployerId.data.map(
            x => new FormSelectOption(x.id, x.description)
          )}
        />

        <FormInput sx={12} name="labelOnCalendar" label={`${t('form.field.labeloncalendar')}`} />
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={createMutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default ChangeWorkSchedule

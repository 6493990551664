import { useGetValidationDocsQuery } from '@epix-web-apps/core'
import { LoadingOverlay } from '@epix-web-apps/ui'
import { TreeItem, TreeView } from '@mui/lab'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Suspense } from 'react'
import { Typography } from '@mui/material'

/* eslint-disable-next-line */
export interface ValidationDocsPageProps {}

function ValidationDocsList() {
  const { data: validationDocs } = useGetValidationDocsQuery()

  return (
    <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
      {validationDocs?.validationDocs.map(docGroup => (
        <TreeItem key={docGroup.group} nodeId={docGroup.group} label={docGroup.group}>
          {docGroup.subGroups.map(docSubGroup => (
            <TreeItem key={docSubGroup.subGroup} nodeId={docSubGroup.subGroup} label={docSubGroup.subGroup}>
              {docSubGroup.validationDocs.map(doc => (
                <TreeItem key={doc.code} nodeId={doc.code} label={doc.code}>
                  <TreeItem
                    nodeId={doc.code + '-code'}
                    label={
                      <Typography>
                        <span className="bold">Code</span>
                        <span>:</span> {doc.code}
                      </Typography>
                    }
                  />
                  <TreeItem
                    nodeId={doc.code + '-description'}
                    label={
                      <Typography>
                        <span className="bold">Description</span>
                        <span>:</span> {doc.description}
                      </Typography>
                    }
                  />
                  <TreeItem
                    nodeId={doc.code + '-pseudoCode'}
                    label={
                      <>
                        <Typography>
                          <span className="bold">Rule</span>
                          <span>:</span> {doc.pseudoCode}
                        </Typography>
                        <br></br>
                      </>
                    }
                  />
                </TreeItem>
              ))}
            </TreeItem>
          ))}
        </TreeItem>
      ))}
    </TreeView>
  )
}

export function ValidationDocsPage(props: ValidationDocsPageProps) {
  return (
    <>
      <Typography variant="h2">Validation</Typography>
      <Suspense fallback={<LoadingOverlay />}>
        <ValidationDocsList />
      </Suspense>
    </>
  )
}

export default ValidationDocsPage

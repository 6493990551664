/* eslint-disable-next-line */
export interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  padding?: string
}

export interface LocationState {
  state: any
}

export const TabPanel = ({ children, value, index, padding = '1.5rem', ...other }: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabIndex-${index}`}
      style={
        value === index
          ? {
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              padding: padding
            }
          : {}
      }
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

export const a11yProps = (index: number) => {
  return {
    id: `tabIndex-${index}`
  }
}

export default TabPanel

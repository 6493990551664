import { TableCell, TableCellProps, useTheme } from '@mui/material'
import React from 'react'

interface BorderedTableCellProps {
  fontWeight?: React.CSSProperties['fontWeight']
  children?: React.ReactNode
}

export function BorderedTableCell(props: BorderedTableCellProps & TableCellProps) {
  const theme = useTheme()
  return (
    <TableCell sx={{ fontWeight: props.fontWeight, border: `1px solid ${theme.palette.divider}` }} {...props}>
      {props.children}
    </TableCell>
  )
}

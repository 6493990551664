import { useState } from 'react'
import { Box, IconButton, ListItemText, MenuItem } from '@mui/material'
import DropdownMenu from '../dropdown-menu/dropdown-menu'
import SettingsIcon from '@mui/icons-material/Settings'
import { useNavigate } from 'react-router-dom'

export interface SettingsButtonProps {
  menuItems: { label: string; to: string }[]
}

function SettingsButton({ menuItems }: SettingsButtonProps) {
  const [settingsAnchorEl, setSettingsAnchorEl] = useState<null | HTMLElement>(null)
  const navigate = useNavigate()

  return (
    <>
      <IconButton
        size="small"
        aria-label="settings button"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setSettingsAnchorEl(e.currentTarget)
        }}
      >
        <SettingsIcon />
      </IconButton>

      <DropdownMenu
        anchorEl={settingsAnchorEl}
        onClose={() => setSettingsAnchorEl(null)}
        closeOnChildClicked={false}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box>
          {menuItems.map((item, index) => (
            <MenuItem onClick={() => navigate(item.to)} key={index}>
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          ))}
        </Box>
      </DropdownMenu>
    </>
  )
}

export default SettingsButton

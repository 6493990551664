import { Typography, Box, useTheme, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { TeamCalendarQueryParams } from '../../../pages/team-calendar'
import {
  FormSelectOption,
  useGetAllTeamsWithRoleForMeQuery,
  useGetTeamsForMeForTeamStructureQuery
} from '@epix-web-apps/core'
import { FilterSelectBox, SelfService, useGlobalStore } from '@epix-web-apps/ui'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface TeamCalendarFilterProps {}

export function TeamCalendarFilter(props: TeamCalendarFilterProps) {
  const { t } = useTranslation()
  const params = useParams<TeamCalendarQueryParams>()
  const theme = useTheme()
  const { me } = useGlobalStore()
  const navigate = useNavigate()

  const { data: getTeamsByPersonId } = useGetTeamsForMeForTeamStructureQuery()

  const { data: getAllTeamsWithRole } = useGetAllTeamsWithRoleForMeQuery()

  const teamsByPersonId = getTeamsByPersonId?.teamsForMeForTeamStructure || []
  const teamWithRole = getAllTeamsWithRole?.allTeamsWithRoleForMe || []

  const allTeams = teamsByPersonId.concat(teamWithRole).filter((team, index, self) => {
    return self.findIndex(t => t.id === team.id) === index
  })

  const [currentTeam, setCurrentTeam] = useState(allTeams.find(x => x.id === params.teamid)?.id)

  return (
    <Box sx={{ minWidth: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box>
        <Typography color={theme.palette.primary.main} variant="h4">
          {t('flyin.team-calendar.team.title')}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          minWidth: '100%',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <FilterSelectBox
          id="teamId"
          label={t('flyin.team-calendar.team.team')}
          keys={allTeams?.map(x => new FormSelectOption(x.id, x.name)) || []}
          value={currentTeam}
          handleChange={(e: string) => {
            setCurrentTeam(e)
            navigate(SelfService.TEAM_CALENDAR_TEAMID_ID(`${e}`, `${me?.personId}`))
          }}
        />
      </Box>

      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
          py: 2,
          zIndex: 10
        }}
      >
        <Button
          variant="outlined"
          onClick={() => navigate(SelfService.TEAM_CALENDAR_TEAMID_ID(`${params.teamid}`, `${me?.personId}`))}
        >
          {t('flyin.team-calendar.team.button.cancel')}
        </Button>
      </Box>
    </Box>
  )
}

export default TeamCalendarFilter

import { Typography } from '@mui/material'
import {
  FormSelectOption,
  numericString,
  useCreateRequestPolicyPayrollCodeMutation,
  useGetAllPayrollCodesForSelfServiceQuery,
  useGetRequestPolicyPayrollCodeByIdQuery,
  useUpdateRequestPolicyPayrollCodeMutation
} from '@epix-web-apps/core'
import { FormContainer } from '@epix-web-apps/ui'
import { FormGridLayout } from '@epix-web-apps/ui'
import { TypeOf, number, object, string } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { FormSelect } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface AddRequestPolicyPayrollcodeProps {
  requestPolicyId: string
  employerId: string
  requestPolicyPayrollCodeId?: string
}

export function AddRequestPolicyPayrollcode({
  employerId,
  requestPolicyId,
  requestPolicyPayrollCodeId
}: AddRequestPolicyPayrollcodeProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: getRequestPolicyPayrollCodeById, refetch: refetchRequestPolicyPayrollCodeById } =
    useGetRequestPolicyPayrollCodeByIdQuery(
      {
        requestPolicyId: requestPolicyId,
        id: requestPolicyPayrollCodeId || ''
      },
      {
        enabled: !!requestPolicyPayrollCodeId
      }
    )

  const { data: getPayrollcodes } = useGetAllPayrollCodesForSelfServiceQuery({
    employerId: employerId || '',
    offset: 0,
    limit: -1
  })

  const editRequestPolicyRequestRightsSchema = object({
    payrollCode: string({
      required_error: t('form.validation.payrollcoderequired'),
      invalid_type_error: t('form.validation.payrollcoderequired')
    }).min(1, t('form.validation.payrollcoderequired')),
    eventValidityInMonths: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      }).positive({ message: t('form.validation.valuegreaterthen0') })
    )
  })

  type AddRequestPolicyPayrollCodeGeneralForm = TypeOf<typeof editRequestPolicyRequestRightsSchema>

  const form = useForm<AddRequestPolicyPayrollCodeGeneralForm>({
    resolver: zodResolver(editRequestPolicyRequestRightsSchema),
    defaultValues: {
      payrollCode: getRequestPolicyPayrollCodeById?.requestPolicyPayrollCodeById?.payrollCodeId ?? undefined,
      eventValidityInMonths: getRequestPolicyPayrollCodeById?.requestPolicyPayrollCodeById?.eventValidityInMonths ?? 12
    }
  })

  const createMutation = useCreateRequestPolicyPayrollCodeMutation()
  const updatemutation = useUpdateRequestPolicyPayrollCodeMutation()

  const handleOnSubmit = async (newRequestPolicyPayrollCode: AddRequestPolicyPayrollCodeGeneralForm) => {
    if (requestPolicyPayrollCodeId === undefined) {
      await createMutation
        .mutateAsync({
          createRequestPolicyPayrollCodeCommand: {
            payrollCodeId: newRequestPolicyPayrollCode.payrollCode,
            requestPolicyId: requestPolicyId,
            eventValidityInMonths: newRequestPolicyPayrollCode.eventValidityInMonths
          }
        })
        .then(() => {
          closeFlyIn()
        })
    } else {
      await updatemutation
        .mutateAsync({
          updateRequestPolicyPayrollCodeCommand: {
            requestPolicyId: requestPolicyId,
            requestPolicyPayrollCodeId: requestPolicyPayrollCodeId,
            eventValidityInMonths: newRequestPolicyPayrollCode.eventValidityInMonths
          }
        })
        .then(() => {
          refetchRequestPolicyPayrollCodeById()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.requestpolicy.payrollcodes.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="payrollCode"
          label={`${t('form.field.payrollcode')} *`}
          options={getPayrollcodes?.allPayrollCodesForSelfService.data.map(
            x => new FormSelectOption(x.id, `${x.userFriendlyDescription ?? x.description}`)
          )}
          disabled={!!requestPolicyPayrollCodeId}
        />

        <FormInput
          sx={12}
          name="eventValidityInMonths"
          placeholder={'0'}
          label={`${t('flyin.requestpolicy.payrollcodes.form.event-validity')} *`}
        />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons isMutating={createMutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddRequestPolicyPayrollcode

import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface FilterActionButtonsProps {
  onFilterClick: () => void
  onCancelClick: () => void
  onResetClick: () => void
}

export function FilterActionButtons({ onFilterClick, onCancelClick, onResetClick }: FilterActionButtonsProps) {
  const { t } = useTranslation()

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button variant="contained" onClick={onFilterClick}>
            {t('flyin.filter.filter')}
          </Button>
          <Button variant="outlined" onClick={onCancelClick}>
            {t('flyin.filter.cancel')}
          </Button>
        </Box>
        <Button variant="text" onClick={onResetClick}>
          {t('flyin.filter.reset')}
        </Button>
      </Box>
    </>
  )
}

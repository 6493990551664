import { EVENT_STARTERS, useGetUpcomingStartersQuery } from '@epix-web-apps/core'
import { People, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { Grid } from '@mui/material'
import { HideableSuspensePaper } from '../../suspense-paper'
import { useTranslation } from 'react-i18next'
import HomepageEventWidget from '../homepage-event-widget'
import { useNavigate } from 'react-router-dom'

/* eslint-disable-next-line */
export interface UpcomingStartersComponentProps {}

export function UpcomingStartersComponent(props: UpcomingStartersComponentProps) {
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const navigate = useNavigate()

  const { data: dataUpcomingStarters, isLoading } = useGetUpcomingStartersQuery(
    {
      icps: icpFilters
    },
    {
      suspense: false
    }
  )
  const limitedData = dataUpcomingStarters?.upcomingStarters.slice(0, 4)

  if (dataUpcomingStarters?.upcomingStarters && dataUpcomingStarters?.upcomingStarters.length === 0) return null

  return (
    <Grid item sm={12} lg={6}>
      <HideableSuspensePaper isLoading={isLoading} title={t('homepage.event.starters')}>
        <HomepageEventWidget
          onClick={personId => navigate(People.PEOPLE_ID_DETAILS(`${personId}`))}
          data={limitedData}
          length={dataUpcomingStarters?.upcomingStarters.length || 0}
          dataType={EVENT_STARTERS}
        />
      </HideableSuspensePaper>
    </Grid>
  )
}

export default UpcomingStartersComponent

import { Configuration, DetailPageBaseQueryParams, useFlyIn } from '@epix-web-apps/ui'
import { Box, IconButton, useTheme, Link } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import EmployersWidgetTitle from '../employers-widget-title'
import { ReadMoreText, TypographyRed, TypographySmall, WidgetRowItem } from '../employer-overview/employer-overview'
import AddEditDayDefinition from '../../day-definition-components/add-edit-day-definition'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import {
  OrderDirection,
  useGetAllDayDefinitionsByEmployerIdQuery,
  useGetAllPayrollCodesByEmployerIdQuery
} from '@epix-web-apps/core'
import { environment } from '../../../../../environments/environment'
import { useEffect } from 'react'

/* eslint-disable-next-line */
export interface EmployersWidgetDaydefinitionsProps {
  employerId: string
  setTabComplete: (newValue: boolean) => void
}

export function EmployersWidgetDaydefinitions({ employerId, setTabComplete }: EmployersWidgetDaydefinitionsProps) {
  const { t } = useTranslation()
  const params = useParams<DetailPageBaseQueryParams>()
  const navigate = useNavigate()
  const { openFlyIn } = useFlyIn()
  const theme = useTheme()

  const { data: dayDefinitions, refetch: refetchDayDefinitions } = useGetAllDayDefinitionsByEmployerIdQuery({
    employerId: employerId,
    offset: 0,
    limit: 3,
    sortByProperty: 'dayDefinitionEntries',
    orderDirection: OrderDirection.Asc
  })

  const { data: payrollCodes } = useGetAllPayrollCodesByEmployerIdQuery({
    employerId: employerId,
    offset: 0,
    limit: 0,
    payrollCodeFilterModel: {
      showCalendarCodes: true
    },
    orderDirection: OrderDirection.Asc
  })

  useEffect(() => {
    if (dayDefinitions?.dayDefinitions) {
      setTabComplete(
        dayDefinitions.dayDefinitions.data.length > 0 &&
          dayDefinitions.dayDefinitions.data.every(d => d.dayDefinitionEntries.length > 0)
      )
    }
  }, [dayDefinitions?.dayDefinitions])

  const disabled = (payrollCodes?.allPayrollCodesByEmployerId.totalCount ?? 0) === 0

  return (
    dayDefinitions?.dayDefinitions &&
    payrollCodes?.allPayrollCodesByEmployerId && (
      <>
        <EmployersWidgetTitle
          title={t('employerspage.widget.daydefinitions.title')}
          url={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_DAYDEFINITIONS(`${params.id}`, `${employerId}`)}
          icon={<CalendarViewWeekOutlinedIcon />}
          hasData={dayDefinitions.dayDefinitions.data.length > 0}
          disabled={disabled}
        />

        {disabled && (
          <TypographySmall
            sx={{
              pb: '0.25rem',
              color: disabled ? theme.palette.text.secondary : ''
            }}
          >
            {t('employerspage.widget.daydefinitions.nodaydefinitions')}
          </TypographySmall>
        )}

        {dayDefinitions?.dayDefinitions.data.map(dayDefinition => (
          <WidgetRowItem key={dayDefinition.id}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TypographySmall>
                {dayDefinition.description} ({dayDefinition.code})
              </TypographySmall>
              {dayDefinition.dayDefinitionEntries && dayDefinition.dayDefinitionEntries.length === 0 && (
                <TypographyRed
                  sx={{
                    display: 'flex',
                    marginLeft: '0.5rem',
                    gap: '0.25rem'
                  }}
                >
                  <ReportProblemOutlinedIcon fontSize="small" />
                  {t('employerspage.widget.daydefinitions.noentries')}
                </TypographyRed>
              )}
            </Box>
            <Box display={'flex'}>
              <IconButton
                size="small"
                sx={{ width: '1.5rem', height: '1.5rem' }}
                onClick={() =>
                  openFlyIn({
                    content: <AddEditDayDefinition employerId={employerId} dayDefinitionId={dayDefinition.id} />,
                    callbackAfterClose: () => refetchDayDefinitions
                  })
                }
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                sx={{ width: '1.5rem', height: '1.5rem' }}
                onClick={() =>
                  navigate(
                    Configuration.ICP_PROVIDERS_ID_EMPLOYERID_DAYDEFINITION_ID_ENTRIES(
                      `${params.id}`,
                      employerId,
                      dayDefinition.id
                    )
                  )
                }
              >
                <KeyboardArrowRightOutlinedIcon />
              </IconButton>
            </Box>
          </WidgetRowItem>
        ))}

        <Box>
          {dayDefinitions.dayDefinitions.totalCount < 4 && !disabled && (
            <TypographySmall>
              <Link
                onClick={() =>
                  openFlyIn({
                    content: <AddEditDayDefinition employerId={employerId} />,
                    callbackAfterClose: () => refetchDayDefinitions
                  })
                }
              >
                {t('employerspage.widget.daydefinitions.adddaydefinition')}
              </Link>
            </TypographySmall>
          )}
          {dayDefinitions.dayDefinitions.totalCount > 3 && (
            <TypographySmall>
              <Link
                onClick={() =>
                  navigate(Configuration.ICP_PROVIDERS_ID_EMPLOYERID_DAYDEFINITIONS(`${params.id}`, employerId))
                }
              >
                {t('employerspage.widget.daydefinitions.and')} {dayDefinitions.dayDefinitions.totalCount - 3}{' '}
                {t('employerspage.widget.daydefinitions.more')}
              </Link>
            </TypographySmall>
          )}
        </Box>

        <ReadMoreText
          text={dayDefinitions.dayDefinitions.data.length === 0 ? t('employerspage.widget.daydefinitions.info') : ''}
          readMoreLink={
            environment.docs.domainName + '/Epix/epix-configuration/provider/employer/calendar/daydefinition'
          }
          alignRight={dayDefinitions.dayDefinitions.data.length > 0}
        />
      </>
    )
  )
}

export default EmployersWidgetDaydefinitions

import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { OrderDirection, PRODUCTIVITYANALYTICS_VIEWPERIODS } from '@epix-web-apps/core'

export const DEFAULT_SUBGROUP_TYPE_SELECTED = ['E01020', 'E01010', 'E01040', 'E01990', 'E01030']

interface IProductivityAnalyticsPersistedStore {
  dateFilter: Date | null
  setDateFilter: (date: Date | null) => void
  viewPeriod: PRODUCTIVITYANALYTICS_VIEWPERIODS
  setViewPeriod: (viewPeriod: PRODUCTIVITYANALYTICS_VIEWPERIODS) => void
  orderDirectionFilter: OrderDirection
  setOrderDirectionFilter: (orderDirection: OrderDirection) => void
  employerFilter: string | null
  setEmployerFilter: (employer: string | null) => void
  paygroupFilter: string | null
  setPaygroupFilter: (paygroup: string | null) => void
  subgroupFilter: string[]
  setSubgroupFilter: (subgroup: string[]) => void
  mainBalanceFilter: boolean
  setMainBalanceFilter: (value: boolean) => void
  policiesFilter: string[]
  setPoliciesFilter: (policies: string[]) => void
}

export const useProductivityAnalyticsPersistedStore = create<IProductivityAnalyticsPersistedStore>()(
  persist(
    (set, get) => ({
      dateFilter: null,
      setDateFilter: (date: Date | null) => {
        set({ dateFilter: date })
      },
      viewPeriod: PRODUCTIVITYANALYTICS_VIEWPERIODS.MONTH,
      setViewPeriod: (viewPeriod: PRODUCTIVITYANALYTICS_VIEWPERIODS) => {
        set({ viewPeriod: viewPeriod })
      },
      orderDirectionFilter: OrderDirection.Desc,
      setOrderDirectionFilter: (orderDirection: OrderDirection) => {
        set({ orderDirectionFilter: orderDirection })
      },
      employerFilter: null,
      setEmployerFilter: (employer: string | null) => {
        set({ employerFilter: employer })
      },
      paygroupFilter: null,
      setPaygroupFilter: (paygroup: string | null) => {
        set({ paygroupFilter: paygroup })
      },
      subgroupFilter: DEFAULT_SUBGROUP_TYPE_SELECTED,
      setSubgroupFilter: (subgroups: string[]) => {
        set({ subgroupFilter: subgroups })
      },
      mainBalanceFilter: true,
      setMainBalanceFilter: (value: boolean) => {
        set({ mainBalanceFilter: value })
      },
      policiesFilter: [],
      setPoliciesFilter: (policies: string[]) => {
        set({ policiesFilter: policies })
      }
    }),
    { name: 'productivityAnalyticsPersistedStore', storage: createJSONStorage(() => localStorage) }
  )
)

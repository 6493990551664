import { OrderDirection, useGetPagedPeopleQuery } from '@epix-web-apps/core'
import HomePageOnboarding from '../home-page-onboarding/home-page-onboarding'
import HomePageWidgets from '../home-page-widgets'

/* eslint-disable-next-line */
export interface HomePageProps {}

export function HomePage(props: HomePageProps) {
  const { data } = useGetPagedPeopleQuery(
    {
      showInactive: false,
      showWithoutContract: false,
      sortByProperty: 'lastName',
      orderDirection: OrderDirection.Asc,
      offset: 0,
      limit: 0
    },
    {
      suspense: true
    }
  )

  return data && data.pagedPeople.totalCount > 0 ? <HomePageWidgets /> : <HomePageOnboarding />
}

export default HomePage

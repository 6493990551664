import { Box } from '@mui/material'
import { CompanyCar, HeaderTitleNavigation } from '@epix-web-apps/ui'
import CompanyCarDetailTabGeneral from '../../components/company-cars-components/company-car-detail/company-car-detail-tab-general/company-car-detail-tab-general'
import { DetailPageBaseQueryParams } from '@epix-web-apps/ui'
import { useGetCompanyCarByIdQuery } from '@epix-web-apps/core'
import { useParams } from 'react-router-dom'

/* eslint-disable-next-line */
export interface CompanyCarDetailPageProps {}

export type CompanyCarDetailPageParams = DetailPageBaseQueryParams & {
  companycarid: string
}

export function CompanyCarDetailPage(props: CompanyCarDetailPageProps) {
  const params = useParams<CompanyCarDetailPageParams>()
  const { data: getCompanyCarById } = useGetCompanyCarByIdQuery(
    {
      companyCarId: params?.id || ''
    },
    {
      enabled: !!params?.id
    }
  )

  const carBrand = getCompanyCarById?.companyCarById.carBrandType.value
  const model = getCompanyCarById?.companyCarById.model ?? ''
  const licensePlate = getCompanyCarById?.companyCarById.licensePlate.toUpperCase()
  const registrationCountry = getCompanyCarById?.companyCarById.country.code

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <HeaderTitleNavigation
          backToLink={CompanyCar.COMPANY_CAR}
          title={`${carBrand} ${model} (${licensePlate}) - ${registrationCountry}`}
        />
      </Box>

      <CompanyCarDetailTabGeneral />
    </Box>
  )
}

export default CompanyCarDetailPage

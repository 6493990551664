import { Typography } from '@mui/material'
import { FormGridLayout } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { useCreateFunctionsMutation, useGetFunctionByIdQuery, useUpdateFunctionsMutation } from '@epix-web-apps/core'
import { object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormContainer } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface AddEditRoleProps {
  roleId?: string
}

export function AddEditRole({ roleId }: AddEditRoleProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const addEditRoleSchema = object({
    code: string({
      required_error: t('form.validation.rolecoderequired'),
      invalid_type_error: t('form.validation.rolecoderequired')
    }).min(1, t('form.validation.rolecoderequired')),
    description: string({
      required_error: t('form.validation.roledescriptionrequired'),
      invalid_type_error: t('form.validation.roledescriptionrequired')
    }).min(1, t('form.validation.roledescriptionrequired'))
  })

  type CreateEditRoleForm = TypeOf<typeof addEditRoleSchema>

  const { data: getFunctionById, refetch: refetchFunctionById } = useGetFunctionByIdQuery(
    {
      id: roleId || ''
    },
    {
      enabled: !!roleId
    }
  )

  const form = useForm<CreateEditRoleForm>({
    resolver: zodResolver(addEditRoleSchema),
    defaultValues: {
      code: getFunctionById?.functionById?.code || undefined,
      description: getFunctionById?.functionById?.description || undefined
    }
  })

  const createmutation = useCreateFunctionsMutation()
  const updatemutation = useUpdateFunctionsMutation()

  const handleOnSubmit = async (newPayGroup: CreateEditRoleForm) => {
    if (roleId === undefined) {
      await createmutation
        .mutateAsync({
          createFunctionCommand: {
            code: newPayGroup.code,
            description: newPayGroup.description
          }
        })
        .then(closeFlyIn)
    } else {
      await updatemutation
        .mutateAsync({
          updateFunctionCommand: {
            id: roleId || '',
            code: newPayGroup.code,
            description: newPayGroup.description
          }
        })
        .then(() => {
          refetchFunctionById()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.role.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="code" label={`${t('form.field.rolecode')}`} />

        <FormInput sx={12} name="description" label={`${t('form.field.description')}`} />
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons
        isMutating={createmutation.isLoading || updatemutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditRole

import { EVENT_PROBATION, useGetUpcomingEndProbationPeriodQuery } from '@epix-web-apps/core'
import { People, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { Grid } from '@mui/material'
import { HideableSuspensePaper } from '../../suspense-paper'
import { useTranslation } from 'react-i18next'
import HomepageEventWidget from '../homepage-event-widget'
import { useNavigate } from 'react-router-dom'

/* eslint-disable-next-line */
export interface UpcomingEndProbationPeriodProps {}

export function UpcomingEndProbationPeriod(props: UpcomingEndProbationPeriodProps) {
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const navigate = useNavigate()
  const { data: dataUpcomingEndProbationPeriod, isLoading } = useGetUpcomingEndProbationPeriodQuery(
    {
      icps: icpFilters
    },
    {
      suspense: false
    }
  )
  const limitedData = dataUpcomingEndProbationPeriod?.upcomingEndProbationPeriod.slice(0, 4)

  if (
    dataUpcomingEndProbationPeriod?.upcomingEndProbationPeriod &&
    dataUpcomingEndProbationPeriod.upcomingEndProbationPeriod.length === 0
  )
    return null

  return (
    <Grid item sm={12} lg={6}>
      <HideableSuspensePaper isLoading={isLoading} title={t('homepage.event.endprobationperiod')}>
        <HomepageEventWidget
          onClick={personId => navigate(People.PEOPLE_ID_DETAILS(`${personId}`))}
          data={limitedData}
          length={dataUpcomingEndProbationPeriod?.upcomingEndProbationPeriod.length || 0}
          dataType={EVENT_PROBATION}
        />
      </HideableSuspensePaper>
    </Grid>
  )
}

export default UpcomingEndProbationPeriod

import { Grid, Typography, Box, useTheme } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useGlobalPersistedStore } from '@epix-web-apps/ui'
import {
  ToShortDate,
  useGetLeaversOfLastMonthQuery,
  useGetMeQuery,
  useGetStartersOfLastMonthQuery
} from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface StartersLeaversComponentProps {
  starters?: number
  leavers?: number
}

const StyledButton = styled(Box)<any>(() => {
  return {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    svg: {
      height: '2.5rem',
      width: '2.5rem'
    },
    h4: {
      margin: '0'
    }
  }
})

export function StartersLeaversComponent(props: StartersLeaversComponentProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { icpFilters } = useGlobalPersistedStore()

  const { data: starters } = useGetStartersOfLastMonthQuery({
    icps: icpFilters
  })

  const { data: leavers } = useGetLeaversOfLastMonthQuery({
    icps: icpFilters
  })

  const { data: me } = useGetMeQuery()

  const startPeriod = ToShortDate(starters?.startersOfLastMonth.periodStart, me?.me.locale.locale ?? '')
  const endPeriod = ToShortDate(starters?.startersOfLastMonth.periodEnd, me?.me.locale.locale ?? '')

  return (
    <Grid textAlign="center" item xs={12} md={12} lg={2.4}>
      <StyledButton>
        <Typography variant="h5">{t('statistics.starters')}</Typography>
        <KeyboardArrowUpIcon sx={{ color: theme.palette.success.main }} />
        <Typography variant="h4">{starters?.startersOfLastMonth.value}</Typography>
      </StyledButton>
      <Typography sx={{ fontSize: '0.7em' }}>
        {startPeriod} - {endPeriod}
      </Typography>
      <StyledButton>
        <Typography variant="h5">{t('statistics.leavers')}</Typography>
        <KeyboardArrowDownIcon sx={{ color: theme.palette.error.contrastText }} />
        <Typography variant="h4">{leavers?.leaversOfLastMonth.value}</Typography>
      </StyledButton>
    </Grid>
  )
}

export default StartersLeaversComponent

import { EndSessionRequest, RedirectRequest } from "@azure/msal-browser"
import { environment } from "./environments/environment"

export const getPort = () => window.location.port ? `:${window.location.port}` : ''
export const SUBDOMAIN_STATE_PREFIX = 'subdomain-'
export const AUTHORITY_BASE = `https://${environment.auth.b2cDomainName}/${environment.auth.tenantName}/`

export const getTenant = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const stateQueryParam = urlParams.get('state')
  let tenant = sessionStorage.getItem('tenant') ?? undefined

  if (!tenant) {
    tenant = window.location.hash.split(SUBDOMAIN_STATE_PREFIX)?.[1]?.split('&')?.[0] || stateQueryParam?.replace(SUBDOMAIN_STATE_PREFIX, '')
    if (!tenant) {
      const domainArray = window.location.host.split('.')
      tenant = domainArray.length > 1 ? domainArray[0] : ''
    }
    sessionStorage.setItem('tenant', tenant)
  }

  return tenant
}

export const loginRequest: RedirectRequest = {
  scopes: ['https://myepix.onmicrosoft.com/525b221b-2a03-4113-a313-badcc1726282/People.ReadWrite.All'],
  state: `${SUBDOMAIN_STATE_PREFIX}${getTenant()}`,
  domainHint: getTenant()
}
export const logoutRequest: EndSessionRequest = {
  state: `${SUBDOMAIN_STATE_PREFIX}${getTenant()}`
}
export const resetPasswordRequest = {
  ...loginRequest,
  authority: 'https://myepix.b2clogin.com/myepix.onmicrosoft.com/b2c_1_reset_password'
}

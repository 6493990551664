import { useGetAllTeamsQuery } from '@epix-web-apps/core'
import { Box, SvgIcon, SvgIconProps, Typography, alpha, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TreeItem, TreeView, TreeItemProps, treeItemClasses } from '@mui/lab'
import { useNavigate } from 'react-router-dom'
import { Configuration } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface TeamsPageProps {}

interface Node {
  code: string
  id: string
  name: string
  parentTeam?: Node | undefined
  children?: Node[] | undefined
}

interface RenderTree {
  id: string
  name: string
  children?: RenderTree[]
}

export function TeamsPage(props: TeamsPageProps) {
  const { t } = useTranslation()
  const { data: allTeams } = useGetAllTeamsQuery({})
  const navigate = useNavigate()

  function buildTree(items: any): Node {
    const groups = items?.reduce((acc: any, item: any) => {
      const parentId = item.parentTeam ? item.parentTeam.id : null
      if (!acc[parentId]) {
        acc[parentId] = []
      }
      acc[parentId].push(item)
      return acc
    }, {})

    function build(node: Node): Node {
      let children
      if (groups) children = groups[node?.id] || []
      if (children?.length === 0) {
        return node
      }
      if (node) node.children = children?.map(build)
      return node
    }
    let rootNode
    if (items) {
      rootNode = items[0]
    }
    return build(rootNode)
  }

  const getIds = (arr: any) => {
    return arr?.map((item: any) => item.id)
  }

  const treeStructure = buildTree(allTeams?.allTeams)
  const getAllNodeIds = getIds(allTeams?.allTeams)

  function MinusSquare(props: SvgIconProps) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 16, height: 16 }} {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
      </SvgIcon>
    )
  }

  function PlusSquare(props: SvgIconProps) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 16, height: 16 }} {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
      </SvgIcon>
    )
  }

  function CloseSquare(props: SvgIconProps) {
    return (
      <SvgIcon className="close" fontSize="inherit" style={{ width: 16, height: 16 }} {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
      </SvgIcon>
    )
  }

  const StyledTreeItem = styled((props: TreeItemProps) => <TreeItem {...props} />)(({ theme }) => ({
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 15,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
    }
  }))

  const renderTree = (nodes: RenderTree) => {
    const sortedChildren = Array.isArray(nodes?.children)
      ? [...nodes.children].sort((a, b) => a.name.localeCompare(b.name))
      : []

    return (
      <StyledTreeItem
        key={nodes?.id}
        nodeId={nodes?.id}
        label={nodes?.name}
        onClick={() => navigate(Configuration.TEAMS_ID(nodes.id))}
      >
        {sortedChildren.map(node => renderTree(node))}
      </StyledTreeItem>
    )
  }

  return (
    <Box sx={{ p: [2] }}>
      <Box
        sx={{
          mb: '2rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography m={0} variant="h2">
          {t('teams.title')}
        </Typography>
      </Box>
      <Box display={'flex'} sx={{ justifyContent: 'space-between' }}>
        <TreeView
          aria-label="customized"
          expanded={getAllNodeIds}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          defaultEndIcon={<CloseSquare />}
          sx={{ height: '100%', flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
        >
          {renderTree(treeStructure)}
        </TreeView>
      </Box>
    </Box>
  )
}

export default TeamsPage

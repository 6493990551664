import { useTranslation } from 'react-i18next'
import { useFlyIn } from '@epix-web-apps/ui'
import { TypeOf, boolean, object, string } from 'zod'
import { useGetExternalCodeByKeyQuery } from '@epix-web-apps/core'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormContainer } from '@epix-web-apps/ui'
import { Box, Table, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import { FormGridLayout } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'
import { GridRowsProp } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { TypographyBold } from '@epix-web-apps/ui'
import { IcpCodeBadge } from '../../icp-components'

/* eslint-disable-next-line */
export interface ViewDetailsExternalCodeProps {
  externalCodeKey?: string
}

export function ViewDetailsExternalCode({ externalCodeKey }: ViewDetailsExternalCodeProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [tableData, setTableData] = useState<GridRowsProp>([])
  const theme = useTheme()

  const viewDetailsExternalCodeSchema = object({
    key: string(),
    value: string(),
    active: boolean()
  })

  type ViewDetailsExternalCodeForm = TypeOf<typeof viewDetailsExternalCodeSchema>

  const { data: getExternalCodeByKey } = useGetExternalCodeByKeyQuery(
    {
      key: externalCodeKey ?? ''
    },
    {
      enabled: !!externalCodeKey
    }
  )

  const form = useForm<ViewDetailsExternalCodeForm>({
    resolver: zodResolver(viewDetailsExternalCodeSchema),
    defaultValues: {
      key: getExternalCodeByKey?.externalCodeByKey?.key,
      value: getExternalCodeByKey?.externalCodeByKey?.value,
      active: getExternalCodeByKey?.externalCodeByKey?.active ?? true
    }
  })

  useEffect(() => {
    if (getExternalCodeByKey) {
      setTableData(
        getExternalCodeByKey.externalCodeByKey.attributedPayrollCodes.map(row => {
          return {
            id: row.id,
            code: row.code,
            provider: row.countryKey,
            employerName: row.employerName
          }
        })
      )
      form.setValue('key', getExternalCodeByKey.externalCodeByKey.key)
      form.setValue('value', getExternalCodeByKey.externalCodeByKey.value)
      form.setValue('active', getExternalCodeByKey.externalCodeByKey.active ?? true)
    }
  }, [getExternalCodeByKey])

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(closeFlyIn)}>
      <Typography variant="h4">{t('flyin.viewdetailsexternalcode.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="key" disabled={!!externalCodeKey} label={`${t('form.field.key')}`} />
        <FormInput sx={12} name="value" disabled={!!externalCodeKey} label={`${t('form.field.value')}`} />
        <FormSwitch sx={12} name="active" disabled={!!externalCodeKey} label={t('form.field.active')} />
      </FormGridLayout>

      <Typography variant="h4">{t('flyin.viewdetailsexternalcode.attributedpaycodes')}</Typography>

      <Table sx={{ border: `1px solid ${theme.palette.divider}` }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ borderRight: `1px solid ${theme.palette.divider}` }}>
              <TypographyBold>{t('flyin.viewdetailsexternalcode.table.column.code')}</TypographyBold>
            </TableCell>
            <TableCell sx={{ borderRight: `1px solid ${theme.palette.divider}` }}>
              <TypographyBold>{t('flyin.viewdetailsexternalcode.table.column.provider')}</TypographyBold>
            </TableCell>
          </TableRow>
        </TableHead>
        {tableData?.map((item, index) => {
          return (
            <TableRow key={index}>
              <TableCell sx={{ borderRight: `1px solid ${theme.palette.divider}` }}>{item.code}</TableCell>

              <TableCell sx={{ borderRight: `1px solid ${theme.palette.divider}` }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                  <IcpCodeBadge key={index} icpCode={item.provider} />
                  <Typography>{item.employerName}</Typography>
                </Box>
              </TableCell>
            </TableRow>
          )
        })}
      </Table>
    </FormContainer>
  )
}

export default ViewDetailsExternalCode

import { ToRouteDateFormat, useGetAllPendingAbsenceRequestByPersonIdQuery } from '@epix-web-apps/core'
import { SelfService, useGlobalStore } from '@epix-web-apps/ui'
import { Box, Link, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DateRange, ToDateRangeFormat } from '@epix-web-apps/core'
import { useEffect, useState } from 'react'
import { PendingRequestsOverview } from '../pending-requests-overview'

/* eslint-disable-next-line */
export interface PendingRequestComponentProps {
  dateParam: Date
}

export function PendingRequestComponent({ dateParam }: PendingRequestComponentProps) {
  const { me } = useGlobalStore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [absenceRequests, setAbsenceRequests] = useState<any[]>([])
  const theme = useTheme()

  const { data: getPendingAbsenceRequests } = useGetAllPendingAbsenceRequestByPersonIdQuery()

  useEffect(() => {
    if (getPendingAbsenceRequests && getPendingAbsenceRequests.pendingAbsenceRequestsByPersonId) {
      setAbsenceRequests(
        getPendingAbsenceRequests.pendingAbsenceRequestsByPersonId.map(a => {
          return {
            id: a.id,
            dateRange: ToDateRangeFormat(
              {
                startDate: new Date(a.startDate),
                endDate: new Date(a.endDate)
              } as DateRange,
              me?.locale.locale ?? ''
            ),
            absenceRequestType: a.absenceRequestType.key,
            requestPolicyUserFriendlyDescription: a.requestPolicyUserFriendlyDescription,
            requestedByPersonFistName: a.requestedByPersonFistName,
            requestedByPersonPreferredLastName: a.requestedByPersonPreferredLastName
          }
        })
      )
    }
  }, [getPendingAbsenceRequests])

  return (
    <Box>
      {absenceRequests.length !== 0 && (
        <>
          <Typography variant="h4">{t('selfservice.homepage.pendingrequests')}</Typography>
          <PendingRequestsOverview dateParam={dateParam} absenceRequests={absenceRequests.slice(0, 3)} />
          {absenceRequests.length > 3 && (
            <Link
              underline="always"
              align="right"
              onClick={() => {
                navigate(SelfService.SELFSERVICE_PENDING_REQUESTS_DATE(`${ToRouteDateFormat(dateParam)}`))
              }}
            >
              <Typography
                color={theme.palette.primary.main}
                fontSize="default"
                fontWeight="bold"
                sx={{ whiteSpace: 'nowrap', marginBottom: 0.5 }}
              >
                {t('selfservice.pendingrequests.view')} {absenceRequests.length - 3}{' '}
                {t('selfservice.pendingrequests.more')}
              </Typography>
            </Link>
          )}
        </>
      )}
    </Box>
  )
}

export default PendingRequestComponent

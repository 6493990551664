import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, IconButton, MenuItem } from '@mui/material'
import { DataTable, ISortModel, SortModel } from '../../components/data-table'
import DropdownMenu from '../../components/dropdown-menu'
import { OrderDirection, useGetCarCategoriesQuery } from '@epix-web-apps/core'
import { useEffect, useState } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import DoneIcon from '@mui/icons-material/Done'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import { CompanyCar, HeaderTitleNavigation, useFlyIn } from '@epix-web-apps/ui'
import AddEditCarCategory from '../../components/company-cars-components/add-edit-car-category'
import AddIcon from '@mui/icons-material/Add'

/* eslint-disable-next-line */
export interface CarCategoriesPageProps {}

export function CarCategoriesPage(props: CarCategoriesPageProps) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [rowCount, setRowCount] = useState(0)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [clickedCarCategoryId, setClickedCarCategoryId] = useState('')
  const { openFlyIn } = useFlyIn()

  const activeActions = (rowParams: GridRenderCellParams) => (rowParams.value ? <DoneIcon /> : <VisibilityOffIcon />)

  const rowActions = (rowParams: GridRenderCellParams) => {
    return (
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setClickedCarCategoryId(rowParams.row.id)
          setAnchorEl(e.currentTarget)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
    )
  }
  const columns: GridColDef[] = [
    {
      field: 'description',
      headerName: t('carcategoriespage.datatable.column.description'),
      flex: 1
    },
    {
      field: 'code',
      headerName: t('carcategoriespage.datatable.column.code'),
      flex: 1
    },
    {
      field: 'active',
      headerName: t('carcategoriespage.datatable.column.active'),
      flex: 1,
      sortable: true,
      editable: false,
      renderCell: activeActions
    },
    { field: '', sortable: false, editable: false, renderCell: rowActions }
  ]

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns[0]?.field, OrderDirection.Asc))
  const [tableData, setTableData] = useState<GridRowsProp>([])

  const {
    data: CarCategoriesData,
    isLoading: isLoadingCarCategories,
    refetch: refetchAllCarCategories
  } = useGetCarCategoriesQuery(
    {
      offset: page * pageSize,
      limit: pageSize,
      sortByProperty: sortModel.field,
      orderDirection: sortModel.orderDirection
    },
    {
      suspense: false
    }
  )

  useEffect(() => {
    if (CarCategoriesData && CarCategoriesData.carCategories) {
      const carCategories = CarCategoriesData.carCategories
      setRowCount(carCategories.totalCount)
      setTableData(
        carCategories.data.map(row => {
          return {
            id: row.id,
            description: row.description,
            code: row.code,
            active: row.active
          }
        })
      )
    }
  }, [CarCategoriesData])

  const filterBar = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: [2]
      }}
    >
      <HeaderTitleNavigation backToLink={CompanyCar.COMPANY_CAR} title="Car categories" />

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() =>
          openFlyIn({
            content: <AddEditCarCategory create={true} />,
            callbackAfterClose: () => refetchAllCarCategories
          })
        }
      >
        {t('carcategoriespage.button.addcarcategory')}
      </Button>
    </Box>
  )

  return (
    <>
      <DataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        page={page}
        onPageChange={setPage}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        onSortChange={setSortModel}
        isLoading={isLoadingCarCategories}
        defaultSortModel={sortModel}
        filterBarElement={filterBar}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <AddEditCarCategory id={clickedCarCategoryId} />,
              callbackAfterClose: () => refetchAllCarCategories
            })
          }
        >
          {t('carcategoriespage.list.row.menu.edit-carcategory')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default CarCategoriesPage

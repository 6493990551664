import { Link, useTheme } from '@mui/material'
import { DocumentModel, downloadFile, useGetDocumentBlobByIdQuery } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface DocumentProps {
  document: DocumentModel
  disabled?: boolean
}

export function Document({ document, disabled = false }: DocumentProps) {
  const theme = useTheme()
  const { isFetching, refetch } = useGetDocumentBlobByIdQuery(
    {
      id: document.id
    },
    { enabled: false, suspense: false }
  )

  return (
    <Link
      color={isFetching || disabled ? theme.palette.text.secondary : theme.palette.primary.main}
      onClick={() => {
        if (isFetching || disabled) {
          return
        }
        refetch().then(r => {
          if (r.data) {
            downloadFile(
              r.data.documentBlobById.blob,
              r.data.documentBlobById.name,
              r.data.documentBlobById.contentType
            )
          }
        })
      }}
    >
      {document.name}
    </Link>
  )
}

export default Document

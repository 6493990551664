import { colors } from '@epix-web-apps/core'
import { Box, useTheme } from '@mui/material'
import { HeatMapDatum, ResponsiveHeatMap } from '@nivo/heatmap'
import { useTranslation } from 'react-i18next'

interface IHeatMapData {
  id: string
  data: Array<HeatMapDatum>
}

export class HeatMapData implements IHeatMapData {
  id: string
  data: Array<HeatMapDatum>

  constructor(id: string, data: Array<HeatMapDatum>) {
    this.id = id
    this.data = data
  }
}

export interface HeatmapComponentProps {
  data?: Array<IHeatMapData>
}

function calculateMaxValue(data: Array<IHeatMapData>) {
  let max = 0
  data.forEach((element: any) => {
    element.data.forEach((item: any) => {
      if (item.y > max) {
        max = item.y
      }
    })
  })
  return max
}

export function HeatmapComponent({ data }: HeatmapComponentProps) {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Box height={'390px'}>
      <ResponsiveHeatMap
        data={data || []}
        margin={{ top: 20, right: 10, bottom: 100, left: 80 }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -90,
          legend: t('analytics.heatmap.seniority'),
          legendPosition: 'start',
          legendOffset: 75
        }}
        axisTop={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: t('analytics.heatmap.age'),
          legendPosition: 'end',
          legendOffset: -60
        }}
        colors={{
          type: 'diverging',
          colors: ['#FFF', colors[1], colors[4]],
          divergeAt: 0.5,
          minValue: 0,
          maxValue: calculateMaxValue(data || [])
        }}
        emptyColor="#555555"
        animate={false}
        cellComponent="circle"
        sizeVariation={{
          sizes: [0.6, 1]
        }}
      />
    </Box>
  )
}

export default HeatmapComponent

import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { OrderDirection, PeopleFilterSearchProperty } from '@epix-web-apps/core'

interface IPeopleFilterStore {
  searchProperty: PeopleFilterSearchProperty
  setSearchProperty: (value: PeopleFilterSearchProperty) => void
  searchString: string | null
  setSearchString: (value: string | null) => void
  employer: string | null
  setEmloyer: (value: string | null) => void
  paygroup: string | null
  setPaygroup: (value: string | null) => void
  showInactive: boolean
  setShowInactive: (value: boolean) => void
  showWithoutContract: boolean
  setShowWithoutContract: (value: boolean) => void
  showValidations: boolean
  setShowValidations: (value: boolean) => void
  sortByProperty: string
  setSortByProperty: (value: string) => void
  sortDirection: OrderDirection
  setSortDirection: (value: OrderDirection) => void
}

export const usePeopleFilterStore = create<IPeopleFilterStore>()(
  persist(
    (set, _) => ({
      searchProperty: PeopleFilterSearchProperty.PersonNumber,
      setSearchProperty: (value: PeopleFilterSearchProperty) => {
        set({ searchProperty: value })
      },
      searchString: null,
      setSearchString: (value: string | null) => {
        set({ searchString: value })
      },
      employer: null,
      setEmloyer: (value: string | null) => {
        set({ employer: value })
      },
      paygroup: null,
      setPaygroup: (value: string | null) => {
        set({ paygroup: value })
      },
      showInactive: false,
      setShowInactive: (value: boolean) => {
        set({ showInactive: value })
      },
      showWithoutContract: false,
      setShowWithoutContract: (value: boolean) => {
        set({ showWithoutContract: value })
      },
      showValidations: false,
      setShowValidations: (value: boolean) => {
        set({ showValidations: value })
      },
      sortByProperty: '',
      setSortByProperty: (value: string) => {
        set({ sortByProperty: value })
      },
      sortDirection: OrderDirection.Asc,
      setSortDirection: (value: OrderDirection) => {
        set({ sortDirection: value })
      }
    }),
    {
      name: 'peopleFilterStore',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
)

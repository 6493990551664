import EmployersWidgetTitle from '../employers-widget-title'
import { ReadMoreText } from '../employer-overview/employer-overview'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Configuration, DetailPageBaseQueryParams} from '@epix-web-apps/ui'
import DataObjectIcon from '@mui/icons-material/DataObject';
import {
  OrderDirection,
  useGetAllPayrollCodesByEmployerIdQuery
} from '@epix-web-apps/core'
import { environment } from '../../../../../environments/environment'

/* eslint-disable-next-line */
export interface EmployersWidgetSmartPayComponentsProps {
  employerId: string
}

export function EmployersWidgetSmartPayComponents({
  employerId
}: EmployersWidgetSmartPayComponentsProps) {
  const { t } = useTranslation()
  const params = useParams<DetailPageBaseQueryParams>()

  const { data: payrollCodes } = useGetAllPayrollCodesByEmployerIdQuery({
    employerId: employerId,
    offset: 0,
    limit: 0,
    payrollCodeFilterModel: { showCalendarCodes: true },
    orderDirection: OrderDirection.Asc
  })

  const disabled = (payrollCodes?.allPayrollCodesByEmployerId.totalCount ?? 0) === 0

  return (
    payrollCodes?.allPayrollCodesByEmployerId &&
    (
      <>
        <EmployersWidgetTitle
          title={t('employerspage.widget.smartpaycomponents.title')}
          url={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_SMART_PAYCOMPONENTS(`${params.id}`, `${employerId}`)}
          icon={<DataObjectIcon />}
          hasData={false}
          disabled={disabled}
        />

        <ReadMoreText
         // TODO (in next item) empty text when no data
          text={
              t('employerspage.widget.smartpaycomponents.info')
          }
          readMoreLink={environment.docs.domainName + '/Epix/epix-configuration/provider/employer/requestpolicy'}
          // TODO (in next item) alignright when data
          alignRight={false}
        />
      </>
    )
  )
}

export default EmployersWidgetSmartPayComponents

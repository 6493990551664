import { useGetMeQuery, formTypeSelectOptions } from '@epix-web-apps/core'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { DetailPageBaseQueryParams, FormGridLayout } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { FormSelect } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'

/* eslint-disable-next-line */
export interface EditUserProps {}

export function EditUser(props: EditUserProps) {
  const { t } = useTranslation()
  const params = useParams<DetailPageBaseQueryParams>()
  const { currencyOptions, localeOptions } = formTypeSelectOptions
  const { data: me } = useGetMeQuery()

  return (
    <Box>
      <FormGridLayout>
        <FormInput sx={6} name="firstName" label={`${t('form.field.firstname')} *`} />
        <FormInput sx={6} name="lastName" label={`${t('form.field.lastname')} *`} />

        <FormInput sx={12} name="userName" label={`${t('form.field.username')} (work email) *`} disabled={true} />

        <FormSelect sx={6} name="localeCode" label={`${t('form.field.defaultlanguage')} *`} options={localeOptions} />

        <FormSelect sx={6} name="currency" label={`${t('form.field.defaultcurrency')} *`} options={currencyOptions} />

        {me?.me.id !== params.id && <FormSwitch sx={12} name="isActive" label={t('form.field.isActive')} />}
      </FormGridLayout>
    </Box>
  )
}

export default EditUser

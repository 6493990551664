import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import {
  DEFAULT_LOCALE,
  OrderDirection,
  useGetPayrollClosureProcessQuery,
  useGetPersonCivilStateCorrectionsByProcessIdQuery
} from '@epix-web-apps/core'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { DataTable, ISortModel, SortModel } from '../../../data-table'
import DataTableEmpty from '../../../data-table/data-table-empty/data-table-empty'
import DoneIcon from '@mui/icons-material/Done'
import { minWidthPayrollClosure } from '../steps'
import { StepParams } from '../../generic-steps'
import DataExceedsPagesize from '../data-exceeds-pagesize'

/* eslint-disable-next-line */
export interface PersonCivilStateCorrectionsComponentProps {}

export function PersonCivilStateCorrectionsComponent(props: PersonCivilStateCorrectionsComponentProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useParams<StepParams>()
  const [rowCount, setRowCount] = useState(0)
  const [pageSize, setPageSize] = useState(101)
  const [page, setPage] = useState(0)
  const [tableData, setTableData] = useState<GridRowsProp>([])

  const { data: getPayrollClosureProcess } = useGetPayrollClosureProcessQuery(
    {
      id: params?.id || ''
    },
    {
      enabled: !!params?.id
    }
  )

  const { data: personCivilStateCorrections, isLoading } = useGetPersonCivilStateCorrectionsByProcessIdQuery(
    {
      processId: getPayrollClosureProcess?.payrollClosureProcess.id || '',
      offset: page * pageSize,
      limit: pageSize
    },
    {
      suspense: false
    }
  )

  const columns: GridColDef[] = [
    {
      field: 'version',
      headerName: t('personcontractdata.datatable.column.version'),
      flex: 200,
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employerNumber',
      headerName: t('personcontractdata.datatable.column.employernumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employeeNumber',
      headerName: t('personcontractdata.datatable.column.employeenumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employeeName',
      headerName: t('personcontractdata.datatable.column.employeename'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'startDate',
      headerName: t('personcontractdata.datatable.column.startdate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'endDate',
      headerName: t('personcontractdata.datatable.column.endDate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'civilState',
      headerName: t('personcontractdata.datatable.column.civilstate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'civilStateKey',
      headerName: t('personcontractdata.datatable.column.civilstatekey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'icpCivilState',
      headerName: t('personcontractdata.datatable.column.icpcivilstate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'icpCivilStateKey',
      headerName: t('personcontractdata.datatable.column.icpcivilstatekey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'partnerTitle',
      headerName: t('personcontractdata.datatable.column.partnertitle'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'partnerTitleKey',
      headerName: t('personcontractdata.datatable.column.partnertitlekey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'partnerBirthDate',
      headerName: t('personcontractdata.datatable.column.partnerbirthdate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'partnerCountryOfBirth',
      headerName: t('personcontractdata.datatable.column.partnercountryofbirth'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'partnerCountryOfBirthKey',
      headerName: t('personcontractdata.datatable.column.partnercountryofbirthkey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'partnerNationality',
      headerName: t('personcontractdata.datatable.column.partnernationality'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'partnerNationalityKey',
      headerName: t('personcontractdata.datatable.column.partnernationalitykey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'partnerNationalNumber',
      headerName: t('personcontractdata.datatable.column.partnernationalnumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'partnerFirstName',
      headerName: t('personcontractdata.datatable.column.partnerfirstname'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'partnerOtherNames',
      headerName: t('personcontractdata.datatable.column.partnerothernames'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'partnerLastNamePrefix',
      headerName: t('personcontractdata.datatable.column.partnerlastnameprefix'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'partnerLastName',
      headerName: t('personcontractdata.datatable.column.partnerlastname'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'partnerGenderType',
      headerName: t('personcontractdata.datatable.column.partnergendertype'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'partnerGenderTypeKey',
      headerName: t('personcontractdata.datatable.column.partnergendertypekey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'partnerDisabled',
      headerName: t('personcontractdata.datatable.column.partnerdisabled'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'partnerDisabledPercentage',
      headerName: t('personcontractdata.datatable.column.partnerdisabledpercentage'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'partnerDependent',
      headerName: t('personcontractdata.datatable.column.partnerdependent'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    }
  ]

  const [sortModel, _] = useState<ISortModel>(new SortModel(columns?.[1]?.field, OrderDirection.Asc))

  useEffect(() => {
    if (personCivilStateCorrections && personCivilStateCorrections?.personCivilStateCorrectionsByProcessId) {
      const personCivilStateCorrectionsData = personCivilStateCorrections.personCivilStateCorrectionsByProcessId
      setTableData(
        personCivilStateCorrectionsData.data.map((row: any, index: number) => {
          return {
            id: index,
            version: row.version,
            employeeNumber: row.employeeNumber,
            employerNumber: row.employerNumber,
            employeeName: row.employeeName,
            startDate: new Date(row.startDate).toLocaleDateString(DEFAULT_LOCALE),
            endDate: row.endDate ? new Date(row.endDate).toLocaleDateString(DEFAULT_LOCALE) : null,
            civilState: row.civilState?.value,
            civilStateKey: row.civilState?.key,
            icpCivilState: row.icpSubCivilState?.name,
            icpCivilStateKey: row.icpSubCivilState?.icpSpecificCode,
            partnerTitle: row.partnerTitle?.value,
            partnerTitleKey: row.partnerTitle?.key,
            partnerBirthDate: row.partnerBirthDate
              ? new Date(row.partnerBirthDate).toLocaleDateString(DEFAULT_LOCALE)
              : null,
            partnerCountryOfBirth: row.partnerCountryOfBirth?.name,
            partnerCountryOfBirthKey: row.partnerCountryOfBirth?.code,
            partnerNationality: row.partnerNationality?.name,
            partnerNationalityKey: row.partnerNationality?.code,
            partnerNationalNumber: row.partnerNationalNumber,
            partnerFirstName: row.partnerFirstName,
            partnerOtherNames: row.partnerOtherNames,
            partnerLastNamePrefix: row.partnerLastNamePrefix,
            partnerLastName: row.partnerLastName,
            partnerGenderType: row.partnerGenderType?.value,
            partnerGenderTypeKey: row.partnerGenderType?.key,
            partnerDisabled: row.partnerDisabled,
            partnerDisabledPercentage: row.partnerDisabledPercentage,
            partnerDependent: row.partnerDependent
          }
        })
      )
      setRowCount(personCivilStateCorrectionsData.totalCount)
    }
  }, [personCivilStateCorrections])

  return (
    <>
      {rowCount === pageSize && <DataExceedsPagesize />}
      <DataTable
        autoHeight
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        hideFooter={true}
        isLoading={isLoading}
        defaultSortModel={sortModel}
        emptyStateElement={
          <DataTableEmpty
            title={t('emptystate.process.contract')}
            icon={<DoneIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} />}
          />
        }
      />
    </>
  )
}

export default PersonCivilStateCorrectionsComponent

import { Avatar, Box, Link, SxProps, Typography } from '@mui/material'
import { MouseEventHandler } from 'react'
import { EpixAvatar } from './epix-avatar'

/* eslint-disable-next-line */
export interface EpixAvatarInfoBlockProps {
  linkText: string
  onClick: MouseEventHandler<HTMLDivElement>
  personId?: string
  sx?: SxProps
  subText?: string
  children?: React.ReactNode
}

export function EpixAvatarInfoBlock({
  personId,
  sx = {},
  linkText,
  subText,
  onClick,
  children
}: EpixAvatarInfoBlockProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} onClick={onClick}>
      {personId && <EpixAvatar personId={personId} sx={{ '.MuiAvatar-root': { marginBottom: 1 } }} />}
      {!personId && (
        <Avatar
          sx={{
            ...{ fontSize: '1rem', marginBottom: 1, cursor: 'pointer' },
            ...sx
          }}
        >
          {children}
        </Avatar>
      )}

      {linkText && (
        <Link color="text.primary" underline="always">
          <Typography fontSize="default" sx={{ whiteSpace: 'nowrap', marginBottom: 0.5 }}>
            {linkText}
          </Typography>
        </Link>
      )}

      {subText && (
        <Typography fontSize="default" color="text.secondary">
          {subText}
        </Typography>
      )}
    </Box>
  )
}

export default EpixAvatarInfoBlock

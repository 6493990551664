import { GraphqlError, ROLE, useGetUserByIdQuery, useUpdateUserRolesMutation } from '@epix-web-apps/core'
import { DetailPageBaseQueryParams, FormActionButtons, FormContainer, FormErrorList } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { boolean, object, TypeOf } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'
import { InfoOutlined } from '@mui/icons-material'

/* eslint-disable-next-line */
export interface UserCustomConfigurationProps {}

export function UserCustomConfiguration(props: UserCustomConfigurationProps) {
  const { t } = useTranslation()
  const params = useParams<DetailPageBaseQueryParams>()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError | Error>>([])

  const updateUserRolesmutation = useUpdateUserRolesMutation()

  const editUserCustomSchema = object({
    createPerson: boolean(),
    managePeopleContracts: boolean(),
    managePersonalCalendar: boolean(),
    manageSalary: boolean(),
    manageSelfService: boolean(),
    viewCalculatedData: boolean(),
    viewValidationErrors: boolean(),
    manageTasksAndProcesses: boolean(),
    finishPayrollClosure: boolean(),
    viewAnalytics: boolean(),
    viewProductivityAnalytics: boolean(),
    viewWorkforceAnalytics: boolean(),
    viewLabourCostAnalytics: boolean()
  })

  const { data: getUserById, refetch: refetchUserById } = useGetUserByIdQuery(
    {
      userId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )

  const currentUserRoles = getUserById?.userById.roles.map(x => x.toUpperCase()) || []

  type EditCustomUserForm = TypeOf<typeof editUserCustomSchema>
  const form = useForm<EditCustomUserForm>({
    resolver: zodResolver(editUserCustomSchema),
    defaultValues: {
      createPerson: currentUserRoles.includes(ROLE.CREATEPERSON),
      managePeopleContracts: currentUserRoles.includes(ROLE.MANAGEPEOPLECONTRACTS),
      managePersonalCalendar: currentUserRoles.includes(ROLE.MANAGEPERSONALCALENDAR),
      manageSalary: currentUserRoles.includes(ROLE.MANAGESALARY),
      manageSelfService: currentUserRoles.includes(ROLE.MANAGESELFSERVICE),
      viewCalculatedData: currentUserRoles.includes(ROLE.VIEWCALCULATEDDATA),
      viewValidationErrors: currentUserRoles.includes(ROLE.VIEWVALIDATIONERRORS),
      manageTasksAndProcesses: currentUserRoles.includes(ROLE.MANAGETASKSANDPROCESSES),
      finishPayrollClosure: currentUserRoles.includes(ROLE.FINISHPAYROLLCLOSURE),
      viewAnalytics: currentUserRoles.includes(ROLE.VIEWANALYTICS),
      viewLabourCostAnalytics: currentUserRoles.includes(ROLE.VIEWLABOURCOSTANALYTICS),
      viewProductivityAnalytics: currentUserRoles.includes(ROLE.VIEWPRODUCTIVITYANALYTICS),
      viewWorkforceAnalytics: currentUserRoles.includes(ROLE.VIEWWORKFORCEANALYTICS)
    }
  })
  const { control } = form

  const handleOnSubmit = async (user: EditCustomUserForm) => {
    const selectedUserRoles = [
      {
        key: ROLE.CREATEPERSON,
        value: user.createPerson
      },
      {
        key: ROLE.MANAGEPEOPLECONTRACTS,
        value: user.managePeopleContracts
      },
      {
        key: ROLE.MANAGESALARY,
        value: user.manageSalary
      },
      {
        key: ROLE.MANAGEPERSONALCALENDAR,
        value: user.managePersonalCalendar
      },
      {
        key: ROLE.MANAGESELFSERVICE,
        value: user.manageSelfService
      },
      {
        key: ROLE.VIEWCALCULATEDDATA,
        value: user.viewCalculatedData
      },
      {
        key: ROLE.VIEWVALIDATIONERRORS,
        value: user.viewValidationErrors
      },
      {
        key: ROLE.MANAGETASKSANDPROCESSES,
        value: user.manageTasksAndProcesses
      },
      {
        key: ROLE.FINISHPAYROLLCLOSURE,
        value: user.finishPayrollClosure
      },
      {
        key: ROLE.VIEWANALYTICS,
        value: user.viewAnalytics
      },
      {
        key: ROLE.VIEWPRODUCTIVITYANALYTICS,
        value: user.viewProductivityAnalytics
      },
      {
        key: ROLE.VIEWWORKFORCEANALYTICS,
        value: user.viewWorkforceAnalytics
      },
      {
        key: ROLE.VIEWLABOURCOSTANALYTICS,
        value: user.viewLabourCostAnalytics
      }
    ]
    const userRolesToAdd = selectedUserRoles.filter(x => x.value).map(x => x.key.toString())
    const userRolesToDelete = selectedUserRoles.filter(x => !x.value).map(x => x.key.toString())
    const newUserRoles = [
      ...new Set(
        currentUserRoles
          .map(x => x.toUpperCase())
          .filter(x => !userRolesToDelete.includes(x))
          .concat(userRolesToAdd.filter(x => !currentUserRoles.includes(x)))
      )
    ]

    await updateUserRolesmutation
      .mutateAsync({
        updateUserRolesCommand: {
          id: params.id || '',
          roles: newUserRoles
        }
      })
      .then(() => {
        refetchUserById()
        setBackendErrors([])
      })
      .catch(e => setBackendErrors([e]))
  }

  const canManagePeopleContracts = useWatch({ control, name: `managePeopleContracts` })
  const canManageTasksAndProcesses = useWatch({ control, name: `manageTasksAndProcesses` })
  const canViewAnalytics = useWatch({ control, name: `viewAnalytics` })

  useEffect(() => {
    form.setValue('viewWorkforceAnalytics', form.getValues('viewAnalytics'))
  }, [])

  return (
    <FormContainer sx={{ width: '100%' }} form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Box
        sx={{
          display: 'flex',
          justifyItems: 'center',
          alignItems: 'center',
          gap: 1
        }}
      >
        <Typography variant="h5">{t('usercustomconfiguration.title.generalsettings')}</Typography>
        <Tooltip title={t('usercustomconfiguration.tooltip.allowedproviders')}>
          <IconButton size="small">
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ display: 'flex', gap: 10 }}>
        <Box>
          <FormSwitch sx={12} name="createPerson" label={t('usercustomconfiguration.form.field.createperson')} />
          <FormSwitch
            sx={12}
            name="managePeopleContracts"
            label={t('usercustomconfiguration.form.field.managepeoplecontracts')}
            onChange={e => {
              form.resetField('manageSalary', { defaultValue: e })
              form.resetField('managePersonalCalendar', { defaultValue: e })
              form.resetField('manageSelfService', { defaultValue: e })
              form.resetField('viewCalculatedData', { defaultValue: e })
            }}
          />
          <Box
            sx={{
              ml: 6
            }}
          >
            <FormSwitch
              sx={12}
              name="manageSalary"
              label={t('usercustomconfiguration.form.field.managesalary')}
              disabled={!canManagePeopleContracts}
            />
            <FormSwitch
              sx={12}
              name="managePersonalCalendar"
              label={t('usercustomconfiguration.form.field.managepersonalcalendar')}
              disabled={!canManagePeopleContracts}
            />
            <FormSwitch
              sx={12}
              name="manageSelfService"
              label={t('usercustomconfiguration.form.field.manageselfservice')}
              disabled={!canManagePeopleContracts}
            />
            <FormSwitch
              sx={12}
              name="viewCalculatedData"
              label={t('usercustomconfiguration.form.field.viewcalculateddata')}
              disabled={!canManagePeopleContracts}
            />
          </Box>
        </Box>
        <Box>
          <FormSwitch
            sx={12}
            name="viewValidationErrors"
            label={t('usercustomconfiguration.form.field.viewvalidationerrors')}
          />
          <FormSwitch
            sx={12}
            name="manageTasksAndProcesses"
            label={t('usercustomconfiguration.form.field.managetasksandprocesses')}
            onChange={e => {
              if (!e) {
                form.resetField('finishPayrollClosure', { defaultValue: e })
              }
            }}
          />
          <Box
            sx={{
              ml: 6,
              mb: 2
            }}
          >
            <FormSwitch
              sx={12}
              name="finishPayrollClosure"
              disabled={!canManageTasksAndProcesses}
              label={t('usercustomconfiguration.form.field.finishpayrollclosure')}
            />
          </Box>
          <FormSwitch
            sx={12}
            name="viewAnalytics"
            label={t('usercustomconfiguration.form.field.viewanalytics')}
            onChange={e => {
              form.resetField('viewWorkforceAnalytics', { defaultValue: e })
              if (!e) {
                form.resetField('viewProductivityAnalytics', { defaultValue: e })
                form.resetField('viewLabourCostAnalytics', { defaultValue: e })
              }
            }}
          />
          <Box
            sx={{
              ml: 6
            }}
          >
            <FormSwitch
              sx={12}
              disabled
              name="viewWorkforceAnalytics"
              label={t('usercustomconfiguration.form.field.viewworkforceanalytics')}
            />
            <FormSwitch
              sx={12}
              name="viewProductivityAnalytics"
              disabled={!canViewAnalytics}
              label={t('usercustomconfiguration.form.field.viewproductivityanalytics')}
            />
            <FormSwitch
              sx={12}
              name="viewLabourCostAnalytics"
              disabled={!canViewAnalytics}
              label={t('usercustomconfiguration.form.field.viewlabourcostanalytics')}
            />
          </Box>
        </Box>
      </Box>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={updateUserRolesmutation.isLoading} />
    </FormContainer>
  )
}

export default UserCustomConfiguration

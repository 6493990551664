import { FormContainer } from '@epix-web-apps/ui'
import { Typography } from '@mui/material'
import { FormGridLayout } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { TypeOf, object, string } from 'zod'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useCreateTeamMutation, useGetTeamByIdQuery, useUpdateTeamMutation } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface AddEditTeamNameProps {
  teamId?: string | undefined
  create?: boolean
}

export function AddEditTeam({ teamId, create }: AddEditTeamNameProps) {
  const { closeFlyIn } = useFlyIn()
  const { t } = useTranslation()

  const addEditTeamSchema = object({
    teamName: string({
      required_error: t('form.validation.teamnamerequired'),
      invalid_type_error: t('form.validation.teamnamerequired')
    }),
    teamCode: string({
      required_error: t('form.validation.teamcoderequired'),
      invalid_type_error: t('form.validation.teamcoderequired')
    })
  })

  const { data: getTeamById, refetch: refetechTeam } = useGetTeamByIdQuery({
    id: teamId || ''
  })

  type AddEditTeamForm = TypeOf<typeof addEditTeamSchema>

  const form = useForm<AddEditTeamForm>({
    resolver: zodResolver(addEditTeamSchema),
    defaultValues: {
      teamName: !create ? getTeamById?.teamById?.name : undefined,
      teamCode: !create ? getTeamById?.teamById.code : undefined
    }
  })

  const editMutation = useUpdateTeamMutation()
  const createMutation = useCreateTeamMutation()

  const handleOnSubmit = async (newTeam: AddEditTeamForm) => {
    if (create) {
      await createMutation
        .mutateAsync({
          createTeamCommand: {
            name: newTeam.teamName,
            code: newTeam.teamCode,
            parentId: getTeamById?.teamById.id || ''
          }
        })
        .then(closeFlyIn)
    } else {
      await editMutation
        .mutateAsync({
          updateTeamCommand: {
            id: teamId || '',
            name: newTeam.teamName,
            code: newTeam.teamCode
          }
        })
        .then(() => {
          refetechTeam()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{create ? t('flyin.addteam.title') : t('flyin.editteam.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="teamCode" label={`${t('form.field.teamcode')} * `} />

        <FormInput sx={12} name="teamName" label={`${t('form.field.teamname')} *`} />
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons isMutating={editMutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddEditTeam

import { styled } from '@mui/system'
import { Box, Grid, IconButton, Typography, useTheme, Divider, Link } from '@mui/material'
import {
  ToBackendFormatedDate,
  ToRouteDateFormat,
  getDatesBetween,
  getDatesOfWeekStartingFrom,
  getNextMonday,
  getPreviousMonday,
  useGetAllTeamsWithRoleForMeQuery,
  useGetTeamByIdForTeamStructureQuery,
  useGetTeamCalendarForMeForTeamStructureQuery,
  useGetTeamsForMeForTeamStructureQuery
} from '@epix-web-apps/core'
import { Fragment, useState } from 'react'
import { EmptyState, HeaderTitleNavigation, SelfService, useGlobalStore } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'

/* eslint-disable-next-line */
export interface TeamCalendarProps {}

export const StyledBox = styled(Box)(() => {
  return {
    height: '20%',
    width: '100%',
    borderRadius: '5px'
  }
})

export const TitleCenteredGridItem = styled(Grid)(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const CenteredColoredGridItem = styled(Grid)(() => {
  const theme = useTheme()
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  }
})

export type TeamCalendarQueryParams = {
  teamid: string
  id: string
}

export function TeamCalendar(props: TeamCalendarProps) {
  const { me } = useGlobalStore()
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useParams<TeamCalendarQueryParams>()
  const navigate = useNavigate()
  const datesOfTheWeek = getDatesOfWeekStartingFrom(new Date())
  const [searchParams] = useSearchParams()
  const dateSearchParam = searchParams.get('date')

  const [viewDate, setViewDate] = useState<Array<Date>>(datesOfTheWeek)

  const { data: getTeamsByPersonId } = useGetTeamsForMeForTeamStructureQuery()

  const { data: getAllTeamsWithRole } = useGetAllTeamsWithRoleForMeQuery()

  const teamsByPersonId = getTeamsByPersonId?.teamsForMeForTeamStructure || []
  const teamWithRole = getAllTeamsWithRole?.allTeamsWithRoleForMe || []

  const allTeams = teamsByPersonId.concat(teamWithRole).filter((team, index, self) => {
    return self.findIndex(t => t.id === team.id) === index
  })

  const { data: getTeamCalendarData } = useGetTeamCalendarForMeForTeamStructureQuery(
    {
      teamId: params.teamid || '',
      startDate: ToBackendFormatedDate(viewDate[0]),
      endDate: ToBackendFormatedDate(viewDate[6])
    },
    {
      enabled: !!params.teamid
    }
  )

  const { data: getTeamById } = useGetTeamByIdForTeamStructureQuery(
    {
      id: params.teamid || ''
    },
    {
      enabled: !!params.teamid
    }
  )

  interface DateEntry {
    date: string
    calculatedEntries: any[]
  }

  const combinedTeamCalendar: {
    personId: string
    personFirstName: string
    personLastName: string
    entries: DateEntry[]
  }[] = []

  getTeamCalendarData?.teamCalendarForMeForTeamStructure.forEach(personData => {
    const personCalendar = {
      personId: personData.personId,
      personFirstName: personData.personFirstName,
      personLastName: personData.personLastName,
      entries: [] as DateEntry[]
    }

    personData.days.forEach(dateEntries => {
      dateEntries.forEach(entry => {
        const date = entry.date
        const existingDateEntry = personCalendar.entries.find(e => e.date === date)

        if (!existingDateEntry) {
          const newDateEntry: DateEntry = {
            date,
            calculatedEntries: []
          }
          if (entry.calculatedEntries.length > 0) {
            newDateEntry.calculatedEntries.push(entry)
          }
          personCalendar.entries.push(newDateEntry)
        } else {
          if (entry.calculatedEntries.length > 0) {
            existingDateEntry.calculatedEntries.push(entry)
          }
        }
      })
    })

    combinedTeamCalendar.push(personCalendar)
  })

  const personHasRoleTowardsSelectedTeam = teamWithRole.find(x => x.id === params.teamid) !== undefined

  combinedTeamCalendar.forEach(teamCalendar => {
    const viewDateRange = getDatesBetween(viewDate[0], viewDate[6])
    viewDateRange.forEach(date => {
      const existingEntry = teamCalendar.entries.find(entry => entry.date === ToRouteDateFormat(date))

      if (!existingEntry) {
        teamCalendar.entries.push({
          date: ToRouteDateFormat(date),
          calculatedEntries: []
        })
      }
    })
  })

  return allTeams.length === 0 ? (
    <Box>
      <HeaderTitleNavigation
        backToLink={SelfService.SELFSERVICE_DATE(
          `${ToRouteDateFormat(dateSearchParam ? new Date(dateSearchParam) : new Date())}`
        )}
        mobileSizeTitle
        title={t('selfservice.team-calendar.title')}
        showDivider={false}
      />
      <EmptyState title={t('selfservice.team-calendar.no-team')} />
    </Box>
  ) : (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <HeaderTitleNavigation
            backToLink={SelfService.SELFSERVICE_DATE(
              `${ToRouteDateFormat(dateSearchParam ? new Date(dateSearchParam) : new Date())}`
            )}
            mobileSizeTitle
            title={t('selfservice.team-calendar.title') + ' - ' + getTeamById?.teamByIdForTeamStructure.name}
            showDivider={false}
          />
        </Box>
        <IconButton
          onClick={e => {
            navigate(SelfService.TEAM_CALENDAR_TEAMID_ID_FILTER(`${params.teamid}`, `${me?.personId}`))
          }}
        >
          <FilterAltOutlinedIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          marginLeft: '-0.5rem',
          display: 'flex'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: 'auto'
          }}
        >
          <IconButton
            size="small"
            sx={{ color: theme.palette.primary.main }}
            onClick={() => setViewDate(getDatesOfWeekStartingFrom(getPreviousMonday(viewDate[0])))}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <Typography fontSize={'1.1rem'} m={1} variant="h4">
            {viewDate[0].toLocaleDateString() + ' - ' + viewDate[6].toLocaleDateString()}
          </Typography>
          <IconButton
            size="small"
            sx={{ color: theme.palette.primary.main }}
            onClick={() => setViewDate(getDatesOfWeekStartingFrom(getNextMonday(viewDate[0])))}
          >
            <KeyboardArrowRight />
          </IconButton>
        </Box>
      </Box>
      <Divider />

      {combinedTeamCalendar.length === 0 ? (
        <EmptyState title={t('selfservice.team-calendar.noteammembers')} />
      ) : (
        <Grid mt={2} container rowSpacing={1.2} columnSpacing={{ xs: 0.8, sm: 1, md: 2 }}>
          <Grid item xs={3.6}>
            <Typography sx={{ color: theme.palette.text.secondary }}>{t('selfservice.team-calendar.name')}</Typography>
          </Grid>
          {datesOfTheWeek.map((x: Date, index: number) => (
            <CenteredColoredGridItem key={index} item xs={1.2}>
              {x.toLocaleDateString(me?.locale.locale, { weekday: 'short' })}
            </CenteredColoredGridItem>
          ))}
          <TitleCenteredGridItem item xs={3.6}></TitleCenteredGridItem>
          {viewDate.map((x: Date, index: number) => (
            <TitleCenteredGridItem key={index} item xs={1.2}>
              {x.getDate()}
            </TitleCenteredGridItem>
          ))}
          {combinedTeamCalendar?.map((person, index) => (
            <Fragment key={person.personId}>
              <Grid item key={index} xs={3.6}>
                {personHasRoleTowardsSelectedTeam ? (
                  <Link
                    fontSize={'0.9em'}
                    color={theme.palette.text.primary}
                    underline="always"
                    onClick={() => {
                      navigate(SelfService.PERSON_CALENDAR_TEAMID_ID(`${params.teamid}`, `${person?.personId}`))
                    }}
                  >
                    {' '}
                    {`${person.personFirstName} ${person.personLastName}`}
                  </Link>
                ) : (
                  <Typography fontSize={'0.9em'}>{person.personFirstName + ' ' + person.personLastName}</Typography>
                )}
              </Grid>
              {viewDate.map((x: Date, index: number) => {
                const dayFordate = person?.entries.find(e => e.date === ToBackendFormatedDate(x)) ?? null

                return (
                  <CenteredColoredGridItem item xs={1.2} key={`${person.personId}-${index}`}>
                    <Box
                      key={index}
                      sx={{
                        borderRadius: '5px',
                        overflow: 'hidden',
                        display: 'flex',
                        width: '100%'
                      }}
                    >
                      {(dayFordate?.calculatedEntries?.length ?? 0) > 0 ? (
                        (() => {
                          const calculatedEntries = dayFordate?.calculatedEntries[0]?.calculatedEntries ?? []

                          const dayPart1Events = calculatedEntries.filter((event: any) => {
                            return event.timeFrameType.key === 'DAY_PART_1'
                          })
                          const dayPart2Events = calculatedEntries.filter(
                            (event: any) => event.timeFrameType.key === 'DAY_PART_2'
                          )
                          const fullDayEvents = calculatedEntries.filter(
                            (event: any) => event.timeFrameType.key === 'FULL_DAY'
                          )
                          return (
                            <>
                              {dayPart1Events.map((data: any, entryIndex: number) => (
                                <Box
                                  key={`${person.personId}-${index}-${entryIndex}`}
                                  sx={{
                                    width: `calc(50%/${dayPart1Events.length})`,
                                    height: '6px',
                                    backgroundColor: data.colourCodeHex
                                  }}
                                ></Box>
                              ))}
                              {fullDayEvents.map((data: any, entryIndex: number) => (
                                <Box
                                  key={`${person.personId}-${index}-${entryIndex}`}
                                  sx={{
                                    width: dayPart1Events.length > 0 || dayPart2Events.length > 0 ? '50%' : '100%',
                                    height: '6px',
                                    backgroundColor: data.colourCodeHex
                                  }}
                                ></Box>
                              ))}
                              {dayPart2Events.map((data: any, entryIndex: number) => (
                                <Box
                                  key={`${person.personId}-${index}-${entryIndex}`}
                                  sx={{
                                    width: `calc(50%/${dayPart2Events.length})`,
                                    height: '6px',
                                    backgroundColor: data.colourCodeHex,
                                    marginLeft: dayPart1Events.length === 0 ? 'auto' : '0'
                                  }}
                                ></Box>
                              ))}
                            </>
                          )
                        })()
                      ) : (
                        <Box
                          sx={{
                            flex: '1',
                            borderRadius: '6px',
                            height: '6px',
                            border: '1.5px solid',
                            borderColor: theme.palette.grey[200]
                          }}
                        ></Box>
                      )}
                    </Box>
                  </CenteredColoredGridItem>
                )
              })}
            </Fragment>
          ))}
        </Grid>
      )}
    </Box>
  )
}

export default TeamCalendar

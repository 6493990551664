import { useTranslation } from 'react-i18next'
import { ACCEPTED_UPLOAD_TYPES, FormActionButtons, FormErrorList, FormFileUpload, ZodFileArray, useFlyIn } from '@epix-web-apps/ui'
import { TypeOf, object } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { GraphqlError, useUploadDocumentForDocumentTypeMutation } from '@epix-web-apps/core'
import { FormContainer } from '@epix-web-apps/ui'
import { Typography } from '@mui/material'
import { FormGridLayout } from '@epix-web-apps/ui'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface AddDocumentProps {
  documentTypeId: string
  contractId?: string
}

export function AddDocument({
  documentTypeId,
  contractId
}: AddDocumentProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const acceptedFileTypes = [ACCEPTED_UPLOAD_TYPES.JPEG, ACCEPTED_UPLOAD_TYPES.PNG, ACCEPTED_UPLOAD_TYPES.PDF];
  const addDocumentSchema = object({
    documents: ZodFileArray(acceptedFileTypes, true)
  })

  type AddDocumentForm = TypeOf<typeof addDocumentSchema>

  const form = useForm<AddDocumentForm>({
    resolver: zodResolver(addDocumentSchema),
    defaultValues: {
      documents: []
    }
  })

  const uploadDocumentForDocumentType = useUploadDocumentForDocumentTypeMutation();

  const handleOnSubmit = async (document: AddDocumentForm) => {
    await Promise.all(document.documents.map(document =>
      uploadDocumentForDocumentType.mutateAsync({
        file: document.file,
        documentTypeId: documentTypeId,
        contractId: contractId
      })
    ))
    .then(closeFlyIn)
    .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.adddocument.title')}</Typography>

      <FormGridLayout>

        <FormFileUpload
          sx={12}
          name="documents"
          buttonText={t('documentcomponent.button.title')}
          accept={acceptedFileTypes}
        />

      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={uploadDocumentForDocumentType.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddDocument

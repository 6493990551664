import {
  formTypeSelectOptions,
  useGetPersonByIdQuery,
  useUpdatePersonCompanyInformationMutation
} from '@epix-web-apps/core'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { date, string, object, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { DetailPageBaseQueryParams, FormGridLayout } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { Typography } from '@mui/material'
import { FormActionButtons, FormDatepicker, FormErrorList } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { FormSelect } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface EditPersonCompanyInfoProps {}

export function EditPersonCompanyInfo(props: EditPersonCompanyInfoProps) {
  const { t } = useTranslation()
  const editPersonCompanyInfoSchema = object({
    functionTitle: string().nullable(),
    functionAreaKeyType: string().optional().nullable(),
    functionLevelKeyType: string().optional().nullable(),
    personNumber: string().nullable(),
    startedOn: date().optional().nullable()
  })
  type EditPersonCompanyInfoForm = TypeOf<typeof editPersonCompanyInfoSchema>
  const params = useParams<DetailPageBaseQueryParams>()
  const { closeFlyIn } = useFlyIn()
  const { functionAreaOptions, functionLevelOptions } = formTypeSelectOptions
  const { data: getPersonById, refetch: refetchPeronById } = useGetPersonByIdQuery(
    {
      personId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )

  const mutation = useUpdatePersonCompanyInformationMutation()
  const form = useForm<EditPersonCompanyInfoForm>({
    resolver: zodResolver(editPersonCompanyInfoSchema),
    defaultValues: {
      functionTitle: getPersonById?.personById?.company?.functionTitle,
      startedOn: getPersonById?.personById?.company?.startedOn
        ? new Date(getPersonById?.personById?.company?.startedOn)
        : null,
      functionAreaKeyType: getPersonById?.personById.company?.functionAreaTypeKey?.key,
      functionLevelKeyType: getPersonById?.personById.company?.functionLevelTypeKey?.key,
      personNumber: getPersonById?.personById?.company?.personNumber
    }
  })

  const handleOnSubmit = async (personCompanyInfo: EditPersonCompanyInfoForm) => {
    await mutation
      .mutateAsync({
        updatePersonCompanyInformationCommand: {
          personId: params.id || '',
          functionTitle: personCompanyInfo.functionTitle,
          functionAreaTypeKey: personCompanyInfo.functionAreaKeyType,
          functionLevelTypeKey: personCompanyInfo.functionLevelKeyType,
          personNumber: personCompanyInfo.personNumber,
          startedOn: personCompanyInfo.startedOn && personCompanyInfo.startedOn
        }
      })
      .then(() => {
        refetchPeronById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editperson.companyinfo.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="functionTitle" label={t('form.field.functiontitle')} />

        <FormSelect
          sx={12}
          name="functionAreaKeyType"
          label={t('form.field.functionArea')}
          options={functionAreaOptions}
        />

        <FormSelect
          sx={12}
          name="functionLevelKeyType"
          label={t('form.field.functionLevel')}
          options={functionLevelOptions}
        />

        <FormDatepicker sx={12} name="startedOn" label={t('form.field.startedon')} />

        <FormInput sx={12} name="personNumber" label={t('form.field.personnumber')} />
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons isMutating={mutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditPersonCompanyInfo

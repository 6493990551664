import { Box, Button, FormControlLabel, Radio, RadioGroup, Switch, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EpixDatePicker } from '../../../epix-date-picker'
import { FLYIN_WIDTH, FilterSelectBox, useFlyIn } from '@epix-web-apps/ui'
import { formTypeSelectOptions, GetFirstDayOfYear, GetLastDayOfYear, ShowCarsType } from '@epix-web-apps/core'
import { CompanyCarFilters } from '../../../../pages/company-cars-page/company-cars-page'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface CompanyCarStatisticsFiltersProps {
  tabIndex?: number
  filters: CompanyCarFilters
  updateStore: (filters: CompanyCarFilters) => void
}

export function CompanyCarStatisticsFilters({
  tabIndex,
  filters: initialFilters,
  updateStore
}: CompanyCarStatisticsFiltersProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { closeFlyIn } = useFlyIn()
  const { countryOptions } = formTypeSelectOptions
  const { carCategoryOptions, motorTypeOptions } = formTypeSelectOptions
  const [filters, setFilters] = useState(initialFilters)

  function handleFilter() {
    updateStore(filters)
    closeFlyIn()
  }

  function handleReset() {
    updateStore({
      startDate: GetFirstDayOfYear(new Date()),
      endDate: GetLastDayOfYear(new Date()),
      carCategory: null,
      country: null,
      motorType: null,
      showZeroScale: false,
      endsInPeriod: false,
      showCars: ShowCarsType.ShowAllCars
    })
    closeFlyIn()
  }

  return (
    <Box sx={{ width: FLYIN_WIDTH.DEFAULT }}>
      <Box sx={{ marginBottom: '1rem' }}>
        <Typography variant="h4">{t('companycar-statistics.filters')}</Typography>
      </Box>
      <Box sx={{ marginBottom: '1rem' }}>
        <EpixDatePicker
          onChange={e => setFilters({ ...filters, startDate: e! })}
          label={t('companycar-statistics.datepicker.fromdate')}
          value={filters.startDate}
          actionBarActions={['today']}
          fullWidth={true}
        />
      </Box>
      <Box sx={{ marginBottom: '1rem' }}>
        <EpixDatePicker
          onChange={e => setFilters({ ...filters, endDate: e! })}
          label={t('companycar-statistics.datepicker.todate')}
          value={filters.endDate}
          actionBarActions={['clear']}
          fullWidth={true}
        />
      </Box>
      <Box sx={{ pb: 2 }}>
        <Switch
          checked={filters.endsInPeriod}
          onChange={e => setFilters({ ...filters, endsInPeriod: e.target.checked })}
          aria-label={t('companycar-statistics.endsinperiod')}
        />
        {t('companycar-statistics.endsinperiod')}
      </Box>
      <FilterSelectBox
        id={'carCategoryId'}
        label={t('companycar-statistics.carcategory')}
        keys={carCategoryOptions}
        handleChange={(e: string | null) => setFilters({ ...filters, carCategory: e })}
        value={filters.carCategory}
      />
      <FilterSelectBox
        id={'countryKey'}
        label={t('companycar-statistics.country')}
        keys={countryOptions}
        handleChange={(e: string | null) => setFilters({ ...filters, country: e })}
        value={filters.country}
      />
      <FilterSelectBox
        id={'motorTypeKey'}
        label={t('companycar-statistics.motortype')}
        keys={motorTypeOptions}
        handleChange={(e: string | null) => setFilters({ ...filters, motorType: e })}
        value={filters.motorType}
      />
      <Box>
        {t('companycar-statistics.filtercarsbyusage')}
        <RadioGroup
          sx={{ pl: 2 }}
          onChange={(_, value) => setFilters({ ...filters, showCars: value as ShowCarsType })}
          value={filters.showCars ?? ShowCarsType.ShowAllCars}
        >
          <FormControlLabel
            value={ShowCarsType.ShowAllCars}
            label={t('companycar-statistics.nofilter')}
            control={<Radio />}
          />
          <FormControlLabel
            value={ShowCarsType.ShowCarsInUseOnly}
            label={t('companycar-statistics.showonlycarsinuse')}
            control={<Radio />}
          />
          <FormControlLabel
            value={ShowCarsType.ShowCarsNotInUseOnly}
            label={t('companycar-statistics.showonlycarsnotinuse')}
            control={<Radio />}
          />
        </RadioGroup>
      </Box>
      {tabIndex === 1 && (
        <Box>
          <Switch
            checked={filters.showZeroScale}
            onChange={e => setFilters({ ...filters, showZeroScale: e.target.checked })}
            aria-label={t('companycar-statistics.showzeroscale')}
          />
          {t('companycar-statistics.showzeroscale')}
        </Box>
      )}
      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: theme.palette.background.default,
          py: 2,
          zIndex: 10
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Button variant="contained" onClick={handleFilter}>
              {t('analytics.flyin.filter')}
            </Button>
            <Button variant="outlined" onClick={closeFlyIn}>
              {t('analytics.flyin.cancel')}
            </Button>
          </Box>
          <Button variant="text" onClick={handleReset}>
            {t('analytics.flyin.reset')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default CompanyCarStatisticsFilters

import { Box, Typography, useTheme } from '@mui/material'
import { TypographyBold, useGlobalStore } from '@epix-web-apps/ui'
import { Trans } from 'react-i18next'
import { DocumentModel } from '@epix-web-apps/core'
import { DownloadDocument } from '../download-document'

/* eslint-disable-next-line */
export interface DocumentComponentProps {
  document: DocumentModel
  documentName: string
  addedOnDate: Date
}

export function DocumentComponent({ document, documentName, addedOnDate }: DocumentComponentProps) {
  const theme = useTheme()
  const { me } = useGlobalStore()

  return (
    <Box
      justifyContent="space-between"
      alignContent="center"
      alignItems="center"
      mt={0.5}
      pl={1}
      border="1.5px solid"
      borderRadius={'2px'}
      borderColor={theme.palette.divider}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginRight: 1
        }}
      >
        <TypographyBold>{documentName}</TypographyBold>
        <DownloadDocument documentData={document} />
      </Box>
      <Typography>
        <Trans
          i18nKey="documentcomponent.details"
          values={{ addeddate: addedOnDate?.toLocaleDateString(me?.locale.locale) }}
          components={{ tt: <span /> }}
        />
      </Typography>
    </Box>
  )
}

export default DocumentComponent

import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import EmployersWidgetTitle from '../employers-widget-title'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { useTranslation } from 'react-i18next'
import { Configuration, DetailPageBaseQueryParams, useFlyIn } from '@epix-web-apps/ui'
import { useNavigate, useParams } from 'react-router-dom'
import { TypographySmall, WidgetRowItem } from '../employer-overview/employer-overview'
import { IconButton } from '@mui/material'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import { EditEmployeeDocumentTypes } from '../../document-components'

/* eslint-disable-next-line */
export interface EmployersWidgetDocumentsProps {
  employerId: string
}

export function EmployersWidgetDocuments({ employerId }: EmployersWidgetDocumentsProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const navigate = useNavigate()
  const params = useParams<DetailPageBaseQueryParams>()

  return (
    <>
      <EmployersWidgetTitle
        title={t('employerspage.widget.documents.title')}
        url={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_DOCUMENTS(`${params.id}`, `${employerId}`)}
        icon={<FolderOutlinedIcon />}
        hasData={true}
      />
      <WidgetRowItem>
        <TypographySmall>{t('employerspage.widget.documenttypes.employee')}</TypographySmall>
        <IconButton
          sx={{ width: '1.5rem', height: '1.5rem' }}
          onClick={() =>
            openFlyIn({
              content: <EditEmployeeDocumentTypes employerId={employerId} />
            })
          }
        >
          <EditOutlinedIcon fontSize="small" />
        </IconButton>
      </WidgetRowItem>
      <WidgetRowItem>
        <TypographySmall>{t('employerspage.widget.documenttypes.company')}</TypographySmall>
        <IconButton
          sx={{ width: '1.5rem', height: '1.5rem' }}
          onClick={() => navigate(Configuration.ICP_PROVIDERS_ID_EMPLOYERID_DOCUMENTS(`${params.id}`, employerId))}
        >
          <KeyboardArrowRightOutlinedIcon />
        </IconButton>
      </WidgetRowItem>
    </>
  )
}

export default EmployersWidgetDocuments

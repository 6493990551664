import {
  isAdmin,
  OrderDirection,
  useCreateIcpMutation,
  useGetAllIcpsQuery,
  useGetIcpByIdQuery,
  useGetMeQuery,
  useUpdateIcpMutation
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { Grid, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { boolean, object, string, TypeOf } from 'zod'
import { FormGridLayout } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'

/* eslint-disable-next-line */
export interface EditIcpProps {
  icpId?: string
}

export function EditIcp({ icpId }: EditIcpProps) {
  const { t } = useTranslation()
  const { data: me } = useGetMeQuery()
  const editIcpInfoSchema = object({
    payrollProvider: string().optional().nullable(),
    country: string().optional().nullable(),
    code: string().max(3, t('form.validation.codemaxchars')),
    active: boolean()
  }).refine(
    input =>
      !getAllIcps?.icps.data.find(icp => icp.code === input.code && icp.active && icp.id !== icpId)
        ? input.code
        : !input.code,
    {
      message: t('form.validation.codeunique'),
      path: ['endDate']
    }
  )
  type EditIcpInfoForm = TypeOf<typeof editIcpInfoSchema>
  const { data: getAllIcps } = useGetAllIcpsQuery(
    {
      activeOnly: false,
      offset: 0,
      limit: -1,
      sortByProperty: null,
      orderDirection: OrderDirection.Asc
    },
    {
      suspense: false
    }
  )
  const { data: getIcpById, refetch: refetchIcpById } = useGetIcpByIdQuery(
    {
      icpId: icpId || ''
    },
    {
      enabled: !!icpId
    }
  )
  const { closeFlyIn } = useFlyIn()
  const mutationUpdate = useUpdateIcpMutation()
  const mutationCreate = useCreateIcpMutation()

  const form = useForm<EditIcpInfoForm>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      payrollProvider: getIcpById?.icpById.payrollProvider,
      country: getIcpById?.icpById.country,
      code: getIcpById?.icpById?.code || getIcpById?.icpById.code,
      active: getIcpById?.icpById.active ?? false
    },
    resolver: zodResolver(editIcpInfoSchema)
  })

  const handleOnSubmit = async (icp: EditIcpInfoForm) => {
    if (icpId) {
      await mutationUpdate
        .mutateAsync({
          updateIcpCommand: {
            id: icpId || '',
            code: icp.code,
            active: icp.active,
            payrollProvider: getIcpById?.icpById?.payrollProvider || ''
          }
        })
        .then(() => {
          refetchIcpById()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editicp.title')}</Typography>

      <FormGridLayout>
        {isAdmin(me?.me.roles) && <FormInput sx={12} name="code" label={t('form.field.icpcode')} />}

        <FormInput disabled sx={12} name="payrollProvider" label={t('form.field.payrollprovider')} />
        <FormInput disabled sx={12} name="country" label={t('form.field.country')} />

        {isAdmin(me?.me.roles) && icpId && <FormSwitch sx={12} name="active" label={t('form.field.active')} />}

        <Grid xs={12} item>
          <Typography sx={{ fontStyle: 'italic' }}>
            <Trans
              i18nKey="form.field.activedescription"
              values={{ settingscode: getIcpById?.icpById.settingsCode }}
              components={{ bold: <span className="bold" /> }}
            />
          </Typography>
        </Grid>
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons
        isMutating={mutationCreate.isLoading || mutationUpdate.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default EditIcp

import { useFlyIn } from '@epix-web-apps/ui'
import { FilterAlt, FilterAltOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'

interface FilterButtonFlyInProps {
  filled: boolean
  flyinContent: React.ReactElement
}

export function FilterButtonFlyIn({ filled, flyinContent }: FilterButtonFlyInProps) {
  const { openFlyIn } = useFlyIn()
  return (
    <IconButton
      size="small"
      aria-label="filter button"
      onClick={() => {
        openFlyIn({ content: flyinContent })
      }}
    >
      {filled ? <FilterAlt color="primary" /> : <FilterAltOutlined />}
    </IconButton>
  )
}

import { Box, IconButton, Typography, useTheme, Grid, Link, Button, Tabs } from '@mui/material'
import { colors, useGetFeaturesQuery } from '@epix-web-apps/core'
import { TabPanel, a11yProps, useFlyIn } from '@epix-web-apps/ui'
import { useState } from 'react'
import AddEditEmployer from '../add-edit-employer'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import EmployersWidgetPaygroups from '../employers-widget-paygroups'
import EmployersWidgetPayrollcodes from '../employers-widget-payrollcodes'
import EmployersWidgetWorkschedules from '../employers-widget-workschedules'
import EmployersWidgetRequestpolicies from '../employers-widget-requestpolicies'
import EmployersWidgetBranchunits from '../employers-widget-branchunits/employers-widget-branchunits'
import EmployersWidgetDaydefinitions from '../employers-widget-daydefinitions/employers-widget-daydefinitions'
import EmployersWidgetCollectivecalendars from '../employers-widget-collectivecalendars'
import { SuspensePaper } from '../../suspense-paper'
import { EmployerBasicModel } from '@epix-web-apps/core'
import IconTab from '../../tab-components/tab/icon-tab'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined'
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import ApartmentIcon from '@mui/icons-material/Apartment'
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined'
import DataObjectIcon from '@mui/icons-material/DataObject';
import { EmployersWidgetDocuments } from '../employers-widget-documents'
import { EmployersWidgetSmartPayComponents } from '../employers-widget-smartpaycomponents'

/* eslint-disable-next-line */
export interface EmployerOverviewProps {
  employer: EmployerBasicModel
  refetchAllEmployers: () => Promise<any>
}

export const TitleBox = styled(Box)(() => {
  const theme = useTheme()
  return {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    '.MuiSvgIcon-root': {
      color: theme.palette.primary.main,
      width: '1.5rem'
    }
  }
})

export const DetailBoxRowItem = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '1.5rem'
  }
})

export const WidgetRowItem = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

export const TypographySmall = styled(Typography)(() => {
  return {
    fontSize: '0.9rem'
  }
})

export const TypographyRed = styled(Typography)(() => {
  const theme = useTheme()
  return {
    fontSize: '0.9rem',
    color: theme.palette.error.dark
  }
})

export const IconSmall = styled(IconButton)(() => {
  const theme = useTheme()
  return {
    color: theme.palette.primary.main,
    width: '1.5rem',
    height: '1.5rem'
  }
})

export const TypographyIcons = styled(Box)(() => {
  const theme = useTheme()
  return {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.8rem',
    '.MuiSvgIcon-root': {
      color: theme.palette.text.primary,
      width: '1rem',
      height: '1rem'
    }
  }
})

export function ReadMoreText({
  text,
  readMoreLink,
  alignRight
}: {
  text: React.ReactElement | string
  readMoreLink: string
  alignRight?: boolean
}) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <TypographySmall
      sx={{
        color: theme.palette.text.secondary,
        textAlign: alignRight ? 'right' : 'left'
      }}
    >
      {text}{' '}
      <Link href={readMoreLink} target="_blank">
        {t('employerspage.widget.readmore')}
      </Link>
    </TypographySmall>
  )
}

function CompleteIcon({ complete }: { complete: boolean }) {
  return (
    <Box>{complete ? <CheckCircleIcon sx={{ color: colors[1] }} /> : <RadioButtonUncheckedIcon color="warning" />}</Box>
  )
}

export function EmployerOverview({ employer, refetchAllEmployers }: EmployerOverviewProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { openFlyIn } = useFlyIn()
  const [guide, setGuide] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)

  const [payrollCodesOk, setpayrollCodesOk] = useState(false)
  const [dayDefinitionsOk, setdayDefinitionsOk] = useState(false)
  const [workSchedulesOk, setworkSchedulesOk] = useState(false)
  const [collectiveCalendarsOk, setcollectiveCalendarsOk] = useState(false)
  const [requestPoliciesOk, setrequestPoliciesOk] = useState(false || !employer.hasSelfService)
  const [payGroupsOk, setpayGroupsOk] = useState(false)
  const [branchUnitsOk, setbranchUnitsOk] = useState(false)

  const { data: getFeatureFlags } = useGetFeaturesQuery();

  const tabTitles = [
    {
      title: t('employerpage.tab.payrollcodes.title'),
      description: t('employerpage.tab.payrollcodes.description'),
      tabComplete: payrollCodesOk
    },
    {
      title: t('employerpage.tab.calendars.title'),
      description: t('employerpage.tab.calendars.description'),
      tabComplete: dayDefinitionsOk && workSchedulesOk && collectiveCalendarsOk && requestPoliciesOk
    },
    {
      title: t('employerpage.tab.paygroups.title'),
      description: t('employerpage.tab.paygroups.description'),
      tabComplete: payGroupsOk
    },
    {
      title: t('employerpage.tab.branchunits.title'),
      description: t('employerpage.tab.branchunits.description'),
      tabComplete: branchUnitsOk
    }
  ]

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: theme.palette.divider,
        borderRadius: '5px',
        padding: '1.5rem',
        marginBottom: '1.5rem'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start'
        }}
      >
        <TitleBox sx={{ pb: '1rem' }}>
          <Typography sx={{ display: 'flex', gap: '10px', m: 0 }} variant="h3">
            {employer.companyName} ({employer.number}
            {(employer.hasCalendarManagement ||
              employer.hasPayComponentManagement ||
              employer.hasSelfService ||
              employer.hasCompanyCars ||
              employer.hasDocumentManagement ||
              employer.hasSmartPayComponents) && (
              <Box>
                {employer.hasPayComponentManagement && <EuroOutlinedIcon />}
                {employer.hasCalendarManagement && <EventOutlinedIcon />}
                {employer.hasSelfService && <PhoneAndroidOutlinedIcon />}
                {employer.hasCompanyCars && <DirectionsCarFilledOutlinedIcon />}
                {employer.hasDocumentManagement && <AttachFileOutlinedIcon />}
                {employer.hasSmartPayComponents && <DataObjectIcon />}
              </Box>
            )}
            )
          </Typography>
          <IconButton
            size="small"
            onClick={() =>
              openFlyIn({
                content: <AddEditEmployer employerId={employer.id} />,
                callbackAfterClose: () => refetchAllEmployers
              })
            }
          >
            <EditOutlinedIcon />
          </IconButton>
        </TitleBox>

        <Box>
          {guide ? (
            <Button variant="outlined" startIcon={<ApartmentIcon />} onClick={() => setGuide(prev => !prev)}>
              {t('employerpage.button.overview')}
            </Button>
          ) : (
            <Button variant="outlined" startIcon={<AutoFixHighIcon />} onClick={() => setGuide(prev => !prev)}>
              {t('employerpage.button.guide')}
            </Button>
          )}
        </Box>
      </Box>

      {guide ? (
        <>
          <Tabs value={tabIndex} onChange={(_, idx) => setTabIndex(idx)}>
            {tabTitles.map((tabTitle, index) => {
              const hideTab = index === 1 && !employer.hasCalendarManagement
              return (
                <IconTab
                  key={index}
                  {...a11yProps(index)}
                  align="start"
                  justify="start"
                  hidden={hideTab}
                  sx={{
                    border: 1,
                    borderColor: theme.palette.divider,
                    maxWidth: 'none',
                    flex: 1,
                    flexDirection: 'column'
                  }}
                  label={
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem',
                          mb: '0.5rem'
                        }}
                      >
                        <CompleteIcon complete={tabTitle.tabComplete} />
                        <Typography>{tabTitle.title}</Typography>
                      </Box>
                      <TypographySmall
                        sx={{
                          textAlign: 'start',
                          textTransform: 'none',
                          color: theme.palette.text.secondary
                        }}
                      >
                        {tabTitle.description}
                      </TypographySmall>
                    </>
                  }
                />
              )
            })}
          </Tabs>

          <TabPanel value={tabIndex} index={0} padding="0">
            <Grid container spacing="0.5rem" pt="0.5rem">
              <Grid item md={6}>
                <SuspensePaper sx={{ display: 'flex' }}>
                  <Box sx={{ m: '0.5rem', mr: '1.5rem' }}>
                    <CompleteIcon complete={payrollCodesOk} />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <EmployersWidgetPayrollcodes setTabComplete={setpayrollCodesOk} employerId={employer.id} />
                  </Box>
                </SuspensePaper>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={tabIndex} index={1} padding="0">
            <Grid container spacing="0.5rem" pt="0.5rem">
              <Grid item md={6}>
                {employer.hasCalendarManagement && (
                  <SuspensePaper sx={{ display: 'flex' }}>
                    <Box sx={{ m: '0.5rem', mr: '1.5rem' }}>
                      <CompleteIcon complete={dayDefinitionsOk} />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <EmployersWidgetDaydefinitions setTabComplete={setdayDefinitionsOk} employerId={employer.id} />
                    </Box>
                  </SuspensePaper>
                )}
              </Grid>

              <Grid item md={6}>
                {employer.hasCalendarManagement && (
                  <SuspensePaper sx={{ display: 'flex' }}>
                    <Box sx={{ m: '0.5rem', mr: '1.5rem' }}>
                      <CompleteIcon complete={collectiveCalendarsOk} />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <EmployersWidgetCollectivecalendars
                        setTabComplete={setcollectiveCalendarsOk}
                        employerId={employer.id}
                      />
                    </Box>
                  </SuspensePaper>
                )}
              </Grid>

              <Grid item md={6}>
                {employer.hasCalendarManagement && (
                  <SuspensePaper sx={{ display: 'flex' }}>
                    <Box sx={{ m: '0.5rem', mr: '1.5rem' }}>
                      <CompleteIcon complete={workSchedulesOk} />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <EmployersWidgetWorkschedules setTabComplete={setworkSchedulesOk} employerId={employer.id} />
                    </Box>
                  </SuspensePaper>
                )}
              </Grid>

              <Grid item md={6}>
                {employer.hasSelfService && (
                  <SuspensePaper sx={{ display: 'flex' }}>
                    <Box sx={{ m: '0.5rem', mr: '1.5rem' }}>
                      <CompleteIcon complete={requestPoliciesOk} />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <EmployersWidgetRequestpolicies setTabComplete={setrequestPoliciesOk} employerId={employer.id} />
                    </Box>
                  </SuspensePaper>
                )}
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={tabIndex} index={2} padding="0">
            <Grid container spacing="0.5rem" pt="0.5rem">
              <Grid item md={6}>
                <SuspensePaper sx={{ display: 'flex' }}>
                  <Box sx={{ m: '0.5rem', mr: '1.5rem' }}>
                    <CompleteIcon complete={payGroupsOk} />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <EmployersWidgetPaygroups setTabComplete={setpayGroupsOk} employerId={employer.id} />
                  </Box>
                </SuspensePaper>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={tabIndex} index={3} padding="0">
            <Grid container spacing="0.5rem" pt="0.5rem">
              <Grid item md={6}>
                <SuspensePaper sx={{ display: 'flex' }}>
                  <Box sx={{ m: '0.5rem', mr: '1.5rem' }}>
                    <CompleteIcon complete={branchUnitsOk} />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <EmployersWidgetBranchunits setTabComplete={setbranchUnitsOk} employerId={employer.id} />
                  </Box>
                </SuspensePaper>
              </Grid>
            </Grid>
          </TabPanel>
        </>
      ) : (
        <Grid container rowSpacing={2} spacing={{ md: 4, lg: 6 }}>
          <Grid item md={6} lg={4}>
            <SuspensePaper sx={{ border: 0 }}>
              <EmployersWidgetPayrollcodes setTabComplete={setpayGroupsOk} employerId={employer.id} />
            </SuspensePaper>
          </Grid>
          {employer.hasCalendarManagement && (
            <Grid item md={6} lg={4}>
              <SuspensePaper sx={{ border: 0 }}>
                <EmployersWidgetDaydefinitions setTabComplete={setdayDefinitionsOk} employerId={employer.id} />
              </SuspensePaper>
            </Grid>
          )}
          {employer.hasCalendarManagement && (
            <Grid item md={6} lg={4}>
              <SuspensePaper sx={{ border: 0 }}>
                <EmployersWidgetWorkschedules setTabComplete={setworkSchedulesOk} employerId={employer.id} />
              </SuspensePaper>
            </Grid>
          )}
          {getFeatureFlags?.features.smartPayComponents && employer.hasSmartPayComponents && (
            <Grid item md={6} lg={4}>
              <SuspensePaper sx={{ border: 0 }}>
                <EmployersWidgetSmartPayComponents employerId={employer.id} />
              </SuspensePaper>
            </Grid>
          )}
          {employer.hasCalendarManagement && (
            <Grid item md={6} lg={4}>
              <SuspensePaper sx={{ border: 0 }}>
                <EmployersWidgetCollectivecalendars
                  setTabComplete={setcollectiveCalendarsOk}
                  employerId={employer.id}
                />
              </SuspensePaper>
            </Grid>
          )}
          <Grid item md={6} lg={4}>
            <SuspensePaper sx={{ border: 0 }}>
              <EmployersWidgetPaygroups setTabComplete={setpayGroupsOk} employerId={employer.id} />
            </SuspensePaper>
          </Grid>
          {employer.hasSelfService && (
            <Grid item md={6} lg={4}>
              <SuspensePaper sx={{ border: 0 }}>
                <EmployersWidgetRequestpolicies setTabComplete={setrequestPoliciesOk} employerId={employer.id} />
              </SuspensePaper>
            </Grid>
          )}
          <Grid item md={6} lg={4}>
            <SuspensePaper sx={{ border: 0 }}>
              <EmployersWidgetBranchunits setTabComplete={setbranchUnitsOk} employerId={employer.id} />
            </SuspensePaper>
          </Grid>
          {employer.hasDocumentManagement && (
            <Grid item md={6} lg={4}>
              <SuspensePaper sx={{ border: 0 }}>
                <EmployersWidgetDocuments employerId={employer.id} />
              </SuspensePaper>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  )
}

export default EmployerOverview

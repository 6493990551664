import {
  DEFAULT_LOCALE,
  OrderDirection,
  useGetAvailablePayrollClosureProcessContractsQuery,
  useGetContractsBehindCurrentProcessPeriodQuery
} from '@epix-web-apps/core'
import { GridColDef, GridRowsProp, GridSelectionModel, GridRenderCellParams } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { DataTable, ISortModel, SortModel } from '../../../data-table'
import DataTableEmpty from '../../../data-table/data-table-empty'
import { StepProps } from '../../generic-steps'
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Box, Link, Typography, useTheme } from '@mui/material'
import { FLYIN_WIDTH, People, useFlyIn } from '@epix-web-apps/ui'
import { TypographyBold } from '@epix-web-apps/ui'
import { useNavigate } from 'react-router-dom'

export function Population({ processId }: StepProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const theme = useTheme()
  const navigate = useNavigate()
  const [rowCount, setRowCount] = useState(0)
  const [populationBehindCount, setPopulationBehindCount] = useState(0)

  const validationRowData = (rowParams: GridRenderCellParams) => {
    const errors = rowParams.value.errors
    const warnings = rowParams.value.warnings

    if (errors === 0 && warnings === 0) {
      return (
        <Typography color={theme.palette.success.main}>
          <CheckIcon />
        </Typography>
      )
    } else {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {warnings > 0 && (
            <Link onClick={() => navigate(People.PEOPLE_ID_VALIDATION_ERRORS(`${rowParams.row.personId}`))}>
              <Typography color={theme.palette.warning.light} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <WarningAmberOutlinedIcon sx={{ fontSize: 18 }} />
                {warnings}
              </Typography>
            </Link>
          )}
          {errors > 0 && (
            <Link onClick={() => navigate(People.PEOPLE_ID_VALIDATION_ERRORS(`${rowParams.row.personId}`))}>
              <Typography color={theme.palette.error.light} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <ErrorOutlineOutlinedIcon sx={{ fontSize: 18 }} />
                {errors}
              </Typography>
            </Link>
          )}
        </Box>
      )
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: t('processpage.populationstep.datatable.column.firstname'),
      flex: 1
    },
    {
      field: 'lastName',
      headerName: t('processpage.populationstep.datatable.column.lastname'),
      flex: 1
    },
    {
      field: 'startDate',
      headerName: t('processpage.populationstep.datatable.column.contractstartdate'),
      flex: 1
    },
    {
      field: 'endDate',
      headerName: t('processpage.populationstep.datatable.column.contractenddate'),
      flex: 1
    },
    {
      field: 'currentPayPeriod',
      headerName: t('processpage.populationstep.datatable.column.currentpayrollperiod'),
      flex: 1
    },
    {
      field: 'validations',
      headerName: t('processpage.populationstep.datatable.column.validations'),
      renderCell: validationRowData,
      sortable: false
    }
  ]

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns?.[1]?.field, OrderDirection.Asc))

  const { data: getAvailableProcessContracts, isLoading } = useGetAvailablePayrollClosureProcessContractsQuery({
    id: processId,
    offset: 0,
    limit: -1,
    orderDirection: sortModel.orderDirection,
    sortByProperty: sortModel.field
  })

  const { data: getContractsBehindCurrentPeriod } = useGetContractsBehindCurrentProcessPeriodQuery({
    id: processId
  })

  const [rowSelectionModel, setRowSelectionModel] = useState<GridSelectionModel>([])
  useEffect(() => {
    if (getAvailableProcessContracts && getAvailableProcessContracts?.availablePayrollClosureProcessContracts) {
      const contractsData = getAvailableProcessContracts.availablePayrollClosureProcessContracts
      setTableData(
        contractsData.data.map(row => {
          return {
            id: row.contractId,
            personId: row.personId,
            firstName: row.firstName,
            lastName: row.lastName,
            startDate: new Date(row.contractStartDate).toLocaleDateString(DEFAULT_LOCALE),
            endDate: row.contractEndDate ? new Date(row.contractEndDate).toLocaleDateString(DEFAULT_LOCALE) : null,
            currentPayPeriod: row.currentPayrollPeriod
              ? new Date(row.currentPayrollPeriod).toLocaleDateString(DEFAULT_LOCALE)
              : null,
            validations: {
              errors: row.validationErrorCount,
              warnings: row.validationWarningCount
            }
          }
        })
      )
      setRowCount(contractsData.totalCount)
    }
    const selection =
      getValues('contractIds') ||
      getAvailableProcessContracts?.availablePayrollClosureProcessContracts.data.map(x => x.contractId) ||
      []
    setRowSelectionModel(selection)
    setValue('contractIds', selection)
  }, [getAvailableProcessContracts])

  useEffect(() => {
    if (getContractsBehindCurrentPeriod && getContractsBehindCurrentPeriod?.contractsBehindCurrentProcessPeriod) {
      const contractsBehindData = getContractsBehindCurrentPeriod.contractsBehindCurrentProcessPeriod
      setTableDataPopulationBehind(
        contractsBehindData.map(row => {
          return {
            id: row.contractId,
            name: row.name,
            contractStartDate: row.contractStartDate
              ? new Date(row.contractStartDate).toLocaleDateString(DEFAULT_LOCALE)
              : null,
            currentPayrollPeriod: row.currentPayrollPeriod
              ? new Date(row.currentPayrollPeriod).toLocaleDateString(DEFAULT_LOCALE)
              : null
          }
        })
      )

      setPopulationBehindCount(contractsBehindData.length)
    }
  }, [getContractsBehindCurrentPeriod])

  const {
    setValue,
    getValues,
    formState: { errors }
  } = useFormContext()

  const [tableData, setTableData] = useState<GridRowsProp>([])

  const filterBar = (
    <TypographyBold
      color={
        rowSelectionModel?.length !== getAvailableProcessContracts?.availablePayrollClosureProcessContracts.totalCount
          ? 'error.light'
          : 'primary'
      }
    >
      {`${rowSelectionModel?.length || 0}/${
        getAvailableProcessContracts?.availablePayrollClosureProcessContracts.totalCount
      }`}{' '}
      {t('processpage.population.contractsselected')}
    </TypographyBold>
  )

  const populationBehindColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('processpage.peoplebehind.datatable.column.name'),
      flex: 2,
      sortable: false
    },
    {
      field: 'contractStartDate',
      headerName: t('processpage.peoplebehind.datatable.column.contractstartdate'),
      flex: 1,
      sortable: false
    },
    {
      field: 'currentPayrollPeriod',
      headerName: t('processpage.peoplebehind.datatable.column.currentpayrollperiod'),
      flex: 1,
      sortable: false
    }
  ]

  const [sortModelPeopleBehind, setSortModelPeopleBehind] = useState<ISortModel>(
    new SortModel(populationBehindColumns?.[2]?.field, OrderDirection.Asc)
  )

  const [tableDataPopulationBehind, setTableDataPopulationBehind] = useState<GridRowsProp>([])

  const PopulationBehind = () => (
    <Box sx={{ width: FLYIN_WIDTH.LARGE }}>
      <Typography variant="h4">{t('processpage.peoplebehind.title')}</Typography>
      <DataTable
        autoHeight
        columns={populationBehindColumns}
        data={tableDataPopulationBehind}
        isLoading={false}
        defaultSortModel={sortModelPeopleBehind}
        hideFooter={true}
      />
    </Box>
  )

  return (
    <>
      {populationBehindCount > 0 && (
        <Typography
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px',
            color: theme.palette.warning.light
          }}
        >
          <Trans
            i18nKey="processpage.population.peoplebehind"
            values={{ numberOfPeople: populationBehindCount }}
            components={{ bold: <span className="bold" /> }}
          />
          <Link
            color="warning.light"
            underline="always"
            sx={{ fontWeight: 'bold' }}
            onClick={() =>
              openFlyIn({
                content: <PopulationBehind />
              })
            }
          >
            <span>{t('processpage.population.showall')}</span>
          </Link>
        </Typography>
      )}
      <DataTable
        isRowSelectable={params =>
          params.row.validations.errors === 0 || getValues('contractIds').includes(params.row.id)
        }
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        onSortChange={setSortModel}
        onSelectionModelChange={selection => {
          setRowSelectionModel(selection)
          setValue('contractIds', selection)
        }}
        selectionModel={rowSelectionModel}
        isLoading={isLoading}
        defaultSortModel={sortModel}
        filterBarElement={filterBar}
        checkboxSelection={true}
        hideFooter
        emptyStateElement={
          <DataTableEmpty
            title={t('processpage.population.nocontractsfound')}
            icon={<DoDisturbAltOutlinedIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} />}
          />
        }
      />
    </>
  )
}

export default Population

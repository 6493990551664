import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  useTheme,
  TableBody,
  Tooltip,
  IconButton,
  Box,
  Divider
} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  DEFAULT_LOCALE,
  GetFirstDayOfNextYear,
  GetFirstDayOfPreviousYear,
  GetFirstDayOfYear,
  GetYearOfDate,
  ToBackendFormatedDate,
  isDateStringBeforeDate,
  useGetBalanceForRequestPolicyContractQuery,
  useGetRequestPolicyContractByIdQuery
} from '@epix-web-apps/core'
import { TypographyBold, FLYIN_WIDTH } from '@epix-web-apps/ui'
import { useState } from 'react'
import { PeriodNavigation, useGlobalStore } from '@epix-web-apps/ui'
import { addYears } from 'date-fns'

/* eslint-disable-next-line */
export interface RequestPolicyContractBalanceProps {
  requestPolicyContractId: string
  title?: string
}

export function RequestPolicyContractBalance({ requestPolicyContractId, title }: RequestPolicyContractBalanceProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { me } = useGlobalStore()
  const { data: requestPolicyContract } = useGetRequestPolicyContractByIdQuery({
    id: requestPolicyContractId
  })

  const [currentYear, setCurrentYear] = useState(
    GetFirstDayOfYear(new Date(requestPolicyContract?.requestPolicyContractById.endDate ?? new Date()))
  )

  const { data: balanceData } = useGetBalanceForRequestPolicyContractQuery({
    requestPolicyContractId: requestPolicyContractId || '',
    viewDate: ToBackendFormatedDate(currentYear)
  })

  const borderStyle = `1px solid ${theme.palette.divider}`

  const BorderTable = styled(Table)(() => ({
    border: borderStyle
  }))

  const BorderTableCell = styled(TableCell)(() => ({
    border: borderStyle
  }))

  const TypographyCell = styled(Typography)(() => ({
    fontSize: '1em'
  }))

  const SmallSecondaryTypography = styled(Typography)(() => ({
    color: theme.palette.text.secondary,
    fontSize: '0.8em'
  }))

  const startYear = GetYearOfDate(addYears(new Date(currentYear), -1), me?.locale.locale || DEFAULT_LOCALE)

  const labelHeader = () => {
    const checkYear = GetYearOfDate(
      new Date(balanceData?.balanceForRequestPolicyContract[0].date),
      me?.locale.locale || DEFAULT_LOCALE
    )

    const endYear = GetYearOfDate(new Date(currentYear), me?.locale.locale || DEFAULT_LOCALE)
    return checkYear !== endYear ? startYear + ' - ' + endYear : endYear
  }

  const checkIfNoData = () => {
    return (
      balanceData?.balanceForRequestPolicyContract.length === 2 &&
      balanceData.balanceForRequestPolicyContract[0].balance === 0
    )
  }

  return (
    <>
      <Typography variant="h4">{title}</Typography>
      <Divider sx={{ marginBottom: 3 }} />
      <PeriodNavigation
        disabledLeftButton={
          currentYear.getFullYear() <=
          new Date(requestPolicyContract?.requestPolicyContractById.startDate).getFullYear()
        }
        disabledRightButton={isDateStringBeforeDate(
          currentYear,
          requestPolicyContract?.requestPolicyContractById.endDate
        )}
        next={() => setCurrentYear(GetFirstDayOfNextYear(currentYear))}
        prev={() => setCurrentYear(GetFirstDayOfPreviousYear(currentYear))}
        currentMonth={labelHeader()}
      />
      {checkIfNoData() ? (
        <Box sx={{ width: FLYIN_WIDTH.LARGE }}>
          <Typography>{t('flyin.requestpolicybalance.nodata')}</Typography>
        </Box>
      ) : (
        <BorderTable sx={{ border: borderStyle, width: '30rem' }}>
          <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
            <TableRow>
              <BorderTableCell>
                <TypographyBold>{t('flyin.requestpolicybalance.table.column.date')}</TypographyBold>
              </BorderTableCell>
              <BorderTableCell>
                <TypographyBold>{t('flyin.requestpolicybalance.table.column.description')}</TypographyBold>
              </BorderTableCell>
              <BorderTableCell>
                <TypographyBold>{t('flyin.requestpolicybalance.table.column.action')}</TypographyBold>
              </BorderTableCell>
              <BorderTableCell>
                <TypographyBold>{t('flyin.requestpolicybalance.table.column.balance')}</TypographyBold>
              </BorderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {balanceData?.balanceForRequestPolicyContract.map((item, index) => {
              const bold =
                index === 0 ||
                index === balanceData.balanceForRequestPolicyContract.length - 1 ||
                (new Date(item.date).toLocaleDateString() === new Date().toLocaleDateString() && item.calculation === 0)
              return (
                <TableRow key={index}>
                  <BorderTableCell>
                    <TypographyCell sx={{ fontWeight: bold ? 'bold' : 'normal' }}>
                      {new Date(item.date).toLocaleDateString()}
                    </TypographyCell>
                    {item.manualEventTypeValue && (
                      <SmallSecondaryTypography>({item.manualEventTypeValue})</SmallSecondaryTypography>
                    )}
                  </BorderTableCell>
                  <BorderTableCell>
                    <TypographyCell sx={{ fontWeight: bold ? 'bold' : 'normal' }}>{item.description}</TypographyCell>
                    {item.comment && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        {item.comment?.length > 15 && (
                          <>
                            <SmallSecondaryTypography>({item.comment.substring(0, 10)}...)</SmallSecondaryTypography>
                            <Tooltip title={item.comment}>
                              <IconButton sx={{ m: 0, p: 0 }}>
                                <InfoOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}

                        {item.comment?.length <= 15 && (
                          <Typography
                            sx={{
                              color: theme.palette.text.secondary,
                              fontSize: '0.8em'
                            }}
                          >
                            ({item.comment})
                          </Typography>
                        )}
                      </Box>
                    )}
                  </BorderTableCell>
                  <BorderTableCell>
                    <TypographyCell sx={{ fontWeight: bold ? 'bold' : 'normal' }}>{item.calculation}</TypographyCell>
                  </BorderTableCell>
                  <BorderTableCell>
                    <TypographyCell sx={{ fontWeight: bold ? 'bold' : 'normal' }}>{item.balance}</TypographyCell>
                  </BorderTableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </BorderTable>
      )}
    </>
  )
}

export default RequestPolicyContractBalance

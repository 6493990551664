import styled from '@emotion/styled'
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { getNumberOfColors, useGetMeQuery, useGetTotalRecordableIncidentsQuery } from '@epix-web-apps/core'
import { Trans, useTranslation } from 'react-i18next'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useAnalyticsPersistedStore } from '../../../stores/analytics-store'
import { useGlobalPersistedStore } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface IncidentRateComponentProps {}

export function IncidentRateComponent(props: IncidentRateComponentProps) {
  const { data: me } = useGetMeQuery()
  const { t } = useTranslation()

  const { icpFilters } = useGlobalPersistedStore()
  const { dateFilter, genderFilter, functionAreaFilter, functionLevelFilter, employeeTypeFilter } =
    useAnalyticsPersistedStore()

  const { data: totalRecordableIncidentRate } = useGetTotalRecordableIncidentsQuery({
    icps: icpFilters,
    selectedDate: dateFilter,
    filterModel: {
      genderType: genderFilter,
      functionAreaType: functionAreaFilter,
      functionLevelType: functionLevelFilter,
      employeeType: employeeTypeFilter
    }
  })

  const StyledTypography = styled(Typography)(() => ({
    fontSize: '1.2em'
  }))

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          sx={{
            color: getNumberOfColors(1)
          }}
        >
          (
          {new Date(totalRecordableIncidentRate?.totalRecordableIncidentRate.from).toLocaleDateString(
            me?.me.locale.locale
          )}{' '}
          -{' '}
          {new Date(totalRecordableIncidentRate?.totalRecordableIncidentRate.to).toLocaleDateString(
            me?.me.locale.locale
          )}
          )
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1em',
          paddingTop: '1em'
        }}
      >
        <Grid container alignItems="center" spacing={2} justifyContent="center">
          <Grid item xs={3}>
            <Box
              sx={{
                gap: '0.2em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              <StyledTypography>{t('analytics.data.incidentrate.trir.title')}</StyledTypography>
              <Tooltip title={t('analytics.data.incidentrate.trir.explanation')}>
                <IconButton sx={{ m: 0, p: 0 }}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <StyledTypography>=</StyledTypography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12} sx={{ borderBottom: '1px solid black', textAlign: 'center' }}>
                <p>
                  <Trans
                    i18nKey="analytics.data.incidentrate.trir.top"
                    values={{
                      incidents: totalRecordableIncidentRate?.totalRecordableIncidentRate.totalIncidents
                    }}
                    components={{ bold: <span className="bold" /> }}
                  />
                </p>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Box
                  sx={{
                    gap: '0.2em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center'
                  }}
                >
                  <p>
                    <Trans
                      i18nKey="analytics.data.incidentrate.trir.bottom"
                      values={{
                        totalhours: totalRecordableIncidentRate?.totalRecordableIncidentRate.totalHours
                      }}
                      components={{ bold: <span className="bold" /> }}
                    />
                  </p>
                  <Tooltip title={t('analytics.data.incidentrate.totalhours.explanation')}>
                    <IconButton sx={{ m: 0, p: 0 }}>
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ textAlign: 'left' }}>
              <StyledTypography>= {totalRecordableIncidentRate?.totalRecordableIncidentRate.trir}</StyledTypography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item xs={4} textAlign={'right'}>
          <Box
            sx={{
              gap: '0.2em',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <Typography>
              {t('analytics.data.incidentrate.hourslost.title')}:{' '}
              {totalRecordableIncidentRate?.totalRecordableIncidentRate.totalHoursLost}
            </Typography>
            <Tooltip title={t('analytics.data.incidentrate.hourslost.explanation')}>
              <IconButton sx={{ m: 0, p: 0 }}>
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default IncidentRateComponent

import styled from '@emotion/styled'
import { OrderDirection, useGetAllIcpsQuery } from '@epix-web-apps/core'
import {
  ToggleButtonGroup as OriginalToggleButtonGroup,
  ToggleButton as OriginalToggleButton,
  useTheme
} from '@mui/material'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useProductivityAnalyticsPersistedStore } from '../../../stores/productivity-store'

const ToggleButtonGroup = styled(OriginalToggleButtonGroup)<any>(() => {
  return {
    backgroundColor: 'none'
  }
})

const ToggleButton = styled(OriginalToggleButton)<any>(() => {
  const theme = useTheme()
  return {
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.light} !important`,
    borderRadius: '50% !important',
    width: '2.75rem',
    margin: '0 0.5rem 0 0 !important',
    padding: '9px',
    border: '0 !important',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: theme.palette.common.white,
      backgroundColor: `${theme.palette.primary.main} !important`
    }
  }
})

export interface IcpFilterProps {
  onChange: (activeIcps: Array<string>) => void
  initialIcps?: Array<string>
}

export function IcpFilter({ onChange, initialIcps }: IcpFilterProps) {
  const productivityAnalyticsStore = useProductivityAnalyticsPersistedStore()

  const { data: getAllIcps } = useGetAllIcpsQuery(
    {
      offset: 0,
      limit: -1,
      sortByProperty: 'Code',
      orderDirection: OrderDirection.Asc
    },
    {
      suspense: false
    }
  )
  const [icps, setIcps] = React.useState<Array<string> | undefined>(initialIcps)
  const [searchParams, setSearchParams] = useSearchParams()

  const handleIcpChange = (event: React.MouseEvent<HTMLElement>, newIcps: Array<string>) => {
    setIcps(newIcps)
    onChange(newIcps)

    // people page params
    searchParams.set('page', '0')
    setSearchParams(searchParams)

    // productivity analytics filters
    productivityAnalyticsStore.setEmployerFilter(null)
  }

  if (getAllIcps?.icps.totalCount === 1) {
    return <></>
  }

  return (
    <ToggleButtonGroup value={icps} onChange={handleIcpChange} aria-label="text formatting">
      {getAllIcps?.icps.data.map((icp, index) => (
        <ToggleButton key={index} value={icp.id} aria-label={icp.code}>
          {icp.code}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

export default IcpFilter

import { OrderDirection, useGetIncidentsQuery } from '@epix-web-apps/core'
import { Box, Typography } from '@mui/material'
import { GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, ISortModel, SortModel } from '../../data-table'
import { FLYIN_WIDTH, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { useAnalyticsPersistedStore } from '../../../stores/analytics-store'

/* eslint-disable-next-line */
export interface FatalInjuriesListComponentProps {}

export function FatalInjuriesListComponent(props: FatalInjuriesListComponentProps) {
  const { t } = useTranslation()
  const [tableDateHistory, setTableDateHistory] = useState<GridRowsProp>([])

  const { icpFilters } = useGlobalPersistedStore()
  const { dateFilter, genderFilter, functionAreaFilter, functionLevelFilter, employeeTypeFilter } =
    useAnalyticsPersistedStore()

  const { data: fatalInjuries, isLoading } = useGetIncidentsQuery({
    icps: icpFilters,
    selectedDate: dateFilter,
    fatal: true,
    filterModel: {
      genderType: genderFilter,
      functionAreaType: functionAreaFilter,
      functionLevelType: functionLevelFilter,
      employeeType: employeeTypeFilter
    }
  })

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('injuryreportingpage.datatable.column.name'),
      flex: 2,
      sortable: false
    },
    {
      field: 'date',
      headerName: t('injuryreportingpage.datatable.column.date'),
      flex: 2,
      sortable: false
    }
  ]

  const [sortModel, setSortModel] = useState<ISortModel>(new SortModel(columns[0]?.field, OrderDirection.Asc))

  useEffect(() => {
    if (fatalInjuries) {
      const fatalInjuriesData = fatalInjuries.incidents.injuryDetailReportingModels
      setTableDateHistory(
        fatalInjuriesData.map((row, index) => {
          return {
            id: index,
            name: row.name,
            date: new Date(row.date).toLocaleDateString()
          }
        })
      )
    }
  }, [fatalInjuries])

  return (
    <Box sx={{ width: FLYIN_WIDTH.DEFAULT }}>
      <Typography variant="h4">{t('flyin.reporting.fatalinjuries')}</Typography>
      <DataTable
        autoHeight
        data={tableDateHistory}
        columns={columns}
        onSortChange={setSortModel}
        isLoading={isLoading}
        defaultSortModel={sortModel}
        hideFooter={true}
      />
    </Box>
  )
}

export default FatalInjuriesListComponent

import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { DEFAULT_CURRENCY, createEnhancedJSONStorage } from '@epix-web-apps/core'
import { endOfMonth, startOfYear } from 'date-fns'
import { DEFAULT_SUBGROUP_TYPE_SELECTED } from '../components/labour-cost-analytics-components/filter-labour-cost-flyin/filter-labour-cost-flyin'

interface ILabourCostAnalyticsPersistedStore {
  dateFromFilter: Date
  setDateFromFilter: (date: Date) => void
  dateToFilter: Date
  setDateToFilter: (date: Date) => void
  currencyToFilter: string | null
  setCurrencyToFilter: (currency: string | null) => void
  genderFilter: string | null
  setGenderFilter: (gender: string | null) => void
  functionAreaFilter: string | null
  setFunctionAreaFilter: (functionArea: string | null) => void
  functionLevelFilter: string | null
  setFunctionLevelFilter: (functionLevel: string | null) => void
  employerFilter: string | null
  setEmployerFilter: (employer: string | null) => void
  employeeTypeFilter: string | null
  setEmployeeTypeFilter: (employeeType: string | null) => void
  subGroupTypesFilter: string[]
  setSubGroupTypesFilter: (subGroupTypes: string[]) => void
}

export const useLabourCostAnalyticsPersistedStore = create<ILabourCostAnalyticsPersistedStore>()(
  persist(
    (set, get) => ({
      dateFromFilter: startOfYear(new Date()),
      setDateFromFilter: async (date: Date) => {
        set({ dateFromFilter: date })
      },
      dateToFilter: endOfMonth(new Date()),
      setDateToFilter: async (date: Date) => {
        set({ dateToFilter: date })
      },
      currencyToFilter: DEFAULT_CURRENCY,
      setCurrencyToFilter: async (currency: string | null) => {
        set({ currencyToFilter: currency })
      },
      genderFilter: null,
      setGenderFilter: async (gender: string | null) => {
        set({ genderFilter: gender })
      },
      functionAreaFilter: null,
      setFunctionAreaFilter: async (functionArea: string | null) => {
        set({ functionAreaFilter: functionArea })
      },
      functionLevelFilter: null,
      setFunctionLevelFilter: async (functionLevel: string | null) => {
        set({ functionLevelFilter: functionLevel })
      },
      employerFilter: null,
      setEmployerFilter: async (employer: string | null) => {
        set({ employerFilter: employer })
      },
      employeeTypeFilter: null,
      setEmployeeTypeFilter: async (employeeType: string | null) => {
        set({ employeeTypeFilter: employeeType })
      },
      subGroupTypesFilter: DEFAULT_SUBGROUP_TYPE_SELECTED,
      setSubGroupTypesFilter: async (groupTypes: string[]) => {
        set({ subGroupTypesFilter: groupTypes })
      }
    }),
    {
      name: 'labourCostAnalyticsPersistedStore',
      storage: createEnhancedJSONStorage(() => localStorage)
    }
  )
)

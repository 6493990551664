import { Typography } from '@mui/material'
import { FormGridLayout } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CompanyCarDetailPageParams } from '../../../../pages/company-car-detail-page/company-car-detail-page'
import { FormActionButtons, FormContainer, FormSelect, useFlyIn, useGlobalStore } from '@epix-web-apps/ui'
import { TypeOf, boolean, object, string } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import {
  formTypeSelectOptions,
  useGetCompanyCarByIdQuery,
  useUpdateCompanyCarUsageInformationMutation
} from '@epix-web-apps/core'
import { FormSwitch } from '../../../form-components/form-switch'

/* eslint-disable-next-line */
export interface EditCompanyCarUsageInformationProps {}

export function EditCompanyCarUsageInformation(props: EditCompanyCarUsageInformationProps) {
  const { t } = useTranslation()
  const params = useParams<CompanyCarDetailPageParams>()
  const { closeFlyIn } = useFlyIn()

  const editCompanyCarUsageInformationSchema = object({
    carUsageKey: string().optional().nullable(),
    hasChargingStation: boolean()
  })

  type EditCompanyCarUsageInformationForm = TypeOf<typeof editCompanyCarUsageInformationSchema>

  const { data: getCompanyCarById, refetch: refetchCompanyCarById } = useGetCompanyCarByIdQuery(
    {
      companyCarId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )
  const car = getCompanyCarById?.companyCarById

  const { carUsageTypeOptions } = formTypeSelectOptions

  const mutation = useUpdateCompanyCarUsageInformationMutation()

  const form = useForm<EditCompanyCarUsageInformationForm>({
    resolver: zodResolver(editCompanyCarUsageInformationSchema),
    defaultValues: {
      carUsageKey: car?.carUsageType?.key,
      hasChargingStation: car?.hasChargingStation ?? false
    }
  })

  const { control, handleSubmit } = form

  const handleOnSubmit = async (usageInformation: EditCompanyCarUsageInformationForm) => {
    await mutation
      .mutateAsync({
        updateCompanyCarUsageInformationCommand: {
          id: params.id || '',
          carUsageTypeKey: usageInformation.carUsageKey && usageInformation.carUsageKey,
          hasChargingStation: usageInformation.hasChargingStation
        }
      })
      .then(() => {
        refetchCompanyCarById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editcompanycarusageinformation.title')}</Typography>

      <FormGridLayout>
        <FormSelect sx={12} name="carUsageKey" label={t('form.field.carusagekey')} options={carUsageTypeOptions} />

        <FormSwitch sx={12} name="hasChargingStation" label={t('form.field.haschargingstation')} />
      </FormGridLayout>

      <FormActionButtons isMutating={mutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditCompanyCarUsageInformation

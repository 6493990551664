export const parseGuid = (inputString: string | undefined): string => {
  if (!inputString) return ''
  return inputString.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5')
}

export function capitalise(input: string) {
  return input.charAt(0).toUpperCase() + input.slice(1)
}

export function getSearchParamNumber(key: string, searchParams: URLSearchParams) {
  const param = Number.isNaN(searchParams.get(key)) ? '0' : searchParams.get(key) ?? '0'
  return parseInt(param)
}

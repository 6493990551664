export function groupBy<T, R extends string>(array: T[], groupingFn: (item: T) => R) {
  const result = array.reduce((prev, curr) => {
    const prop = groupingFn(curr)
    return {
      ...prev,
      [prop]: prev[prop] ? [...prev[prop], curr] : [curr],
    }
  }, {} as { [x in R]: T[] })

  return result
}

export function isLastEntryById(array: any[] | undefined, id: string | undefined) {
  if (!array || !id) {
    return false
  }

  if (array.length === 0) {
    return false
  }

  return array[array.length - 1]?.id === id
}

import {
  useGetPayrollClosureProcessQuery,
  useGetSelectedPayrollClosureProcessContractsQuery
} from '@epix-web-apps/core'
import { Box, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { StepParams } from '../../generic-steps'

/* eslint-disable-next-line */
export interface FinishPayrollClosureOverviewProps {
  contractsWithErrors: number
}

export function FinishPayrollClosureOverview({ contractsWithErrors }: FinishPayrollClosureOverviewProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useParams<StepParams>()

  const { data: getPayrollClosureProcess } = useGetPayrollClosureProcessQuery(
    {
      id: params?.id || ''
    },
    {
      enabled: !!params?.id
    }
  )

  const { data: getSelectedPayrollClosureProcessContracts } = useGetSelectedPayrollClosureProcessContractsQuery(
    {
      id: params?.id || ''
    },
    {
      enabled: !!params?.id
    }
  )

  return (
    <>
      <Typography variant="h3">{t('processpage.finishoverview.title')}</Typography>
      <Box sx={{ mb: 3 }}>
        <Typography>
          {t('form.field.payrollprovider')}: {getPayrollClosureProcess?.payrollClosureProcess.payrollProviderName} (
          {getPayrollClosureProcess?.payrollClosureProcess.payrollProviderCode})
        </Typography>
        <Typography>
          {t('form.field.employer')}: {getPayrollClosureProcess?.payrollClosureProcess.employerName} (
          {getPayrollClosureProcess?.payrollClosureProcess.employerNumber})
        </Typography>
        <Typography>
          {t('form.field.paygroup')}: {getPayrollClosureProcess?.payrollClosureProcess.payGroup.name} (
          {getPayrollClosureProcess?.payrollClosureProcess.payGroup.code})
        </Typography>
        <Typography>
          {t('form.field.type')}: {getPayrollClosureProcess?.payrollClosureProcess.parameterType?.value}
        </Typography>
        {getPayrollClosureProcess?.payrollClosureProcess.startDate && (
          <Typography>
            {t('form.field.period')}:{' '}
            {`${getPayrollClosureProcess?.payrollClosureProcess.startDate} - ${getPayrollClosureProcess?.payrollClosureProcess.endDate}`}
          </Typography>
        )}
      </Box>

      {getPayrollClosureProcess?.payrollClosureProcess.isTestRun && (
        <Typography color={theme.palette.warning.light} sx={{ mb: 1 }}>
          {t('processpage.finishoverview.istestrun')}
        </Typography>
      )}

      <Typography>
        {t('processpage.finishoverview.numberofcontracts')}:{' '}
        {getSelectedPayrollClosureProcessContracts?.selectedPayrollClosureProcessContracts.length}
      </Typography>
      {contractsWithErrors > 0 && !getPayrollClosureProcess?.payrollClosureProcess?.completed && (
        <Typography color="error" variant="subtitle2" sx={{ mb: 1 }}>
          {contractsWithErrors} {t('processpage.finishoverview.contractvalidations')}
        </Typography>
      )}
    </>
  )
}

export default FinishPayrollClosureOverview

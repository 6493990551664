import {
  FormSelectOption,
  OrderDirection,
  PeopleFilterSearchProperty,
  useGetAllEmployersByIcpIdsQuery,
  useGetAllPayGroupsByEmployerIdQuery
} from '@epix-web-apps/core'
import {
  Box,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FilterActionButtons } from '../../filter-components/filter-action-buttons'
import { PeopleFilters } from '../../../pages/people-page/people-page'
import { useState } from 'react'
import { FLYIN_WIDTH, FilterSelectBox, useFlyIn, useGlobalPersistedStore } from '@epix-web-apps/ui'

interface FilterPeopleFlyInProps {
  filters: PeopleFilters
  updateFilters: (value: PeopleFilters) => void
}

export function FilterPeopleFlyIn({ filters: initialFilters, updateFilters }: FilterPeopleFlyInProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { closeFlyIn } = useFlyIn()
  const { icpFilters } = useGlobalPersistedStore()

  const [filters, setFilters] = useState(initialFilters)

  const { data: employers } = useGetAllEmployersByIcpIdsQuery({ icps: icpFilters, active: true })
  const filteredEmployers =
    employers?.employersByIcpIds
      // sort on numbers then alphabetically
      .sort((a, b) => (b.number > a.number ? -1 : 1))
      .map(e => new FormSelectOption(e.id, `${e.companyName} (${e.number})`)) ?? []

  const { data: paygroupData } = useGetAllPayGroupsByEmployerIdQuery(
    { employerId: filters.employer!, orderDirection: OrderDirection.Asc },
    { enabled: !!filters.employer, suspense: false }
  )
  const paygroupOptions =
    paygroupData?.allPayGroupsByEmployerId.map(p => new FormSelectOption(p.id, `${p.name} (${p.code})`)) ?? []

  function resetFilters() {
    updateFilters({
      searchProperty: PeopleFilterSearchProperty.PersonNumber,
      searchString: null,
      employer: null,
      paygroup: null,
      showInactive: false,
      showWithoutContract: false,
      showValidations: false
    })
    closeFlyIn()
  }

  return (
    <Box width={FLYIN_WIDTH.DEFAULT}>
      <Typography color={theme.palette.primary.main} variant="h4">
        {t('peoplepage.filters.title')}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ pb: 2 }}>
          <Typography>{t('peoplepage.filters.searchon')}</Typography>
          <RadioGroup
            sx={{ pb: 1, pl: 2 }}
            onChange={(_, value) => setFilters({ ...filters, searchProperty: value as PeopleFilterSearchProperty })}
            value={filters.searchProperty}
          >
            <FormControlLabel
              value={PeopleFilterSearchProperty.PersonNumber}
              label={t('peoplepage.filters.personnumber')}
              control={<Radio />}
            />
            <FormControlLabel
              value={PeopleFilterSearchProperty.EmployeeNumber}
              label={t('peoplepage.filters.employeenumber')}
              control={<Radio />}
            />
          </RadioGroup>
          <TextField
            id={'searchValue'}
            label={t('peoplepage.filters.number')}
            size="small"
            fullWidth
            value={filters.searchString ?? ''}
            onChange={e => setFilters({ ...filters, searchString: e.currentTarget.value })}
          />
        </Box>

        <Divider sx={{ mb: 2 }} />

        <FilterSelectBox
          id={'employer'}
          label={t('peoplepage.filters.employer')}
          keys={filteredEmployers}
          value={filters.employer}
          handleChange={(e: string) => setFilters({ ...filters, employer: e })}
        />

        {!!filters.employer && (
          <FilterSelectBox
            id={'paygroup'}
            label={t('peoplepage.filters.paygroup')}
            keys={paygroupOptions ?? []}
            value={filters.paygroup}
            handleChange={(e: string) => setFilters({ ...filters, paygroup: e })}
          />
        )}

        <Divider sx={{ mb: 2 }} />

        <Typography>{t('peoplepage.filters.showonly')}</Typography>
        <Box sx={{ pb: 2, pl: 2 }}>
          <FormControlLabel
            onChange={(_e, checked) => setFilters({ ...filters, showInactive: checked })}
            label={t('peoplepage.filters.inactive')}
            checked={filters.showInactive}
            aria-label={t('peoplepage.filters.inactive')}
            control={<Switch />}
          />
          <FormControlLabel
            onChange={(_e, checked) => setFilters({ ...filters, showWithoutContract: checked })}
            label={t('peoplepage.filters.withoutcontract')}
            checked={filters.showWithoutContract}
            aria-label={t('peoplepage.filters.withoutcontract')}
            control={<Switch />}
          />
          <FormControlLabel
            onChange={(_e, checked) => setFilters({ ...filters, showValidations: checked })}
            label={t('peoplepage.filters.validations')}
            checked={filters.showValidations}
            aria-label={t('peoplepage.filters.validations')}
            control={<Switch />}
          />
        </Box>
      </Box>

      <FilterActionButtons
        onFilterClick={() => {
          updateFilters(filters)
          closeFlyIn()
        }}
        onCancelClick={() => closeFlyIn()}
        onResetClick={() => resetFilters()}
      />
    </Box>
  )
}

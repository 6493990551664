import BarchartComponent from '../../../../../analytics-components/barchart-component'

/* eslint-disable-next-line */
export interface CompanyCarsAverageCo2ComponentProps {
  color: string
  data: any
  showZeroScale: boolean
}

export function CompanyCarsAverageCo2Component({ color, data, showZeroScale }: CompanyCarsAverageCo2ComponentProps) {
  const co2Data: number[] = data?.map((b: any) => b.AverageCO2)

  return (
    <BarchartComponent
      data={data}
      keys={['AverageCO2']}
      indexBy={'monthAndYear'}
      layoutMode={'vertical'}
      groupMode={'stacked'}
      innerPadding={0}
      marginRight={50}
      marginLeft={50}
      colors={[color]}
      showLegend={false}
      minValueScaleOn={showZeroScale ? undefined : co2Data}
    />
  )
}

export default CompanyCarsAverageCo2Component

import { Box, IconButton, CircularProgress } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'

/* eslint-disable-next-line */
export interface DownloadButtonProps {
  disabled?: boolean
  isFetching: boolean
  onClick: () => void
}

export function DownloadButton({ disabled = false, isFetching, onClick }: DownloadButtonProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {isFetching ? (
        <CircularProgress color="inherit" size={30} />
      ) : (
        <IconButton disabled={disabled} onClick={onClick}>
          <DownloadIcon />
        </IconButton>
      )}
    </Box>
  )
}

export default DownloadButton

export * from './add-person'
export * from './edit-person-bank-information'
export * from './edit-person-civil-state'
export * from './edit-person-company-info'
export * from './edit-person-contact-info'
export * from './edit-person-family-relations'
export * from './edit-person-identification'
export * from './edit-person-official-address'
export * from './edit-person-residence-address'
export * from './search-person'

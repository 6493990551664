import { useTranslation } from 'react-i18next'
import { EVENT_CAR, useGetDriversEndingSoonQuery } from '@epix-web-apps/core'
import { Grid } from '@mui/material'
import { HideableSuspensePaper } from '../../suspense-paper'
import { People, useGlobalPersistedStore } from '@epix-web-apps/ui'
import HomepageEventWidget from '../homepage-event-widget'
import { useNavigate } from 'react-router-dom'

/* eslint-disable-next-line */
export interface DriversEndingSoonComponentProps {}

export function DriversEndingSoonComponent(props: DriversEndingSoonComponentProps) {
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const navigate = useNavigate()
  const { data: driversEndingSoon, isLoading } = useGetDriversEndingSoonQuery(
    {
      days: 90,
      icps: icpFilters
    },
    {
      suspense: false
    }
  )

  if (driversEndingSoon?.driversEndingSoon && driversEndingSoon.driversEndingSoon.length === 0) return null

  return (
    <Grid item sm={12} lg={6}>
      <HideableSuspensePaper isLoading={isLoading} title={t('homepage.event.driversendingsoon')}>
        <HomepageEventWidget
          onClick={(personId, contractId) => navigate(People.PEOPLE_ID_CONTRACTS_ID(personId, contractId))}
          data={driversEndingSoon?.driversEndingSoon}
          length={driversEndingSoon?.driversEndingSoon.length || 0}
          dataType={EVENT_CAR}
        />
      </HideableSuspensePaper>
    </Grid>
  )
}

export default DriversEndingSoonComponent

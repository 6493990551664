import { Box, Link, Typography, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { OrderDirection, useGetAllValidationErrorsByPersonIdForCurrentUserQuery } from '@epix-web-apps/core'
import { useNavigate } from 'react-router-dom'
import { SelfService } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface TasksWarningProps {
  basicView?: boolean
}

export function TasksWarning({ basicView = false }: TasksWarningProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { data: validations } = useGetAllValidationErrorsByPersonIdForCurrentUserQuery({
    showHasSeen: true,
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc
  })

  if (validations && validations.allValidationErrorsByPersonIdForCurrentUser.totalCount > 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: 2
        }}
      >
        <WarningAmberOutlinedIcon
          sx={{ color: theme.palette.warning.light }}
          fontSize={basicView ? 'small' : 'medium'}
        />
        <Link
          color={theme.palette.warning.light}
          onClick={() => {
            navigate(SelfService.PENDING_TASKS())
          }}
        >
          {basicView ? (
            <Typography
              sx={{
                fontSize: '1em'
              }}
            >
              {t('selfservice.tasks-warning.pending')}
            </Typography>
          ) : (
            <Trans
              i18nKey="selfservice.tasks-warning.pending-number"
              values={{ numberofvalidations: validations?.allValidationErrorsByPersonIdForCurrentUser.totalCount }}
            />
          )}
        </Link>
      </Box>
    )
  } else {
    return null
  }
}

export default TasksWarning

import {
  formTypeSelectOptions,
  useGetPersonByIdQuery,
  useUpdatePersonPersonalInformationMutation
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { object, string, TypeOf } from 'zod'
import { DetailPageBaseQueryParams, FormGridLayout } from '@epix-web-apps/ui'
import { FormActionButtons,  } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { FormSelect } from '@epix-web-apps/ui'

export function EditPersonPersonalInformation() {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const { educationLevelOptions } = formTypeSelectOptions
  const editPersonPersonalInfoSchema = object({
    educationLevelTypeKey: string()
  })
  type EditPersonPersonalInfoForm = TypeOf<typeof editPersonPersonalInfoSchema>

  const params = useParams<DetailPageBaseQueryParams>()

  const { data: getPersonById, refetch: refetchPersonById } = useGetPersonByIdQuery(
    {
      personId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )

  const mutation = useUpdatePersonPersonalInformationMutation()
  const form = useForm<EditPersonPersonalInfoForm>({
    resolver: zodResolver(editPersonPersonalInfoSchema),
    defaultValues: {
      educationLevelTypeKey: getPersonById?.personById?.educationLevelType?.key
    }
  })

  const { handleSubmit } = form

  const handleOnSubmit = async (personPersonalInfo: EditPersonPersonalInfoForm) => {
    await mutation
      .mutateAsync({
        updatePersonPersonalInformation: {
          personId: params.id || '',
          educationLevelTypeKey: personPersonalInfo.educationLevelTypeKey
        }
      })
      .then(() => {
        refetchPersonById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editperson.personalinformation.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="educationLevelTypeKey"
          label={t('form.field.educationlevel')}
          options={educationLevelOptions}
        />
      </FormGridLayout>

      <FormActionButtons isMutating={mutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditPersonPersonalInformation

import { Trans, useTranslation } from 'react-i18next'
import { useFlyIn } from '@epix-web-apps/ui'
import { DetailBox, DetailBoxRow } from '@epix-web-apps/ui'
import { IconButton } from '@mui/material'
import EditCompanyCarCarFinancialInformation from '../../edit-company-car-car-financial-information'
import EditIcon from '@mui/icons-material/Edit'
import { CompanyCarDetailModel } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface CompanyCarCarFinancialInformationProps {
  car?: CompanyCarDetailModel
  currencySymbol: string | undefined
  preferredCurrencySymbol: string | undefined
}

export function CompanyCarCarFinancialInformation({
  car,
  currencySymbol,
  preferredCurrencySymbol
}: CompanyCarCarFinancialInformationProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()

  return (
    <DetailBox
      title={t('companycardetailpage.carfinancialinformation.title')}
      actionIcon={
        <IconButton
          size="small"
          onClick={() =>
            openFlyIn({
              content: <EditCompanyCarCarFinancialInformation />
            })
          }
        >
          <EditIcon />
        </IconButton>
      }
    >
      {(car?.totalCarCost || car?.totalCarCost === 0) && (
        <DetailBoxRow>
          <p>
            <Trans
              i18nKey="companycardetailpage.totalcarcost"
              values={{
                totalcarcost: car?.totalCarCost,
                currency: currencySymbol
              }}
              components={{ bold: <span className="bold" /> }}
            />
            {currencySymbol !== preferredCurrencySymbol && (
              <Trans
                i18nKey="companycardetailpage.totalcarcostrate"
                values={{
                  totalcarcostrate: car?.totalCarCostRate,
                  preferredcurrency: preferredCurrencySymbol
                }}
              />
            )}
            {t('companycardetailpage.exclvat')}
          </p>
        </DetailBoxRow>
      )}
      {(car?.vat || car?.vat === 0) && (
        <DetailBoxRow>
          <p>
            <Trans
              i18nKey="companycardetailpage.vat"
              values={{ vat: car?.vat, currency: currencySymbol }}
              components={{ bold: <span className="bold" /> }}
            />
            {currencySymbol !== preferredCurrencySymbol && (
              <Trans
                i18nKey="companycardetailpage.vatrate"
                values={{
                  vatrate: car?.vatRate,
                  preferredcurrency: preferredCurrencySymbol
                }}
              />
            )}
          </p>
        </DetailBoxRow>
      )}
      {(car?.catalogueValue || car?.catalogueValue === 0) && (
        <DetailBoxRow>
          <p>
            <Trans
              i18nKey="companycardetailpage.cataloguevalue"
              values={{
                cataloguevalue: car?.catalogueValue,
                currency: currencySymbol
              }}
              components={{ bold: <span className="bold" /> }}
            />
            {currencySymbol !== preferredCurrencySymbol && (
              <Trans
                i18nKey="companycardetailpage.cataloguevaluerate"
                values={{
                  cataloguevaluerate: car?.catalogueValueRate,
                  preferredcurrency: preferredCurrencySymbol
                }}
              />
            )}
          </p>
        </DetailBoxRow>
      )}
      {(car?.options || car?.options === 0) && (
        <>
          <DetailBoxRow>
            <p>
              <Trans
                i18nKey="companycardetailpage.options"
                values={{ options: car?.options, currency: currencySymbol }}
                components={{ bold: <span className="bold" /> }}
              />
              {currencySymbol !== preferredCurrencySymbol && (
                <Trans
                  i18nKey="companycardetailpage.optionsrate"
                  values={{
                    optionsrate: car?.optionsRate,
                    preferredcurrency: preferredCurrencySymbol
                  }}
                />
              )}
              {t('companycardetailpage.exclvat')}
            </p>
          </DetailBoxRow>
          <span>&nbsp;</span>
        </>
      )}
      {car?.hasTaxCalculation && (
        <DetailBoxRow>
          <p>
            <Trans
              i18nKey="companycardetailpage.hastaxcalculation"
              values={{ hastaxcalculation: car?.hasTaxCalculation }}
              components={{ bold: <span className="bold" /> }}
            />
          </p>
        </DetailBoxRow>
      )}
    </DetailBox>
  )
}

export default CompanyCarCarFinancialInformation

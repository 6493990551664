import { EVENT_LEAVERS, useGetUpcomingLeaversQuery } from '@epix-web-apps/core'
import { People, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { Grid } from '@mui/material'
import { HideableSuspensePaper } from '../../suspense-paper'
import { useTranslation } from 'react-i18next'
import HomepageEventWidget from '../homepage-event-widget'
import { useNavigate } from 'react-router-dom'

/* eslint-disable-next-line */
export interface UpcomingLeaversComponentProps {}

export function UpcomingLeaversComponent(props: UpcomingLeaversComponentProps) {
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const navigate = useNavigate()
  const { data: dataUpcomingLeavers, isLoading } = useGetUpcomingLeaversQuery(
    {
      icps: icpFilters
    },
    {
      suspense: false
    }
  )
  const limitedData = dataUpcomingLeavers?.upcomingLeavers.slice(0, 4)

  if (dataUpcomingLeavers?.upcomingLeavers && dataUpcomingLeavers.upcomingLeavers.length === 0) return null

  return (
    <Grid item sm={12} lg={6}>
      <HideableSuspensePaper isLoading={isLoading} title={t('homepage.event.leavers')}>
        <HomepageEventWidget
          onClick={personId => navigate(People.PEOPLE_ID_DETAILS(`${personId}`))}
          data={limitedData}
          length={dataUpcomingLeavers?.upcomingLeavers.length || 0}
          dataType={EVENT_LEAVERS}
        />
      </HideableSuspensePaper>
    </Grid>
  )
}

export default UpcomingLeaversComponent

import { SvgIcon, Tooltip, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

export function FeatureForPurchaseIndicator() {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <Tooltip title={t('paidoption')}>
      <SvgIcon fontSize="inherit">
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill={theme.palette.secondary.main}
            stroke="none"
          >
            <path
              d="M2440 4254 c-68 -31 -114 -74 -148 -139 -22 -42 -26 -63 -27 -130 0
-81 13 -123 61 -196 l17 -26 -336 -445 c-199 -264 -357 -464 -388 -491 -175
-153 -445 -173 -635 -48 -32 21 -155 135 -273 253 l-213 215 35 46 c118 155
72 372 -98 460 -56 28 -167 35 -233 13 -69 -23 -143 -91 -173 -158 -33 -74
-33 -184 0 -250 28 -58 90 -122 139 -144 l37 -16 165 -665 c91 -366 173 -678
182 -694 39 -64 -116 -60 2010 -57 1828 3 1935 4 1965 21 18 9 40 29 49 42 9
14 91 325 183 691 l167 666 32 17 c50 26 107 86 133 139 33 66 33 176 0 250
-30 67 -104 135 -173 158 -66 22 -177 15 -233 -13 -173 -90 -218 -321 -90
-470 l34 -41 -227 -223 c-240 -237 -282 -269 -405 -304 -69 -20 -195 -22 -269
-5 -70 17 -172 69 -227 116 -25 21 -197 242 -383 491 l-338 453 29 45 c39 62
55 124 48 193 -13 154 -142 272 -297 272 -49 0 -79 -7 -120 -26z"
            />
            <path
              d="M650 1452 c-19 -9 -45 -33 -58 -52 -23 -34 -23 -38 -20 -257 l3 -223
38 -37 37 -38 1909 -3 1908 -2 34 23 c63 42 69 68 69 287 0 183 -1 199 -22
240 -14 27 -36 50 -58 62 -33 17 -115 18 -1920 18 -1775 0 -1887 -1 -1920 -18z"
            />
          </g>
        </svg>
      </SvgIcon>
    </Tooltip>
  )
}

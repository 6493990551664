import { Typography, Divider } from '@mui/material'
import PersonIdentificationComponent from '../../person-identification-component'
import { useTranslation } from 'react-i18next'
import CalendarTypeList from '../calendar-type-list'

/* eslint-disable-next-line */
export interface OverviewCalendarDayProps {
  start: Date
  contractId: string | undefined
}

export function OverviewCalendarDay({ start, contractId }: OverviewCalendarDayProps) {
  const { t } = useTranslation()

  return (
    <>
      <PersonIdentificationComponent title={t('flyin.overviewcalendar.title')} />
      <Divider sx={{ marginBottom: 3 }} />
      <Typography variant="h6" color={'gray'}>
        {t('flyin.collectiveschedule-entry.date')}: {start?.toLocaleDateString()}
      </Typography>
      <CalendarTypeList contractId={contractId} start={start || new Date()} />
    </>
  )
}

export default OverviewCalendarDay

import { preprocess, ZodTypeAny } from 'zod'
import { isValidHexColor } from './color-helpers'

export const numericString = (schema: ZodTypeAny) =>
  preprocess(value => {
    if (typeof value === 'string') {
      if (value === '') return undefined
      return parseFloat(value.replace(',', '.'))
    } else if (typeof value === 'number') {
      return value
    } else {
      return undefined
    }
  }, schema)

export const regex = (schema: ZodTypeAny) =>
  preprocess(value => {
    if (typeof value !== 'string' || !isValidHexColor(value)) {
      return undefined
    }
    return value
  }, schema)

import { useNavigate, useSearchParams } from 'react-router-dom'

export function useNavigateWithParams() {
  const navigate = useNavigate()
  const [searchParams, _] = useSearchParams()

  function navigateFunc(path: string, params?: Record<string, string>) {
    if (params) {
      for (const key in params) {
        searchParams.set(key, params[key])
      }
    }
    navigate({
      pathname: path,
      search: searchParams.toString()
    })
  }

  return navigateFunc
}

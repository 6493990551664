import {
  useCreateCollectiveScheduleMutation,
  useGetCollectiveScheduleByIdQuery,
  useGetEmployerByIdQuery,
  useUpdateCollectiveScheduleMutation
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'
import { FormGridLayout } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { FormInput } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface AddEditCollectiveScheduleProps {
  employerId?: string
  collectiveScheduleId?: string
}

export function AddEditCollectiveSchedule({ employerId, collectiveScheduleId }: AddEditCollectiveScheduleProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const AddEditCollectiveScheduleSchema = object({
    code: string({
      required_error: t('form.validation.collectiveschedulecoderequired'),
      invalid_type_error: t('form.validation.collectiveschedulecoderequired')
    }).min(1, t('form.validation.collectiveschedulecoderequired')),
    description: string({
      required_error: t('form.validation.descriptionrequired'),
      invalid_type_error: t('form.validation.descriptionrequired')
    }).min(1, t('form.validation.descriptionrequired')),
    employerId: string({
      required_error: t('form.validation.employerrequired'),
      invalid_type_error: t('form.validation.employerrequired')
    }).min(1, t('form.validation.employerrequired'))
  })

  type CreateCollectiveScheduleForm = TypeOf<typeof AddEditCollectiveScheduleSchema>

  const { data: getCollectiveScheduleById, refetch: refetchCollectiveScheduleById } = useGetCollectiveScheduleByIdQuery(
    {
      collectiveScheduleId: collectiveScheduleId || ''
    },
    {
      enabled: !!collectiveScheduleId
    }
  )

  const form = useForm<CreateCollectiveScheduleForm>({
    resolver: zodResolver(AddEditCollectiveScheduleSchema),
    defaultValues: {
      code: getCollectiveScheduleById?.collectiveSchedule.code,
      description: getCollectiveScheduleById?.collectiveSchedule.description,
      employerId: employerId
    }
  })

  const createmutation = useCreateCollectiveScheduleMutation()
  const updatemutation = useUpdateCollectiveScheduleMutation()

  const handleOnSubmit = async (newCollectiveSchedule: CreateCollectiveScheduleForm) => {
    if (!collectiveScheduleId) {
      await createmutation
        .mutateAsync({
          createCollectiveScheduleCommand: {
            code: newCollectiveSchedule.code,
            description: newCollectiveSchedule.description,
            employerId: employerId || ''
          }
        })
        .then(closeFlyIn)
    } else {
      await updatemutation
        .mutateAsync({
          updateCollectiveScheduleCommand: {
            collectiveScheduleId: collectiveScheduleId,
            code: newCollectiveSchedule.code,
            description: newCollectiveSchedule.description
          }
        })
        .then(() => {
          refetchCollectiveScheduleById()
          closeFlyIn()
        })
    }
  }

  const { data: getEmployerById } = useGetEmployerByIdQuery({
    employerId: employerId || ''
  })

  const company = getEmployerById?.employerById.companyName

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">
        {collectiveScheduleId ? t('flyin.collectiveschedule.edittitle') : t('flyin.collectiveschedule.addtitle')}
      </Typography>
      <Typography variant="h5" color={'gray'}>
        {t('flyin.collectiveschedule.subtitle')}: {company}
      </Typography>
      <FormGridLayout>
        <FormInput sx={12} name="code" label={`${t('form.field.collectiveschedulecode')}`} />

        <FormInput sx={12} name="description" label={`${t('form.field.description')}`} />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons
        isMutating={createmutation.isLoading || updatemutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditCollectiveSchedule

import { IFormSelectOption } from '@epix-web-apps/core'
import { Chip } from '@mui/material'

type FilterType = { [key: string]: string | boolean | Date | null }

export interface FilterChipProps {
  filters: FilterType
  filterLabels: IFormSelectOption[]
  updateFilters: (filters: any) => void
}

/**
 * @param filters Should only be objects of type { [key: string]: string | boolean | null }
 */
export function FilterChip({ filters, filterLabels, updateFilters }: FilterChipProps) {
  function handleFilterDelete(filter: string) {
    updateFilters({ ...filters, [filter]: null })
  }

  return (
    <>
      {Object.entries(filters).map(
        entry =>
          entry[1] && (
            <Chip
              key={entry[0]}
              size="small"
              variant="outlined"
              label={filterLabels.find(l => l.id === entry[1] || l.id === entry[0])?.label}
              onDelete={() => handleFilterDelete(entry[0])}
            />
          )
      )}
    </>
  )
}

export default FilterChip

import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, date, object, TypeOf } from 'zod'
import { FormGridLayout } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { useFlyIn } from '@epix-web-apps/ui'
import {
  GraphqlError,
  useCreateInjuryCommandMutation,
  useGetInjuryByIdQuery,
  useUpdateInjuryCommandMutation
} from '@epix-web-apps/core'
import { FormActionButtons, FormDatepicker, FormErrorList } from '@epix-web-apps/ui'
import { useParams } from 'react-router-dom'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'
import { FormContainer } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface AddEditInjuryProps {
  injuryId?: string
}

export function AddEditInjury({ injuryId }: AddEditInjuryProps) {
  const { t } = useTranslation()

  const addEditInjurySchema = object({
    injuryDate: date({
      required_error: t('form.validation.injurydaterequired'),
      invalid_type_error: t('form.validation.injurydaterequired')
    }),
    fatal: boolean()
  })

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  type AddEditInjuryForm = TypeOf<typeof addEditInjurySchema>
  const params = useParams<ContractDetailPageParams>()
  const { closeFlyIn } = useFlyIn()
  const personId = params.id || ''

  const { data: injury, refetch } = useGetInjuryByIdQuery(
    {
      injuryId: injuryId || ''
    },
    {
      enabled: !!injuryId
    }
  )

  const form = useForm<AddEditInjuryForm>({
    resolver: zodResolver(addEditInjurySchema),
    defaultValues: {
      injuryDate: new Date(injury?.injuryById?.injuryDate),
      fatal: injury?.injuryById?.fatal ?? false
    }
  })

  const createMutation = useCreateInjuryCommandMutation()
  const updateMutation = useUpdateInjuryCommandMutation()

  const handleOnSubmit = async (newInjury: AddEditInjuryForm) => {
    if (!injuryId) {
      await createMutation
        .mutateAsync({
          createInjuryCommand: {
            injuryDate: newInjury.injuryDate,
            fatal: newInjury.fatal,
            personId: personId
          }
        })
        .then(() => {
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    } else {
      await updateMutation
        .mutateAsync({
          updateInjuryCommand: {
            injuryId: injuryId,
            injuryDate: newInjury.injuryDate,
            fatal: newInjury.fatal
          }
        })
        .then(() => {
          closeFlyIn()
          refetch()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{injuryId ? t('flyin.editinjury.title') : t('flyin.addinjury.title')}</Typography>

      <FormGridLayout>
        <FormDatepicker sx={12} name="injuryDate" label={t('form.field.injury.injurydate')} />

        <FormSwitch sx={12} name="fatal" label={t('form.field.injury.fatal')} />
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={createMutation.isLoading || updateMutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditInjury
